<template>
  <main-layout>
    <template v-if="appointment && !carousel">
      <v-row
        v-if="false"
        justify="center"
      >
        <v-btn
          v-if="showAllowNotifications"
          class="mb-4"
          color="primary"
          @click="requestNotificationPermission"
        >
          {{ $_t('button_enable_notifications') }}
        </v-btn>
      </v-row>
      <v-row justify="center">
        <v-btn
          v-if="!location && false"
          class="mb-4"
          color="primary"
          @click="requestLocation"
        >
          {{ $_t('button_enable_gps') }}
        </v-btn>
      </v-row>
      <v-row
        align="center"
        justify="center"
      >
        <v-col
          cols="12"
          md="12"
          lg="8"
        >
          <v-card
            class="mx-auto"
            width="100%"
            height="100%"
            style="max-width: 600px"
            elevation="6"
            color="primary"
          >
            <v-card-text class="text-center">
              <v-row>
                <v-col>
                  <v-img
                    src="/img/logo.png"
                    max-height="60"
                    contain
                  />
                </v-col>
              </v-row>
              <v-row
                justify="center"
                align="center"
                class="text-center"
              >
                <v-col>
                  <div class="text-h4 ma-1 mt-3 text-center white--text">
                    {{ appointment.patient.firstName }}
                    {{ appointment.patient.lastName }}
                  </div>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text style="background-color: white">
              <div class="text-h5 text-center text-break">
                {{ $_t('Sorry, the online registration is no longer possible for this appointment.') }}<br/>
                {{ $_t('If you have not confirmed your appointment and wish to try again please wait for 1 hour before re-attempting.') }}
              </div>
              <v-list-item three-line v-if="false">
                <v-list-item-content>
                  <v-container>
                    <v-row>
                      <v-list
                        two-line
                        style="width: 100%"
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-cellphone
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                appointment.patient.mobileNumber1 || $_t('no_mobile_number_on_record')
                              }}
                            </v-list-item-title>
                            <v-list-item-subtitle></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-dialog
                        v-model="dialogEditPhone"
                        max-width="400"
                      >
                        <v-card>
                          <v-card-text class="">
                            <v-container>
                              <v-row class="mb-4">
                                <h4 class="subtitle-1">
                                  {{ $_t('you_can_enter_or_edit_your_mobile_number_below') }}
                                </h4>
                              </v-row>
                              <v-row>
                                <v-text-field
                                  v-model="newPhone"
                                  outlined
                                  dense
                                  :label="$_t('Mobile number')"
                                />
                              </v-row>
                              <template v-if="phoneVerification">
                                <v-row class="mb-4">
                                  <h4 class="subtitle-2">
                                    {{ $_t('five_digit_code_sent') }}
                                  </h4>
                                </v-row>
                                <v-row>
                                  <v-text-field
                                    v-model="newPhoneVerificationCode"
                                    outlined
                                    dense
                                    :label="$_t('One time code')"
                                  />
                                </v-row>
                              </template>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              @click="cancelEditPhone"
                            >
                              {{ $_t('Cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              text
                              @click="savePhone"
                            >
                              {{ $_t('Save') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                    <v-row>
                      <v-list
                        two-line
                        style="width: 100%"
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-email
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{ appointment.patient.emailAddress1 }}
                            </v-list-item-title>
                            <v-list-item-subtitle>Email address</v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-dialog v-model="patientEmailEdit">
                        <v-card>
                          <v-card-text class="">
                            <v-container>
                              <v-row class="mb-4">
                                <h4 class="subtitle-1">
                                  {{ $_t('you_can_enter_or_edit_your_email_below') }}
                                </h4>
                              </v-row>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    v-model="patientEmail"
                                    label="Email"
                                    outlined
                                  />
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              @click="cancelPatientEmailEdit"
                            >
                              {{ $_t('Cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              text
                              @click="savePatientEmail"
                            >
                              {{ $_t('Save') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                    <v-row>
                      <v-list
                        two-line
                        style="width: 100%"
                      >
                        <v-list-item>
                          <v-list-item-icon>
                            <v-icon color="primary">
                              mdi-home
                            </v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title>
                              {{
                                ([appointment.patient.address1, appointment.patient.address2, appointment.patient.address3].join(',') || 'No address on record')
                              }}
                            </v-list-item-title>
                            <v-list-item-title>
                              {{ appointment.patient.town }}
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-dialog v-model="patientAddressEdit">
                        <v-card>
                          <v-card-text class="">
                            <v-container>
                              <v-row class="mb-4">
                                <h4 class="subtitle-1">
                                  {{ $_t('you_can_enter_or_edit_your_address_below') }}
                                </h4>
                              </v-row>
                              <v-row>
                                <appointment-address-fields @input="patientAddressEdited" />
                              </v-row>
                            </v-container>
                          </v-card-text>
                          <v-card-actions>
                            <v-btn
                              text
                              @click="cancelPatientAddressEdit"
                            >
                              {{ $_t('Cancel') }}
                            </v-btn>
                            <v-spacer />
                            <v-btn
                              text
                              @click="savePatientAddress"
                            >
                              {{ $_t('Save') }}
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-row>
                    <v-row v-if="false">
                      <v-col
                        cols="6"
                        xs="12"
                        class="text-center"
                      >
                        <v-btn
                          rounded
                          outlined
                        >
                          {{ $_t('Take a quiz') }}
                        </v-btn>
                      </v-col>
                      <v-col
                        cols="6"
                        xs="12"
                        class="text-center"
                      >
                        <v-btn
                          rounded
                          outlined
                        >
                          {{ $_t('Leave feedback') }}
                        </v-btn>
                      </v-col>
                    </v-row>
                    <v-row
                      v-if="appointment.avatar && appointment.avatar !== '_blank-avatar.png'"
                      justify="center"
                    >
                      <v-img
                        max-width="150"
                        max-height="150"
                        contain
                        class="mt-5"
                        :src="appointmentAvatar"
                      />
                    </v-row>
                    <v-row
                      v-if="shouldShowArriveButton"
                      justify="center"
                      class="mt-12"
                    >
                      <v-btn
                        class="mb-4"
                        color="primary"
                        x-large
                        outlined
                        width="100%"
                        @click="arrivalDialog = true"
                      >
                        {{ $_t('button_i_have_arrived') }}
                      </v-btn>
                    </v-row>
                  </v-container>

                  <v-list v-if="false">
                    <v-list-item-group>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon v-if="!consentForm">
                            mdi-currency-eur
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $_t('payment') }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon v-if="!payment">
                            mdi-minus-box-outline
                          </v-icon>
                          <v-icon v-if="payment">
                            mdi-check-box-outline
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon v-if="!consentForm">
                            mdi-format-list-checks
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $_t('Required preparations') }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon v-if="!payment">
                            mdi-minus-box-outline
                          </v-icon>
                          <v-icon v-if="payment">
                            mdi-check-box-outline
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                      <v-list-item>
                        <v-list-item-icon>
                          <v-icon v-if="!consentForm">
                            mdi-format-list-checks
                          </v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>
                            {{ $_t('consent_form') }}
                          </v-list-item-title>
                        </v-list-item-content>
                        <v-list-item-icon>
                          <v-icon v-if="!consentForm">
                            mdi-minus-box-outline
                          </v-icon>
                          <v-icon v-if="consentForm">
                            mdi-check-box-outline
                          </v-icon>
                        </v-list-item-icon>
                      </v-list-item>
                    </v-list-item-group>
                  </v-list>
                  <v-container>
                    <v-row
                      align="center"
                      justify="center"
                      v-if="appointment.statusCode === 'C'"
                    >
                      <h2>
                        {{ $_t('appointment_date') }}: {{
                          appointment.date ? moment(appointment.date).utc().format(formatDate) : $_t('Not yet scheduled')
                        }}
                      </h2>
                    </v-row>
                  </v-container>
                </v-list-item-content>
              </v-list-item>
              <v-list-item v-if="false">
                <v-container>
                  <template v-for="(appointmentProcedure, index) in appointment.appointmentProcedures">
                    <v-row :key="index">
                      <v-col cols="12">
                        <v-alert
                          three-line
                          border="bottom"
                          colored-border
                          elevation="4"
                          color="primary"
                          :icon="getStatusConfigProperty(appointmentProcedure.status, 'icon')"
                        >
                          <h3>
                            <span v-if="appointment.statusCode === 'C'">{{ appointmentProcedure.startTime ? moment(appointmentProcedure.startTime).utc().format(formatTime) : '' }}</span>
                            <p>{{ appointmentProcedure.procedure.name }}</p>
                          </h3>
                          <template v-if="['G'].includes(appointmentProcedure.status)">
                            <h5 :style="{ color: 'red' }">
                              {{ getStatusConfigProperty(appointmentProcedure.status, 'patientText') + appointmentProcedure.roomName }}
                            </h5>
                            <v-btn
                              class="my-4"
                              color="primary"
                              outlined
                              width="100%"
                              @click="changeProcedureStatus(appointmentProcedure, 'O')"
                            >
                              {{ $_t('button_im_on_my_way') }}<br>
                            </v-btn>
                            <h5 class="subtitle-2">
                              {{ $_t('Press the button above when you are on your way to: ') + appointmentProcedure.roomName }}
                            </h5>
                            <h5 class="subtitle-2">
                              {{ appointmentProcedure.roomDescription }}
                            </h5>
                          </template>
                          <template v-else-if="['O'].includes(appointmentProcedure.status)">
                            <h5>
                              {{
                                getStatusConfigProperty(appointmentProcedure.status, 'patientText') + ` (${ $_t('room') } ${ appointmentProcedure.roomName })`
                              }}
                            </h5>
                            <v-btn
                              v-if="['O'].includes(appointmentProcedure.status)"
                              class="my-4"
                              color="primary"
                              outlined
                              width="100%"
                              @click="arriveInRoom(appointmentProcedure)"
                            >
                              {{ $_t('I have arrived') }}
                            </v-btn>
                            <h5 class="subtitle-2">
                              {{ $_t('Press the button above when you arrive in: ') + appointmentProcedure.roomName }}
                            </h5>
                            <h5 class="subtitle-2">
                              {{ appointmentProcedure.roomDescription }}
                            </h5>
                          </template>
                          <template v-else-if="['A', 'AR'].includes(appointmentProcedure.status)">
                            <h5>
                              {{ getStatusConfigProperty(appointmentProcedure.status, 'patientText') }}
                            </h5>
                          </template>
                          <template v-else>
                            <h5>
                              {{ getStatusConfigProperty(appointmentProcedure.status, 'patientText') }}
                            </h5>
                          </template>
                        </v-alert>
                      </v-col>
                    </v-row>
                    <v-row
                      v-for="(message, procIndex) in messages(appointmentProcedure)"
                      :key="'m' + procIndex"
                    >
                      <v-col cols="12">
                        <v-alert
                          :type="message.type"
                          border="left"
                          colored-border
                          elevation="4"
                        >
                          {{ message.text }}
                        </v-alert>
                      </v-col>
                    </v-row>
                  </template>
                </v-container>
              </v-list-item>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </template>
    <template v-if="appointment && carousel">
      <v-scroll-y-reverse-transition>
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            md="12"
            :lg="isIframeContent ? '12' : '8'"
          >
            <v-card
              :elevation="isIframeContent ? 0 : 9"
              color="rgba(255,255,255,0.93)"
            >
              <appointment-carousel
                :components-list="carouselComponents"
                @close="carousel = false; fetchAppointment();"
                :override-carousel-page="carouselPage"
                @is-iframe-content="iFrameContent"
              />
            </v-card>
          </v-col>
        </v-row>
      </v-scroll-y-reverse-transition>
    </template>
    <template v-if="!appointment">
      <v-container
        fluid
        fill-height
      >
        <v-row
          align="center"
          justify="center"
        >
          <v-col
            cols="12"
            align-self="center"
          >
            <v-container>
              <v-row
                v-if="!errorMessage"
                justify="center"
              >
                <v-progress-circular
                  :size="128"
                  :width="7"
                  color="primary"
                  indeterminate
                />
              </v-row>
              <v-row
                v-if="!errorMessage"
                justify="center"
              >
                <h1 class="display-1 mt-5 text-center">
                  {{ $_t('loading_please_wait') }}
                </h1>
              </v-row>
              <v-row
                v-if="errorMessage"
                justify="center"
              >
                <v-col cols="12">
                  <div class="display-1 mt-5 red--text text-center">
                    {{ $_t(errorMessage) }}
                  </div>
                </v-col>
                <v-col cols="12 text-center">
                  <v-btn
                    color="primary"
                    @click="redirectToAuth"
                  >
                    {{ $_t('Click here to sign in again') }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-dialog
      v-model="arrivalDialog"
      persistent
      max-width="500"
    >
      <v-card class="">
        <v-card-text>
          <h4 class="headline mb-4 pt-4">
            {{ $_t('arrival_dialog_title') }}
          </h4>
          {{ $_t('arrival_dialog_subtitle') }}
        </v-card-text>
        <v-card-actions class="pl-6 pr-6 pb-4">
          <v-btn
            outlined
            color="primary"
            @click="arrivalDialog = false"
          >
            {{ $_t('button_not_arrived_yet') }}
          </v-btn>
          <v-spacer />
          <v-btn
            outlined
            color="primary"
            @click="patientArrived"
          >
            {{ $_t('button_yes_i_have') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </main-layout>
</template>

<script>
import MainLayout from '@layouts/main.vue'
import api from '@services/api.service'
import config from '@src/config'
import statuses from '@state/modules/statuses'
import { mapMutations, mapGetters } from 'vuex'
import moment from 'moment'
import commonMessages from '@src/locales/en.json'
import AppointmentCarousel from '@components/appointment/carousel/Carousel.vue'
import AppointmentAddressFields from '@components/appointment/carousel/AddressFields.vue'
import auth from '@helpers/auth'

export default {
  components: {
    MainLayout,
    AppointmentCarousel,
    AppointmentAddressFields
  },
  props: {
    currentCarouselPageName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      carousel: false,
      carouselComponents: [],
      carouselPage: 0,
      config: {},
      newPhone: '',
      newPhoneVerificationCode: '',
      phoneVerification: false,
      dialogEditPhone: false,
      offline: false,
      errorMessage: '',
      notificationsPermissions: null,
      procedures: [],
      proceduresLastStatus: [],
      notificationsShownForProcedures: [],
      arrivalDialog: false,
      location: null,
      payment: false,
      consentForm: false,
      phoneEditable: false,
      refreshTimeout: null,
      patientAddressEdit: false,
      patientAddressData: {},
      patientEmail: '',
      patientEmailEdit: false,
      error: false,
      isIframeContent: false
    }
  },
  computed: {
    ...mapGetters('appointment', ['appointment']),
    showAllowNotifications() {
      return this.notificationsAvailable && (this.notificationsPermissions === 'default' || this.notificationsPermissions === 'prompt')
    },
    appointmentId() {
      return this.$route.params.appointmentId.replace(/\W/g, '')
    },
    appointmentAvatar() {
      if (typeof this.appointment.avatar === 'undefined' || !this.appointment.avatar) {
        return ''
      }
      return config.urlAvatars + this.appointment.avatar
    },
    statusConfig() {
      return statuses
    },
    moment() {
      return moment
    },
    shouldShowArriveButton() {
      if (this.appointment.status !== 'C') {
        return false
      }

      const appointmentDate = moment(this.appointment.date + ' ' + this.appointment.time)
      const today = moment()

      const duration = moment.duration(today.diff(appointmentDate))
      const hours = duration.asHours()

      return hours >= -2 && hours < 0
    }
  },
  watch: {
    'appointment.statusCode'(statusCode) {
      if (statusCode === 'AC' || statusCode === 'N') {
        this.carouselComponents = config.newAppointmentComponents ? config.newAppointmentComponents.split(/,/).map((componentName, index) => {
          if (componentName.trim() === this.currentCarouselPageName) {
            this.carouselPage = index
          }
          return {
            name: componentName.trim()
          }
        }) : []
        this.carousel = true
      } else if (statusCode === 'C') {
        this.carouselComponents = [{
          name: 'Welcome'
        }]
        if (typeof this.appointment.appointmentProcedures !=='undefined') {
          this.appointment.appointmentProcedures.map(apProcedure => {
            if (apProcedure.procedure?.referralTemplate?.formJson) {
              try {
                const form = JSON.parse(apProcedure.procedure?.referralTemplate?.formJson || '')
                form.documentName = apProcedure.procedure.name
                form.rowId = apProcedure.procedure?.referralTemplate.id
                form.complete = true
                this.carouselComponents.push({
                  name: 'Forms',
                  form: form
                })
              } catch (e) {
                this.$_notify.error(e)
              }
            }
          })
        }
        const pages = this.carouselComponents.length
        config.confirmedAppointmentComponents.split(/,/).map((componentName, index) => {
          if (componentName.includes(':')) {
            const formBits = componentName.split(':');
            this.carouselComponents.push({
              name: formBits[0].trim(),
              form: formBits[1].trim()
            })
          } else {
            if (componentName.trim() === this.currentCarouselPageName) {
              this.carouselPage = pages + index
            }
            this.carouselComponents.push({
              name: componentName.trim()
            })
          }
        })
        if ('Payment' === this.currentCarouselPageName && config.paymentEnabled) {
          this.carouselComponents.push({
            name: 'Payment'
          })
          this.carouselComponents.push({
            name: 'ThankYouBooking'
          })
          this.carouselPage = this.carouselComponents.length - 2
        }
        this.carousel = true
      } else if (statusCode === 'S') {
        if ('Payment' === this.currentCarouselPageName && config.paymentEnabled) {
          this.carouselComponents.push({
            name: 'Payment'
          })
          this.carouselComponents.push({
            name: 'ThankYouBooking'
          })
          this.carousel = true
          this.carouselPage = 0
        }
      }
    }
  },
  mounted() {
    this.drawerHide()
    if (!this.appointmentId) {
      this.errorMessage = 'sorry_no_appointment_reference_provided'
      return
    }
    if (typeof Notification !== 'undefined') {
      this.notificationsPermissions = Notification.permission
    }
    if (typeof navigator !== 'undefined' && typeof navigator.permissions !== 'undefined' && typeof navigator.permissions.query !== 'undefined') {
      this.notificationsAvailable = true
      navigator.permissions.query({ name: 'notifications' }).then(notificationPerm => {
        notificationPerm.onchange = () => {
          this.notificationsPermissions = notificationPerm.state
        }
      })
    } else {
      this.notificationsAvailable = false
    }
    this.service = api('appointment')
    this.getData()
  },
  methods: {
    iFrameContent(param) {
      this.isIframeContent = param
    },
    ...mapMutations('app', ['drawerHide']),
    messages(appointmentProcedure) {
      if (typeof this.config.messages === 'object' && typeof this.config.messages[appointmentProcedure.status] === 'object') {
        return this.config.messages[appointmentProcedure.status]
      }
      return []
    },
    requestNotificationPermission() {
      if (typeof Notification !== 'undefined') {
        Notification.requestPermission().then(result => {
          this.notificationsPermissions = result
        })
      }
    },
    requestLocation() {
      if (typeof navigator !== 'undefined' && typeof navigator.permissions !== 'undefined' && typeof navigator.permissions.query !== 'undefined') {
        navigator.geolocation.getCurrentPosition(location => {
          this.location = location
        }, error => this.$_notify.error(error.message))
      }
    },
    fetchAppointment(callback) {
      this.loading = true
      this.$store.dispatch('appointment/setAppointment', null)

      this.$_rest.get(`/appointments/access-code/${ this.appointmentId }`,
        { groups: 'all, procedure_procedure_clinics, appointment_status, appointment_procedure_status, clinic_scan_room, appointment_procedure_procedure, appointment_appointment_procedures, appointment_patient, procedure_scan, procedure_referral_template, appointment_clinic, appointment_insurer, appointment_practitioner_practice, practitioner_practice, appointment_preferred_clinic, procedure_procedure_clinics, clinic_insurer_clinics, insurer_clinic_insurer' },
        appointmentResponse => {
          this.loading = false
          this.$store.dispatch('appointment/setAppointment', appointmentResponse.data)
          if (callback) {
            callback();
          }
        },
        () => {
          this.errorMessage = 'Sorry, we couldn\'t find your appointment.'
          this.loading = false
        },
     { prefixRoutesWithPatientId: true }
      )
    },
    getData() {
      this.fetchAppointment(() => this.appointmentLoaded())
    },
    getConfig() {
      api('configuration').getList({ code: 'appointment' }, data => {
        this.config = data[0].data
      })
    },
    appointmentLoaded () {
      if (this.shouldShowArriveButton) {
        this.arrivalDialog = true
      }
    },
    getStatusConfigProperty(status, property) {
      const config = this.statusConfig.find(st => st.code === status)
      if (typeof config === 'undefined') return
      return config[property]
    },
    patientArrived() {
      this.appointment.status = 'A'
      this.appointment.appointmentProcedures.map(proc => {
        proc.status = 'A'
      })
      this.requestLocation()
      this.saveAppointment()
      api('hl7/outbound/create').save({
        HL7TemplateCode: 'HL7_ARRIVED',
        appointmentsObjectId: this.appointment.objectId,
        params: {
          status: 'ARRIVED'
        }
      })
    },
    arriveInRoom(appointmentProcedure) {
      appointmentProcedure.status = 'AR'
      this.saveAppointment()
      api('hl7/outbound/create').save({
        HL7TemplateCode: 'HL7_ARRIVED_ROOM',
        appointmentsObjectId: this.appointment.objectId,
        params: {
          status: 'ARRIVED'
        }
      })
    },
    editPhone() {
      this.dialogEditPhone = true
    },
    editEmail() {
      this.patientEmailEdit = true
    },
    savePhone() {
      if (this.phoneVerification) {
        api(`appointment/${this.appointmentId}/phone`)
          .save({ verificationCode: this.newPhoneVerificationCode }, () => {
            this.phoneVerification = false
            this.dialogEditPhone = false
            this.newPhone = ''
            this.newPhoneVerificationCode = ''
            this.$_notify.success('Phone number updated')
            this.fetchAppointment()
          }, error => {
            this.$_notify.error(error)
          })
      } else {
        api(`appointment/${this.appointmentId}/phone`).save({ phone: this.newPhone }, () => {
          this.phoneVerification = true
        }, error => this.$_notify.error(error))
      }
    },
    cancelEditPhone() {
      this.dialogEditPhone = false
      this.phoneVerification = false
    },
    changeProcedureStatus(appointmentProcedure, status) {
      appointmentProcedure.status = status
      this.saveAppointment()
    },
    saveAppointment() {
      api(`appointment/${this.appointmentId}`).save(this.appointment, () => {
      }, error => this.$_notify.error(error))
    },
    cancelPatientAddressEdit() {
      this.patientAddressEdit = false
    },
    cancelPatientEmailEdit() {
      this.patientEmailEdit = false
    },
    savePatientEmail() {
      this.loading = true
      this.$_rest.put(`/appointments/${ this.appointmentId }/patient`,
        {
          emailAddress1: this.patientEmail
        },
        undefined, () => {
          this.patientEmailEdit = false
          this.fetchAppointment()
        }, error => {
          this.$_notify.error(error.errors[0].message)
        },
        { prefixRoutesWithPatientId: true })
    },
    savePatientAddress() {
      this.loading = true
      this.$_rest.put(`/appointments/${ this.appointmentId }/patient`, this.patientAddressData, undefined, () => {
        this.patientAddressEdit = false
        this.fetchAppointment()
      }, error => {
        this.$_notify.error(error.errors[0].message)
      },
      { prefixRoutesWithPatientId: true })
    },
    patientAddressEdited(data) {
      this.patientAddressData = data
    },
    redirectToAuth() {
      localStorage.setItem('lastToRoute', this.$router.currentRoute.path)
      auth.redirectToAuth()
    }
  },
  i18n: {
    messages: {
      en: {
        arrival_dialog_title: 'Have you arrived for your appointment?',
        arrival_dialog_subtitle: 'When you select Yes, we will ask you to share your location to allow us help you with directions. This is optional and you can opt out from sharing the location.',
        button_enable_notifications: 'Enable notifications',
        button_i_have_arrived: 'I have arrived',
        button_not_arrived_yet: 'Not yet',
        button_yes_i_have: 'Yes I have',
        button_enable_gps: 'Enable location',
        button_im_on_my_way: 'I\'m on my way',
        loading_please_wait: 'Loading, please wait...',
        sorry_no_appointment_reference_provided: 'Sorry, no appointment reference provided',
        please_go_to_room: 'Please go to room ',
        payment: 'Payment',
        consent_form: 'Consent Form',
        no_mobile_available: 'No mobile available',
        appointment_date: 'Appointment Date',
        appointment_time: 'Appointment Time',
        no_mobile_number_on_record: 'No mobile number on record',
        mobile_number_tap_to_edit: 'mobile number',
        you_can_enter_or_edit_your_mobile_number_below: 'You can enter or edit your mobile number below',
        you_can_enter_or_edit_your_email_below: 'You can enter or edit your email below',
        you_can_enter_or_edit_your_address_below: 'You can enter or edit your address below',
        five_digit_code_sent: 'We have sent a one time 5 digit code to your mobile number. Please enter it below.',
        'Take a quiz': 'Take a quiz',
        'Mobile number': 'Mobile number',
        'One time code': 'One time code',
        'No time specified': 'No time specified',
        Cancel: 'Cancel',
        Save: 'Save',
        'Press the button above when you are on your way to: ': 'Press the button above when you are on your way to: ',
        'Press the button above when you arrive in: ': 'Press the button above when you arrive in: ',
        room: 'Room'
      }
    },
    sharedMessages: commonMessages
  }
}
</script>

<style scoped>
.scrollable {
  overflow-y: auto;
  overflow-x: hidden;
}

.v-carousel__item {
  height: 100% !important;
  padding-bottom: 50px;
}
</style>

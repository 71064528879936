<template>
  <practitioner-layout
    :right-drawer.sync="getRightDrawer"
    @right-drawer-refresh="fetchPatientAppointments"
    @close-right-driver="closeRightDrawer"
  >
    <v-progress-linear
      v-if="!e6"
      indeterminate
      class="mt-12"
    />

    <v-stepper
      v-model="e6"
      v-if="e6"
      vertical
    >
      <v-stepper-step
        :complete="e6 > 1"
        step="1"

      >
        <span class="text-h5 font-weight-light">
          Patient
          <v-chip
            v-if="stepSummary1"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ stepSummary1 }}
          </v-chip>
        </span>
      </v-stepper-step>
      <v-stepper-content step="1" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
        <v-card>
          <v-row v-if="showPatientSearch">
            <v-col
              cols="12"
              md="12"
              lg="6"
            >
              <patient-search
                v-model="patient"
                @search="timesSearched++"
                @clear-storage="deleteLocalStorageItems"
              ></patient-search>
            </v-col>
            <v-col v-show="false"
              cols="12"
              md="12"
              lg="6"
            >
              <patient-search-details
                v-model="patient"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card-actions>
                 <!-- {{this.$vuetify.breakpoint.name}} -->
                <v-row>
                  <v-col
                  cols="12"
                  md="6"
                  lg="6"
                  sm="6"
                  xs="12"
                  >
                    <v-autocomplete
                      v-model="secondaryPractitionerPracticeModel"
                      class="pa-3"
                      :label="$_t('Create as')"
                      :items="practitionerPracticeList"
                      item-text="text"
                      item-value="id"
                      :loading="practitionerPracticeLoader"
                      @change="practitionerPracticeChanged"
                    ></v-autocomplete>
                  </v-col>
                  <v-col cols="12" md="6" lg="6" sm="6" xs="12">
                    <v-select
                      v-model="selectedPractitionerSpeciality"
                      class="pa-3"
                      :label="$_t('Select Speciality')"
                      :items="specialityListFiltered"
                      item-text="name"
                      item-value="id"
                    ></v-select>

                    <v-btn
                      :disabled="!patientAndSpecialityFilled"
                      color="primary"
                      @click="nextStep"
                      :class="isMobile ? 'mt-3':'mt-3 float-right'"
                      :block="isMobile"
                      :loading="loading"
                    >
                      {{ $_t('Create order for selected patient') }}
                      <v-icon right>
                        mdi-arrow-right-circle
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

              </v-card-actions>
              <v-card-actions v-if="false">

                <v-btn
                  v-if="timesSearched"
                  :disabled="!timesSearched || !!patient"
                  color="primary"
                >
                  <v-icon right>
                    mdi-plus
                  </v-icon>
                  {{ $_t('Add a new patient') }}
                </v-btn>

                <v-btn
                  v-if="!!timesSearched && !!patient"
                  color="primary"
                  @click="patient = null"
                >
                  <v-icon right>
                    mdi-close
                  </v-icon>
                  {{ $_t('Cancel') }}
                </v-btn>

              </v-card-actions>

            </v-col>

          </v-row>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 2"
        step="2"
        @click.native="stepClick(2,e6 > 2)"
      >
        <span class="text-h5 font-weight-light">
          {{ $_t('Examinations') }}
          <v-chip
            v-for="(summary, index) in stepSummary2"
            :key="index"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ summary }}
          </v-chip>
        </span>
      </v-stepper-step>

      <v-stepper-content step="2" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
        <v-card>
          <v-row>
            <v-col
              cols="12"
              md="12"
              lg="6"
            >
              <procedure-search
                v-model="procedure"
                :fetch-route="proceduresFetchRoute"
                :scan-id="procedureScanId"
              ></procedure-search>
            </v-col>
            <v-col
              cols="12"
              md="12"
              lg="6"
            >
              <procedure-list
                v-model="appointment.appointmentProcedures"
                @procedureRemove="removeExistingProcedure"
              ></procedure-list>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="loading || !appointment.appointmentProcedures || !appointment.appointmentProcedures.length"
              @click="nextStep"
            >
              {{ $_t('Continue') }}
              <v-icon right>
                mdi-arrow-right-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 3"
        step="3"
        @click.native="stepClick(3,e6 > 3)"
      >
        <span class="text-h5 font-weight-light">
           {{ $_t('Location') }}
          <v-chip
            v-if="stepSummary4"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ stepSummary4 }}
          </v-chip>
        </span>
      </v-stepper-step>

      <v-stepper-content step="3" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
        <v-card>
          <appointment-clinic-availability
            v-if="this.e6 === 3"
            :appointment.sync="appointment"
            :complete.sync="clinicComplete"
          />
          <v-card-actions>
            <v-btn
              color="primary"
              @click="prevStep"
            >
              {{ $_t('Back') }}
              <v-icon right>
                mdi-arrow-left-circle
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!clinicComplete"
              @click="nextStep"
            >
              {{ $_t('Continue') }}
              <v-icon right>
                mdi-arrow-right-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 4"
        step="4"
        @click.native="stepClick(4,e6 > 4)"
      >
        <span class="text-h5 font-weight-light">
          {{ $_t('Details') }}
          <v-chip
            v-if="stepSummary3"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ stepSummary3 }}
          </v-chip>
        </span>
      </v-stepper-step>

      <v-stepper-content step="4" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
        <v-card>
          <appointment-edit
            v-if="e6 === 4"
            :appointment.sync="appointmentCopy"
            :complete.sync="appointmentEditComplete"
             :patient-id="this.patient.id"
          />
          <v-card-actions>
            <v-btn
              color="primary"
              @click="prevStep"
            >
              {{ $_t('Back') }}
              <v-icon right>
                mdi-arrow-left-circle
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!appointmentEditComplete"
              @click="saveAppointment"
            >
              {{ $_t('Continue') }}
              <v-icon right>
                mdi-arrow-right-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step
        :complete="e6 > 5"
        step="5"
        @click.native="stepClick(5,e6 > 5)"
      >
        <span class="text-h5 font-weight-light">
           {{ $_t('Safety questions') }}
          <v-chip
            v-if="stepSummary5"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ stepSummary5 }}
          </v-chip>
        </span>
      </v-stepper-step>

      <v-stepper-content step="5" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="loading"
            :indeterminate="true"
          />
        </div>
        <v-card>
          <appointment-safety-questions
            v-if="this.e6 === 5"
            :appointment="appointment"
            :patient-id="this.patient.id"
            :complete.sync="safetyQuestionsComplete"
            :fetch-route="appointmentSafetyQuestionsFetchRoute"
            :save-route="appointmentSafetyQuestionsSavehRoute"
          />
          <v-card-actions>
            <v-btn
              color="primary"
              @click="prevStep"
            >
              {{ $_t('Back') }}
              <v-icon right>
                mdi-arrow-left-circle
              </v-icon>
            </v-btn>
            <v-spacer />
            <v-btn
              color="primary"
              :disabled="!safetyQuestionsComplete"
              @click="nextStep"
            >
              {{ $_t('Continue') }}
              <v-icon right>
                mdi-arrow-right-circle
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-stepper-content>

      <v-stepper-step step="6"
        @click.native="stepClick(6,e6 > 6)"
      >
        <span class="text-h5 font-weight-light">
           {{ $_t('Forms') }}
          <v-chip
            v-if="stepSummary6"
            outlined
            color="primary"
            class="ml-3"
          >
            {{ stepSummary6 }}
          </v-chip>
        </span>
      </v-stepper-step>
      <v-stepper-content step="6" class="ma-0 ml-md-12 pa-0">
        <div style="height: 5px">
          <v-progress-linear
            v-if="appointmentFormsLoading"
            :indeterminate="true"
          />
        </div>
        <v-card class="pa-3">
          <div v-if="this.e6 === 6">
            <appointment-forms
              v-for="(form, index) in appointmentForms"
              ref="appointmentForms"
              :key="index"
              :form.sync="form"
              :loading.sync="appointmentFormsLoading"
              :complete.sync="form.complete"
              :save-route="appointmentFormsSaveRoute"
              category-code="RL"
              :hide-complete-button="true"
              :subtitle="form.procedureNames && Array.isArray(form.procedureNames) ? form.procedureNames.join(', ') : ''"
              @change="checkCompletion"
            />
          </div>
          <v-card-actions>
            <v-container fluid>
              <v-row dense>
                <v-btn
                  class="ma-1"
                  color="primary"
                  @click="prevStep"
                >
                  {{ $_t('Back') }}
                  <v-icon right>
                    mdi-arrow-left-circle
                  </v-icon>
                </v-btn>
                <v-spacer />
                <v-btn
                  v-if="appointmentHasForms() && !formsCompleted"
                  class="ma-1"
                  color="primary"
                  @click="showUnansweredQuestions"
                >
                  {{ $_t('Show unanswered questions') }}
                  <v-icon right>
                    mdi-search
                  </v-icon>
                </v-btn>
                <v-btn
                  class="ma-1"
                  color="primary"
                  :disabled="appointmentHasForms() && !formsCompleted"
                  :loading="loadingComplete"
                  @click="completeAppointment"
                >
                  {{ $_t('Complete') }}
                  <v-icon right>
                    mdi-arrow-right-circle
                  </v-icon>
                </v-btn>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-stepper-content>
    </v-stepper>
   <ConfirmDialog :confirmDialogOptions = "confirmDialogOptions" @show="showConfirmDialog" @action="actionConfirmDialog" />
  </practitioner-layout>
</template>
<script>
import PractitionerLayout from '@layouts/practitioner.vue'
import PatientSearch from '@components/patient/Search.vue'
import PatientSearchDetails from '@components/patient/SearchDetails.vue'
import ProcedureSearch from '@components/procedure/Search.vue'
import ProcedureList from '@components/procedure/List.vue'
import AppointmentSafetyQuestions from '@components/appointment/SafetyQuestions.vue'
import AppointmentForms from '@components/appointment/Forms.vue'
import AppointmentEdit from '@components/appointment/EditNew.vue'
import AppointmentClinicAvailability from '@components/appointment/ClinicAvailability.vue'
import ConfirmDialog from '@components/common/ConfirmDialog.vue'
import PatientAppointmentBookingSlots from '@components/appointment/booking/slots/Slots.vue'
import moment from 'moment'
import useCommonHelpers from '@helpers/useCommonHelpers'
const { parseJson } = useCommonHelpers()
import config from '@src/config'


export default {
  components: { PatientSearchDetails, PractitionerLayout, PatientSearch, ProcedureSearch, ProcedureList,
    AppointmentSafetyQuestions, AppointmentForms, AppointmentEdit, AppointmentClinicAvailability, ConfirmDialog },
  data () {
    return {
      e6: null,
      patient: null,
      loading: false,
      procedure: null,
      appointment: { id: null },
      patientAppointments: [],
      timesSearched: 0,
      appointmentsLoading: false,
      appointmentFormsLoading: false,
      rightDrawer: {},
      route: '/practitioner/appointment/new',
      appointmentEditComplete: false,
      clinicComplete: false,
      safetyQuestionsComplete: false,
      stepMax: 6,
      appointmentCopy: null,
      formsCompleted: false,
      practitionerPracticeList: [],
      practitionerPracticeModel: null,
      secondaryPractitionerPracticeModel: null,
      practitionerPracticeLoader: null,
      allSpecialitiesList: [],
      selectedPractitionerSpeciality: null,
      confirmDialogOptions: {open:false, message:''},
      loadingComplete: false,
      procedureScanId: null,
      pollAppointmentStatus: false,
      pollAppointmentStatusInterval: 5000,
      pollAppointmentStatusTimeout: null,
      pollAppointmentStatusAjax: null
    }
  },
  computed: {
    showPatientSearch(){
      return !this.patientEpisodeId
    },
    getRightDrawer: {
      get: function () {
        return this.rightDrawer
      },
      set: function (val) {
        this.rightDrawer = val
      }
    },
    currentUserId() {
      return this.$store.getters['app/currentUserId']
    },
    moment () {
      return moment
    },
    patientRouteId() {
      return this.$route.params['patientId']
    },
    patientEpisodeId () {
      return this.$store.getters['appointment/patientEpisodeId'] || null
    },
    patientLocationId () {
      return this.$store.getters['appointment/patientLocationId'] || null
    },
    step () {
      return typeof this.$route.params['step'] !== 'undefined' ? this.$route.params['step'] : 1
    },
    stepSummary1 () {
      if (!this.patient || !this.selectedPractitionerSpeciality || !this.practitionerPracticeModel) {
        return
      }
      return [this.patient.fullName, `DOB: ${this.patient.dateOfBirth ? this.moment(this.patient.dateOfBirth).format(this.formatDate) : ''}`].join(' ')
    },
    stepSummary2 () {
      if (!this.appointment || !this.appointment.appointmentProcedures) {
        return
      }

      return this.appointment.appointmentProcedures.map(appointmentProcedure => {
        return appointmentProcedure.procedure?.scan?.nameShort+' '+appointmentProcedure?.procedure?.name
    })

    },
    stepSummary3 () {
      let summary = ''
      if (this.appointment.schedule) {
        summary = summary + this.$_t('Booked for') + ': ' + this.appointment.schedule.date + ' ' + this.appointment.schedule.time
      } else if (this.appointment.preferredAppointmentDate) (
        summary = summary + this.$_t('Preferred date') + ': ' + this.appointment.preferredAppointmentDate
      )

      if (this.appointment.priorityCode && this.appointment.priority) {
        summary = summary + ' ' + this.$_t('Priority') + ': ' + this.appointment.priority.name
      }
      return summary
    },
    stepSummary4 () {
      return this.appointment.preferredClinicId ? this.appointment.preferredClinic.name : ''
    },
    stepSummary5 () {
      return this.safetyQuestionsComplete ? 'Complete' : false
    },
    stepSummary6 () {
      return this.formsCompleted ? 'Complete' : false
    },
    appointmentSafetyQuestionsFetchRoute () {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/safety-questions`
    },
    appointmentSafetyQuestionsSavehRoute () {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/safety-questions`
    },
    appointmentFormsSaveRoute () {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/appointment-documents`
    },
    proceduresFetchRoute () {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/procedures`
    },
    appointmentForms () {
      const forms = {}
      if(typeof this.appointment.appointmentProcedures !=='undefined') {
        this.appointment.appointmentProcedures.map(apProcedure => {
          if (apProcedure.procedure?.referralTemplate?.formJson) {
            try {
              let form = parseJson(apProcedure.procedure?.referralTemplate?.formJson)
              if(typeof form.data !== 'undefined') {
                const templateId = apProcedure.procedure?.referralTemplate.id

                if (templateId) {
                  if (typeof forms[templateId] !== 'undefined') {
                    forms[templateId].procedureNames.push(apProcedure.procedure.name)
                  } else {
                    form = form.data
                    form.procedureNames = [apProcedure.procedure.name]
                    form.rowId = apProcedure.procedure?.referralTemplate.id
                    form.templateId = apProcedure.procedure?.referralTemplate.id
                    form.complete = false
                    forms[form.templateId] = form
                  }
                }
              }
            } catch (e) {
              this.$_notify.error(e)
            }
          }
        })
      }
      return forms
    },
    specialityListFiltered() {
      if (!this.secondaryPractitionerPracticeModel){
        return []
      }

      // Pick out currently selected practitioner data
      const currentPractitionerPractice = this.practitionerPracticeList?.find(item => item.id === this.secondaryPractitionerPracticeModel)

      if (!currentPractitionerPractice) {
        return []
      }

      // Pick out specialities assigned to currently selected practitioner
      const currentPractitionerPracticeSpecialitiesCsv = currentPractitionerPractice?.practitioner?.specialityIdCsv


      // Compare all available specialities with those assigned to a practitioner if they exist
      const filteredSpecialities = this.allSpecialitiesList.filter(item => currentPractitionerPracticeSpecialitiesCsv?.split(',')?.includes(item.id))

      // If a practitioner doesnt have any speciality assigned, return all available specialities with filtering if they are set as active,
      // or if one of them is defined as a default one which could be a case, per the DB data design
      if (!currentPractitionerPracticeSpecialitiesCsv) {
        return this.allSpecialitiesList.filter(speciality => {
          return this.practitionerPracticeList.find(practitionerPractice => practitionerPractice.id === this.secondaryPractitionerPracticeModel)?.defaultSpeciality?.id === speciality.id || speciality.isActive
        })
      } else {
      return filteredSpecialities
      }
    },
    patientAndSpecialityFilled() {
      return !!(this.patient && this.selectedPractitionerSpeciality)
    }
  },
  created() {
    this.setEvent()
  },
  mounted () {
    Promise.allSettled([
      this.fetchPractitionerPractice(),
      this.fetchAllSpecialities()
    ]).then(() => {
      this.selectDefaultSpeciality()
    })
      this.resolveRoute()
  },
  methods: {
    getAppointmentStatus() {
      return new Promise((resolve, reject) => {
        if (this.pollAppointmentStatusAjax) {
          this.pollAppointmentStatusAjax.abort()
        }
        this.appointmentStatusAjax = this.$_rest.get(
          `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}`,
          {},
          response => {
            resolve(response.data.statusCode)
          },
          (error) => {
            reject(error)
          }
        )
      })
    },
    async verifyAppointmentStatus() {
      const statusCode = await this.getAppointmentStatus()
      if (statusCode === 'N') {
        if (this.pollAppointmentStatus) {
          this.pollAppointmentStatusAjax = null
          if (this.pollAppointmentStatusTimeout) {
          clearTimeout(this.pollAppointmentStatusTimeout)
          }
          this.pollAppointmentStatusTimeout = setTimeout(this.verifyAppointmentStatus, this.pollAppointmentStatusInterval)
        }
        return true
      }

      this.$_notify.error(this.$_t('This appointment is no longer valid'))
      await this.$router.push('/')

      return false
    },
    practitionerPracticeChanged() {
      this.selectDefaultSpeciality()
    },
    stepClick (stepNo,param) {
      if(param) {
        this.processStep(stepNo)
      }
    },
    setEvent() {
      this.$root.$on('open-navbar',(param)=>{
        this.openAppointmentNavbar(param)
      })
    },
    appointmentHasForms () {
      for (const i in this.appointmentForms) {
        return true
      }
      return false
    },
    showUnansweredQuestions () {
      this.$refs.appointmentForms.every(form => {
        try {
          form.validateForm()
        } catch {
          return false
        }
        return true
      })
    },
    async completeAppointment () {
      this.loadingComplete = true

      // check if appointment status hasn't changed
      const verifyStatus = await this.verifyAppointmentStatus()
      if (!verifyStatus) {
        return
      }

      this.pollAppointmentStatus = false

      this.submitForms().then(resolve => {
          if (config.practitionerNewSplitBy && config.practitionerNewSplitBy.length) {
            this.splitAppointment().then(splitResponse => {
              splitResponse.data.forEach((splitAppointment, index) => {
                this.advanceAppointment(splitAppointment.id).then(() => {
                  if (index + 1 === splitResponse.data.length) {
                    this.successRedirect()
                  }
                })
              })
            })
          } else {
            this.advanceAppointment().then(() => {
              this.successRedirect()
            })
          }
      }).catch(error => {
        this.$_notify.error(`Failed saving form; reason: ${error}`)
        this.loadingComplete = false
      })
    },
    successRedirect() {
      this.$_notify.success(this.$_t('Order complete. Redirecting to orders/appointments...'))
      setTimeout(() => {
        this.$router.push('/practitioner/appointments')
      }, 3000)
    },
    splitAppointment() {
      const split = {}
      if (this.appointment?.appointmentProcedures?.length) {
        this.appointment.appointmentProcedures.forEach(el => {
          const scanCode = el.procedure.scan.code

          const preferredDate = el.preferredAppointmentDate || null

          const priorityCode = el.appointmentPriority || 'N'

          const indexMembers = []
          if (config.practitionerNewSplitBy.includes('SCAN')) {
            indexMembers.push(scanCode)
          }
          if (config.practitionerNewSplitBy.includes('PREFERRED_DATE')) {
            indexMembers.push(preferredDate)
          }
          if (config.practitionerNewSplitBy.includes('PRIORITY')) {
            indexMembers.push(priorityCode)
          }
          if (config.practitionerNewSplitBy.includes('VETTING')) {
            const reqProtocolling = parseInt(el.procedure?.requireProtocolling, 10)
            indexMembers.push(`protocolling${reqProtocolling}`)
          }
          if (config.practitionerNewSplitBy.includes('PROCEDURE_CATEGORY')) {
            const categoryIds = (el.procedure?.categoryIdCsv || '').split(',').filter(x => x).sort().join('|') || 'none'
            indexMembers.push(`procedureCategory${categoryIds}`)
          }
          const index = indexMembers.join('_')
          if (typeof split[index] === 'undefined') {
            split[index] = {
              appointmentProcedureIds: [],
              preferredDate: preferredDate,
              priorityCode: priorityCode
            }
          }

          split[index].appointmentProcedureIds.push(el.id)
        })
      }
      const splitArr = Object.values(split)
      return new Promise((resolve, reject) => {
        this.$_rest.post(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/split`,
          { appointmentProceduresSplit: splitArr },
          data => resolve(data),
          error => reject(error)
        )
      })
    },
    advanceAppointment (appointmentId = null) {
      if (!appointmentId) {
        appointmentId = this.appointment.id
      }
      return new Promise((resolve, reject) => {
        this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ appointmentId }/advance`,
          {},
          undefined,
          data => resolve(data),
          error => reject(error)
        )
      })
    },
    submitForms () {
      if (this.appointmentHasForms()) {
        const saveRequests = []
        const uniqueForms = {}
        this.$refs.appointmentForms.forEach(formRef => {
          uniqueForms[formRef.form.templateId] = formRef
        })
        Object.values(uniqueForms).map(form => {
          saveRequests.push(form.saveForm())
        })
        return Promise.all(saveRequests)
      } else {
        return new Promise(resolve => resolve())
      }
    },
    restart () {
      window.location.href = this.route
    },
    resolveRoute () {
      if (this.patientRouteId && (!this.patient || !this.patient.id)) {
        this.fetchPatient().then(() => {
          const appointmentId = this.$route.params['appointmentId']
          if (appointmentId && (!this.appointment || !this.appointment.id)) {
            this.fetchAppointment(appointmentId).then(() => {
              this.pollAppointmentStatus = true
              this.resolveSteps()
            })
          } else {
            return this.resolveSteps()
          }
        })
      } else {
        return this.resolveSteps()
      }
    },
    resolveSteps () {
      if (!this.patient || !this.patient.id || !this.step || !this.appointment || !this.appointment.id) {
        return this.e6 = 1
      }
      if (this.appointment && this.appointment.id && this.step < 2) {
        return this.e6 = 2
      }
      if (this.step > this.stepMax) {
        this.redirect(`${ this.route }/${this.stepMax}/${ this.patient.id }/${ this.appointment.id }`)
      }
      this.e6 = Math.min(this.step, this.stepMax)
    },
    saveAppointment () {
      this.loading = true
      Promise.allSettled([
        new Promise((resolve, reject) => {
          this.$_rest.put(
            `practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/priorities`, {
              priorityCode: this.appointmentCopy.priorityCode
            },
            undefined,
            response => resolve(response),
            error => reject(error)
          )
        }),
        new Promise((resolve, reject) => {
          if(this.appointmentCopy.categoryId) {
            this.$_rest.put(
              `practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/appointment-category`, {
                appointmentCategoryId: this.appointmentCopy.categoryId
              },
              undefined,
              response => resolve(response),
              error => reject(error)
            )
          }
          resolve()
        }),
        new Promise((resolve, reject) => {
          if(this.appointmentCopy.patientLocationId) {
            this.$_rest.put(
              `practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/patient-location`, {
                patientLocationId: this.appointmentCopy.patientLocationId
              },
              undefined,
              response => resolve(response),
              error => reject(error)
            )
          }
          resolve()
        }),
        new Promise((resolve, reject) => {
          this.$_rest.put(
            `practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/preferred-date`, {
              date: moment(this.appointmentCopy.preferredAppointmentDate).format('YYYY-MM-DD')
            },
            undefined,
            response => resolve(response),
            error => reject(error)
          )
        })
      ]).then((resp) => {
        if(this.appointmentCopy.patientEpisodeId) {
          this.$_rest.put(
            `practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointment.id}/patient-episode`, {
              patientEpisodeId: this.appointmentCopy.patientEpisodeId
            },
            undefined,
            response => {
              this.appointment = Object.assign({}, this.appointmentCopy)
              this.loading = false
              this.nextStep()
            },
            error => {
              this.$_notify.error(error)
              this.loading = false
            }
          )
        }
      }).catch(error => {
        this.$_notify.error(error)
        this.loading = false
      })

    },
    checkCompletion () {
      for (const formIndex in this.appointmentForms) {
        if (!this.appointmentForms[formIndex].complete) {
          this.formsCompleted = false
          return
        }
      }
      this.formsCompleted = true
    },
    nextStep () {
      const nextStep = parseInt(this.e6, 10) + 1
      switch (nextStep) {
        case 2:
          this.loading = true
          this.createAppointment().then(appointmentResponse => {
            this.appointment = appointmentResponse.data
            this.appointmentCopy = Object.assign(this.appointment)
            this.loading = false
            this.processStep(nextStep)
            this.pollAppointmentStatus = true
          })
          return
        case 6:
          // this.fetchAppointmentForms()
          this.processStep(nextStep)
          return
        default:
          this.appointmentCopy = Object.assign(this.appointment)
          if (this.e6 + 1 > this.stepMax) {
            return this.restart()
          }
      }
      this.processStep(nextStep)
    },
    prevStep () {
      const prevStep = this.e6 -1
      this.processStep(Math.max(2, prevStep))
    },
    processStep (step) {
      switch (parseInt(step, 10)) {
        case 2:
          if (!this.patient || !this.patient.id) {
            this.fetchPatient()
          }
          if (!this.appointment || !this.appointment.id) {
            this.fetchAppointment()
          }
          break
        default:
          if (step > this.stepMax) {
            return this.restart()
          }
      }
      this.redirect(`${ this.route }/${ step }/${ this.patient.id }/${ this.appointment.id }`)
      this.e6 = step
    },
    addProcedureToAppointment (procedureId) {
      this.loading = true
      this.$_rest.post(`/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/appointment-procedures?groups=${
        ['all', 'appointment_procedure_status', 'appointment_procedure_procedure', 'procedure_scan', 'procedure_referral_template', 'procedure_procedure_clinics', 'procedure_clinic_clinic', 'clinic_clinic_scan_rooms', 'clinic_scan_room_scan'].join(',')
        }`,
        {
          procedureId: procedureId
        }, response => {
          this.loading = false
          this.appointment.appointmentProcedures = this.appointment.appointmentProcedures || []
          this.appointment.appointmentProcedures.push(response.data)
        }, error => {
          this.loading = false
          this.$_notify.error(error)
        })
    },
    removeExistingProcedure (procedureId) {
      this.loading = true
      this.$_rest.remove(`/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.appointment.id }/appointment-procedures/${procedureId}`,
        () => {
          this.loading = false
          this.appointment.appointmentProcedures = this.appointment.appointmentProcedures.filter(appointmentProcedures => appointmentProcedures.id !== procedureId)
          this.fetchAppointment(this.appointment.id)
        }, error => {
          this.loading = false
          this.$_notify.error(error)
        }
      )
    },
    fetchPatient () {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patients/${ this.patientRouteId }`, {
          groups: 'all'
        }, (data) => {
          resolve(data)
          this.loading = false
          const fullName = [data.data.title, data.data.firstName, data.data.lastName].filter(el => el).join(' ')
          this.patient = Object.assign({}, data.data, { fullName })
          this.fetchPatientAppointments().then(() => {
            this.loading = false
          })
        }, error => {
          reject(error)
          this.loading = false
        })
      })
    },
    redirect (href) {
      this.$router.resolve(href)
      this.resolveRoute()
      this.$router.replace(href).catch(() => {})
    },
    fetchAppointment (appointmentId) {
      this.loading = true
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ appointmentId }`, {
          groups: ['all','patient_appointments','appointment_protocolling_status','appointment_status','appointment_appointment_procedures',
            'appointment_procedure_procedure','procedure_scan', 'appointment_preferred_clinic', 'procedure_referral_template','practitioner_appointment',
            'procedure_procedure_clinics', 'procedure_clinic_clinic', 'clinic_clinic_scan_rooms', 'clinic_scan_room_scan'].join(',')
        }, (data) => {
          if (data.data.status.code !== 'N') {
            this.appointment = {}
            this.appointmentCopy = {}
            this.$_notify.error(this.$_t('Appointment could not be loaded'))
            reject()
            this.redirect(`${ this.route }/1/${ this.patient.id }`)
          } else {
            this.appointment = data.data
            this.appointment.appointmentProcedures = this.appointment.appointmentProcedures.filter(appointmentProcedures => !['X', 'XR'].includes(appointmentProcedures.statusCode))
            this.appointmentCopy = Object.assign({}, this.appointment)
            this.loading = false
              resolve(data)
          }
        }, error => {
          reject(error)
          this.loading = false
        })
      })
    },
    fetchPractitionerPractice() {
      const practitionerId = this.$store.getters['app/practitioner'].id
      const practiceId = this.$store.getters['app/practitionerPractice'].practice.id
      const practitionerPracticeId = this.$store.getters['app/practitionerPractice'].id

      this.practitionerPracticeLoader = true
      this.practitionerPracticeModel = practitionerPracticeId
      this.secondaryPractitionerPracticeModel = practitionerPracticeId
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ practitionerId }/practices/${practiceId}`, {
          groups:['all','practice_practitioner_practices', 'practice_practitioner_practitioner', 'practitioner_practice_default_speciality', 'practitioner_practice_practitioner'].join(',')
        }, (data) => {
          const practitionerLists = data.data.practitionerPractices
          this.practitionerPracticeList = practitionerLists ? practitionerLists.sort((a, b) => (a.practitionerName > b.practitionerName) ? 1 : ((b.practitionerName > a.practitionerName) ? -1 : 0)).map(el => {
            el.text = (el.practitionerName || '') + (el.practitioner && el.practitioner.identificationNumber ? ' (' + el.practitioner.identificationNumber + ')' : '')
            return el
          }) : []
          this.practitionerPracticeLoader = false
          resolve(data)
        }, error => {
          this.practitionerPracticeLoader = false
          reject(error)
        })
      })
    },
    fetchAllSpecialities(){
      const practitionerId = this.$store.getters['app/practitioner'].id
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ practitionerId }/specialities`, {
          groups: ['all', 'practitioner_speciality', 'practitioner_all', 'practitioner_practice_default_speciality'].join(','),
          limit: 10000
        }, (data) => {
          resolve(data)
          const specialityList = data.data
          this.allSpecialitiesList = specialityList ? specialityList : []
        }, error => {
          reject(error)
        })})
    },

    selectDefaultSpeciality() {
        const specialityId = this.practitionerPracticeList.find(practitionerPractice => practitionerPractice.id === this.secondaryPractitionerPracticeModel)?.defaultSpeciality?.id
        this.selectedPractitionerSpeciality = this.specialityListFiltered.find( speciality => speciality.id === specialityId)?.id
    },

    fetchPatientAppointments (refresh = false) {
      this.$store.dispatch('patient/setPatient',this.patient)
      this.$store.commit('app/patientBannerShow',true)

      this.loading = true
      this.appointmentsLoading = true
      this.rightDrawer.loading = true

       this.$store.commit('patient/setAppointmentLoading',this.appointmentsLoading)
      return new Promise((resolve, reject) => {

        this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/patient-appointments`, {
        filter: {
          patientId: { x: this.patient.id, type: 'eq' }
        }
      }, (data) => {
          this.loading = false
          this.patientAppointments = this.mapDatasetResponse(data)
          this.appointmentsLoading = false
          this.$store.commit('patient/setAppointmentLoading',this.appointmentsLoading)
          this.$store.commit('patient/setAppointmentCount',this.patientAppointments.length > 0 ? this.patientAppointments.length : 0)

          if (this.step == 1) {
            this.rightDrawer = {
              visible: refresh ? !!this.patientAppointments.length : false,
              patientId: this.patient.id,
              loading: this.loading,
              data:this.patientAppointments.length >0 ? this.patientAppointments : []
            }
          }
          this.patientAppointmentsFiltered()
         // this.patientAppointments = data.data.appointments
         resolve()
        }, error => {
          reject(error)
          this.loading = false
          this.appointmentsLoading = false
          this.$store.commit('patient/setAppointmentLoading',this.appointmentsLoading)
          this.rightDrawer = Object.assign({}, this.rightDrawer)
        })
      })
    },
    patientAppointmentsFiltered () {
      let filtered = []
      const now = moment()
      filtered =  this.patientAppointments.filter(ap => {
          return (!ap.appointmentDate || now.diff(moment(ap.appointmentCreatedDatetime), 'days') <= 1 || now.diff(moment(ap.appointmentDate), 'days') <= 31)
                  && !['X', 'XR'].includes(ap.appointmentStatusCode)
      })
      this.$store.commit('patient/setAppointmentFilteredCount',filtered.length > 0 ? filtered.length : 0)
    },
    closeRightDrawer(state) {
      if(!state) {
        this.rightDrawer.visible = state
      }
    },
    openAppointmentNavbar(param){
        this.rightDrawer.visible = false
        this.rightDrawer.visible = param
    },
    setPractitioners() {
      if(this.practitionerPracticeModel !== this.secondaryPractitionerPracticeModel) {
        const practitioners = {secondaryPractitionerPracticeId:null, practitionerPracticeId:null}
        practitioners.secondaryPractitionerPracticeId = this.practitionerPracticeModel

        practitioners.practitionerPracticeId = this.secondaryPractitionerPracticeModel
        this.practitionerPracticeModel = this.secondaryPractitionerPracticeModel

        return practitioners
      } else {
        return {practitionerPracticeId:this.practitionerPracticeModel}
      }
    },
    checkPatientEpisodeId() {
      const patientEpisode = {patientEpisodeId:null}
      if(this.patientEpisodeId) {
        patientEpisode.patientEpisodeId = this.patientEpisodeId
        return patientEpisode
      }
    },
    checkPatientLocationId() {
      const patienLocation = {patientLocationId:null}
      if(this.patientLocationId) {
        patienLocation.patientLocationId = this.patientLocationId
        return patienLocation
      }
    },
    createAppointment () {
      this.loading = true
      const params = {
        sourceId: 2,

        specialityId: this.selectedPractitionerSpeciality,
        ...this.setPractitioners(),
        ...this.checkPatientEpisodeId(),
        ...this.checkPatientLocationId()
      }

      if (this.patient.isPatientTemporary) {
        params['patientTemporaryId'] = this.patient.id
        params['isPatientTemporary'] = true
      } else {
        params['patientId'] = this.patient.id
      }

      return new Promise((resolve, reject) => {
        this.$_rest.post(`/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments?groups=${['all ',
          'appointment_appointment_procedures'].join(',')}`, params, data => {
          this.loading = false
          this.deleteLocalStorageItems()
          this.clearEpisodeState()
          resolve(data)
        }, error => {
          this.loading = false
          this.$_notify.error(error)
          reject(error)
        })
      })

    },
    actionConfirmDialog(){
      this.addProcedureToAppointment(this.procedure.id)
      this.procedure = {}
    },
    showConfirmDialog(param,msg = '', days) {
      this.confirmDialogOptions.open = param
      this.confirmDialogOptions.message = msg.length ? msg : this.$_t('This procedure has already been ordered for this patient in the last {days} day(s). Are you sure that you wish to order it again?', {days})
      if(!param){
        this.procedure = {}
      }
    },
    compareDatesAppointments(appointmentDate) {
      const currentDate = moment(new Date())
      const duration = moment.duration(currentDate.diff(appointmentDate))
      return Math.floor(duration.asDays())
    },

    checkProcedureBeforeAdd(procedureId) {
      procedureId = (procedureId || '').toString()
      const appointmentFiltered = this.patientAppointments
        .filter(data => (data.procedureId || '').toString() === procedureId)
        .sort((a, b) => new Date(b.appointmentCreatedDatetime) - new Date(a.appointmentCreatedDatetime))
      if(!appointmentFiltered.length){
        return false
      }

      const mostRecentAppointment = appointmentFiltered[0]
      return this.compareDatesAppointments(mostRecentAppointment.appointmentCreatedDatetime)

    },
    deleteLocalStorageItems() {
      window.localStorage.removeItem('currentPractitionerPractice_' + this.currentUserId + '_btnWeeks')
      window.localStorage.removeItem('currentPractitionerPractice_' + this.currentUserId + '_btnMonths')
    },
    pollAppointmentStatusCancel() {
      if (this.pollAppointmentStatusAjax) {
        this.pollAppointmentStatusAjax.abort()
      }

      if (this.pollAppointmentStatusTimeout) {
        clearTimeout(this.pollAppointmentStatusTimeout)
      }
    },
    clearEpisodeState(){
      this.$store.commit('appointment/setPatientEpisodeId', null)
      this.$store.commit('appointment/setPatientLocationId', null)
    }

  },
  watch: {
    patient (patient) {
      if (patient) {
        this.redirect(`${ this.route }/1/${ this.patient.id }`)
        this.fetchPatientAppointments()
        this.$store.commit('app/patientBannerShow', true)
      } else {
        this.$store.commit('app/patientBannerShow', false)
      }
    },
    patientRouteId(value) {
      if (typeof value === 'undefined') {
        this.patient = null
        this.appointment = { id: null }
        this.rightDrawer = Object.assign({}, this.rightDrawer)
        this.deleteLocalStorageItems()
        this.clearEpisodeState()
        this.$store.commit('app/patientBannerShow', false)
        this.resolveRoute()
      }
    },
    procedure(procedure) {
      if (procedure && procedure.id) {
        if (!this.procedureScanId) {
          this.procedureScanId = procedure.scan.id
        }
        const daysAllowed = procedure.allowRequestAgainInDays || 1
        const checkResult = this.checkProcedureBeforeAdd(procedure.id)

        if (checkResult === false) {
          this.addProcedureToAppointment(procedure.id)
          this.procedure = {}
        } else if (checkResult < daysAllowed) {
          this.showConfirmDialog(true, [], daysAllowed)
        } else {
          this.addProcedureToAppointment(procedure.id)
          this.procedure = {}
        }
      }
    },
    appointment: {
      handler(app){
        if(app && app.id && app.appointmentProcedures.length) {
          const foundScanId = app.appointmentProcedures[0].procedure.scan.id
          this.procedureScanId = foundScanId
        } else {
          this.procedureScanId = null
        }
      }, deep: true
    },
    pollAppointmentStatus (val) {
      if (val) {
        this.verifyAppointmentStatus()
      } else {
        this.pollAppointmentStatusCancel()
      }
    }
  },
  beforeDestroy() {
    this.pollAppointmentStatus = false
    this.pollAppointmentStatusCancel()
    this.$root.$off('open-navbar')
    this.$store.commit('app/patientBannerShow', false)
    this.clearEpisodeState()
  }
}
</script>

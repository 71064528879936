<template>
  <div class="text-center" v-if="clinic">
    <p>{{ $_t('You are booking your appointment at') }}:</p>
    <p>
      <span class="text-h6 font-weight-black">{{ clinic.name }}</span><br>
      <span v-if="clinic.address" class="text-overline">{{ clinic.address }}</span>
    </p>
  </div>
</template>

<script>
import Vue from 'vue'
import config from '@config'

export default Vue.extend({
  components: {},
  name: 'BookingClinics',
  props: {
    clinic: {
      type: Object,
      default: () => {
      }
    }
  },
  data: () => ({
    config: config
  }),
  mounted () {
  },
  methods: {
  }
});
</script>

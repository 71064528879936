import PatientCancellationAppointmentGrid from '@views/patientCancellation/AppointmentGrid.vue'
import PatientCancellationAppointmentRescheduleClinics from '@views/patientCancellation/AppointmentRescheduleClinics.vue'
import PatientCancellationAppointmentRescheduleSlots1 from '@views/patientCancellation/AppointmentRescheduleSlots1.vue'
import PatientCancellationAppointmentRescheduleSlots2 from '@views/patientCancellation/AppointmentRescheduleSlots2.vue'
import PatientCancellationAppointmentRescheduleConfirmation from '@views/patientCancellation/AppointmentRescheduleThankYou.vue'
import PatientAppointments from '@views/patient/Appointments.vue'
import PatientAppointment from '@views/patient/Appointment.vue'
import PatientAppointmentClinics from '@views/patient/booking/Clinics.vue'
import PatientAppointmentSlots from '@views/patient/booking/Slots.vue'
import PatientAppointmentSlotsWithinHour from '@views/patient/booking/SlotsWithinHour.vue'
import PatientProfile from '@views/patient/Profile.vue'
import PatientSettings from '@views/patient/Settings.vue'
import PractitionerAppointmentNew from '@views/practitioner/appointment/New.vue'
import PractitionerAppointmentsGrid from '@views/practitioner/AppointmentGrid.vue'
import PractitionerEpisodesGrid from '@views/practitioner/PractitionerEpisodesGrid.vue'
import PractitionerAlerts from '@views/practitioner/alerts/AlertGrid.vue'
import PractitionerScheduleHome from '@views/practitioner/schedule/Home.vue';
import PractitionerHome from '@views/practitioner/Home.vue';
import PractitionerTiles from '@views/practitioner/appointment/Tiles.vue';
import PatientDetails from '@views/practitioner/patientDetails/PatientDetails.vue'

import ShareViewer from '@views/patient/ShareViewer.vue'
import AlertGridDetails from '@views/practitioner/alerts/AlertGridDetails.vue'
import PatientEpisodeGridDetails from '@views/patient/PatientEpisodeGridDetails.vue'
import AppointmentGridDetails from '@views/practitioner/AppointmentGridDetails.vue'
import Acknowledge from '@views/patient/Acknowledge.vue'
import { PractitionerRoles } from '../enum/PractitionerRoles'

import { AppMode } from '@src/enum/App'

const routes = [
  {
    path: '/auth',
    name: 'Auth'
  },
  {
    path: '/404',
    name: '404',
    component: require('@views/_404.vue').default,
    // Allows props to be passed to the 404 page through route
    // params, such as `resource` to define what wasn't found.
    props: true
  },
  {
    path: '/dev',
    name: 'Dev',
    component: require('@views/Dev.vue').default
  },
  {
    path: '/acknowledge/:hash',
    name: 'Acknowledge',
    component: Acknowledge,
  },
]

if (process.env.VUE_APP_MODE.toLowerCase() === AppMode.practitioner) {
  routes.push(
    {
      path: '/',
       // @ts-ignore
      redirect: '/practitioner/appointments'
    },
    {
      path: '/practitioner/appointment/new/:step?/:patientId?/:appointmentId?',
      name: 'PractitionerAppointmentNew',
      component: PractitionerAppointmentNew,
      props: false,
      meta: {
       // accessRoles: [PractitionerRoles.new]
      }
    },
    {
      path: '/practitioner/appointments',
      name: 'PractitionerAppointments',
      component: PractitionerAppointmentsGrid,
      props: false,
      meta: {
       // accessRoles: [PractitionerRoles.orders]
      },
      children: [{
        path: '/practitioner/appointments/appointment-details/:id?',
        name: 'appointment-details',
        component:  AppointmentGridDetails,
        props: false,
        meta: {
          // accessRoles: [PractitionerRoles.orders]
         },
      }]
    },
    {
      path: '/practitioner/episodes',
      name: 'PractitionerEpisodes',
      component: PractitionerEpisodesGrid,
      props: false,
      meta: {
       // accessRoles: [PractitionerRoles.episodes]
      },
      children: [{
        path: '/practitioner/episodes/episode-details/:id?',
        name: 'episode-details',
        component:  PatientEpisodeGridDetails,
        props: false,
        meta: {
          // accessRoles: [PractitionerRoles.alerts]
         },
      }]
    },
    {
      path: '/practitioner/alerts',
      name: 'PractitionerAlerts',
      component: PractitionerAlerts,
      props: false,
      meta: {
       // accessRoles: [PractitionerRoles.alerts]
      },
      children: [{
        path: '/practitioner/alerts/alert-details/:id?',
        name: 'alert-details',
        component:  AlertGridDetails,
        props: false,
        meta: {
          // accessRoles: [PractitionerRoles.alerts]
         },
      }]
    },
    {
      path: '/practitioner/tiles',
      name: 'PractitionerTiles',
      component: PractitionerTiles,
      props: false,
      meta: {
       // accessRoles: [PractitionerRoles.tiles]
      }
    },
    {
      path: '/',
      name: 'Home',
      component: PractitionerHome,
      props: false,
      meta: {
      //  accessRoles: [PractitionerRoles.home]
      }
    },
    {
      path: '/share/:shareAccessToken',
      name: 'ShareViewer',
      component: ShareViewer,
      props: false
    },
    {
      path:'/practitioner/patient/:patientId',
      name: 'PatientDetails',
      component: PatientDetails,
      props:true,
      children: [{
        path: '/practitioner/patient/:patientId/appointment-details/:id?',
        name: 'patient-appointment-details',
        component:  AppointmentGridDetails,
        props: true
      }]
    }
  );
}
else if (process.env.VUE_APP_MODE.toLowerCase() === AppMode.practitionerSchedule) {
  routes.push(
    {
      path: '/',
      name: 'Home',
      component: PractitionerScheduleHome,
      props: false
    },
    {
      path: '/practitioner/schedule',
      name: 'PractitionerScheduleHome',
      component: PractitionerScheduleHome,
      props: false
    }
  );
} else if (process.env.VUE_APP_MODE.toLowerCase() === AppMode.patient) {
  routes.push(
    {
      path: '/a/:appointmentId',
      // @ts-ignore
      redirect: '/appointment/:appointmentId'
    },
    {
      path: '/',
      name: 'PatientAppointments',
      component: PatientAppointments,
      props: false
    },
    {
      path: '/profile',
      name: 'PatientProfile',
      component: PatientProfile,
      props: false
    },
    {
      path: '/settings',
      name: 'PatientSettings',
      component: PatientSettings,
      props: false
    },
    {
      path: '/appointment/:appointmentId',
      name: 'AppointmentQuestionnaire',
      component: PatientAppointment,
      props: false
    },
    {
      path: '/appointment/:appointmentId/payment',
      name: 'AppointmentPayment',
      component: PatientAppointment,
      props: {
        currentCarouselPageName: 'Payment'
      }
    },
    {
      path: '/appointment/booking/clinics/:appointmentAccessCode',
      name: 'AppointmentClinics',
      component: PatientAppointmentClinics,
      props: false
    },
    {
      path: '/appointment/booking/slots',
      name: 'AppointmentSlots',
      component: PatientAppointmentSlots,
      props: false
    },
    {
      path: '/appointment/booking/slots-2',
      name: 'AppointmentSlots',
      component: PatientAppointmentSlotsWithinHour,
      props: false
    },
    {
      path: '/share/:shareAccessToken',
      name: 'ShareViewer',
      component: ShareViewer,
      props: false
    },
    {
      path: '/c/:appointmentId',
      name: 'SMS Appointment'
    },
    {
      path: '/payment',
      name: 'Payment'
    },
    {
      path: '/default',
      name: 'Default'
    },
  );
} else if (process.env.VUE_APP_MODE.toLowerCase() === AppMode.patientCancellation) {
  routes.push(
      {
        path: '/',
        name: 'PatientAppointments',
        component: PatientCancellationAppointmentGrid,
        props: false
      },
      {
        path: '/reschedule/clinics/:appointmentId',
        name: 'PatientAppointmentRescheduleClinics',
        component: PatientCancellationAppointmentRescheduleClinics,
        props: false
      },
      {
        path: '/reschedule/slots1',
        name: 'PatientAppointmentRescheduleSlots1',
        component: PatientCancellationAppointmentRescheduleSlots1,
        props: false
      },
      {
        path: '/reschedule/slots2',
        name: 'PatientAppointmentRescheduleSlots2',
        component: PatientCancellationAppointmentRescheduleSlots2,
        props: false
      },
      {
        path: '/reschedule/confirmation',
        name: 'PatientAppointmentRescheduleConfirmation',
        component: PatientCancellationAppointmentRescheduleConfirmation,
        props: false
      }
  );
} else {
  console.error('Invalid VUE_APP_MODE specified: ' + process.env.VUE_APP_MODE)
}

routes.push({
  path: '*',
  // @ts-ignore
  redirect: '/'
});

export default routes;

// Redirect any unmatched routes to the 404 page. This may
// require some server configuration to work in production:
// https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations

// Lazy-loads view components, but with better UX. A loading view
// will be used if the component takes a while to load, falling
// back to a timeout view in case the page fails to load. You can
// use this component to lazy-load a route with:
//
// component: () => lazyLoadView(import('@views/my-view'))
//
// NOTE: Components loaded with this strategy DO NOT have access
// to in-component guards, such as beforeRouteEnter,
// beforeRouteUpdate, and beforeRouteLeave. You must either use
// route-level guards instead or lazy-load the component directly:
//
// component: () => import('@views/my-view')
//
// function lazyLoadView(AsyncView) {
//   const AsyncHandler = () => ({
//     component: AsyncView,
//     // A component to use while the component is loading.
//     loading: require('@views/_loading.vue').default,
//     // Delay before showing the loading component.
//     // Default: 200 (milliseconds).
//     delay: 400,
//     // A fallback component in case the timeout is exceeded
//     // when loading the component.
//     error: require('@views/_timeout.vue').default,
//     // Time before giving up trying to load the component.
//     // Default: Infinity (milliseconds).
//     timeout: 10000,
//   })
//
//   return Promise.resolve({
//     functional: true,
//     render(h, { data, children }) {
//       // Transparently pass any props or children
//       // to the view component.
//       return h(AsyncHandler, data, children)
//     },
//   })
// }

<template>
  <v-text-field
      v-model="search"
      :disabled="disabled"
      prepend-icon="mdi-account-search"
      :label="label"
      single-line
      hide-details
  ></v-text-field>
</template>


<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  props: {
      value: {
        type: String
      },
      label:{
          type:String,
          default:'Search'
      },
      disabled: {
        type: Boolean,
        default: false
      }
  },
  data() {
    return {
      search: '',
      debounce: 0
    };
  },
  methods: {
    setValue() {
      this.$emit('input', this.search)
    }
  },
  watch: {
    search(val) {
      if (this.debounce) {
        clearTimeout(this.debounce)
      }

      this.debounce = setTimeout(this.setValue, 600)
    }
  }
})
</script>

<template>
  <v-card :disabled="disabled" :style="cssStyle" elevation="4">
    <v-card-text class="py-2">
      <v-btn
        v-if="sharable"
        :color="selected ? 'success' : 'white'"
        fab
        small
        absolute
        top
        right
      >
        <v-icon v-if="selected" color="white">mdi-check-bold</v-icon>
        <v-icon v-else color="primary">mdi-check-outline</v-icon>
      </v-btn>
      <v-container>
        <v-row align="center">
          <v-col cols="12" lg="6" class="py-0">
            <p class="mb-2 caption">{{ $_t('Procedure') }}</p>
            <p class="mb-6 subtitle-2">
              {{ appointmentProcedure.procedure.name }}
            </p>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pt-0 pt-sm-2"
            :class="$vuetify.breakpoint.xs ? '' : 'text-right'"
          >
            <a
              v-if="
              appointmentProcedure.procedure.scan
                .webBookingScanPreparationDocumentLink
            "
              :href="
              appointmentProcedure.procedure.scan
                .webBookingScanPreparationDocumentLink
            "
              class="body-2 blue--color text-decoration-none"
              target="_blank"
              v-show="isUpcoming"
            ><span>{{ $_t('How to prepare for the examination?') }}</span>
              <v-icon class="d-none d-sm-inline ml-1" color="#0078FF" left>
                mdi-calendar-check
              </v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" lg="6" class="py-0">
            <p class="mb-2 caption">{{ $_t('Date / Time') }}</p>
            <p class="mb-6 subtitle-2">
              {{
                _formatDateTime(appointment.date, appointmentProcedure.startTime)
              }}
            </p>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row align="center">
          <v-col cols="12" lg="6" class="py-0">
            <p class="mb-2 caption">{{ $_t('Status') }}</p>
            <p class="mb-6 subtitle-2">
              <label
                class="d-inline-block py-2 px-4"
                :style="{
                backgroundColor: (
                  appointmentProcedure.status.colorCodeWeb ||
                  appointmentProcedure.status.colorCode
                ).replace('^', '#'),
                borderRadius: '5px'
              }"
              >
                {{
                  appointmentProcedure.status.nameWeb ||
                  appointmentProcedure.status.name
                }}
              </label>
            </p>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0">
          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
        <v-row v-if="appointment.clinic" align="center">
          <v-col cols="12" lg="6" class="py-0">
            <p class="mb-2 caption">{{ $_t('Clinic') }}</p>
            <p class="mb-2 subtitle-2">{{ appointment.clinic.name }}</p>
            <p class="mb-6 body-2">{{ appointment.clinic.address }}</p>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pt-0 pt-sm-2"
            :class="$vuetify.breakpoint.xs ? '' : 'text-right'"
          >
            <a
              v-if="appointment.clinic.lat && appointment.clinic.lng"
              @click="showMap(appointment.clinic)"
              class="body-2 blue--color"
            ><span>{{ $_t('Show on map') }}</span>
              <v-icon class="d-none d-sm-inline ml-1" color="#0078FF" left>
                mdi-map-marker
              </v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row class="pa-0 ma-0" v-if="appointment.clinic">
          <v-col cols="12" class="pa-0 ma-0">
            <v-divider class="mb-2"></v-divider>
          </v-col>
        </v-row>
      </v-container>
      <!-- CANCEL DIALOG -->
      <v-dialog v-model="cancelDialog" max-width="600">
        <v-card class="pa-4">
          <v-card-title class="title justify-center">
            <span style="word-break: break-word">{{
                $_t('Are you sure you wish to cancel your appointment?')
              }}</span>
          </v-card-title>
          <v-card-text v-if="appointment.appointmentProcedures.length > 1">
            <h4 class="my-4">
              {{
                $_t('All procedures from this appointment will be cancelled:')
              }}
            </h4>
            <p
              class="mt-0 mb-2"
              v-for="appProc in appointment.appointmentProcedures"
              :key="appProc.id"
            >
              {{ appProc.procedure.name }}
            </p>
          </v-card-text>
          <v-card-actions class="justify-center">
            <v-btn @click="cancelDialog = false" color="primary" outlined tile>
              {{ $_t('No, go back') }}
            </v-btn>
            <v-btn
              color="error"
              outlined
              tile
              :loading="cancellingAppointment"
              @click="cancelAppointment(appointment.id)"
            >
              {{ $_t('Yes, cancel') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-text>
    <v-card-actions v-if="$vuetify.breakpoint.xsOnly">
      <v-container>
        <v-row dense>
          <v-col v-if="report && report.contentFileDirectory && report.contentFileName">
            <v-btn
              v-if="config.reportViewer"
              @click="viewOrDownloadReport(appointment.id, appointmentProcedure.id)"
              class="text-none"
              color="#0078FF"
              outlined
              width="100%"
            >
              {{ $_t('Report') }}
              <v-icon small class="ml-1">mdi-eye</v-icon>
            </v-btn>
            <v-btn
              v-else
              @click="viewOrDownloadReport(appointment.id, appointmentProcedure.id, true)"
              class="text-none"
              color="#0078FF"
              outlined
              width="100%"
            >
              <v-tooltip
                top
                v-if="report.appointmentProcedureReportSignatures.length > 0"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    color="green"
                    small
                    class="mr-1"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-shield-check-outline
                  </v-icon>
                </template>
                <span>{{ $_t('This report is signed') }}</span>
              </v-tooltip>
              {{ $_t('Report') }}
              <v-icon small class="ml-1">mdi-download</v-icon>
            </v-btn>

          </v-col>
          <v-col v-else-if="report">
            <span class="subtitle-2 font-weight-regular">
              {{$_t('Report not available for download, please contact the clinic')}}.
            </span>
          </v-col>
          <v-col v-if="appointmentProcedure.reportNotFound">
            <span
              class="subtitle-2 font-weight-regular">{{ $_t('Report for this procedure not available online') }}.</span>
          </v-col>
          <v-col v-if="studyViewerLinkExists">
            <v-btn
              @click="
                generateViewerLink(
                  appointment.id,
                  appointmentProcedure.id,
                  appointmentProcedure.appointmentProcedureStudyId
                )
              "
              class="text-none"
              color="#0078FF"
              outlined
              :loading="viewerLinkLoading"
              width="100%"
            >
              {{ $_t('Examination') }}
              <v-icon class="ml-1" small>mdi-eye</v-icon>
            </v-btn>
            <v-btn
              @click="howToDownloadImagesDialog = true"
              v-if="studyViewerLinkExists && config.patientExaminationImagesEnabled"
              color="primary"
              style="text-transform: none"
              text
              width="100%"
            >
              {{ $_t('How to download images?') }}
            </v-btn>
          </v-col>
          <v-col v-if="cancelAllowed">
            <v-btn
              @click="cancelDialog = true"
              class="text-none"
              color="#0078FF"
              outlined
            >
              {{ $_t('Cancel visit') }}
              <v-icon class="ml-1" small>mdi-cancel</v-icon>
            </v-btn>
          </v-col>
          <v-col v-if="rescheduleAllowed && config.rescheduleEnabled">
            <v-btn
              @click="goToReschedule"
              class="text-none"
              color="#0078FF"
              outlined
            >{{ $_t('Reschedule') }}
              <v-icon class="ml-1" small>mdi-calendar-clock</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-actions>
    <v-card-actions class="pb-4 px-4" v-else>
      <template
        v-if="report && report.contentFileDirectory && report.contentFileName"
      >
        <v-btn
          v-if="config.reportViewer"
          @click="viewOrDownloadReport(appointment.id, appointmentProcedure.id)"
          class="mr-2 text-none"
          color="#0078FF"
          outlined
        >
          {{ $_t('Report') }}
          <v-icon small class="ml-1">mdi-eye</v-icon>
        </v-btn>
        <v-btn
          v-else
          @click="viewOrDownloadReport(appointment.id, appointmentProcedure.id, true)"
          class="mr-2 text-none"
          color="#0078FF"
          outlined
        >
          <v-tooltip
            top
            v-if="report.appointmentProcedureReportSignatures.length > 0"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-icon color="green" small class="mr-1" v-bind="attrs" v-on="on">
                mdi-shield-check-outline
              </v-icon>
            </template>
            <span>{{ $_t('This report is signed') }}</span>
          </v-tooltip>
          {{ $_t('Report') }}
          <v-icon class="ml-1" small>mdi-download</v-icon>
        </v-btn>
      </template>
      <span v-else-if="report"
            class="subtitle-2 font-weight-regular">{{$_t('Report not available for download, please contact the clinic')}}.</span>
      <span v-if="appointmentProcedure.reportNotFound"
            class="subtitle-2 font-weight-regular">{{ $_t('Report for this procedure not available online') }}.</span>
      <v-btn
        @click="
          generateViewerLink(
            appointment.id,
            appointmentProcedure.id,
            appointmentProcedure.appointmentProcedureStudyId
          )
        "
        v-if="studyViewerLinkExists && config.patientExaminationImagesEnabled"
        class="mr-2 text-none"
        color="#0078FF"
        outlined
        :loading="viewerLinkLoading"
      >
        {{ $_t('Examination') }}
        <v-icon class="ml-1" small>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        @click="howToDownloadImagesDialog = true"
        v-if="studyViewerLinkExists && config.patientExaminationImagesEnabled"
        color="primary"
        class="ml-0 mr-2"
        style="text-transform: none"
        text
      >
        {{ $_t('How to download images?') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
        @click="goToCancel"
        v-if="cancelAllowed"
        class="mr-5 text-none"
        color="#0078FF"
        outlined
      >
        {{ $_t('Cancel visit') }}
        <v-icon class="ml-1" small>mdi-close-circle-outline</v-icon>
      </v-btn>
      <v-btn
        @click="goToReschedule"
        v-if="rescheduleAllowed && config.rescheduleEnabled"
        class="text-none"
        color="#0078FF"
        outlined
      >{{ $_t('Reschedule') }}
      </v-btn>
    </v-card-actions>
    <v-dialog persistent v-model="downloadingDocument">
      <v-card>
        <v-card-text class="text-center pa-5">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <h3 class="mt-2">{{ $_t('Your document is being prepared...') }}</h3>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="howToDownloadImagesDialog">
      <v-card min-height="80vh">
        <v-card-title class="text-h5 primary--text">
          {{ $_t('How to download images?') }}
        </v-card-title>
        <v-card-text v-if="config.patientExaminationImagesDownloadManualUrl">
          <iframe
            :src="config.patientExaminationImagesDownloadManualUrl"
            style="border: 0; width: 100%; height: 70vh;"
          />
        </v-card-text>
        <v-card-text v-else>
          <p class="mt-4">
            <v-img src="/img/ImagesDownloadManual.png" contain></v-img>
          </p>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="howToDownloadImagesDialog = false"
          >
            {{ $_t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="reportDialog"
      v-model="reportDialog"
      transition="dialog-bottom-transition"
      :width="reportDialogWidth"
      scrollable
    >
      <v-card width="100%" max-height="95vh" color="#fff">
        <div style="width: 100%; overflow-y: scroll;">
          <pdf
            v-for="i in reportNumPages"
            :key="i"
            :src="reportUrl"
            :page="i"
          ></pdf>
        </div>
        <v-divider />
        <v-card-actions>
          <v-btn
            color="primary"
            text
            @click="downloadReport(reportUrl)"
          >
            {{ $_t('Download') }}
            <v-icon class="ml-1" small>mdi-download</v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="reportDialogHide"
          >
            {{ $_t('Close') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-card>
</template>

<style lang="scss" scoped>
.blue--color {
  color: #0078ff;
}
</style>

<script>
const days = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
]

import pdf from 'vue-pdf'
import config from '@config'
import { format } from 'date-fns'

export default {
  name: 'AppointmentCardComponent',
  components: { pdf },
  props: {
    appointment: {
      type: Object, default: () => {
      }
    },
    appointmentProcedure: {
      type: Object, default: () => {
      }
    },
    report: {
      type: Object, default: () => {
      }
    },
    studyViewerLinkExists: {type: Boolean, default: false},
    disabled: {type: Boolean, default: false},
    sharable: {type: Boolean, default: false},
    selected: {type: Boolean, default: false},
    cssStyle: {type: String, default: ''},
    isCancelled: {type: Boolean, default: false},
    isPast: {type: Boolean, default: false},
    isUpcoming: {type: Boolean, default: false},
  },
  data () {
    return {
      cancelDialog: false,
      howToDownloadImagesDialog: false,
      downloadingDocument: false,
      cancellingAppointment: false,
      examinationImageUrl: null,
      viewerLinkLoading: false,
      reportDownloadAttempts: 0,
      config: config,
      reportUrl: '',
      reportPdf: null,
      reportZoom: 100,
      reportDialog: false,
      reportDialogAppointmentId: '',
      reportDialogAppointmentProcedureId: '',
      reportNumPages: undefined
    };
  },
  mounted () {
  },
  computed: {
    reportDialogWidth () {
      if (this.$vuetify.breakpoint.smAndDown) {
        return '97vw'
      } else if (this.$vuetify.breakpoint.mdOnly) {
        return '85vw'
      } else {
        return '70vw'
      }
    },
    cancelAllowed () {
      if (
        !['N', 'S', 'C'].includes(this.appointment.status.code) ||
        !['N', 'S', 'C'].includes(this.appointmentProcedure.status.code)
      ) {
        config;
        return false;
      }

      const today = new Date();
      const appointmentDate = new Date(this.appointment.date.replace(/-/g, '/').slice(0, 19).replace('T', ' '));
      const startTime = new Date(this.appointmentProcedure.startTime.replace(/-/g, '/').slice(0, 19).replace('T', ' '));
      appointmentDate.setHours(startTime.getHours(), startTime.getMinutes());
      return appointmentDate >= today;
    },
    rescheduleAllowed () {
      // todo: add reschedule logic
      return this.cancelAllowed;
    }
  },
  watch: {},
  methods: {
    reportDialogShow(url) {
      this.reportPdf = pdf.createLoadingTask(url)
      this.reportPdf.promise.then(pdf => {
        this.reportNumPages = pdf.numPages
        this.reportUrl = url
        this.reportDialog = true
      })
    },
    reportDialogHide(url) {
      this.reportDialog = false
      this.reportUrl = ''
      this.reportZoom = 100
    },
    _formatDateTime (appointmentDate, startTime) {
      const dateObj = new Date(appointmentDate.replace(/-/g, '/').slice(0, 19).replace('T', ' '))
      const timeObj = new Date(startTime);
      const dateTxt = this.dateFormatted(dateObj);
      const timeTxt = this.timeFormatted(timeObj);
      const dayName = this.$t(days[dateObj.getDay()]);
      return `${dayName} ${dateTxt} ${timeTxt}`;
    },
    dateFormatted (date) {
      return format(date, config.dateFormat)
    },
    timeFormatted (date) {
      let hours = date.getHours();
      let minutes = date.getMinutes();
      if (hours < 10) {
        hours = '0' + (hours + '');
      }
      if (minutes < 10) {
        minutes = '0' + (minutes + '');
      }
      return hours + ':' + minutes;
    },
    showMap (clinic) {
      window.open(
        'https://www.google.com/maps/search/?api=1&query=' +
        clinic.lat +
        ',' +
        clinic.lng,
        '_blank'
      );
    },
    viewOrDownloadReport (appointmentId, appointmentProcedureId, forceDownload) {
      this.downloadingDocument = true;
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appointmentProcedureId}/report/file`,
        {},
        response => {
          if (this.reportDownloadAttempts > 9) {
            this.$_notify.error(
              this.$_t('The report could not be downloaded. Please refresh the page and try again.')
            );
            this.downloadingDocument = false;
            this.reportDownloadAttempts = 0;
            return;
          }

          if (response.status === 202) {
            setTimeout(() => {
              this.viewOrDownloadReport(appointmentId, appointmentProcedureId, forceDownload)
            }, 500);
          } else if (response.status === 200) {
            response.blob().then(blob => {
              const objectUrl = window.URL.createObjectURL(blob);

              if (config.reportViewer && !forceDownload) {
                this.reportDialogShow(objectUrl)
              } else {
                this.downloadReport(objectUrl)
              }

              this.downloadingDocument = false;
            })
            this.reportDownloadAttempts = 0;
          } else {
            this.$_notify.error(
              this.$_t('The file could not be downloaded.')
            );
            this.downloadingDocument = false;
            this.reportDownloadAttempts = 0;
          }
        },
        error => {
          this.$_notify.error(
            this.$_t('The file could not be downloaded.')
          );
          this.downloadingDocument = false;
          this.reportDownloadAttempts = 0;
        },
        {
          handleResponse: false
        }
      );
    },
    downloadReport(url) {
      const anchor = document.createElement('a');
      anchor.href = url;
      anchor.download = this.report.contentFileName;
      anchor.click();
      window.URL.revokeObjectURL(url);
    },
    cancelAppointment (appointmentId) {
      if (config.cancelDialogEnabled) {
        this.cancellingAppointment = true;
        this.$_rest.post(
          `/patients/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/cancel`,
          {},
          response => {
            this.cancellingAppointment = false;
            this.cancelDialog = false;
            this.$_notify.success('Appointment has been cancelled.')
            this.$emit('appointmentCancelled', this.appointment);
          },
          error => {
            this.cancellingAppointment = false;
            this.cancelDialog = false;
            this.$_notify.error('Could not cancel the appointment.')
          }
        );
      } else {
        this.$_notify.error('Action not allowed, enable it in application configuration')
      }
    },
    goToCancel () {
      if (config.cancelDialogEnabled) {
        this.cancelDialog = true
      } else {
        window.open(config.webBookingContactLink, '_blank');
      }
    },
    goToReschedule () {
      window.open(config.webBookingContactLink, '_blank');
    },
    generateViewerLink (appointmentId, appProcId, appointmentProcedureStudyId) {
      this.viewerLinkLoading = true;
      const pacsViewerCode = (config.ignorePacsViewerCode) ? 'ORIGINAL' : config.pacsViewerCode;
      this.$_rest.get(
        `/patients/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appProcId}/appointment-procedure-studies/${appointmentProcedureStudyId}/viewer-link/${pacsViewerCode}`,
        {},
        resp => {
          if (resp.data.viewerUrl) {
            window.open(resp.data.viewerUrl, '_blank');
          } else {
            this.$_notify.error('Could not generate a viewer link');
          }
          this.viewerLinkLoading = false;
        }
      );
    }
  }
};
</script>

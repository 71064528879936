<template>
    <v-card
        flat
        light
        class="ma-0"
        color="rgba(255,255,255,0)"
        max-height="110"
        width="100%"
    >
        <v-row>
            <v-col cols="12" lg="12" md="12" sm="12" class="mt-0 mb-0 pb-1">
                <h2 class="font-weight-regular">
                    {{  
                        `${getPatientDetails.fullName || [getPatientDetails.firstName, getPatientDetails.lastName].filter(x => x).join(' ')}`
                    }}<span class="body-1"> {{ `${getAge()}, ${getSex()}` }}</span>
                </h2>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" lg="3" md="3" sm="12" class="pt-0 mt-0">
                <h5 class="font-weight-regular">
                    {{ $_t('Address') }}:&nbsp;<span class="font-weight-bold">{{
                        getAddress()
                    }}</span>
                </h5>
                <h5 class="font-weight-regular">
                    {{ $_t('Post code') }}:&nbsp;<span class="font-weight-bold">{{
                        `${
                            getPatientDetails.postCode
                                ? getPatientDetails.postCode
                                : ''
                        }`
                    }}</span>
                </h5>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="12" class="pt-0 mt-0">
              <h5 class="font-weight-regular">
                    {{ $_t('NHS No') }}:&nbsp;<span class="font-weight-bold">{{
                        `${
                            getPatientDetails.number1
                                ? getPatientDetails.number1
                                : ''
                        }`
                    }}</span>
                </h5>
                <h5 class="font-weight-regular" v-if="getPatientDetails.idExternal">
                    {{ $_t('BHR No') }}:&nbsp;<span class="font-weight-bold">{{
                        `${
                            getPatientDetails.idExternal
                                ? getPatientDetails.idExternal
                                : ''
                        }`
                    }}</span>
                </h5>
            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" class="pt-0 mt-0">
                <h5 class="font-weight-regular">
                    {{ $_t('Born') }}:&nbsp;<span class="font-weight-bold">{{ getBornDate() }}</span>
                </h5>
                <h5 class="font-weight-regular">
                    {{ $_t('Contact') }}:&nbsp;<span class="font-weight-bold">{{
                        getPhones()
                    }}</span>
                </h5>
            </v-col>
            <v-col cols="12" lg="5" md="5" sm="10" class="pt-0 mt-0 ml-0"
                   v-if="getPatientAlerts && getPatientAlerts.length">
              <h5 class="font-weight-regular" v-if="getPatientDetails.idExternal">
                {{ $_t('Patient alerts') }}:
                <v-badge
                  class="ml-2"
                  :content="getPatientAlerts.length"
                  color="red"
                />
              </h5>
                  <PatientAlertsList
                    :alerts="getPatientAlerts"
                    :alert-types="alertTypes"
                  />

            </v-col>
        </v-row>
        <v-row v-if="extendedBanner">
            <v-col cols="12" lg="4" md="4" sm="12" class="pt-0 mt-0">

            </v-col>
            <v-col cols="12" lg="2" md="2" sm="6" class="pt-0 mt-0">

            </v-col>
            <v-col cols="12" lg="5" md="5" sm="10" class="pt-0 mt-0 ml-0"
                   v-if="getPatientAlerts.length"
            >
                <PatientAlertsList
                    :alerts="getPatientAlerts"
                    :alert-types="alertTypes"
                />
            </v-col>
        </v-row>
        <v-row>
            <v-fab-transition>
                <v-btn
                    color="primary"
                    dark
                    absolute
                    bottom
                    right
                    fab
                    :loading="getAppointmentLoading"
                    @click.stop.prevent="$root.$emit('open-navbar',true)"
                >
                    <v-badge v-if="getAppointmentCount > 0"
                             color="primary"
                             :content="getAppointmentCount"
                             bordered
                             offset-x="-25"
                             offset-y="-8"
                    >
                    </v-badge>
                    <v-badge v-if="getAppointmentFilteredCount > 0"
                             color="red"
                             :content="getAppointmentFilteredCount"
                             bordered
                             offset-x="-25"
                             offset-y="25"
                    >
                    </v-badge>
                    <v-icon large>mdi-calendar-text</v-icon>
                </v-btn>
            </v-fab-transition>
        </v-row>
    </v-card>
</template>
<script>
import PatientAlertsList from '@components/patient/PatientAlertsList.vue'
import useDateHelpers from '../../helpers/useDateHelpers'

const {useHumanDate, useDifferenceInYears} = useDateHelpers()
import {PatientBannerEnum} from '../../enum/PatientBanner'

export default {
    components: {
        PatientAlertsList
    },
    data() {
        return {
            extendedBanner: false,
            alertTypes: {
                red: {
                    icon: 'alert',
                    iconColor: 'white',
                    bgColor: 'red'
                },
                amber: {
                    icon: 'alert',
                    iconColor: 'black',
                    bgColor: 'yellow'
                },
                green: {
                    icon: 'alert',
                    iconColor: 'white',
                    bgColor: 'green'
                }
            }
        }
    },
    watch: {
        extendedBanner(val) {
            if (typeof panelNumber !== 'undefined') {
                this.$root.$emit('extend', true)
            } else {
                this.$root.$emit('extend', false)
            }
        }
    },
    computed: {
        getPatientAlerts() { //alertJson
            const alerts = this.getPatientDetails.alertJson
            return alerts ? alerts : []
        },
        getPatientDetails() {
            return this.$store.getters['patient/patient']
        },
        getAppointmentCount() {
            return this.$store.getters['patient/appointmentCount']
        },
        getAppointmentFilteredCount() {
            return this.$store.getters['patient/appointmentFilteredCount']
        },
        getAppointmentLoading() {
            return this.$store.getters['patient/appointmentLoading']
        }
    },
    methods: {
        getSex() {
            return this.getPatientDetails.sex
                ? this.getPatientDetails.sex == 'M'
                    ? 'Male'
                    : 'Female'
                : ''
        },
        getAge() {
            return this.getPatientDetails.dateOfBirth ? useDifferenceInYears(new Date(), this.getPatientDetails.dateOfBirth) : ''
        },
        getBornDate() {
            return this.getPatientDetails.dateOfBirth ? useHumanDate(this.getPatientDetails.dateOfBirth) : ''
        },
        getAddress() {

            const a1 = this.getPatientDetails.address1
                ? this.getPatientDetails.address1
                : ''
            const a2 = this.getPatientDetails.address2
                ? this.getPatientDetails.address1
                : ''
            const a3 = this.getPatientDetails.address3
                ? this.getPatientDetails.address1
                : ''
            const town = this.getPatientDetails.town
                ? this.getPatientDetails.town
                : ''
            const postCode = this.getPatientDetails.postCode
                ? this.getPatientDetails.postCode
                : ''
            const countryCode = this.getPatientDetails.countryCode
                ? this.getPatientDetails.countryCode
                : ''
            const fullAddress = [a1, a2, a3, town, postCode, countryCode].filter(el => el)
                .join(', ')
            return fullAddress
        },
        getPhones() {
            const p1 = this.getPatientDetails.phoneNumber1
                ? this.getPatientDetails.phoneNumber1
                : ''
            const p2 = this.getPatientDetails.phoneNumber2
                ? ',' + this.getPatientDetails.phoneNumber2
                : ''
            return `${p1}${p2}`
        }
    }
}
</script>
<style scoped>
.full-width {
    margin: 0px;
    margin-left: -15px;
    padding: 0px;
    width: 100%;
    position: absolute;
    bottom: 2px;
}
</style>

const state = {
  appointment: null,
  list:[],
  patientEpisodeId: null,
  patientLocationId: null
}

const actions = {
  setAppointment ({ commit }, appointment) {
    commit('setAppointment', appointment)
    return true
  },
  setAppointmentsList ({ commit }, list) {
    commit('setAppointmentsList', list)
    return true
  },
  setPatientEpisodeId({ commit }, episodeId) {
    commit('setPatientEpisodeId', episodeId)
    return true
  },
  setPatientLocationId({ commit }, locationId) {
    commit('setPatientLocationId', locationId)
    return true
  }
}

const mutations = {
  setAppointment (state, appointment) {
    state.appointment = appointment
  },
  setAppointmentsList (state, list) {
    state.list = list
  },
  shiftAppointmentsList (state) {
    state.list.shift()
  },
  setPatientEpisodeId (state, episodeId) {
    state.patientEpisodeId = episodeId
  },
  setPatientLocationId (state, locationId) {
    state.patientLocationId = locationId
  }
}

const getters = {
  appointment (state) {
    return state.appointment
  },
  list (state) {
    return state.list
  },
  patientFullName (state) {
    return [state.appointment.patient.firstName, state.appointment.patient.middleName, state.appointment.patient.lastName].filter(el => el && el.trim()).join(' ')
  },
  patientEpisodeId (state) {
    return state.patientEpisodeId
  },
  patientLocationId (state) {
    return state.patientLocationId
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

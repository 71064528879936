import {MenuItemsInterface} from 'src/interfaces/MenuItems'
import {PractitionerRoles} from '../enum/PractitionerRoles'


export const MenuItems: MenuItemsInterface[] = [
  {id:'home',title: 'Home', icon: 'mdi-home', linkTo: '/',roles:[PractitionerRoles.home]},
  {id:'new',title: 'New', icon: 'mdi-clipboard-plus', linkTo: '/practitioner/appointment/new',roles:[PractitionerRoles.new]},
  {id:'orders',title: 'Orders/Appointments', icon: 'mdi-account-group', linkTo: '/practitioner/appointments',roles:[PractitionerRoles.orders]},
  {id:'alerts',title: 'Alerts', icon: 'mdi-alert', linkTo: '/practitioner/alerts', badge: 1,roles:[PractitionerRoles.alerts]},
  {id:'episodes',title: 'Episodes', icon: 'mdi-history', linkTo: '/practitioner/episodes',roles:[PractitionerRoles.episodes]},
  {id:'tiles',title: 'Tiles', icon: 'mdi-overscan', linkTo: '/practitioner/tiles',roles:[PractitionerRoles.tiles]},
  {id:'search', title: 'Patient Search', icon: 'mdi-account-search-outline', linkTo: '',roles:[]}
]

<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <grid
        v-if="showGrid"
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        :data="items"
        :loading="loading"
        @rowAdd="rowAdd"
        @refresh="getData"
        @rowClick="rowClicked"
        :disable-pagination="false"
        :hide-default-footer="loading"
        @grid-options-changed="gridOptionsChanged"
        :server-items-length="rowsNumber"
        @grid-search="gridSearch"
        :disable-sort="false"
        :hide-filters="true"
        :search-value.sync="filter.searchValue"
        :show-grid-settings="true"
      >
        <template v-slot:filters>
          <v-col v-if="showFilters" cols="12" lg="8" class="mt-md-1">
            <v-select
              v-model="appointmentStatuses"
              :items="filterData.appointmentStatuses"
              dense
              chips
              :label="$_t('Appointment statuses')"
              item-text="nameWeb"
              item-value="code"
              multiple
              @blur="getData(true)"
            >
            </v-select>
            <div v-if="showPracticeSwitch">
              <v-switch
                class="mb-0"
                v-model="onlyCurrentPractice"
                :label="onlyCurrentPractice ? $_t('All Referrals Within Current Practice') : $_t('My Referrals Only (All practices)')"
              />
              <div>
                <span class="text-caption font-weight-light">
                {{ $_t('Toggle On - You can see ALL referrals made within the current practice you are in.') }}
                </span>
              </div>
              <div>
                <span class="text-caption font-weight-light">
              {{ $_t('Toggle Off - You will see only referrals that YOU have made, regardless of which practice you are currently in.') }}
                </span>
              </div>
            </div>
          </v-col>
          <v-col v-if="false" cols="12" lg="2" class="md-1">
            <category-dropdown
              v-model="filter.appointmentCategoryId"
              dense
              :label="$_t('Patient group')"
            />
          </v-col>
          <v-col v-if="false" cols="12" lg="2" class="md-1">
            <v-select
              v-model="filter.specialityId"
              class="pa-3"
              :label="$_t('Speciality')"
              :items="specialitiesList"
              item-text="name"
              item-value="id"
              :clearable="true"
            ></v-select>
          </v-col>
        </template>
      </grid>
    </v-container>
  </practitioner-layout>
</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import appointmentsPracticesHeaders from '@src/data/dataset/gridHeaders/appointmentPractices'
import CategoryDropdown from '@components/appointment/AppointmentCategoryDropdown.vue'
import config from '@src/config'

export default {
  name: 'AppointmentGrid',
  components: {
    CategoryDropdown,
    Grid,
    PractitionerLayout
  },
  data () {
    return {
      items: [],
      loading: false,
      headersPresetType:'appointmentPractices',
      headersPreset:appointmentsPracticesHeaders,
      render:true,
      gridOptions: {},
      rowsNumber: 0,
      gridSearchPhrase: '',
      restRequest: null,
      onlyCurrentPractice: true,
      filterData: {
        appointmentStatuses: []
      },
      filter: {
        searchValue: ''
      },
      specialitiesList: [],
      appointmentStatuses: [],
      getDataRequest: undefined,
      showGrid: false
    }
  },
  methods: {
    getAppointmentStatuses() {
      this.$_rest.get(`/practitioners/${this.$store.getters['app/currentUserId']}/appointment-statuses`, {
        isActive: 1
      }, response => {
        this.filterData.appointmentStatuses = response.data.filter(status => status.nameWeb).sort((a, b) => (a.nameWeb > b.nameWeb) ? 1 : -1)
      }, error => {
        console.error(error)
        this.$_notify.error('Failed to load appointment statuses')
      }, {
        prefixRoutesWithOrganizationId: false
      });
    },
    getData (resetPage = false) {
      if (resetPage) {
        this.gridOptions.page = 1
      }
      const url = this.onlyCurrentPractice ? `/practitioners/${this.$store.getters['app/currentUserId']}/datasets/appointments` :
        `/practitioners/${this.$store.getters['app/currentUserId']}/datasets/referred-appointments`
      this.items = []
      this.loading = true
      if (this.restRequest) {
        this.restRequest.abort();
        this.restRequest = null
      }
      if (this.getDataRequest) {
        this.getDataRequest.abort()
      }
      this.getDataRequest = this.restRequest = this.$_rest.get(url ,{
          appointmentStatuses: this.appointmentStatuses || [],
          filter: this.gridFilter || {},
          sorting: {columns: this.gridOptions.sortBy, desc: this.gridOptions.sortDesc},
          limit: this.gridOptions.itemsPerPage || 50,
          offset: ((this.gridOptions.page || 1) - 1) * this.gridOptions.itemsPerPage
        }, response => {
          this.rowsNumber = response.meta.rowsNumber
          this.items = this.mapDatasetResponse(response)
          this.$store.dispatch('appointment/setAppointmentsList', this.items).then(() => {
            this.loading = false
          })
        }, error => {
          this.loading = false
          if (error.code !== this.$_rest.const.ABORT_ERROR) {
            this.$_notify.error(error)
          }
        })
    },
    rowAdd () {
    },
    rowClicked (row) {
      this.$store.commit('appointment/setAppointmentsList', this.items)
      this.$router.push({ name: 'appointment-details', params: { id: row.appointmentId } })
    },
    gridOptionsChanged(options) {
      this.gridOptions = options
      this.getData()
    },
    gridSearch(val) {
      this.gridSearchPhrase = val
      this.getData(true)
    },
    fetchAllSpecialities(){
      const practitionerId = this.$store.getters['app/practitioner'].id
      return new Promise((resolve, reject) => {
        this.$_rest.get(`/practitioners/${ practitionerId }/specialities`, {
          groups: ['all', 'practitioner_speciality', 'practitioner_all', 'practitioner_practice_default_speciality'].join(','),
          limit: 10000
        }, (response) => {
          this.specialitiesList = response.data ? response.data : []
          resolve(response)
        }, error => {
          reject(error)
        })})
    },
  },
  computed:{
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    },
    showPracticeSwitch(){
      return config.showPracticeSwitch
    },
    showFilters() {
      return !config.hideAppointmentGridFilters
    },
    gridFilter() {
      let filter = {}
      if(this.onlyCurrentPractice){
        filter = {
          practitionerPracticeId: this.$store.getters['app/practitionerPractice'].id,
        }
      } else {
        filter = {
          practitionerId: this.$store.getters['app/practitioner'].id,
        }
      }

      const searchableColumns = [
        'searchPatientFullName',
        'searchClinicNameShort',
        'searchProcedureName',
        'searchInsurerName',
        'searchClinicScanRoomCode',
        'searchPatientNumber1',
        'searchPatientIdShort',
        'searchPatientIdExternal',
        'searchAppointmentIdShort'
      ];

      if (this.gridSearchPhrase) {
        searchableColumns.forEach((col) => {
          filter[col] = {
            type: 'like',
            x: this.gridSearchPhrase,
            connector: 'OR'
          }
        })
      }

      return filter
    }
  },
  watch:{
    refreshed(val) {
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    },
    onlyCurrentPractice(){
      this.getData()
    }
  },
  mounted () {
    if (this.$store.getters['app/practitionerPractice']) {
      this.showGrid = true
      this.getAppointmentStatuses()
      this.fetchAllSpecialities()
    }
  }
}
</script>

<template>
  <pdf v-bind="$attrs" />
</template>
<script>
import pdf from 'vue-pdf'

export default {
  name: 'PdfViewer',
  components: {
    pdf,
  },
  props: {
  },
  errorCaptured() {
    return false
  },
}
</script>

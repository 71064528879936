<template>
<v-list>
  <template >
    <div v-for="(item, index) in contactsList" :key="index">
     <v-list-item
      :key="index"
      @click="editContactDetails(index)"
    >
      <v-list-item-content>
        <v-list-item-title class="text-uppercase font-weight-medium">
          <v-icon small>
            mdi-account
          </v-icon>
          {{ getFullName(item) }}
        </v-list-item-title>
        <v-list-item-subtitle>
          <v-icon small>
            mdi-phone
          </v-icon>
          {{ item.mobileNumber1 }}
        </v-list-item-subtitle>
        <v-list-item-subtitle>
          <v-icon  small>
            mdi-tag
          </v-icon>
          {{ item.type.name }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-action>
        <v-btn
          color="error"
          fab
          dark
          x-small
          @click.stop="$emit('contact-delete', index)"
        >
          <v-icon>mdi-trash-can</v-icon>
        </v-btn>
      </v-list-item-action>
      </v-list-item>
      <v-divider v-if="index < contactsList.length - 1" :key="`divider-${index}`"></v-divider>
    </div>

  </template>
</v-list>

</template>

<script>
export default {
  props: {
    contactsList: {
      type: Array,
      required: true
    },
  },
  data() {
    return {
      selected: [],
    };
  },
  methods: {
    editContactDetails(index) {
      this.$emit('contact-edit', index);
    },
    getFullName(item){
      return [item.title, item.firstName, item.lastName].map(el => el?.trim()).filter(el => el).join(' ')
    }
  }
};
</script>

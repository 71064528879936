<template>
  <v-container class="pa-0 ma-0">
    <v-card elevation="0">
      <v-card-text class="scheduler-background">
        <div
          v-if="schedulerLoading || schedulingAppointment"
          class="text-center pt-12 pb-6"
        >
          <v-progress-circular
            :size="75"
            color="primary"
            indeterminate
          ></v-progress-circular>
          <br /><br />
          <span v-if="schedulerLoading">{{
              $_t('Fetching availability...')
            }}</span>
          <span v-if="schedulingAppointment">{{
              $_t('Booking appointment...')
            }}</span>
        </div>
        <div v-else class="pt-5">
          <div class="">
            <v-row class="scheduler-background">
              <v-col
                v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView"
                cols="12"
                class="text-center primary--text font-weight-bold subtitle-1"
              >
                <v-row v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView">
                  <v-col cols="2" class="px-0 pt-5 text-right">
                    <v-btn v-if="false" icon color="primary" @click="prevMonth">
                      <v-icon x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="8">
                    <p class="mt-3">{{ selectedMonthText }}</p>
                  </v-col>
                  <v-col cols="2" class="px-0 pt-5 text-left">
                    <v-btn v-if="false" icon color="primary" @click="nextMonth">
                      <v-icon x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                v-if="$vuetify.breakpoint.mdAndUp"
                cols="12"
                class="mb-4 text-center primary--text font-weight-bold subtitle-1"
              >
                <v-btn
                  v-if="false"
                  icon
                  color="primary"
                  class="mt-n1 mr-6"
                  @click="prevMonth"
                >
                  <v-icon x-large>mdi-chevron-left</v-icon>
                </v-btn>
                <span>{{ selectedMonthText }}</span>
                <v-btn
                  v-if="false"
                  icon
                  color="primary"
                  class="mt-n1 ml-6"
                  @click="nextMonth"
                >
                  <v-icon x-large>mdi-chevron-right</v-icon>
                </v-btn>
              </v-col>
              <v-col cols="12" class="mb-4">
                <div
                  v-if="$vuetify.breakpointsm && !alternativeMobileView"
                  class="mb-2 body-1 black--text text-center"
                >
                  <p>{{ $_t('Week') }} {{ selectedWeekText }}</p>
                </div>
                <v-row v-if="$vuetify.breakpointsm && !alternativeMobileView" align="center">
                  <v-col cols="3" class="px-0 pt-5 text-center">
                    <v-btn v-if="prevWeekAvailable" @click="prevWeek" icon color="primary">
                      <v-icon x-large>mdi-chevron-left</v-icon>
                      <br />
                      <span style="font-size: 10px;">{{ $_t('Prev') }}<br />{{ $_t('week') }}</span>
                    </v-btn>
                  </v-col>
                  <v-col cols="6">
                    <v-select
                      v-model="selectedDay"
                      :items="daysMobile"
                      :label="$_t('Select day')"
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="3" class="px-0 pt-5 text-center">
                    <v-btn icon color="primary" @click="nextWeek">
                      <span style="font-size: 10px;">{{ $_t('Next') }}<br />{{ $_t('week') }}</span>
                      <br />
                      <v-icon x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.mdAndUp">
                  <v-col cols="1">
                    <v-btn
                      v-if="prevWeekAvailable"
                      @click="prevWeek"
                      icon
                      color="primary"
                      class="mt-2"
                    >
                      <v-icon x-large>mdi-chevron-left</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col cols="10">
                    <v-row>
                      <v-col
                        v-for="day in days"
                        :key="day"
                        class="text-center"
                      >
                        <div
                          style="height: 100%"
                          @click="onDayClicked(day)"
                          v-bind:class="{
                            'scheduler-button': true,
                            'scheduler-button-selected': selectedDay === day,
                            'scheduler-button-disabled': !getNumberOfSlotsInDay(
                              day
                            )
                          }"
                        >
                          {{ getDayLabel(day) }}<br />
                          {{ $_t(getDayLabel(day, 'weekday')) }}<br />
                          {{ getNumberOfSlotsInDay(day) }}
                          {{
                            getNumberOfSlotsInDay(day) === 1
                              ? $_t('slot')
                              : $_t('slots')
                          }}
                        </div>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="1">
                    <v-btn
                      icon
                      color="primary"
                      class="mt-2"
                      @click="nextWeek"
                    >
                      <v-icon x-large>mdi-chevron-right</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" v-if="selectedDay && timeSlots.length">
                <v-row v-if="$vuetify.breakpointsm && !alternativeMobileView">
                  <v-col cols="2" class="px-0 pt-2"> </v-col>
                  <v-col cols="8">
                    <div class="mb-2 body-1 black--text">
                      <p>
                        {{ selectedDayTextMobile }}
                        <span style="white-space: nowrap;">
                          {{ availableSlotsText }}</span
                        >
                        <br />
                        {{ howManySlotsText }}
                      </p>
                    </div>
                    <v-select
                      v-model="selectedTimeSlot"
                      :items="timeSlots"
                      :label="$_t('Select time slot')"
                      outlined
                      hide-details
                    ></v-select>
                  </v-col>
                  <v-col cols="2" class="px-0 pt-2"> </v-col>
                </v-row>
                <v-row v-if="$vuetify.breakpoint.mdAndUp">
                  <v-col cols="1" class="px-0 pt-2"> </v-col>
                  <v-col cols="10">
                    <div
                      class="d-flex mb-2 body-1 black--text"
                      style="justify-content: space-between"
                    >
                      <span>{{ selectedDayTextDesktop }}</span>
                      <span
                      >{{ availableSlotsText }},
                        {{ howManySlotsText }}</span
                      >
                    </div>
                    <v-chip
                      v-for="(timeSlot, index) in timeSlots"
                      :key="index"
                      class="mr-4 mb-4"
                      color="primary"
                      text-color="white"
                      label
                      @click="onTimeSlotClicked(timeSlot)"
                    >
                      <v-icon left>
                        mdi-clock
                      </v-icon>
                      <span class="text-subtitle-1">
                        {{ timeSlot.slice(0, 5) }}
                      </span>
                    </v-chip>
                  </v-col>
                  <v-col cols="1" class="px-0 pt-2"> </v-col>
                </v-row>
              </v-col>
              <v-col v-if="availabilitySearchIterationsLimitReached">
                <p class="body-1 text-center red--text">
                  <strong>
                    {{ $_t('Could not find any slots within next') }}
                    {{ MAX_AVAILABILITY_SEARCH_ITERATIONS }}
                    {{ $_t('weeks') }}.
                  </strong>
                </p>
              </v-col>
              <v-col v-else-if="!selectedDay">
                <p class="body-1 text-center">
                  <strong>{{
                      $_t('Please select a date to view available appointment times.')
                    }}</strong>
                </p>
              </v-col>
              <v-col v-else-if="selectedDay && !timeSlots.length && !alternativeMobileView">
                <p class="body-1 text-center">
                  <strong>{{
                      $_t('No time slots available for the selected date.')
                    }}</strong>
                </p>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-card-text>
      <v-card-actions v-if="$vuetify.breakpoint.smAndDown && !alternativeMobileView">
        <v-btn
          :disabled="!selectedTimeSlot"
          @click="onScheduleAppointmentClicked"
          width="50%"
          color="primary"
          class="text-none"
          x-large
        >{{ $_t('Confirm') }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import Vue from 'vue'
import config from '@src/config'
import { ref, onMounted, computed, watch, getCurrentInstance } from '@vue/composition-api'
import moment from 'moment/moment'

const MAX_AVAILABILITY_SEARCH_ITERATIONS = 8

export default {
  props: ['clinic', 'appointment'],
  name: 'ClinicSchedule',
  setup(props, context) {
    const _vm = getCurrentInstance()
    const schedulerStartDate = ref(null)
    const schedulerStartTime = ref(null)
    const availability = ref([])
    const availabilitySearchIterations = ref(0)
    const availabilitySearchIterationsLimitReached = ref(false)
    const selectedDay = ref(null)
    const selectedTimeSlot = ref(null)
    const days = ref([])
    const timeSlots = ref([])
    const schedulerLoading = ref(true)
    const schedulingAppointment = ref(false)
    const appointment = computed(() => props.appointment)
    const selectedBodyPartId = computed(() => props.appointment.bodyPartId)
    const selectedInsurerId = computed(() => props.appointment.insurer ? props.appointment.insurer.id : null)
    const selectedClinicId = computed(() => props.appointment.preferredClinic.id)
    const selectedClinic = computed(() => props.appointment.preferredClinic)

    const today = ref(new Date())
    const dateFrom = ref(new Date())
    const dateTo = ref(moment(dateFrom).add(7, 'days').toDate())

    const getNumberOfSlotsInDay = (day) => {
      const slotsInDayObj = availability.value[day]
      const slotsArr = Object.values(slotsInDayObj)
      return slotsArr.length
    }

    const getClinicSlots = (
      insurerId,
      clinicId
    ) => {
      schedulerLoading.value = true
      availability.value = []

      _vm.proxy.$_rest.get(
        `/practitioners/${ _vm.proxy.$store.getters['app/currentUserId'] }/appointments/${ appointment.value.id }/appointment-procedures/${ appointment.value.appointmentProcedures[0].id }/availability`,
        {
          clinicId: clinicId || null,
          insurerId: insurerId || null,
          dateFrom: moment(dateFrom.value).format('YYYY-MM-DD'),
          dateTo: moment(dateTo.value).format('YYYY-MM-DD')
        },
        response => {
          if (!response.data) {
             _vm.proxy.$_notify.error('Failed to fetch slots of this clinic')
            return
          }
          availability.value = response.data
          days.value = Object.keys(response.data)
          schedulerLoading.value = false
        },
        error => (error) => {
          _vm.proxy.$_notify.error(error)
        }
      )
    }

    const scheduleAppointmentError = () => {
      schedulingAppointment.value = false
      _vm.proxy.$_notify.error('It has not been possible to book this time slot. Please try again.')
      getClinicSlots(
        selectedInsurerId.value,
        selectedClinicId.value
      )
    }

    const scheduleAppointment = () => {
      schedulingAppointment.value = true
      const slotInfo =
        availability.value[selectedDay.value][selectedTimeSlot.value]
      const time = selectedTimeSlot.value.substring(0, 5)

      _vm.proxy.$_rest.post(
        `/practitioners/${ _vm.proxy.$store.getters['app/currentUserId'] }/appointments/${ appointment.value.id }/appointment-procedures/${ appointment.value.appointmentProcedures[0].id }/schedule`,
        {
          clinicScanRoomId: slotInfo.clinic_scan_room_id,
          date: selectedDay.value,
          time: time
        },
        response => {
          if (response.data?.statusCode === 'S') {
            _vm.proxy.$_notify.success('Time slot successfully booked.')
            schedulingAppointment.value = false
            context.emit('scheduled', { date: moment(selectedDay.value).format(config.dateFormatLong), time: time })
          } else {
            scheduleAppointmentError()
          }
        },
        () => {
          scheduleAppointmentError()
        }
      )
    }

    const showClinicsSlots = () => {
      schedulerLoading.value = true
      getClinicSlots(
        selectedInsurerId.value,
        selectedClinicId.value
      )
    }

    const prevWeek = () => {
      dateFrom.value = moment(dateFrom.value).subtract(7, 'days').toDate()
      dateTo.value = moment(dateTo.value).subtract(7, 'days').toDate()
      showClinicsSlots()
    }

    const nextWeek = () => {
      dateFrom.value = moment(dateFrom.value).add(7, 'days').toDate()
      dateTo.value = moment(dateFrom.value).add(7, 'days').toDate()

      showClinicsSlots()
    }

    const setDay = (day) => {
      selectedDay.value = day
    }

    const setTimeSlot = (timeSlot) => {
      selectedTimeSlot.value = timeSlot
    }

    const selectedDayTextMobile = computed(() =>
      moment(selectedDay.value).format(config.dateFormat)
    )

    const selectedDayTextDesktop = computed(() => {
      const day = moment(selectedDay.value)
      return (
        day.format('DD ') +
         _vm.proxy.$_t(day.format('MMMM')) +
        day.format(' YYYY') +
        ', ' +
         _vm.proxy.$_t(day.format('dddd'))
      )
    })

    const selectedWeekText = computed(() => {
      const start = moment(days.value[0])
      const end = moment(days.value[days.value.length - 1])
      return `${start.format('DD.MM')} - ${end.format(config.dateFormat)}`
    })

    const selectedMonthText = computed(() => {
      const start = moment(days.value[0])
      const end = moment(days.value[days.value.length - 1])
      if (start.format('MM') === end.format('MM')) {
        return (
           _vm.proxy.$_t(`${start.format('MMMM')}`) +
          ' ' +
          `${start.format('YYYY')}`
        )
      } else {
        return (
           _vm.proxy.$_t(`${start.format('MMMM')}`) +
          ' - ' +
           _vm.proxy.$_t(`${end.format('MMMM')}`) +
          ' ' +
          `${end.format('YYYY')}`
        )
      }
    })

    const firstAvailableSlot = computed(() => timeSlots.value[0])

    const lastAvailableSlot = computed(
      () => timeSlots.value[timeSlots.value.length - 1]
    )

    const availableSlotsText = computed(
      () => `${ _vm.proxy.$_t('Available slots')}: ${timeSlots.value.length}`
    )

    const howManySlotsText = computed(
      () =>
        `${firstAvailableSlot.value.substring(
          0,
          5
        )} - ${lastAvailableSlot.value.substring(0, 5)}`
    )

    const daysMobile = computed(() => {
      const _days = []
      days.value.forEach((day) => {
        const slots = getNumberOfSlotsInDay(day)
        const dayMoment = moment(day)
        const dateFormatted =
           _vm.proxy.$_t(dayMoment.format('ddd')) +
          moment(day).format(', DD.MM')
        const text = `${dateFormatted} (${slots} ${
          slots === 1 ?  _vm.proxy.$_t('slot') :  _vm.proxy.$_t('slots')
        })`
        const value = day
        const disabled = slots === 0
        _days.push({
          text,
          value,
          disabled
        })
      })
      return _days
    })

    const prevWeekAvailable = computed(() => {
      return moment(dateFrom.value).diff(today, 'days') > 0
    })

    const init = async () => {
      showClinicsSlots()
    }

    onMounted(init)

    watch(selectedDay, () => {
      timeSlots.value = Object.keys(availability.value[selectedDay.value])
      selectedTimeSlot.value = null
    })

    const prevMonth = () => {
      const start = moment(days.value[0])
      const firstDayOfMonth = start.clone().startOf('month')
      const lastDayNextMonth = firstDayOfMonth.clone().subtract(1, 'days')
      schedulerStartDate.value = lastDayNextMonth.format('YYYY-MM-DD')
      schedulerStartTime.value = '00:00:00'
      availabilitySearchIterationsLimitReached.value = false
      showClinicsSlots()
    }

    const nextMonth = () => {
      const end = moment(days.value[days.value.length - 1])
      const lastDayOfMonth = end.clone().endOf('month')
      const fistDayNextMonth = lastDayOfMonth.clone().add(1, 'days')
      schedulerStartDate.value = fistDayNextMonth.format('YYYY-MM-DD')
      schedulerStartTime.value = '00:00:00'
      availabilitySearchIterationsLimitReached.value = false
      showClinicsSlots()
    }

    const getDayLabel = (day, type) => {
      const date = moment(day)
      if (type === 'weekday') {
        return date.format('ddd')
      } else {
        return  _vm.proxy.$_t(date.format('MMM')) + ' ' + date.format('DD')
      }
    }

    const onDayClicked = (day) => {
      if (!getNumberOfSlotsInDay(day)) {
        return
      }
      setDay(day)
    }

    const onTimeSlotClicked = (timeSlot) => {
      setTimeSlot(timeSlot)
      scheduleAppointment()
    }

    const onScheduleAppointmentClicked = async () => {
      await scheduleAppointment()
    }

    const datesList = computed(() => {
      const list = {}
      if (selectedClinic.value.availability_date) {
        const startDateParts = selectedClinic.value.availability_date.split('-')
        const date = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2], 0, 0, 0, 0)

        const len = Object.keys(availability.value || []).length || 6

        for (let i = 0; i < len; i++) {
          const dateUnformatted = moment(date).format('YYYY-MM-DD')

          list[dateUnformatted] = {
            dateText: moment(date).format('MMM DD, dddd'),
            date: dateUnformatted,
            slots: typeof availability.value[dateUnformatted] !== 'undefined' ? Object.values(availability.value[dateUnformatted]) : []
          }
          date.setHours(24)
        }
      }

      return list
    })

    const scheduleToDateAndTime = async (date, time) => {
      setDay(date)
      setTimeSlot(time)
      await scheduleAppointment()
    }

    const loadingMore = ref(false)

    const showMore = async () => {
      const lastDay = Object.keys(datesList.value).pop()
      if (lastDay) {
        const lastDayParts = lastDay.split('-')
        const newDate = new Date(lastDayParts[0], lastDayParts[1] - 1, lastDayParts[2], 0, 0, 0, 0)
        newDate.setHours(24)
        loadingMore.value = true
        const _availability = await getClinicSlots(
          selectedInsurerId.value,
          selectedClinicId.value
        )
        loadingMore.value = false
        availability.value = {...availability.value, ..._availability}
      }
    }

    return {
      MAX_AVAILABILITY_SEARCH_ITERATIONS,
      config,
      schedulerLoading,
      schedulingAppointment,
      getNumberOfSlotsInDay,
      prevMonth,
      nextMonth,
      prevWeek,
      nextWeek,
      prevWeekAvailable,
      availabilitySearchIterations,
      availabilitySearchIterationsLimitReached,
      selectedDay,
      selectedDayTextMobile,
      selectedDayTextDesktop,
      selectedWeekText,
      selectedMonthText,
      selectedTimeSlot,
      availableSlotsText,
      howManySlotsText,
      days,
      daysMobile,
      timeSlots,
      onDayClicked,
      onTimeSlotClicked,
      onScheduleAppointmentClicked,
      getDayLabel,
      scheduleAppointment,
      datesList,
      scheduleToDateAndTime,
      showMore,
      loadingMore
    }
  }
}
</script>
<style scoped>
  .scheduler-button {
    padding: 5px 0;
    color: #fff;
    cursor: pointer;
    border-radius: 5px;
    background-color: rgb(114, 182, 255);
  }
  .scheduler-button-selected {
    background-color: #0078ff;
  }
  .scheduler-button-disabled {
    background-color: #98bee9;
    cursor: not-allowed;
  }
</style>

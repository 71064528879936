export default [
    {
      value: 'patientId',
      text: 'Patient ID',
      align: 'left',
      visible: false
    },
    {
      value: 'patientEpisodeNumber',
      text: 'Episode Number',
      align: 'left',
      visible: true
    },
    {
      value: 'patientEpisodeStatusName',
      text: 'Status',
      align: 'left',
      visible: true
    },
    {
      value: 'patientFullName',
      text: 'Patient Name',
      align: 'left',
      visible: true
    },
    {
      value: 'patientIdShort',
      text: 'Patient ID',
      align: 'left',
      visible: true
    },
    {
      value: 'patientNumber1',
      text: 'NHS Number',
      align: 'left',
      visible: true
    },
    {
      value: 'patientIdExternal',
      text: 'BHR Number',
      align: 'left',
      visible: true
    },
    {
      value: 'createdDatetime',
      text: 'Created Date',
      align: 'left',
      visible: true
    },
    {
      value: 'referringPractitionerFullName',
      text: 'Referrer Name',
      align: 'left',
      visible: true
    },
    {
      value: 'referringPractitionerIdentificationNumber',
      text: 'Referrer Number',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerId',
      text: 'Consulting PractitionerId',
      align: 'left',
      visible: false
    },
    {
      value: 'consultingPractitionerFullName',
      text: 'Consulting Practitioner Name',
      align: 'left',
      visible: true
    },
    {
      value: 'consultingPractitionerIdentificationNumber',
      text: 'Consulting Practitioner Number',
      align: 'left',
      visible: false
    }
  ]

<template>
  <v-container>
    <v-row>
      <v-col cols="10">
        <v-autocomplete
          v-model="model"
          :items="items"
          :loading="isLoading"
          :search-input.sync="search"
          item-text="fullName"
          item-value="id"
          :label="$_t('Patient search')"
          :placeholder="$_t('Start typing to search')"
          prepend-inner-icon="mdi-account-search"
          return-object
          no-filter
          clearable
          @keyup="searchPatients"
          @keydown="isTyping = true"
          @blur="isTyping = false"
          :filter="() => true"
        >
          <template v-slot:no-data>
            <v-list-item v-if="isLoading">
              {{ $_t('Loading...') }}
            </v-list-item>
            <v-list-item v-else-if="isTyping">
              ...
            </v-list-item>
            <v-list-item v-else-if="searchVal && (!items || !items.length)">
              {{ $_t('No patients found') }}
            </v-list-item>
            <v-list-item v-else>
              {{ $_t('Start typing to search for patients') }}
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">

            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title v-text="$_t('{name}, born: {date}', {name: item.fullName || '', date: item.dateOfBirth ? moment(item.dateOfBirth).format(formatDate) : ''}) + ' ' + `${patientNumbers(item)}`"></v-list-item-title>
              <v-list-item-subtitle v-text="`${item.fullAddress || ''} ${item.fullPhoneNumbers || ''}`"></v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
      <v-col cols="2">
        <v-btn
          v-if="newPatientEnabled"
          :disabled="newPatientDisabled"
          small
          color="primary"
          :title="!timesSearched ? 'Search patients first' : ''"
          class="mt-5"
          @click="newPatientDialog = true"
        >
          <v-icon small class="mr-2">mdi-plus-circle</v-icon>
          New patient
        </v-btn>
      </v-col>
    </v-row>
    <div v-if="showSelectedPatientDetails">
      <v-divider></v-divider>
      <v-expand-transition>
        <v-list
          v-if="model"
        >
          <v-list-item
            v-for="(label, field) in fieldsAllowed"
            :key="field"
          >
            <v-list-item-content>
              <v-list-item-title v-text="getFieldValue(field, label)"></v-list-item-title>
              <v-list-item-subtitle v-text="typeof label === 'object' ? label.caption : label"></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-expand-transition>
      <v-card-actions>
        <v-btn
          :disabled="!timesSearched"
          color="primary"
        >
          <v-icon right>
            mdi-plus
          </v-icon>
          {{ $_t('Add Patient') }}
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!model"
          color="primary"
        >
          {{ $_t('Proceed') }}
          <v-icon right>
            mdi-arrow-right-circle
          </v-icon>
        </v-btn>
      </v-card-actions>
    </div>
    <v-dialog
      v-model="newPatientDialog"
      scrollable
    >
      <component
        :is="newPatientForm"
        ref="components"
        @created="newPatientCreated"
        @cancel="newPatientDialog = false"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import moment from 'moment'
import config from '@src/config'
import PatientFormUK from '@src/components/patient/new/UK.vue'

export default {
  name: 'PatientSearch',
  components: { PatientFormUK },
  props: {
    value: {
      type: Object
    },
    showSelectedPatientDetails: {
      type: Boolean
    }
  },
  data () {
    return {
      descriptionLimit: 60,
      entries: [],
      isLoading: false,
      search: null,
      searchTimeout: null,
      searchAjax: null,
      searchVal: '',
      timesSearched: 0,
      isTyping: false,
      fieldsAllowed:
        {
          number1: 'Patient number', firstName: 'First name', middleName: 'Middle name', lastName: 'Last name',
          dateOfBirth: { caption: 'Date of birth', fn: (val) => this.moment(val).format(this.formatDate) },
          phoneNumber1: 'Phone 1', phoneNumber2: 'Phone 2',
          address1: 'Address 1', address2: 'Address 2', address3: 'Address 3', postCode: 'Post code', town: 'Town'
        },
      newPatientDialog: false
    }
  },
  mounted () {

  },
  methods: {
    getFieldValue (field, label) {
      if (!this.model[field]) return '-'
      if (typeof label === 'object') {
        return label.fn(this.model[field])
      }
      return this.model[field]
    },
    searchPatients () {
      if (this.searchTimeout) {
        window.clearTimeout(this.searchTimeout)
      }

      if (this.searchAjax) {
        this.searchAjax.abort()
      }

      if (!this.searchVal || !this.searchVal.trim() || this.searchVal.trim().length < 3) {
        this.entries = []
        this.isTyping = false
        return
      }

      this.searchTimeout = setTimeout(() => {
        this.isTyping = false
        this.isLoading = true

        this.searchAjax = this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patients`, {
          groups: 'all,test',
          search: this.searchVal
        }, data => {
          this.isLoading = false
          this.timesSearched++
          this.$emit('search', { searchVal: this.searchVal, data: data.data})
          this.entries = data.data
        }, error => {
          this.isLoading = false
          if (error?.code !== this.$_rest.const.ABORT_ERROR) {
            this.$_notify.error(error)
          }
        })
      }, 700)
    },
    newPatientCreated (patientTemporary) {
      this.newPatientDialog = false
      this.model = this.processPatient({ ...patientTemporary, ...{'isPatientTemporary' : true }})
    },
    processPatient (patientData) {
      const fullName = [patientData.title, patientData.firstName, patientData.lastName].filter(el => el).join(' ')
      const fullAddress = [patientData.address1, patientData.address2, patientData.address3, patientData.postCode, patientData.town].filter(el => el)
        .join(', ')
      const phoneNumbers = [patientData.phoneNumber1, patientData.phoneNumber2].filter(el => el)
      const fullPhoneNumbers = phoneNumbers.length ? '| phone: ' + phoneNumbers.join(', ') : ''
      return Object.assign({}, patientData, { fullName, fullAddress, fullPhoneNumbers })
    },
    patientNumbers(patient) {
      return (patient.number1 ? ' | ' + this.$_t('NHS No.: ') + patient.number1 : '') +
             (patient.idExternal ? ' | ' + this.$_t('BHR No.: ') + patient.idExternal : '')
    }
  },
  computed: {
    moment () {
      return moment
    },
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('clear-storage')
      }
    },
    items () {
      return this.entries.map(entry => {
        return this.processPatient(entry)
      })
    },
    newPatientEnabled () {
      return config.practitionerNewCreatePatientEnabled && config.practitionerNewCreatePatientForm
    },
    newPatientDisabled () {
      return !!this.value
    },
    newPatientForm () {
      return config.practitionerNewCreatePatientForm
    }
  },

  watch: {
    search (val) {
      this.searchVal = val
    }
  }
}
</script>

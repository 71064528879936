import { render, staticRenderFns } from "./PractitionerPractice.vue?vue&type=template&id=340d74dd&scoped=true&"
import script from "./PractitionerPractice.vue?vue&type=script&lang=js&"
export * from "./PractitionerPractice.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "340d74dd",
  null
  
)

export default component.exports
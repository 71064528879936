<template>
  <v-main class="practitioner">
    <v-dialog
      v-model="searchDialogModel"
      @click:outside="closeSearchDialog"
      style="overflow: hidden"
      transition="dialog-bottom-transition"
    >
      <PatientSearchDialog  @close="closeSearchDialog"/>
    </v-dialog>
    <v-navigation-drawer
      v-if="!getMobile"
      app
      mini-variant
      mini-variant-width="80"
      v-model="drawer"
      disable-resize-watcher
      clipped
    >
      <v-list>
        <v-list-item
          v-for="item in menuItems"
          :key="item.title"
          link
          @click="handleMenuItemClick(item)"
        >
          <v-list-item-icon>
            <v-tooltip
              right
              fixed
            >
              <template v-slot:activator="{ on, attrs }">
                <div v-if="item.title === 'Alerts' && alertsBadge">
                  <v-badge
                    color="error"
                    overlap
                    :content="alertsBadge"
                    :value="typeof item.badge !== 'undefined'"
                  >
                    <v-icon
                      large
                      v-on="on"
                      v-bind="attrs"
                      color="primary"
                      @click="$router.push(item.linkTo)"
                    >
                      {{ item.icon }}
                    </v-icon>
                  </v-badge>
                </div>
                <div v-else>
                  <v-icon
                    large
                    v-on="on"
                    v-bind="attrs"
                    color="primary"
                    @click="$router.push(item.linkTo)"
                  >
                    {{ item.icon }}
                  </v-icon>
                </div>
              </template>
              <span>{{ item.title }}</span>
            </v-tooltip>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-navigation-drawer
      v-model="rightDrawerModel"
      temporary
      right
      app
      width="85%"
      disable-resize-watcher
      @input="closeRightDrawer"
    >
      <patient-appointment-grid
        v-if="rightDrawer && rightDrawer.visible"
        :right-drawer="rightDrawer"
        :patientId="rightDrawer.patientId"
        :loading="rightDrawer && rightDrawer.loading"
        @refresh="$emit('right-drawer-refresh',true)"
      ></patient-appointment-grid>
    </v-navigation-drawer>
    <v-app-bar
      :extended="extendedToolbarShow"
      :extension-height="extensionHeight"
      app
      clipped-left
      :collapse="getMobile && appBarCollapse"
      color="primary"
      dark
    >
      <v-toolbar-items>
        <v-btn
          icon
          color="white"
          v-if="getMobile"
          @click="appBarCollapse = !appBarCollapse"
        >
          <v-icon>
            mdi-menu
          </v-icon>
        </v-btn>
      </v-toolbar-items>
      <v-toolbar-title>
        <v-img
          v-if="config.appLogo"
          contain
          :src="config.appLogo"
          max-height="50"
          max-width="50"
          class="mt-0 mr-11"
        />
      </v-toolbar-title>
      <v-toolbar-title v-if="showPracticeDropdown" class="pt-1">
        <practitioner-practice
          v-if="$store.getters['app/practitioner']"
          v-model="currentPractitionerPractice"
          :practitioner-practices="$store.getters['app/practitioner'].practitionerPractices"
          :loading="$store.getters['app/practitioner'].practitionerPracticesLoading"
        />
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="!getMobile || !appBarCollapse">
        <component
          :is="`user-profile-${currentUser.authType}`" :class="getMobile ? 'mt-1' : 'mt-2'"
        />
      </v-toolbar-items>
      <template v-slot:extension v-if="extendedToolbarShow">
          <PatientBanner />
      </template>
    </v-app-bar>
    <v-container
      fluid
      class="pa-0 pa-md-2"
    >
      <slot/>
    </v-container>
    <v-bottom-navigation
      v-model="bottomNavigationValue"
      v-if="getMobile"
      app
      color="primary"
      hide-on-scroll
    >
      <v-btn
        v-for="( item, index ) in menuItems"
        :key="item.title"
        :value="index"
        @click="$router.push(item.linkTo)"
        style="min-width:40px"
      >
        <span class="caption">{{ $_t(item.title) }}</span>
        <v-badge
          color="error"
          overlap
          :content="alertsBadge"
          :value="typeof item.badge !== 'undefined' && alertsBadge"
        >
          <v-icon>{{ item.icon }}</v-icon>
        </v-badge>
      </v-btn>

    </v-bottom-navigation>

  </v-main>
</template>

<script>
import { computed, defineComponent, getCurrentInstance, ref, WritableComputedRef, PropType, reactive } from '@vue/composition-api'
import PatientAppointmentGrid from '@components/patient/PatientAppointmentGridComponent.vue'
import PractitionerPractice from '@components/practitioner/PractitionerPractice.vue'
import UserProfilePatient from '@components/userProfile/Patient.vue'
import UserProfilePractitioner from '@components/userProfile/Practitioner.vue'
import PatientBanner from '@components/common/PatientBanner.vue'
import { PatientBannerEnum } from '@src/enum/PatientBanner'
import { MenuItems } from '@src/data/MenuItems'
import useCommonHelpers from '../../helpers/useCommonHelpers';
import { PractitionerRoles } from '../../enum/PractitionerRoles'
import config from '@src/config'
import PatientSearchDialog from '../../components/practitioner/PatientSearchDialog.vue'

export default defineComponent({
  name: 'PractitionerLayout',
  props: {
    rightDrawer: {
      type: Object,
      default:()=>{}
    }
  },
  components: {
    PatientSearchDialog,
    PatientAppointmentGrid, PractitionerPractice, UserProfilePatient, UserProfilePractitioner,PatientBanner
  },
  emits:['setExtendedToolbarHeight'],
  setup(props, {emit}) {
    const { isGrantedAny } = useCommonHelpers()

    const vm = getCurrentInstance()

   const menuItemsCopy = MenuItems
   const menuItems = []

    // const menuItems = computed(()=>{  //temporary switch of Greg
    //   return MenuItems.filter(menuItem => isGrantedAny([...menuItem.roles as any, PractitionerRoles.superAdmin]))
    // })

    config.practitionerMenuItems.forEach((item) => {
      const foundMenuItem = menuItemsCopy.find((menu)=> menu.id === item.trim())
      if(typeof foundMenuItem !== 'undefined'){
        menuItems.push(foundMenuItem)
      }
    })

    const extendedToolbarHeight = reactive({
        mobileHeight:PatientBannerEnum.defaultMobileHeight,
        desktopHeight:PatientBannerEnum.defaultDesktopHeight
    })

    const showPracticeDropdown = ref(config.showPracticeDropdown)

    const setExtendedToolbarHeight = (mobileHeight,desktopHeight) =>{
        extendedToolbarHeight.mobileHeight = mobileHeight
        extendedToolbarHeight.desktopHeight = desktopHeight
    }

    const bottomNavigationValue = ref(0)
    bottomNavigationValue.value = menuItemsCopy.findIndex(item => item.title === vm.proxy.$route.name)

    const drawerMini = ref(true)
    const appBarCollapse = ref(true)
    const getMobile = computed(() => {
      return vm?.root.proxy.isMobile
    })

    const transitioned = () => {}
    const drawer = ref(true)
    const bottomNav = ref()

    // const getMobile = comp(ctx.root.$vuetify.breakpoint.mobile)
    const temp = ref(false)
    const drawer2 = ref(false)
    const drawerRight = ref(false)
    // @ts-ignore

    const currentUser = computed(() => {
      return vm?.proxy.$store.getters['app/currentUser']
    })
    const extendedToolbarShow = computed(() => {
      return vm?.proxy.$store.getters['app/patientBanner']
    })
    const alertsBadge = computed(() => {
      return vm?.proxy.$store.getters['alerts/count']
    })
    const rightDrawerModel = computed({
      get() {
        return props.rightDrawer && props.rightDrawer.visible
      },
      set(value) {
        emit('update:rightDrawer', props.rightDrawer, {visible: value})
      }
    });

    const currentPractitionerPractice = computed({
      get() {
        return vm?.proxy.$store.getters['app/practitionerPractice']
      },
      set(practice) {
        vm?.proxy.$store.dispatch('app/setPractitionerPractice', practice).then(() => {
          window.location.reload()
        })
      }
    });

    const closeRightDrawer = (state) => {
      if (!state) {
        rightDrawerModel.value = state
        emit('close-right-driver', state)
      }
    }
    const searchDialogModel = computed({
      get() {
        return vm?.proxy.$store.getters['app/showSearchDialog']
      },
      set(){
        vm?.proxy.$store.commit('app/setShowSearchDialog')
      }
    });

    const closeSearchDialog = () => {
      vm?.proxy.$store.commit('app/setShowSearchDialog', false)
    }

    const handleMenuItemClick = (item) => {
      if (item.id === 'search') {
        vm?.proxy.$store.commit('app/setShowSearchDialog', true)
      }

      if(item.id === 'new') {
        vm?.proxy.$store.commit('appointment/setPatientEpisodeId', null)
        vm?.proxy.$store.commit('appointment/setPatientLocationId', null)
      }
    }

    const setEvents = ()=>{
        vm?.proxy.$root.$on('extend-panel',(mobileHeight,desktopHeight)=>{
            setExtendedToolbarHeight(mobileHeight,desktopHeight)
        })
    }

    const extensionHeight = computed(() => {
      return getMobile.value ? extendedToolbarHeight.mobileHeight : (vm?.proxy.$store.getters['app/patientBannerExtended'] ? '600' : '120')
    })

    vm?.proxy.$store.dispatch('alerts/pollAlerts', 30000)
    setEvents()

    return {
      bottomNavigationValue,
      drawer,
      drawerMini,
      menuItems,
      bottomNav,
      transitioned,
      temp,
      drawer2,
      drawerRight,
      appBarCollapse,
      currentUser,
      alertsBadge,
      currentPractitionerPractice,
      rightDrawerModel,
      closeRightDrawer,
      extendedToolbarShow,
      extendedToolbarHeight,
      setExtendedToolbarHeight,
      getMobile,
      extensionHeight,
      handleMenuItemClick,
      closeSearchDialog,
      searchDialogModel,
      showPracticeDropdown,
      config

    }
  }
})
</script>

<style>
.practitioner .indented {
  margin-left: 60px;
}
.practitioner div.v-toolbar__extension {
  background-color: white;
  align-items: normal;
}
</style>

<template>
  <RouteDialog
    :allow-full-screen="true"
    @fullScreenChange="fullScreenChange"
    @close="close"
  >
    <v-container fluid>
      <v-row>
        <v-col :cols="isFullScreen ? 8 : 12">
          <v-card class="mx-3 mt-5">
            <v-card-text>
              <v-row v-if="getProcedureList && getProcedureList.length">
                <v-col>
                  <appointment-procedure-list
                    v-if="appointmentId"
                    :appointment-id="appointmentId"
                    :loader="appointmentProcedureLoader"
                    :list="getProcedureList"
                    :display-report="isFullScreen"
                    :alerts="alertsForAppointmentProcedure"
                    @show-report="showReportOverlay"
                    @acknowledged="alertAcknowledged"
                  />
                </v-col>
              </v-row>
              <v-row v-if="appointmentId && alertsForAppointment.length">
                <v-col>
                  <v-container fluid>
                    <v-subheader><h4>{{ $_t('Alerts') }}</h4></v-subheader>
                    <appointment-alert-list
                      v-if="appointmentId"
                      :list="alertsForAppointment"
                      @acknowledged="alertAcknowledged"
                      class="mx-4"
                    />
                  </v-container>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <appointment-document-list
                    v-if="appointmentId"
                    :appointment-id="appointmentId"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="showAppointmentDetails" :cols="isFullScreen ? 4 : 12">
          <grid
            vertical
            :data="getActiveRow"
            hide-filters
            hide-search
            :headers-preset-type="headersPresetType"
            :headers-preset="headersPreset"
          ></grid>
        </v-col>
      </v-row>
    </v-container>
    <appointment-report-overlay
      v-if="dialogReport"
      v-model="dialogReport"
      :report-route="reportRoute"
      :row.sync="activeRowReport"
      :actions="[]"
    />
    <template v-slot:toolbar>
      <v-btn
        v-if="source === 'appointment_grid'"
        outlined
        dark
        color="black"
        :loading="loading"
        class="mr-2 ml-1"
        @click="nextAppointment"
      >
        {{ $_t('Next') }}
        <v-icon></v-icon>
      </v-btn>
      <v-btn
        v-if="config.practitionerMDTEnable"
        outlined
        dark
        color="black"
        :loading="loading"
        class="mr-2 ml-1"
        @click="mdtDialog = true"
      >
        {{ $_t('Refer to MDT') }}
        <v-icon></v-icon>
      </v-btn>
    </template>
    <v-dialog
      v-model="mdtDialog"
      transition="dialog-top-transition"
      max-width="800"
    >
      <template v-slot:default="">
        <v-card>
          <v-toolbar
            color="primary"
            dark
            dense
          >{{ $_t('MDT referral') }}</v-toolbar>
          <v-card-text>
            <v-row class="mt-4">
              <v-autocomplete
                v-model="mdt"
                :label="$_t('Start typing to search for scheduled MDT')"
                :items="mdtItems"
                return-object
              />
            </v-row>
            <v-row>
              <v-textarea
                auto-grow
                outlined
                :label="$_t('Referral details')"
              />
            </v-row>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="mdtDialog = false"
            >{{ $_t('Cancel') }}</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
  </RouteDialog>
</template>
<script>
import RouteDialog from '@components/core/RouteDialog.vue'
import Grid from '@components/common/Grid.vue'
import appointmentsPracticesHeaders from '@src/data/dataset/gridHeaders/appointmentPractices'
import config from '@src/config'
import AppointmentReportOverlay from '@components/common/AppointmentReport.vue'
import AppointmentProcedureList from '@components/procedure/AppointmentProcedureList.vue'
import AppointmentAlertList from '@components/appointment/AlertList.vue'
import AppointmentDocumentList from '@components/appointment/DocumentList.vue'

export default {
  name:'AppointmentGridDetails',
  components: { RouteDialog, Grid, AppointmentReportOverlay, AppointmentProcedureList, AppointmentAlertList, AppointmentDocumentList },
  data () {
    return {
      searchResult: {},
      loading: false,
      rightDrawerModel: false,
      activeRow: null,
      dialogReport: false,
      reportRoute: '',
      activeRowReport: {},
      appointmentProcedureList: [],
      appointmentProcedureLoader: false,
      headersPresetType:'appointmentPractices',
      headersPreset: appointmentsPracticesHeaders,
      config,
      render: true,
      appointmentId: null,
      isFullScreen: false,
      mdtDialog: false,
      alerts: [],
      defaultSource: 'appointment_grid'
    }
  },
  props:{
    id:{
      type:String,
      default:null
    }
  },
  computed:{
    source(){
     return this.$route.params?.source || this.defaultSource
    },
    items() {
      return this.$store.getters['appointment/list']
    },
    getActiveRow(){
      if (this.activeRow) {
        return [this.activeRow]
      }
      if(this.source === 'patient_timeline'){
        return [this.$store.getters['appointment/appointment']]
      }
      return []
    },
    showAppointmentDetails() {
      return !config.hideAppointmentGridDetailsBasics
    },
    proceduresFetchRoute () {
      return `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}`
    },
    getProcedureList () {
      return this.appointmentProcedureList
    },
    alertsForAppointment() {
      if (!this.alerts || !Array.isArray(this.alerts)) {
        return []
      }
      return this.alerts.filter(el => el.appointmentId && !el.appointmentProcedureId)
    },
    alertsForAppointmentProcedure() {
      return this.alerts.filter(el => el.appointmentId && el.appointmentProcedureId)
    }
  },
  methods:{
    fullScreenChange(isFullScreen) {
      this.isFullScreen = isFullScreen
    },
    showReportOverlay (reportId) {
      const row = this.getActiveRow[0]
      this.dialogReport = true
      this.activeRowReport = row
      this.reportRoute = `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/reports/${reportId}/file`
    },
     procedureStudiesFetchRoute (appointmentProcedureId) {
      return `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-procedures/${appointmentProcedureId}/appointment-procedure-studies?status=S&orderBy[id]=desc&limit=1`
    },
    async getProcedureStudies (resolve) {
      let count = 0;
      this.appointmentProcedureLoader = true
      this.appointmentProcedureList.forEach((apProc) => {
        this.$_rest.get(this.procedureStudiesFetchRoute(apProc.id), {}, response => {
          count++;
          if (response.data.length === 1) {
            apProc.study = response.data[0]
            apProc.appointmentId = this.appointmentId
          } else {
            apProc.study = null
          }
          if (count === this.appointmentProcedureList.length) {
            resolve()
          }
        }, error => {
          if(this.source === 'patient_timeline') {
            this.close()
          }
          this.appointmentProcedureLoader = false
          this.$_notify.error(error)
        })
      })
    },
    getProcedures () {
      this.appointmentProcedureLoader = true
      this.$_rest.get(this.proceduresFetchRoute, {
        groups: ['all', 'appointment_practitioner_practice', 'appointment_appointment_procedures', 'appointment_procedure_procedure', 'appointment_procedure_appointment_filtered_procedure_reports'].join(',')

      }, async response => {
        this.appointmentProcedureList = []
        response.data.appointmentProcedures.forEach((ap) => {
          if (!['NA', 'X', 'XR'].includes(ap.statusCode)) {
            this.appointmentProcedureList.push(ap)
          }
        })
        // if viewer enabled
        if (this.config.practitionerExaminationImagesEnabled && this.appointmentProcedureList.length > 0) {
          await this.getProcedureStudies(() => {
            this.appointmentProcedureLoader = false
          })
        } else {
          this.appointmentProcedureLoader = false
        }
      }, error => {
        if(this.source === 'patient_timeline') {
          this.close()
        }
        this.appointmentProcedureLoader = false
        this.$_notify.error(error)
      })
    },
    setParams() {
      this.appointmentId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : this.id

      return this.appointmentId  ? true : false
    },
    processRouteParam() {
      if (this.setParams()){
        this.activeRow = this.items.find(item=>item.appointmentId === this.appointmentId)
        this.loading = false
        this.getProcedures()
        this.getAlerts()
      } else {
        this.$_notify.error(this.$_t('Wrong params'))
        this.loading = false
      }
    },
    alertAcknowledged() {
      this.getAlerts()
    },
    nextAppointment() {
      const activeRowIndex = this.items.findIndex(item => item.appointmentId === this.appointmentId)

      if (activeRowIndex && typeof this.items[activeRowIndex + 1] !== 'undefined') {
        this.$router.push('/practitioner/appointments/appointment-details/' + this.items[activeRowIndex + 1].appointmentId)
      } else {
        this.close()
      }
    },
    close() {
      if(this.source === 'patient_timeline'){
        this.$router.push(`/practitioner/patient/${this.$store.getters['patient/patientId']}`)
      } else {
        this.$router.push('/practitioner/appointments')
      }
    },
    getAlerts() {
      this.loading = true
      this.alerts = []
      this.$_rest.get(`practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/alerts`, {
        groups: 'all,alert_type,alert_alert_recipients,alert_recipient_practitioner_practice'
      }, response => {
        this.alerts = response.data
        this.loading = false
      }, error => {
        if(this.source === 'patient_timeline') {
          this.close()
        }
        this.$_notify.error(this.$_t('Failed to fetch alerts'))
        this.loading = false
      })
    }
  },
  watch:{
    '$route.params.id'(val) {
      this.processRouteParam()
    },
    '$route.params.source'(val){
      this.processRouteParam()
    },
    items(val){
      this.loading = true
       if(val.length) {
        this.processRouteParam()
      }
    }
  },
  mounted(){
    this.processRouteParam()
  }
}
</script>

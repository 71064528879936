<template>
  <div>
    <div v-for="(alert, index) in list" :key="index">
      <v-list-item class="pa-0 ma-0">
        <v-list-item-content>
          <v-list-item-title style="max-width: 500px;text-wrap: normal;" class="font-weight-medium">
            <span>{{ alert.type.name }}</span>
          </v-list-item-title>
          <v-list-item-content v-if="alert.body.trim()">
            {{ alert.body }}
          </v-list-item-content>
        </v-list-item-content>
        <v-list-item-action v-if="alertEligibleToAcknowledgement(alert) && !alert.type.isNoteRequired">
          <v-btn
            @click="acknowledgeAlert(alert)"
            color="primary"
            outlined
            :loading="loading"
          >
            <v-icon left color="primary">mdi-check-circle</v-icon>
            {{ $_t('Acknowledge') }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="alertEligibleToAcknowledgement(alert) && alert.type.isNoteRequired" class="pa-0 ma-0">
        <v-list-item-content>
          <v-textarea
            counter
            auto-grow
            rows="3"
            :label="`${$_t('Add note')} *`"
            :disabled="loading"
            v-model="alert.acknowledgeNote"
          ></v-textarea>
        </v-list-item-content>
      </v-list-item>
      <v-list-item v-if="alertEligibleToAcknowledgement(alert) && alert.type.isNoteRequired" class="pa-0 ma-0">
        <v-list-item-content></v-list-item-content>
        <v-list-item-action>
          <v-btn
            @click="acknowledgeAlert(alert)"
            color="primary"
            outlined
            :loading="loading"
            :disabled="!alert.acknowledgeNote || !alert.acknowledgeNote.trim()"
          >
            <v-icon left color="primary">mdi-check-circle</v-icon>
            {{ $_t('Acknowledge') }}
          </v-btn>
        </v-list-item-action>
      </v-list-item>
      <v-list-item v-if="alert.statusCode === 'A'" class="pa-0 ma-0">
        <v-list-item-content>
          <v-list-item-title>
            {{ $_t('Acknowledged by') }}
          </v-list-item-title>
          <v-list-item-subtitle>
            <div v-for="(recipient, index) in getAcknowledgedData(alert)" :key="index">
              {{ recipient.practitionerPractice.practitionerName }} - {{ useHumanDateTime(recipient.acknowledgedDatetime) }}
            </div>
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
      <v-divider :key="index"></v-divider>
    </div>
  </div>
</template>
<script>
import useDateHelpers from '../../helpers/useDateHelpers';
import config from '@src/config'

const { useHumanDateTime } = useDateHelpers()
export default {
  name: 'AppointmentAlertList',
  components: { },
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    loader: Boolean,
  },
  data() {
    return {
      loading: false,
      useHumanDateTime: useHumanDateTime,
      alerts: []
    }
  },
  methods: {
    getAcknowledgedData(alert){
      return alert.alertRecipients.filter(recipient => recipient.isRead)
    },
    alertEligibleToAcknowledgement(alert) {
      return !this.loading && alert.status !== 'A' && this.getCurrentUserAlertRecipient(alert) && !alert.type?.isNoteRequired
    },
    getCurrentUserAlertRecipient(alert) {
      return alert.alertRecipients?.find(recipient => !recipient.isRead && recipient.practitionerPractice?.id === this.$store.getters['app/practitionerPractice']?.id)
    },
    acknowledgeAlert(alert) {
      this.loading = true

      this.$store.dispatch('alerts/acknowledge', {
        alertRecipientId: this.getCurrentUserAlertRecipient(alert).id,
        note: alert.acknowledgeNote || '',
        appointmentId: alert.appointmentId
      }).then(() => {
        this.$_notify.success(this.$_t('The alert has been successfully acknowledged.'))
        this.$store.dispatch('alerts/getAlerts')
        this.$emit('acknowledged', alert)
      }).catch(error => {
        this.$_notify.error(this.$_t('Alert acknowledgment failed. Please try again.'))
        this.loading = false
      })
    }
  }
};
</script>

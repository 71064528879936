declare module 'vue/types/vue' {
  interface VueConstructor {
    http: any;
  }
}

interface AwaitingResolverInterface {
  resolve: (list) => {};
  reject: (list) => {};
}

const state = {
  list: [],
  loading: false,
  _awaitingResolvers: [] as AwaitingResolverInterface[]
};

const actions = {
  getPriorities(
    { commit }: any
  ) {
    return new Promise ((resolve, reject) => {
      if (state.list?.length) {
        resolve(state.list)
        return
      }
      if (state.loading) {
        commit('_addAwaitingResolver', {resolve, reject})
        return
      }
      commit('setLoading', true);
      (this as any)._vm.$_rest.get(
        '/appointment-priorities',
        {},
        (response) => {
          commit('setList', response.data);
          commit('setLoading', false)
          state._awaitingResolvers.forEach(({resolve}) => {
            resolve(state.list)
          })
          resolve(state.list)
        },
        (error) => {
          commit('setLoading', false)
          state._awaitingResolvers.forEach(({reject}) => {
            reject(state.list)
          })
          reject(error)
        }, {
          prefixRoutesWithOrganizationId: false
        }
      )
    })
  }
};

const mutations = {
  setList: (state: any, list: any[]) => {
    state.list = list
  },
  setLoading (state: any, loading: boolean) {
    state.loading = loading
  },
  _addAwaitingResolver (state: any, resolver: AwaitingResolverInterface) {
    state._awaitingResolvers.push(resolver)
  }
};

const getters = {
  list: (state: any) => state.list,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

import { mapDatasetResponse } from '@src/helpers/helper'
import { Alerts } from '@src/interfaces/AlertsInterface'
import { GenericObject } from '@/interfaces/Type/GenericType'

export interface AlertsState extends Alerts{}

const state: Alerts = {
  count: 0,
  countSet: false,
  data: [],
  pollInterval: 0,
  pollActive: false,
  getAlertsTimeout: undefined
}

const actions = {
  getAlerts ({ state, dispatch, commit, getters, rootState, rootGetters }) {
    if (!rootGetters['app/practitionerPractice']) {
      return
    }
    return new Promise((resolve, reject) => {
      if (state.getAlertsTimeout) {
        commit('setGetAlertsTimeout', null)
      }
      // @ts-ignore
      (this as any)._vm.$_rest.get(`/practitioners/${ rootGetters['app/currentUserId'] }/datasets/alerts`, {
        filter: { practitionerPracticeId: rootGetters['app/practitionerPractice']?.id }
      }, response => {
        commit('setData', mapDatasetResponse(response))
        // @ts-ignore
        commit('setCount', response.data.filter(el => el[3] === 'C').length)
        if (state.pollInterval > 0) {
          commit('setGetAlertsTimeout', setTimeout(() => {
              dispatch('getAlerts')
            }, state.pollInterval)
          )
        }
        resolve()
      }, error => {
        reject()
      })
    })
  },
  pollAlerts({ dispatch, commit, getters }, interval: Alerts) {
    if (getters.isPollActive) {
      return
    }
    commit('setPollInterval', interval)
    commit('setPollActive', true)
    dispatch('getAlerts')
  },
  acknowledge({ state, rootGetters, commit }, {alertRecipientId, note, appointmentId}) {
    return new Promise((resolve, reject) => {
      (this as any)._vm.$_rest.put(
        `/practitioners/${ rootGetters['app/practitioner'].id }/alert-recipients`,
        {
          isRead: 1,
          practitionerPractice: rootGetters['app/practitionerPractice']?.id,
          note,
          appointmentId,
          groups: ['all', 'alert_recipient_alert', 'alert_status']
        },
        alertRecipientId,
        response => {
          const row = state.data.find(entry => entry.alertRecipientId === alertRecipientId)
          if (!row) {
            throw new Error((this as any)._vm.$_t('Couldn\'t find acknowledged alert. Please refresh page'))
          }
          const rowCopy = {...row}
          rowCopy.alertStatusCode = response?.data?.alert?.status?.code
          rowCopy.alertStatusName = response?.data?.alert?.status?.name
          const dataCopy = [...state.data]
          dataCopy[state.data.indexOf(row)] = rowCopy
          commit('setData', dataCopy)
          resolve(rowCopy)
        },
        error => reject(error)
      )
    })
  }
}

const mutations = {
  setCount (state: AlertsState, count: number) {
    state.count = count
    state.countSet = true
  },
  minusOne (state: AlertsState) {
    state.count = state.count - 1
  },
  setPollInterval (state: AlertsState, value: number) {
    state.pollInterval = value
  },
  setPollActive (state: AlertsState, value: boolean) {
    state.pollActive = value
  },
  setData(state: AlertsState, data: Array<GenericObject>) {
    state.data = data
  },
  setGetAlertsTimeout(state: AlertsState, timeout: number) {
    clearTimeout(state.getAlertsTimeout)
    if (timeout) {
      state.getAlertsTimeout = timeout
    }
  },
}

const getters = {
  count (state: AlertsState) {
    return state.count
  },
  countSet (state: AlertsState) {
    return state.countSet
  },
  data (state: AlertsState) {
    return state.data
  },
  isPollActive (state: AlertsState) {
    return state.pollActive
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

<template>
  <span>
    <span v-if="model" class="text-subtitle-2">
    {{ $_t('Practice') }}: {{ model.practiceName }}
    </span>
    <v-dialog v-model="dialog" :persistent="!selectedPractitionerPractice" width="500">
      <template v-slot:activator="{ on, attrs }" v-if="selectedPractitionerPractice">
        <v-btn
          class="ml-2"
          color="white"
          outlined
          v-bind="attrs"
          v-on="on"
          small
          :disabled="$route.name === 'PractitionerAppointmentNew'"
        >
          {{ $_t('Change') }}
        </v-btn>
      </template>
      <v-card shaped elevation="2">
        <v-card-title class="text-h5">
          {{ $_t('If you intend to generate a new request, please select the current physical site you are referring from.') }}
        </v-card-title>
        <v-card-text>
          <v-btn
            class="mb-4"
            v-for="practitionerPractice in practitionerPracticesFiltered"
            :key="practitionerPractice.id"
            block
            rounded
            color="primary"
            @click="save(practitionerPractice)"
            :disabled="selectedPractitionerPractice && selectedPractitionerPractice.id === practitionerPractice.id"
          >
              {{practitionerPractice.practiceName}}
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  name: 'PractitionerPractice',
  props: {
    practitionerPractices: {
      type: Array,
      required: true
    },
    value: {
      type: null,
      required: true
    },
    loading: {
      type: Boolean
    }
  },
  data() {
    return {
      availablePractices: [],
      dialog: false,
      selectedPractitionerPractice: null
    }
  },
  mounted() {
    if (!this.value) {
      this.dialog = true
    } else {
      this.selectedPractitionerPractice = this.value
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      }
    },
    practitionerPracticesFiltered() {
      return this.practitionerPractices.filter(practitionerPractice => (practitionerPractice.isActive && practitionerPractice.practice.isActive)
        || (this.model && practitionerPractice.id === this.model.id))
    },
  },
  methods: {
    save(practitionerPractice) {
      this.selectedPractitionerPractice = practitionerPractice
      this.$emit('input', practitionerPractice)
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <div
    class="ma-0 pa-0"
    ref="top"
  >
    <v-carousel
      v-model="carouselPage"
      touchless
      hide-delimiters
      :show-arrows="false"
      height="auto"
      light
    >
      <v-carousel-item
        v-for="(component, i) in localComponents"
        :key="i"
        class="pa-2"
      >
      <component v-if="component.name === 'Privacy' && !iframe.isFormIframe"
          :is="PrivacyComponent || component.name"
          ref="components"
          :options="component"
          :appointment="appointment"
          :patient="patient"
          :current-date="useHumanDate(new Date().toISOString())"
          :config="config"
          @next="nextPage"
          @prev="prevPage"
          :iframe="iframe"
          v-on="$listeners"
      />
        <component v-else
          :is="component.name"
          ref="components"
          :options="component"
          :appointment="appointment"
          :patient="patient"
          :current-date="useHumanDate(new Date().toISOString())"
          @next="nextPage"
          @prev="prevPage"
          :iframe="iframe"
        />
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
import SafetyQuestions from '@components/appointment/carousel/SafetyQuestions.vue'
import Welcome from '@components/appointment/carousel/Welcome.vue'
import Insurance from '@components/appointment/Insurance.vue'
import ThankYou from '@components/appointment/ThankYou.vue'
import ThankYouForms from '@components/appointment/ThankYouForms.vue'
import Forms from '@components/appointment/carousel/Forms.vue'
import Address from '@components/appointment/carousel/Address.vue'
import Privacy from '@components/appointment/Privacy.vue'
import Booking from '@components/appointment/carousel/Booking.vue'
import ThankYouBooking from '@components/appointment/carousel/ThankYouBooking.vue'
import Payment from '@components/appointment/carousel/Payment.vue'
import Practitioner from '@components/appointment/carousel/Practitioner.vue'
import PatientContacts from '@components/appointment/carousel/PatientContacts.vue'
import Vue from 'vue';
import { mapGetters } from 'vuex'
import config from '@src/config'
import useDateHelpers from '@helpers/useDateHelpers';
import IframeComponent from '@components/custom/IframeComponent.vue'
import { cloneDeep } from 'lodash'

const { useHumanDate } = useDateHelpers()

export default {
  name: 'AppointmentCarousel',
  props: {
    componentsList: {
      type: Array
    },
    overrideCarouselPage: {
      type: Number,
      default: 0
    }
  },
  components: {
    SafetyQuestions,
    Welcome,
    Insurance,
    ThankYou,
    Forms,
    ThankYouForms,
    Address,
    Privacy,
    Booking,
    ThankYouBooking,
    Payment,
    Practitioner,
    PatientContacts,
    IframeComponent
  },
  data () {
    return {
      carouselPage: 0,
      PrivacyComponent: null,
      useHumanDate:useHumanDate,
      config,
      localComponentsList: [],
      iframeComponentIndexes: []
    }
  },
  computed: {
  ...mapGetters('appointment', { appointment: 'appointment' }),
  ...mapGetters('app', { patient: 'currentUser' }),
    templateId() {
      return this.config.PRIVACY_FORM_TEMPLATE_ID
    },
    iframe() {
      return {
        isFormIframe:this.config.isFormIframe,
        iframeFormLink: this.config.iframeFormLink,
        iframeFormReplaceComponentCsv: this.config.iframeFormReplaceComponentCsv.split(',') || []
      }
    },
    localComponents() {
      return this.localComponentsList
    }
  },
  watch: {
    carouselPage(val) {
    if(this.iframeComponentIndexes.includes(val)) {
      this.$emit('is-iframe-content', true)
    } else {
      this.$emit('is-iframe-content', false)
    }

    }
  },
  mounted () {
    this.carouselPage = this.overrideCarouselPage
    this.processComponents()
  },
  created() {
    if (!this.iframe.isFormIframe) {
      // functionality disabled if config.isFormIframe exists in env variables ticket RIS-9029
      this.getTemplate()
    }

  },
  methods: {
    processComponents () {
      this.iframeComponentIndexes = []
      this.localComponentsList = cloneDeep(this.componentsList)
      if(!this.iframe.isFormIframe) {
        return
      }
      for (const [i,item] of this.localComponentsList.entries()) {
        if(this.iframe.iframeFormReplaceComponentCsv.includes(item.name)) {
          item.name = 'IframeComponent'
          this.iframeComponentIndexes.push(i)
        }

      }


    },
    getTemplate() {
      // functionality disabled if config.isFormIframe exists in env variables ticket RIS-9029
      if(!this.templateId) {
        return
      }

      this.$_rest.get(
        `/patients/${this.patient.id}/templates/${this.templateId}`,
        { groups: 'all,template' },
        (response) => {

          const componentToRender = response.data.component
          if(!componentToRender) {
            return
          }
          const options = {
            moduleCache: {
              vue: Vue
            },
            getFile(url) {
              if ( url === '/PrivacyComponent.vue' ) { // component name is doesn't matter , loadModule function required url param , then compares it, and then load component from file or from string
                return Promise.resolve(componentToRender)

              }
            },
            addStyle() { /* unused here */ },
          }
          const { loadModule } = window['vue2-sfc-loader'];
          loadModule('/PrivacyComponent.vue', options)
          .then(component => {

            this.PrivacyComponent = component
          });


        },
        (error) => {
          this.PrivacyComponent = null
          this.$_notify.error(error)
        },
        {
          prefixRoutesWithOrganizationId: true,
        }
      );
    },
    nextPage () {
      if (this.carouselPage + 1 >= this.componentsList.length) {
        this.closeCarousel()
      }
      this.carouselPage++
      this.triggerInit()
    },
    prevPage () {
      if (this.carouselPage === 0) {
        return
      }
      this.$store.commit('app/setAppBarButton2Disabled', false)
      this.carouselPage--
      this.triggerInit()
    },
    triggerInit () {
      if (typeof this.$refs.components[this.carouselPage] !== 'undefined') {
        if (
          typeof this.$refs.components[this.carouselPage].init === 'function'
        ) {
          this.$refs.components[this.carouselPage].init()
        }
      }
    },
    closeCarousel () {
      this.$store.dispatch('app/appBarHide', '')
      this.$emit('close')
    }
  }
}
</script>

<template>
  <v-card tile :loading="loading" elevation="10" class="text-h2 pa-2" v-if="!loading">
    <v-list>
      <v-list-item
        v-for="(appointment, appointmentIndex) in pendingAppointments"
        :key="appointmentIndex"
        two-line>
        <v-list-item-content>
          <v-list-item-title class="text-left">
          <span class="headline pa-5 font-weight-light"
                :style="'border-radius:5px; background-color:' + appointment.status.colorCode.replace('^', '#')">
            {{ (appointment.statusCode === 'FRNOT') ? 'Completed' : appointment.status.name }}
          </span>
          </v-list-item-title>
          <v-list-item-subtitle class="pt-8">
            <span class="text-h6">{{ appointment.firstProcedureName }}</span>
            <v-divider class="mt-4" />
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-list class="transparent">
              <v-list-item class="row">
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Location') }}:</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0" v-if="appointment.clinic && appointment.clinic.name">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-map-marker
                  </v-icon>
                  {{ appointment.clinic.name }}
                </span>
                  <span v-else class="d-block d-sm-inline-block mt-2 mt-sm-0">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-map-marker-off
                  </v-icon>
                  {{ $_t('Not available') }}
                </span>
                </v-list-item-title>
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Date and Time') }}:</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0" v-if="appointment.date">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-calendar-check
                  </v-icon>
                  {{ formatDateToString(appointment.date) }}&nbsp;
                  {{ appointment.firstProcedureStartTime }}
                  </span>
                  <span v-else class="d-block d-sm-inline-block mt-2 mt-sm-0">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-calendar-remove
                  </v-icon>
                  {{ $_t('Not scheduled') }}
                </span>
                </v-list-item-title>
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Appointment ID') }}:</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0">
                    {{appointment.firstProcedureidHis}}
                  </span>
                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
          </v-list-item-subtitle>
          <v-list-item-action>
            <div v-if="appointment.statusCode === 'X' && linked">
              {{ $_t('To reschedule your appointment, please contact us on one of the numbers shown in the following link') }} <a :href="config.appointmentCentreLink" target="_blank">{{ config.appointmentCentreLink }}</a>
            </div>

            <div v-if="cancelAllowed(appointment)">
              <span class="text-h6 pr-4">{{ $_t('Actions') }}:</span>
              <br class="d-sm-none">
              <v-btn outlined tile color="error" @click="dialog = true"
                     :loading="loading">{{ $_t('Cancel') }}
              </v-btn>
              <v-btn outlined tile color="primary" @click="$router.push('/reschedule/clinics/' + appointment.id)"
                     :loading="loading" class="ml-2 ml-sm-4">{{ $_t('Reschedule') }}
              </v-btn>
              <v-dialog
                v-model="dialog"
                persistent
                max-width="600"
              >
                <v-card>
                  <v-card-title>
                    <span style="word-break: break-word" v-if="!linked">{{ $_t('Are you sure you wish to cancel your appointment?') }}</span>
                    <span style="word-break: break-word" v-else>{{ $_t('Are you sure you wish to cancel your follow-up appointment?') }}</span>
                  </v-card-title>
                  <v-card-text><span class="font-weight-bold red--text">{{ $_t('Important!') }}</span>
                    <span style="word-break: break-word" v-if="!linked">
                {{ $_t('You will need to book new appointments at') }}<br>
                <a :href="config.webBookingLink" target="_blank">{{ config.webBookingLink }}</a>
              </span>
                    <span v-else>
                {{ $_t('Please contact us by phone to reschedule your appointment. Contact details will be shown after cancellation.') }}
              </span>
                  </v-card-text>
                  <v-card-actions class="pa-6">
                    <v-btn
                      color="primary"
                      outlined
                      tile
                      @click="dialog = false"
                    >
                      {{ $_t('No, go back') }}
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      outlined
                      tile
                      :disabled="cancelled"
                      @click="cancelAppointment(appointment.id)"
                    >
                      {{ $_t('Yes, cancel') }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>
          </v-list-item-action>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text>
      <!--   Linked appointments   -->
      <div>
        <span class="headline">{{ $_t('Linked appointments') }}<br/><br></span>
        <v-divider />
        <div class="pa-5">
          <div v-if="patientAppointments.length">
            <LinkedAppointments
              :appointments="patientAppointments"
            />
          </div>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>

import LinkedAppointments from '@components/appointment/cards/LinkedAppointments.vue'
import config from '@config';

export default {
  name: 'AppointmentCancellationCardComponent',
  components: {
    LinkedAppointments
  },
  props: {
    upcoming: Boolean,
    linked: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      loading: true,
      cancelled: false,
      childProcedures: [],
      patientAppointments: [],
      dialog: false,
      config: config,
      allowedAppointmentStatusCodes: ['C']
    }
  },
  computed: {
    pendingAppointments () {

      return this.patientAppointments
        .filter(appointment => new Date(appointment.date) > new Date() && this.allowedAppointmentStatusCodes.includes(appointment.statusCode))
        .map(appointment => {
          const firstProcedure = (appointment?.appointmentProcedures || [])[0]
          return {
            ...appointment,
            firstProcedureName: firstProcedure?.procedure?.name || this.$_t('n/a'),
            firstProcedureStartTime: firstProcedure?.startTime?.slice(11, 16) || this.$_t('n/a'),
            firstProcedureidHis: firstProcedure?.idHis || this.$_t('n/a')
          }
        });
    }

  },
  methods: {
    cancelAllowed (appointment) {
      if (appointment.parentAppointmentStatusCode && appointment.parentAppointmentStatusCode === 'C') {
        return false;
      }

      let allowed = false;
      if (this.allowedAppointmentStatusCodes.includes(appointment.statusCode)) {
        allowed = true;
      }

      if (appointment.date && appointment.appointmentProcedures.length) {
        const today = new Date();
        const appointmentDate = new Date(appointment.date.replace(/-/g, '/').slice(0,19).replace('T', ' '));
        const procedureTime = new Date(appointment.appointmentProcedures[0].startTime.replace(/-/g, '/').slice(0,19).replace('T', ' '));
        appointmentDate.setHours(procedureTime.getHours(), procedureTime.getMinutes());
        if (appointmentDate < today) {
          allowed = false;
        }
      }
      return allowed;
    },
    formatDateToString (date) {
      return date.slice(0,10)
    },
    cancelAppointment (id) {
      this.dialog = false
      this.loading = true
      this.$_rest.post(`/patients/${this.$store.getters['app/currentUserId']}/appointments/${id}/cancel`, {},
        response => {
          this.cancelled = true
          this.$_notify.info(this.$_t(`Successfully cancelled ${response.meta.appointmentsCancelledCount} appointment(s)`))
          setTimeout(() => {
            this.$router.go(this.$router.currentRoute);
          }, 1500);
        }, () => {
          this.$_notify.error(this.$_t('Not possible to cancel the appointment, contact the appointment centre'))
          this.loading = false
        })
    },
    mapProcedure (ap) {
      const apCopy = ap
      const childProc = {}
      childProc.appointment = ap.appointment
      delete apCopy.appointment
      childProc.appointment.appointmentProcedures = [apCopy]
      return childProc
    },
    mapAppointmentStatus(appointment) {
      if (!['C', 'XR', 'FRNOT', 'X'].includes(appointment.statusCode)) {
        appointment.statusCode = 'C';
      }
      if (appointment.statusCode === 'C') {
        appointment.status.colorCode = '^8eff93';
        appointment.status.name = 'Confirmed';
      }
    },
    getPatientAppointments () {
      this.$_rest.get(`/patients/${this.$store.getters['app/currentUserId']}/appointments`, {
        'groups': 'all,appointment_appointment_procedures,appointment_procedure_parent_appointment_procedure,appointment_procedure_procedure,appointment_procedure_appointment,appointment_status,appointment_clinic'
      }, response => {
        response.data.map((appointment) => {
          this.mapAppointmentStatus(appointment);
        })
        this.patientAppointments = response.data
        this.loading = false
        return response.data
      }, error => {
        this.$_notify.error(error)
      })
    }
  },
  mounted () {
    this.getPatientAppointments()
  }
}
</script>

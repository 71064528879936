<template>
  <v-container dark>
  <v-row
    justify="center"
    align="center"
    class="pa-2 text-center"
  >
    <v-col cols="12">
      <div class="text-h6">
      <span class="edit">
        Our Data Protection Promise
      </span>
      </div>
      <p class="text-justify">
        <span class="edit">
          Affidea is committed to respect your rights under data protection laws. Keeping your personal and health data safe is very
          important to us. Our <a href="https://www.affidea.ie/privacy-policy/" target="_blank">Data Privacy Notice for Patients</a> ("Notice") contains the essential information about our data processing
          when providing your medical services. We kindly ask you to take a moment to read the Notice so that you are clear about why
          and how we process your personal data. In the Notice you can find information on the following: (a) the identity and the
          contact details of the data controller; (b) the contact details of the data protection officer; (c) the purposes and legal basis for
          processing; (d) the sources of your personal data; (e) the recipients of your personal data; (f) the period for which your personal
          data will be stored; (g) your rights under data protection laws; (h) international data transfers.
        </span>
      </p>
      <p class="text-justify">
        <span class="edit">
          <b>I. Your Acknowledgement</b> (see clause 1. 7 of the Notice)<br />I, the
          undersigned, by signing this statement acknowledge that I have
          received the Affidea Data Privacy Notice and its Annex providing
          information about how Affidea will process my personal data for the
          purpose of performing medical diagnosis and/or providing medical
          treatment.
        </span>
      </p>
      <p class="text-justify">
        <span class="edit">
          <b>ll. Additional authorization(s)</b> - optional (see clause
          1.3. of the Notice)
            <br />I understand that by ticking the boxes below I
          can voluntarily authorize Affidea to perform additional processing
          of my personal data (section 1.3 of the Notice provides more
          details). I also understand that if I do not consent, this will not
          have any impact on the medical services provided to me. I further
          understand that I can withdraw my consent at any time.
        </span>
      </p>
      <br />
      <p class="font-weight-medium text-justify">
        <span class="edit">
          I consent to my personal information, including my health data,
          being de-identified so that Affidea may use it and share it for
          research and development, educational, statistical and commercial
          purposes. The set of personal information subject to the
          de-identification includes my personal information as collected by
          Affidea in the past (i.e. before I provided this consent) and as
          may be collected by Affidea in the future if I make use of
          Affidea's services.
        </span>
      </p>
      <v-row class="dark--text">
        <v-col cols="12">
          <v-radio-group
            ref="refResearch"
            v-model="consent.research"
            row
          >
            <v-radio
              label="Yes, I consent"
              value="Yes"
            />
            <v-radio
              label="No, I don't consent"
              value="No"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <p class="font-weight-medium text-justify">
        I consent to be informed of Affidea's latest offering in medical
        services via e-mail (e-marketing).
      </p>
      <v-row class="light--text">
        <v-col cols="12">
          <v-radio-group
            ref="refOffers"
            v-model="consent.offers"
            row
          >
            <v-radio
              label="Yes, I consent"
              value="Yes"
            />
            <v-radio
              label="No, I don't consent"
              value="No"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <p class="font-weight-medium text-justify">
        I consent to be contacted for personalized marketing purposes through the following means:
      </p>
      <v-row
        ref="marketing"
        class="light--text"
      >
        <v-col cols="6">
          <v-checkbox
            v-model="consent.marketing.email"
            :disabled="noMarketing"
            label="e-mail"
            value="Yes"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="consent.marketing.phone"
            :disabled="noMarketing"
            label="phone"
            value="Yes"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            v-model="consent.marketing.SMS"
            :disabled="noMarketing"
            label="SMS"
            value="Yes"
          ></v-checkbox>
        </v-col>
        <v-col cols="6">
          <v-checkbox
              v-model="noMarketing"
              label="Please do not contact me"
          ></v-checkbox>
        </v-col>
      </v-row>
      <p class="font-weight-medium text-justify">
        Ill. Collection of historic medical reports within Affidea's
        group of companies
      </p>
      <br />
      <p class="text-justify">
        I understand that the availability of my historic medical records
        may facilitate the medical diagnosis and/or medical treatment I
        request from Affidea. I authorize Affidea to collect my relevant
        historic medical records from (an)other data controller(s) belonging
        to Affidea's group of companies in Ireland if needed.
      </p>
      <v-row class="light--text">
        <v-col cols="12">
          <v-radio-group
            ref="refHistoric"
            v-model="consent.historic"
            row
          >
            <v-radio
              label="Yes, I consent"
              value="Yes"
            />
            <v-radio
              label="No, I don't consent"
              value="No"
            />
          </v-radio-group>
        </v-col>
      </v-row>
      <v-row>
        <v-col
          ref="refSignature1"
          cols="12"
          class="text-left"
        >
          <p class="text-left">
            Signature of the Patient:
          </p>
          <div style="max-width: 600px">
            <div class="text-subtitle-2">
              Please use your mouse, touchpad or touchscreen of your device to draw your signature in the box below.
            </div>
            <div style="border: 1px solid black">
              <VueSignaturePad
                  ref="signature1"
                  height="150px"
                  :options="{ onEnd: () => { saveSignature1() } }"
              />
            </div>
            <v-btn @click="undoSignature1">
              Undo
            </v-btn>
          </div>
        </v-col>
      </v-row>
      <p class="text-justify">
        If the patient cannot make a statement and/or sign on their own
        behalf, please provide us with the reason and the identity of the
        representative (optional):
      </p>
      <v-row class="light--text">
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="checkbox1"
              label="Vulnerable adult"
              value="Vulnerable adult"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
          cols="12"
          md="4"
        >
          <v-checkbox
              v-model="checkbox2"
              label="Child under 16"
              value="Child under 16"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
            cols="12"
            md="4"
        >
          <v-checkbox
              v-model="checkbox3"
              label="Other"
              value="Other"
              hide-details
          ></v-checkbox>
        </v-col>
        <v-col
            ref="refOther"
            v-if="checkbox3"
            cols="12"
        >
          <v-text-field
              v-model="checkboxOther"
              label="Please specify"
              outlined
          />
        </v-col>
      </v-row>
      <v-row
        v-if="checkbox1 || checkbox2 || checkbox3"
        class="text-left">
        <v-col
            ref="refOnbehalfName"
            cols="12"
            md="6"
        >
          <v-text-field
            v-model="consent.onbehalfName"
            label="Full name of the representative"
            outlined
          />
        </v-col>
        <v-col
            ref="refSignature2"
            cols="12"
        >
          <p class="text-left">
            Signature of the representative:
          </p>
          <div style="max-width: 600px">
            <div class="text-subtitle-2">
              Please use your mouse, touchpad or touchscreen of your device to draw your signature in the box below.
            </div>
            <div style="border: 1px solid black">
              <VueSignaturePad
                  ref="signature2"
                  height="150px"
                  :options="{ onEnd: () => { saveSignature2() } }"
              />
            </div>
            <v-btn @click="undoSignature2">
             Undo
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</v-container>
</template>
<script>
export default {
  name: 'AppointmentPrivacy',
  props: {
    options: { type: Object, default: () => {} },
    editMode: { type: Boolean, default: false },
  },
  data() {
    return {
        noMarketing: false,
        checkbox1: '',
        checkbox2: '',
        checkbox3: '',
        checkboxOther: '',
        formEdited: false,
        consent: {
          research: '',
          offers: '',
          marketing: { email: 'No', SMS: 'No', phone: 'No' },
          onbehalf: '',
          onbehalfName: '',
          historic: '',
          signature1: '',
          signature2: '',
          repName: ''
        }
    };
  },
  computed: {
    getOptions() {
      return this.options
    },
    appointment() {
      return this.$store.getters['appointment/appointment']
    }
  },
  watch: {
    noMarketing(val) {
      if (val) {
        this.consent.marketing = { email: 'No', SMS: 'No', phone: 'No' }
      }
      this.formEdited = true
    },
    checkbox1(val) {
      if (val) {
        this.consent.onbehalf = val
        this.checkbox2 = false
        this.checkbox3 = false
      }
      else if (val === null) {
        this.consent.onbehalf = ''
      }
      this.formEdited = true
    },
    checkbox2(val) {
      if (val) {
        this.consent.onbehalf = val
        this.checkbox1 = false
        this.checkbox3 = false
      }
      else if (val === null) {
        this.consent.onbehalf = ''
      }
      this.formEdited = true
    },
    checkbox3(val) {
      if (val) {
        this.consent.onbehalf = val
        this.checkbox1 = false
        this.checkbox2 = false
      }
      else if (val === null) {
        this.consent.onbehalf = ''
      }
    },
    checkboxOther(val) {
      this.consent.onbehalf = 'Other: ' + val
      this.formEdited = true
    }

  },

  methods: {
    saveSignature1() {
      this.$refs.signature1.saveSignature()
      const { isEmpty, data } = this.$refs.signature1.saveSignature()
      if (!isEmpty) {
        this.formEdited = true
        this.consent.signature1 = data
      }
      else {
        this.consent.signature1 = ''
      }
      this.formEdited = true
    },
    saveSignature2() {
      this.$refs.signature2.saveSignature()
      const { isEmpty, data } = this.$refs.signature2.saveSignature()
      if (!isEmpty) {
        this.formEdited = true
        this.consent.signature2 = data
      }
      else {
        this.consent.signature2 = ''
      }
      this.formEdited = true
    },
    saveConsentData() {
      if(this.editMode) {
        return 
      }
      const consentText = `Research: ${this.consent.research} | Offers: ${this.consent.offers} | Marketing: Email: ${this.consent.marketing.email} SMS: ${this.consent.marketing.SMS} Phone: ${this.consent.marketing.phone} | Historic: ${this.consent.historic} | On behalf: ${this.consent.onbehalf}`
      this.$_rest.put(
        `/appointments/${this.appointment.id}/custom`,
        {
          custom7: consentText
        }, undefined,
        response => {
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )

      const data = {
        research: this.consent.research,
        offers: this.consent.offers,
        marketing: `Email: ${this.consent.marketing.email} | SMS: ${this.consent.marketing.SMS} | Phone:  ${this.consent.marketing.phone}`,
        onbehalf: this.consent.onbehalf,
        onbehalfName: this.consent.onbehalfName,
        historic: this.consent.historic,
        signature1: this.consent.signature1,
        signature2: this.consent.signature2,
      }

      this.$_rest.post(
        `/appointments/${this.appointment.id}/documents`,
        {
          categoryCode: 'DPN',
          documentName: 'Data Protection and Consent',
          dataJson: {templateId: 24, data: data},
          templateId: 24
        },
        response => {
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    },
    init() {
      this.$store.commit('app/setAppBarTitle', 'Data protection')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Finish',
        icon: 'mdi-chevron-right',
        click: () => {
          let stop = false
          if (!this.consent.research) {
            this.$vuetify.goTo(this.$refs.refResearch)
            stop = true
          }
          else if (!this.consent.offers) {
            this.$vuetify.goTo(this.$refs.refOffers)
            stop = true
          }
          else if (!this.consent.historic) {
            this.$vuetify.goTo(this.$refs.refHistoric)
            stop = true
          }

          if (this.consent.onbehalf) {
            if (this.consent.onbehalf.substr(0, 5) === 'Other' && !this.checkboxOther.trim()) {
              this.$vuetify.goTo(this.$refs.refOther)
              stop = true
            }
            else if (!this.consent.onbehalfName || !this.consent.onbehalfName.trim()) {
              this.$vuetify.goTo(this.$refs.refOnbehalfName)
              stop = true
            }
            else if (!this.consent.signature2) {
              this.$vuetify.goTo(this.$refs.refSignature2)
              stop = true
            }
          }
          else {
            if (!this.consent.signature1) {
              this.$vuetify.goTo(this.$refs.refSignature1)
              stop = true
            }
          }

          if (stop) {
            this.$_notify.error('Please provide all required answers.')
            return
          }

          if (this.formEdited) {
            this.saveConsentData()
          }
          this.formEdited = false
          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
    undoSignature1() {
      this.$refs.signature1.undoSignature()
      const { isEmpty, data } = this.$refs.signature1.saveSignature()
      this.consent.signature1 = isEmpty ? '' : data
      this.formEdited = true
    },
    undoSignature2() {
      this.$refs.signature2.undoSignature()
      const { isEmpty, data } = this.$refs.signature2.saveSignature()
      this.consent.signature2 = isEmpty ? '' : data
      this.formEdited = true
    },
    nextPage(page) {
      this.$emit('page', page)
    }
    
    
  },
  created() {
    this.init()
  }
 
};
</script>
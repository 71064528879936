<template>
  <v-container>
    <v-row align="center">
      <v-col cols="12">
        <v-autocomplete
          v-model="model"
          :items="items"
          item-value="id"
          item-text="name"
          :loading="loading"
          :search-input.sync="search"
          :label="$_t('Search an examination')"
          :placeholder="$_t('Start typing to search')"
          prepend-inner-icon="mdi-account-search"
          return-object
          clearable
          @keydown="isTyping = true"
          @blur="isTyping = false"
          no-filter
        >
          <template v-slot:no-data>
            <v-list-item v-if="loading">
              Loading...
            </v-list-item>
            <v-list-item v-else-if="isTyping">
              ...
            </v-list-item>
            <v-list-item v-else-if="search && (!items || !items.length)">
              No examinations found
            </v-list-item>
            <v-list-item v-else>
              Start typing to search for examinations
            </v-list-item>
          </template>
          <template v-slot:item="{ item }">
            <v-list-item-action>
              <v-icon>mdi-account</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>
                {{ (item.scan && item.scan.nameShort ? item.scan.nameShort + ' | ' : '') + item.name }}
              </v-list-item-title>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { defineComponent, ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import config from '@src/config'

export default defineComponent({
  name: 'ProcedureSearch',
  props: {
    value:{type:Object,default:()=>{}},
    fetchRoute: String,
    scanId: {type: String,default:null}
  },
  setup(props, {emit}) {
    const _vm = getCurrentInstance()
    const items = ref([])
    const search = ref('')
    const loading = ref(false)
    const isTyping = ref(false)
    let searchTimeout
    let searchAjax = null

    const model = computed({
      get: () => props.value,
      set: newVal => {
        emit('input', newVal)
      }
    })

    const setPatientStore = (res) => {
      _vm.proxy.$store.dispatch('app/setCurrentOrganization', res.data.organization)
      _vm.proxy.$store.dispatch('app/setCurrentPatient', res.data)
      loading.value = false
    }

    const errorPatientEndpoint = () => {
      _vm.proxy.errorMessage = 'Sorry, we couldn\'t retrieve your details.'
      loading.value = false
    }

    const getScanId = computed(()=>{
      return !config.practitionerNewMultiScan && props.scanId ? { scanId: props.scanId } : {}
    })

    const processProcedures = (procedures) => {
      items.value = []
      const groupedByScan = {}

      if (procedures && Array.isArray(procedures)) {
        procedures.forEach((el) => {
          let scanId = ''
          if (el.scan && el.scan.id) {
            scanId = el.scan.name
          }

          if (!groupedByScan[scanId]) {
            groupedByScan[scanId] = []
          }

          groupedByScan[scanId].push(el)
        })
      }

      const sorted = Object.keys(groupedByScan)
        .sort()
        .reduce((accumulator, key) => {
          accumulator[key] = groupedByScan[key].sort((a, b) => {
            if ( a.name > b.name) {
              return 1
            }
            if ( a.name < b.name) {
              return -1
            }
            return 0
          });
          return accumulator;
        }, {});

      items.value = Object.values(sorted).flat(1)
    }

    watch(search, val => {
      if (searchTimeout) {
        clearTimeout(searchTimeout)
      }

      if (searchAjax) {
        searchAjax.abort()
      }

      val = (val || '').trim()
      if (val.length < 2) {
        items.value = []
        isTyping.value = false
        return
      }

      searchTimeout = setTimeout(() => {
        const paramsObject = {
           search: search.value,
           ...getScanId.value
        }
        isTyping.value = false
        loading.value = true
        searchAjax = _vm.proxy.$_rest.get(props.fetchRoute, { groups:'all,procedure_scan', ...paramsObject, limit: 10000  }, procedures => {
          loading.value = false
          processProcedures(procedures.data)
        }, error => {
          loading.value = false
          if (error?.code !== 20) {
            _vm.proxy.$_notify.error(error)
          }
        })
      }, 700)
    })

    return {
      items,
      model,
      search,
      loading,
      isTyping
    }
  }
})
</script>
<style scoped></style>

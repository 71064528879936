<template>
  <v-container>
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
    <v-row
      justify="center"
      align="center"
      class=""
    >
      <v-col cols="12">
        <div class="mb-6 text-center">
          <h5 class="text-h5 mb-2" v-if="config.patientContactHeader">
            {{ config.patientContactHeader }}
          </h5>
          <h5 class="text-h5 mb-2" v-else>
            {{ $_t('Next of Kin contacts.') }}
          </h5>
          <p v-if="config.patientContactSubtitle">
            {{ config.patientContactSubtitle }}
          </p>
          <p v-else>
            {{ $_t('Please fill out all of the required information. Please ensure all details entered here are correct as any inaccuracy can result in a delay in receiving your appointment.') }}
          </p>
          <p v-if="config.patientContactAdditionalMessage" v-html="config.patientContactAdditionalMessage">

          </p>
          <p v-if="config.patientContactRequired">
            {{ $_t('In order to proceed, at least one contact details must be provided.') }}
          </p>
        </div>
      </v-col>
    </v-row>
      <patient-contacts-list
        v-if="!loading && contactsList.length"
        :contacts-list="contactsList"
        @contact-edit="editContact"
        @contact-delete="deleteContact"
      />
      <v-card v-if="showContactForm">
        <v-card-title>
          {{ $_t('Next of kin person contact details')}}
        </v-card-title>
        <div class="pa-8">
          <patient-next-of-kin-form
            :contact-data="contactFormData"
            :contact-types="contactTypes"
            @save="submitContact"
            @cancel="hideForm"
          />
        </div>
      </v-card>
      <v-btn
        v-if="!showContactForm"
        color="primary"
        class="float-right ma-4 pa-2"
        @click="newContact"
      >
        {{$_t('Add new contact')}}
      </v-btn>
  </v-container>
</template>

<script>
import PatientContactsList from '@components/patient/PatientContactsList.vue'
import { mapGetters } from 'vuex'
import PatientNextOfKinForm from '@components/patient/PatientNextOfKinForm.vue'
import config from '@config'

export default {
  name: 'PatientContacts',
  components: {
    PatientNextOfKinForm,
    PatientContactsList
  },
  data() {
    return {
      loading: true,
      patientContactsList: [],
      selectedContact: {},
      contactId: null,
      displayContactForm: false,
      contactTypes: [],
      contactsLimit: 10
    };
  },
  computed: {
    config() {
      return config
    },
    ...mapGetters('app', { currentPatient: 'currentUser' }),
    patientId () {
      return this.currentPatient?.id || ''
    },
    contactsList(){
      return this.patientContactsList.filter(item => item.isActive)
    },
    contactFormData(){
      return this.selectedContact ? this.selectedContact : {}
    },
    isEditMode(){
      if (!this.contactId){
        return false
      }
      return this.contactsList.find(item => item.id === this.contactId)
    },
    showContactForm(){
      return this.displayContactForm
    },
    nextButtonDisabled(){
      return config.nextOfKinRequired && !this.contactsList.length
    }
  },
  methods: {
    getPatientContacts() {
      this.loading = true
      this.$_rest.get(`/patients/${this.patientId}/patient-contacts`, {
        groups: 'all, patient_contact_type'
      }, response => {
        this.patientContactsList = response.data
        this.loading = false
        this.checkNextButton()
      }, (error) => {
        this.loading = false
        this.$_notify('Error getting patient contacts')
      });
    },
    getContactTypes(){
      this.$_rest.get(`/patients/${this.patientId}/patient-contact-types`, {}, response => {
        this.contactTypes = response.data.filter(item => item.isActive)
      }, error => {
        this.$_notify.error(this.$_t('Failed to fetch contacts'))
        console.error(error)
      })
    },
    editContact(index){
      this.selectedContact = this.contactsList[index]
      this.contactId = this.contactsList[index].id
      this.displayContactForm = true

    },
    submitContact(contactDetails){
      this.contactId = contactDetails.id
      if(this.isEditMode){
        this.updateContactDetails(contactDetails)
      } else {
        this.addNewContact(contactDetails)
      }
    },
    updateContactDetails(contact){
      this.$_rest.put(`/patients/${this.patientId}/patient-contacts/${contact.id}`,{
        firstName: contact.firstName,
        lastName: contact.lastName,
        mobileNumber1: contact.mobileNumber1,
        typeId: contact.typeId
      }, undefined, response => {
        this.displayContactForm = false
        this.selectedContact = {}
        this.$_notify.success(this.$_t('Contact details updated'))
        this.getPatientContacts()
      }, error => {
        this.loading = false
        this.$_notify.error(this.$_t('Failed to update contact details'))
      })
    },
    addNewContact(contact){
      contact.patientId = this.patientId
      this.loading = true

      this.$_rest.post(`/patients/${this.patientId}/patient-contacts`,{
        firstName: contact.firstName,
        lastName: contact.lastName,
        mobileNumber1: contact.mobileNumber1,
        typeId: contact.typeId,
      }, response => {
        this.selectedContact = {}
        this.displayContactForm = false
        this.getPatientContacts()
        this.$_notify.success(this.$_t('Contact added succesfully, you can proceed to the next page'))
      }, error => {
        this.loading = false
        this.$_notify.error(this.$_t('Failed to add Contact'))
      })
    },
    deleteContact(index){
      const contactId = this.contactsList[index].id
      this.loading = true
      this.$_rest.remove(`/patients/${this.patientId}/patient-contacts/${contactId}`,response => {
        this.$_notify.info(this.$_t('Contact has been deleted'))
        this.getPatientContacts()
      }, error => {
        this.$_notify.error(this.$_t('Failed to delete contact'))
        this.loading = false
      })
    },
    init(){
      this.$store.commit('app/setAppBarTitle', 'Contacts')
      this.getPatientContacts()
      this.getContactTypes()
    },
    hideForm(){
      this.displayContactForm = false
    },
    newContact(){
      if(this.contactsList.length >= this.contactsLimit){
        this.$_notify.error(this.$_t('{limit} active contacts limit reached. Please delete one', { limit: this.contactsLimit }))
        return
      }
      this.selectedContact = {}
      this.displayContactForm = true
    },
    checkNextButton(){
      if(this.nextButtonDisabled){
        this.$store.commit('app/setAppBarButton2Disabled', true)
      }else {
        this.$store.commit('app/setAppBarButton2Disabled', false)
      }
    }
  },
  mounted() {
    this.$store.commit('app/setAppBarTitle', this.$_t('Contacts'))
    this.getPatientContacts();
    this.getContactTypes()
  },
};
</script>

<template>
  <div>
    <handy-uploader
      :documentAttachment.sync="attachments"
      :fileUploaderType="options.fileUploaderType"
      :maxFileSize="options.maxFileSize"
      :imageCompressor="options.imageCompressor"
      :imageCompressLevel="options.imageCompressLevel"
      :maxFileCount="options.maxFileCount"
      :badgeCounter="options.badgeCounter"
      :thumb="options.thumb"
      :changeFileName="options.changeFileName"
      :addFileDescription="options.addFileDescription"
      :showCards="options.showCards"
      :fabButton="options.fabButton"
      :btnColor="options.btnColor"
      :buttonIcon="options.buttonIcon"
      :buttonText="options.buttonText"
    >
    </handy-uploader>
  </div>
</template>

<script>
import {
  ref,
  watch,
  defineComponent,
  reactive,
  PropType,
  computed,
  toRefs,
  getCurrentInstance
} from '@vue/composition-api';
import handyUploader from '@helpers/uploader/handyUploader.vue';
import useCommonHelpers from '../../helpers/useCommonHelpers';

export default defineComponent({
  name: 'PatientPhotoUpload',
  components: {
    handyUploader
  },
  props: {
    uploadOptions: {
      type: Object,
      default: () => {}
    },
    patientId: {
      type: String,
      default: null
    },
    categoryCode: {
      type: String,
      default: 'general'
    }
  },
  setup(props,{emit}) {
    const vm = getCurrentInstance()

    const { getPractitionerId } = useCommonHelpers()
    const attachments = ref([]);
    const uploadDone = ref(false);

    //can add another options , from plugin doc
    const uploadOptions = reactive({
      fileUploaderType: 'simple',
      maxFileSize: 10240,
      imageCompressor: true,
      imageCompressLevel: 0.8,
      maxFileCount: 10,
      badgeCounter: true,
      thumb: false,
      changeFileName: true,
      addFileDescription: true,
      showCards: false,
      fabButton: true,
      btnColor: 'info',
      buttonIcon:'mdi-camera',
      buttonText: 'Upload',
      categoryCode: props.categoryCode ?? 'general'
    });
    const options = computed(() => {
      // props auto override defualt options ,but is not required
      return { ...uploadOptions, ...props.uploadOptions }
    });

    const sendAttachments = (attachments) =>{
      emit('attachments',attachments)
    }

    const uploadPhotoPatient = (files) => {
      const patientId = props.patientId
      const practitionerId =  getPractitionerId()
      for (const element of files) {
        const fileObject = element
        return new Promise((resolve, reject) => {
          // @ts-ignore
          vm?.proxy.$_rest.upload(
            `/practitioners/${practitionerId}/patients/${patientId}/photo`,
            fileObject.file.fileData,
            response => {
              // @ts-ignore
              vm?.proxy.$_notify.success('Photo has been uploaded')
              resolve(true)
            },
            error => {
              // @ts-ignore
              vm?.proxy.$_notify.error('Photo upload error')
              resolve(false)
            }
          );
        });
      }
    };
    watch(uploadDone, val => {
      if (val) {
        attachments.value = []
      }
    });
    watch(attachments, (val) => {
      sendAttachments(val)
      uploadDone.value = false;
      uploadPhotoPatient(val)?.then((res) => {
        if (res) {
          uploadDone.value = true
        }
      });
    });

    return {
      attachments,
      options
    };
  }
});
</script>

<template>
  <v-flex class="mt-16">
    <re-captcha @verify="verifyRecaptcha" ref="reCaptcha"></re-captcha>
    <v-col cols="12" offset-md="3" md="6" v-if="loading">
      <v-card class="elevation-4">
        <v-card-text class="text-center">
          <v-progress-circular
            color="primary"
            indeterminate
          />
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" offset-md="3" md="6" v-else>
      <v-card class="elevation-4" v-if="externalLink && !error && !success">
        <br/>
        <v-img style="width:75px;margin:0 auto;" v-if="config.appLogo" :src="config.appLogo"></v-img>
        <v-card-title>{{ $_t('Acknowledgement for')}}&nbsp;{{appointmentId}}</v-card-title>
        <v-card-text class="my-10">
          <div v-html="config.acknowledgeContentHtml"></div>
          <v-checkbox v-model="tcAccept">
            <template v-slot:label>
              <div v-html="config.acknowledgeTermsHtml"></div>
              <a v-if="config.acknowledgeTermsLink" @click.stop :href="config.acknowledgeTermsLink" target="_blank" style="padding-left: 4px">{{$_t('Terms & Conditions')}}</a>
            </template>
          </v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="reject" outlined>{{$_t('Reject')}}</v-btn>
          <v-spacer />
          <v-btn @click="acknowledge" color="primary" :disabled="!tcAccept">{{$_t('Acknowledge')}}</v-btn>
        </v-card-actions>
      </v-card>
      <v-card class="elevation-4" v-else-if="error">
        <br/>
        <v-img style="width:75px;margin:0 auto;" v-if="config.appLogo" :src="config.appLogo"></v-img>
        <v-card-title>{{ $_t('This link is no longer valid') }}</v-card-title>
        <v-card-text>
          <v-alert color="warning" type="error">
          {{ $_t(error) }}
          </v-alert>
        </v-card-text>
      </v-card>
      <v-card class="elevation-4" v-else-if="success">
        <v-card-title>{{ $_t('All done!') }}</v-card-title>
        <v-card-text>
          <v-alert color="green" type="success">
            {{ $_t(success) }}
          </v-alert>
        </v-card-text>
      </v-card>
    </v-col>
  </v-flex>
</template>

<script>
import config from '@src/config';
import ReCaptcha from '@components/common/ReCaptcha.vue';

export default {
  name: 'Acknowledge',
  components: {
    ReCaptcha
  },
  data() {
    return {
      tcAccept: false,
      loading: true,
      linkHash: null,
      config: config,
      isAcknowledged: false,
      externalLink: null,
      error: null,
      success: null,
      appointmentId: null
    };
  },
  mounted() {
    this.linkHash = this.$route.params.hash
    this.appointmentId = this.$route.query.apId
  },
  computed: {},
  methods: {
    acknowledge() {
      this.loading = true
      this.isAcknowledged = true
      this.$refs.reCaptcha.resetRecaptcha()
      this.$refs.reCaptcha.execute();
    },
    reject() {
      this.loading = true
      this.isAcknowledged = false
      this.$refs.reCaptcha.resetRecaptcha()
      this.$refs.reCaptcha.execute();
    },
    getExternalLinkByHash(recaptchaToken) {
      this.$_rest.get('/public/link/' + this.linkHash, {}, response => {
        this.externalLink = response.data
        this.loading = false
      }, error => {
        this.error = 'The link has already been used or expired'
        this.loading = false
      }, {
        prefixRoutesWithOrganizationId: false,
        dontIncludeAuthHeader: true,
        recaptchaToken
      })
    },
    updateExternalLinkByHash(recaptchaToken) {
      this.$_rest.put('/public/link', {
        acknowledgementStatusCode: this.isAcknowledged ? 'A' : 'X'
      }, this.linkHash, response => {
        this.success = 'Your response has been successfully stored'
        this.loading = false
      }, error => {
        this.error = 'An error occurred while trying to store your response, refresh the page and try again'
        this.loading = false
      }, {
        prefixRoutesWithOrganizationId: false,
        dontIncludeAuthHeader: true,
        recaptchaToken
      })

    },
    verifyRecaptcha(recaptchaToken) {
      if (this.externalLink === null) {
        this.getExternalLinkByHash(recaptchaToken)
      } else {
        this.updateExternalLinkByHash(recaptchaToken)
      }
    },
  },
  watch: {
  }
};
</script>

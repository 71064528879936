<template>
  <validation-observer ref="observer" v-slot="{ invalid }">
    <form @submit.prevent="submit">
      <validation-provider v-slot="{ errors }" :name="$_t('First Name')" rules="required">
        <v-text-field
          v-model="contactDataCopyLocal.firstName"
          :error-messages="errors"
          :label="$_t('First Name')"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" :name="$_t('Last Name')" rules="required">
        <v-text-field
          v-model="contactDataCopyLocal.lastName"
          :error-messages="errors"
          :label="$_t('Last Name')"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" :name="$_t('Phone Number')" rules="required">
        <v-text-field
          v-model="contactDataCopyLocal.mobileNumber1"
          :error-messages="errors"
          :label="$_t('Phone Number')"
          required
        ></v-text-field>
      </validation-provider>
      <validation-provider v-slot="{ errors }" :name="$_t('Contact Type')" rules="required">
        <v-select
          v-model="contactDataCopyLocal.typeId"
          :items="contactTypes"
          item-value="id"
          item-text="name"
          :error-messages="errors"
          :label="$_t('Contact Type')"
          required
        ></v-select>
      </validation-provider>
      <v-col
        cols="12"
      >
        <div class="float-right">
          <v-btn
            class="mr-2"
            color="error"
            @click="cancel"
          >
            {{ $_t('Cancel') }}
          </v-btn>

          <v-btn
            class="float-right"
            type="submit"
            color="primary"
            :disabled="invalid"
          >
            {{ $_t('Save') }}
          </v-btn>
        </div>
      </v-col>
    </form>
  </validation-observer>
</template>

<script>
import { required } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'

setInteractionMode('eager')

extend('required', {
  ...required,
  message: '{_field_} can not be empty',
})


export default {
  props: {
    contactData: {
      type: Object,
      required: true,
    },
    contactTypes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      contactDataCopyLocal: {}
    };
  },
  components: {
    ValidationObserver,
    ValidationProvider
  },
  computed: {
  },
  methods: {
    submit () {
      this.$emit('save', this.contactDataCopyLocal)
    },
    cancel(){
      this.$emit('cancel')
    }
  },
  mounted(){
    this.contactDataCopyLocal = {...this.contactData}
  }
};
</script>

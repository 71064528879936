<template>
  <v-select
    v-model="model"
    :items="episodes"
    item-value="patientEpisodeId"
    item-text="patientEpisodeLabel"
    :loading="loading"
    :required="mandatory"
    :rules="checkRules"
    :error="showError"
    clearable
  />
</template>

<script>
import useDateHelpers from '../../helpers/useDateHelpers';
const { useHumanDateTime } = useDateHelpers()

export default {
  name: 'PatientEpisodeDropdown',
  props: {
    value: {
      type: String
    },
    patientId: {
      type: String
    },
    mandatory: {
      type:Boolean,
      default: false
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    },
    checkRules(){
      if(this.mandatory) {
      return (this.model) ? [] : [this.$_t('Required')]
      } else {
        return []
      }
    },
    showError(){
      if(this.mandatory) {
        return (this.model) ? false : true

      } else {
        return false
      }
    }
  },
  data () {
    return {
      episodes: [],
      loading: false,
      useHumanDateTime:useHumanDateTime
    }
  },
  mounted () {
    this.getData()
  },
  methods: {
    processItems() {
      const copyEpisodes = [...this.episodes]
      for(const episode of copyEpisodes) {
        for (const [key, value ] of Object.entries(episode)) {
          if(!value) {
            episode[key] = 'N/A'
          }
        }
        episode.patientEpisodeLabel = `${episode.patientEpisodeNumber} | ${useHumanDateTime(episode.createdDatetime)} | ${episode.patientLocationName} | ${episode.patientEpisodeStatusName}  ${episode.isDischarged ? '| '+this.$_t('Discharged') : ''} | ${episode.consultingPractitionerFullName}`
      }
      
      this.episodes = [...copyEpisodes]
    },
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/episodes`, {
        filter: { patientId: {  x: this.patientId, type: 'eq'}}
      }, response => {
        this.loading = false
        this.episodes = this.mapDatasetResponse(response)
        this.processItems()
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },

  }
}
</script>

<style scoped>

</style>

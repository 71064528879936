export default [
  {
    value: 'alertId',
    text: 'Alert Id',
    align: 'left',
    visible:false
  }, {
    value: 'createdDatetime',
    text: 'Created',
    align: 'left',
    width: 150,
    visible:true
  }, {
    value: 'alertStatusCode',
    text: 'Alert Status',
    align: 'left',
    visible:false
  }, {
    value: 'alertBody',
    text: 'Alert',
    align: 'left',
    width: 300,
    visible:true
  }, {
    value: 'alertStatusName',
    text: 'Alert Status Name',
    align: 'left',
    width: 180,
    visible:true
  }, {
    value: 'alertStatusColorCode',
    text: 'Alert Status Color Code',
    align: 'left',
    visible:false
  }, {
    value: 'alertTypeId',
    text: 'Alert Type Id',
    align: 'left',
    visible:false
  }, {
    value: 'alertTypeName',
    text: 'Alert Type',
    align: 'left',
    width: 250,
    visible:true
  }, {
    value: 'alertTypeColorCode',
    text: 'Alert Type Color Code',
    align: 'left',
    visible:false
  }, {
    value: 'priorityDisplayOrder',
    text: 'Priority Display Order',
    align: 'left',
    visible:false
  }, {
    value: 'isRead',
    text: 'Is Read',
    align: 'left',
    visible:false
  }, {
    value: 'requireAcknowledgment',
    text: 'Require Acknowledgment',
    align: 'left',
    visible:false
  }, {
    value: 'acknowledgedDatetime',
    text: 'Acknowledged Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'alertAcknowledgmentTypeId',
    text: 'Alert Acknowledgment Type Id',
    align: 'left',
    visible:false
  }, {
    value: 'acknowledgmentNote',
    text: 'Acknowledgment Note',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureId',
    text: 'Appointment Procedure Id',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentIdShort',
    text: 'Appt. ID',
    align: 'left',
    visible:true,
    width: 140
  }, {
    value: 'appointmentProcedureStatusCode',
    text: 'Appointment Procedure Status Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureStatusName',
    text: 'Procedure Status',
    align: 'left',
    visible:true,
    width: 200
  }, {
    value: 'appointmentStatusCode',
    text: 'Appointment Status Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentStatusName',
    text: 'Appointment Status Name',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentStatusColorCode',
    text: 'Appointment Status Color Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProtocollingStatusCode',
    text: 'Appointment Protocolling Status Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProtocollingStatusName',
    text: 'Appointment Protocolling Status Name',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProtocollingStatusColorCode',
    text: 'Appointment Protocolling Status Color Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentSourceId',
    text: 'Appointment Source Id',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentSourceName',
    text: 'Appointment Source Name',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentSourceColorCode',
    text: 'Appointment Source Color Code',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDate',
    text: 'Appointment Date',
    align: 'left',
    width: 150,
    visible:true
  }, {
    value: 'practitionerPracticeId',
    text: 'Practitioner Practice Id',
    align: 'left',
    visible:false
  }, {
    value: 'procedureId',
    text: 'Procedure Id',
    align: 'left',
    visible:false
  }, {
    value: 'scanId',
    text: 'Scan Id',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentId',
    text: 'Appointment Document Id',
    align: 'left',
    visible:false
  }, {
    value: 'startTime',
    text: 'Start Time',
    align: 'left',
    width: 120,
    visible:true
  }, {
    value: 'duration',
    text: 'Duration',
    align: 'left',
    visible:false
  }, {
    value: 'removedDatetime',
    text: 'Removed Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'removedByUserId',
    text: 'Removed By User Id',
    align: 'left',
    visible:false
  }, {
    value: 'removedByUserFullName',
    text: 'Removed By User Full Name',
    align: 'left',
    visible:false
  }, {
    value: 'removalNote',
    text: 'Removal Note',
    align: 'left',
    visible:false
  }, {
    value: 'procedureNameGeneric',
    text: 'Procedure Name Generic',
    align: 'left',
    visible:false
  }, {
    value: 'procedureName',
    text: 'Procedure',
    align: 'left',
    width: 200,
    visible:true
  }, {
    value: 'procedureCodeRis',
    text: 'Procedure Code Ris',
    align: 'left',
    visible:false
  }, {
    value: 'clinicId',
    text: 'Clinic Id',
    align: 'left',
    visible:false
  }, {
    value: 'clinicCodeRis',
    text: 'Clinic Code Ris',
    align: 'left',
    visible:false
  }, {
    value: 'clinicFacilityCodeRis',
    text: 'Clinic Facility Code Ris',
    align: 'left',
    visible:false
  }, {
    value: 'clinicName',
    text: 'Clinic Name',
    align: 'left',
    width: 150,
    visible:true
  }, {
    value: 'clinicNameShort',
    text: 'Clinic Name Short',
    align: 'left',
    visible:false
  }, {
    value: 'insurerId',
    text: 'Insurer Id',
    align: 'left',
    visible:false
  }, {
    value: 'insurerName',
    text: 'Insurer Name',
    align: 'left',
    visible:false
  }, {
    value: 'title',
    text: 'Title',
    align: 'left',
    visible:false
  }, {
    value: 'firstName',
    text: 'First Name',
    align: 'left',
    visible:false
  }, {
    value: 'lastName',
    text: 'Last Name',
    align: 'left',
    visible:false
  }, {
    value: 'motherMaidenName',
    text: 'Mother Maiden Name',
    align: 'left',
    visible:false
  }, {
    value: 'sex',
    text: 'Sex',
    align: 'left',
    width: 85,
    visible:true
  }, {
    value: 'dateOfBirth',
    text: 'Date Of Birth',
    align: 'left',
    width: 130,
    visible:true
  }, {
    value: 'address1',
    text: 'Address1',
    align: 'left',
    visible:false
  }, {
    value: 'address2',
    text: 'Address2',
    align: 'left',
    visible:false
  }, {
    value: 'address3',
    text: 'Address3',
    align: 'left',
    visible:false
  }, {
    value: 'town',
    text: 'Town',
    align: 'left',
    visible:false
  }, {
    value: 'postCode',
    text: 'Post Code',
    align: 'left',
    visible:false
  }, {
    value: 'countryCode',
    text: 'Country Code',
    align: 'left',
    visible:false
  }, {
    value: 'countryName',
    text: 'Country Name',
    align: 'left',
    visible:false
  }, {
    value: 'phoneNumber1',
    text: 'Phone Number1',
    align: 'left',
    visible:false
  }, {
    value: 'phoneNumber2',
    text: 'Phone Number2',
    align: 'left',
    visible:false
  }, {
    value: 'mobileNumber1',
    text: 'Mobile Number1',
    align: 'left',
    visible:false
  }, {
    value: 'emailAddress1',
    text: 'Email Address1',
    align: 'left',
    visible:false
  }, {
    value: 'clinicScanRoomCode',
    text: 'Clinic Scan Room Code',
    align: 'left',
    visible:false
  }, {
    value: 'clinicScanRoomId',
    text: 'Clinic Scan Room Id',
    align: 'left',
    visible:false
  }, {
    value: 'practitionerFullName',
    text: 'Practitioner Full Name',
    align: 'left',
    visible:false
  }, {
    value: 'illnessDataJson',
    text: 'Illness Data Json',
    align: 'left',
    visible:false
  }, {
    value: 'registrationFullName',
    text: 'Registration Full Name',
    align: 'left',
    visible:false
  }, {
    value: 'examinationUserId',
    text: 'Examination User Id',
    align: 'left',
    visible:false
  }, {
    value: 'examinationFullName',
    text: 'Examination Full Name',
    align: 'left',
    visible:false
  }, {
    value: 'reportingUserId',
    text: 'Reporting User Id',
    align: 'left',
    visible:false
  }, {
    value: 'examinationUserIdCsv',
    text: 'Examination User Id Csv',
    align: 'left',
    visible:false
  }, {
    value: 'reportingUserIdCsv',
    text: 'Reporting User Id Csv',
    align: 'left',
    visible:false
  }, {
    value: 'examinationStartedDatetime',
    text: 'Examination Started Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'examinationFinishedDatetime',
    text: 'Examination Finished Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'reportingStartedDatetime',
    text: 'Reporting Started Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'reportingApprovedDatetime',
    text: 'Reporting Approved Datetime',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureCustom1',
    text: 'Appointment Procedure Custom1',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureCustom2',
    text: 'Appointment Procedure Custom2',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureCustom3',
    text: 'Appointment Procedure Custom3',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureCustom4',
    text: 'Appointment Procedure Custom4',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentIssueDate',
    text: 'Appointment Document Issue Date',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentValidityDate',
    text: 'Appointment Document Validity Date',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentValidityPeriod',
    text: 'Appointment Document Validity Period',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentSerialNumber',
    text: 'Appointment Document Serial Number',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentDocumentDeliveredDate',
    text: 'Appointment Document Delivered Date',
    align: 'left',
    visible:false
  }, {
    value: 'appointmentProcedureReportId',
    text: 'Appointment Procedure Report Id',
    align: 'left',
    visible:false
  }, {
    value: 'patientFullName',
    text: 'Patient',
    align: 'left',
    width: 200,
    visible:true
  }, {
    value: 'patientIdShort',
    width: '120px',
    text: 'Patient ID',
    align: 'left',
    visible: true
  }, {
    value: 'patientNumber1',
    text: 'NHS No.',
    align: 'left',
    visible: true
  }, {
    value: 'patientIdExternal',
    width: '100px',
    text: 'BHR No.',
    align: 'left',
    visible: true
  }, {
    value: 'isAppointmentCancelRequested',
    text: 'Request Cancellation',
    align: 'left',
    width: 50,
    visible:true
  }
]

<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <grid
        fixedHeader
        :data="items"
        :loading="loading"
        @rowAdd="rowAdd"
        @refresh="getData"
        @rowClick="rowClicked"
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        :filter-name="filterName"
        :search-value.sync="search"
        :filter-value="locationSelected"
        :hide-filters="true"
        :show-grid-settings="true"
      >
        <template v-slot:filters>
          <v-col cols="12" md="8" class="mt-md-1">
            <v-autocomplete
              v-model="locationSelected"
              :items="locations"
              prepend-icon="mdi-filter-menu"
              item-value="id"
              item-text="name"
              :label="$_t(filterName)"
              clearable
            ></v-autocomplete>
          </v-col>
        </template>
      </grid>
    </v-container>
  </practitioner-layout>

</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import episodesHeadersPreset from '@src/data/dataset/gridHeaders/patientEpisodes';
import { roundToNearestMinutesWithOptions } from 'date-fns/fp';

export default {
  name: 'PractitionerEpisodesGrid',
  components: { Grid, PractitionerLayout },
  data () {
    return {
      filterName: this.$_t('Patient location'),
      rightDrawerModel: false,
      search: '',
      rightDrawer : {
        visible: false,
        data: [],
        loading: true,
        patientId: null,
        patientEpisodeId: null,
        patientLocationId: null
      },
      patientAppointments: [],
      dialog: false,
      reportRoute: '',
      activeRow: '',
      items: [],
      locations: [],
      locationSelected: '',
      searchResult: {},
      loading: false,
      headersPresetType: 'episodes',
      render: true,
      headersPreset: episodesHeadersPreset
    }
  },
  computed:{
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    },
    currentUserId (){
        return this.$store.getters['app/currentUserId']
    }
  },
  methods: {
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/episodes`, {
        filter: { patientLocationId: {  x: this.locationSelected, type: 'eq'}}
      }, response => {
        this.loading = false
        this.items = this.mapDatasetResponse(response)
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    getLocations () {
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patient-locations`, {
        limit: 10000
      }, response => {
        this.loading = false
        this.locations = response.data

        const savedLocationId = window.localStorage.getItem(this.currentUserId+'_episodeLocationId')

        if (savedLocationId && this.locations.findIndex(el => el.id === savedLocationId) > -1) {
          this.locationSelected = savedLocationId
        }
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    rowAdd () {},
    rowClicked (row) {
        this.$store.commit('appointment/setPatientEpisodeId', row.patientEpisodeId)
        if(row.patientLocationId) {
          this.$store.commit('appointment/setPatientLocationId', row.patientLocationId)
        }
        if(row.patientId){
          this.$router.push({ name: 'episode-details', params: { id: row.patientId } }).then(() => {})
        }
    }
  },
  watch:{
    refreshed (val) {
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    },
    locationSelected (val) {
      window.localStorage.setItem(this.currentUserId+'_episodeLocationId', val);
      if (val) {
        this.getData()
      }
    }
  },
  mounted () {
    this.getLocations()
  }
}
</script>

<style scoped>

</style>

<template>
  <v-container>
    <v-row v-if="loading">
      <v-progress-linear
        v-if="loading"
        indeterminate
        color="primary"
      />
    </v-row>
    <v-row
      v-if="!loading"
      justify="center"
      align="center"
      class="text-center"
    >
      <v-col cols="12">
        <h6 class="text-h6">
          {{ $_t('Thank you, your appointment is booked') }}
        </h6>

      </v-col>
      <v-col cols="12">
        <div v-if="config.labelBookingConfirmationAdditionalCaption"
             v-html="config.labelBookingConfirmationAdditionalCaption"
             class="text-body-1 font-weight-bold blue--text">
        </div>
        <div class="text-subtitle-1">
          {{ $_t('Your confirmation email contains important information and any preparation required for your scan. Please make sure to read it.') }}
          <br><br>
        </div>
        <div class="text-subtitle-1">
          {{ $_t('Your appointment details are below.') }}
          <br>{{ $_t('Please note that you are required to arrive in the clinic prior to your appointment time. The exact required arrival time is indicated in the confirmation email and will also be stated in the reminder SMS message.') }}
          <br><br>{{ $_t('For more information, please visit') }}&nbsp;<a :href="'https://' + config.clientWebsiteUrl" target="_blank">{{config.clientWebsiteUrl}}</a>
        </div>
        <div
          v-if="config.labelBookingConfirmationAdditionalSection"
          v-html="config.labelBookingConfirmationAdditionalSection"
          class="text-body-1 text-indigo-darken-4 font-weight-black">
        </div>
        <div v-if="!config.labelBookingConfirmationAdditionalCaption && showFaceMaskInfo" class="text-subtitle-1 red--text">
          {{ $_t('Please note that all patients are required to wear a face mask at all times while in the clinic. Refusal to do so will result in the procedure being cancelled.')}}
        </div>
        <div class="my-8">
          <h6 class="text-h6 text-left">
            {{ $_t('Preparations:') }}
          </h6>
          <p class="text-left">
            {{ $_t('It is important to carefully follow the preparation instructions below. Not doing so will result in your appointment not being completed at the time above.') }}
          </p>
          <ul>
            <li v-for="prep in appointmentPreparations" :key="prep.preparationRequirementId" class="text-left">
              <span>{{ prep[1] }}</span><!-- preparationRequirementName -->
            </li>
          </ul>
        </div>
        <v-progress-linear
          v-if="loading"
          color="primary"
          indeterminate
        ></v-progress-linear>
        <appointment-procedures-card
          v-if="appointmentUpdated"
          :appointment="appointmentUpdated"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, computed, onMounted, ref } from '@vue/composition-api'
import moment from 'moment'
import config from '@config'
import AppointmentProceduresCard from '@components/appointment/cards/AppointmentProceduresCard.vue'

export default defineComponent({
  name: 'AppointmentThankYouBooking',
  components: { AppointmentProceduresCard },
  props: {
    options: { type: Object, default: {} },
    appointment: {
      type: Object,
      default: () => {}
    }
  },
  setup (props, ctx) {
    const getOptions = computed(() => {
      return props.options
    })

    const nextPage = (page) => {
      ctx.emit('page', page)
    }

    const loading = ref(true)
    const appointmentUpdated = ref({})
    const appointmentPreparations = ref([]);

    const showFaceMaskInfo = computed(() => {
      return !config.hideCovidFaceMaskInfo
    })

    const fetchAppointment = () => {
      loading.value = true
      ctx.root.$_rest.get(
        `/appointments/${props.appointment.id}`,
        {
          groups:
            'all,appointment_status,appointment_procedure_status,clinic_scan_room,appointment_procedure_procedure,appointment_appointment_procedures,appointment_patient,procedure_scan,procedure_referral_template,appointment_clinic'
        },
        appointmentResponse => {
          loading.value = false
          appointmentUpdated.value = appointmentResponse.data
          loading.value = false
        },
        () => {
          //error
          loading.value = false
        },
        { prefixRoutesWithPatientId: true }
      );
    }

    const fetchAppointmentPreparations = () => {
      ctx.root.$_rest.get(
        '/datasets/appointment-preparations/default',
        {
          groups:
            'all,appointment_status,appointment_procedure_status,clinic_scan_room,appointment_procedure_procedure,appointment_appointment_procedures,appointment_patient,procedure_scan,procedure_referral_template,appointment_clinic',
          filter: { appointmentId: props.appointment.id }
        },
        appointmentPreparationsResponse => {
          appointmentPreparations.value = appointmentPreparationsResponse.data
        },
        () => {
        },
        { prefixRoutesWithPatientId: true }
      );
    }

    const init = () => {
      ctx.root.$store.commit('app/setAppBarTitle', 'Thank you')
      ctx.root.$store.commit('app/setAppBarButton1', null)
      ctx.root.$store.commit('app/setAppBarButton2', null)
      ctx.root.$store.dispatch('app/appBarShow')
      ctx.root.$emit('scrollToTop')

      fetchAppointment()
      fetchAppointmentPreparations()
    }

    const getTimeText = (time) => {
      if (!time) {
        return ''
      }
      return time.substr(11,5)
    }

    init()

    return {
      moment,
      getOptions,
      nextPage,
      init,
      appointmentUpdated,
      appointmentPreparations,
      loading,
      config,
      getTimeText,
      showFaceMaskInfo
    }
  }
})
</script>
<style scoped lang="css">
  table.appointment {
    border: 0;
    border-collapse: collapse;
  }
  table.appointment td {
    text-align: left;
  }
</style>

<template>
  <v-card v-if="filteredAppointments.length" tile elevation="10" class="text-h2 pa-2">
    <v-list>
      <v-list-item
        v-for="(appointment, appointmentIndex) in filteredAppointments"
        :key="appointmentIndex"
        two-line>
        <v-list-item-content>
          <v-list-item-title class="text-left">
          <span class="headline pa-5 font-weight-light"
                :style="'border-radius:5px; background-color:' + appointment.status.colorCode.replace('^', '#')">
            {{ (appointment.statusCode === 'FRNOT') ? 'Completed' : appointment.status.name }}
          </span>
          </v-list-item-title>
          <v-list-item-subtitle class="pt-8">
            <span class="text-h6 mb-2">{{ appointment.firstProcedureName }}</span>
            <v-divider class="mt-4" />
          </v-list-item-subtitle>
          <v-list-item-subtitle>
            <v-list class="transparent">
              <v-list-item class="row">
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Location') }}</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0" v-if="appointment.clinic && appointment.clinic.name">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-map-marker
                  </v-icon>
                  {{ appointment.clinic.name }}
                </span>
                  <span v-else class="d-block d-sm-inline-block mt-2 mt-sm-0">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-map-marker-off
                  </v-icon>
                  {{ $_t('Not available') }}
                </span>
                </v-list-item-title>
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Date and Time') }}</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0" v-if="appointment.date">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-calendar-check
                  </v-icon>
                  {{ formatDateToString(appointment.date) }}&nbsp;
                  {{ appointment.firstProcedureStartTime }}
                </span>
                  <span v-else class="d-block d-sm-inline-block mt-2 mt-sm-0">
                  <v-icon class="d-none d-sm-inline" left>
                    mdi-calendar-remove
                  </v-icon>
                  {{ $_t('Not scheduled') }}
                </span>
                </v-list-item-title>
                <v-list-item-title class="col-md-12">
                  <div class="font-weight-bold float-none float-sm-left mb-2 mb-sm-0" style="width: 150px;">{{ $_t('Appointment ID') }}</div>
                  <span class="d-block d-sm-inline-block mt-2 mt-sm-0">
                    {{appointment.firstProcedureidHis}}
                  </span>

                </v-list-item-title>
              </v-list-item>
            </v-list>
            <v-divider />
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <v-card-text>

    </v-card-text>
  </v-card>
  <v-card v-else>
    <v-card-text>
      {{ $_t('There aren\'t any historical appointments') }}
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'LinkedAppointments',
  props: {
    appointments: Array,
    default: ()=>[]
  },
  data () {
    return {
      loading: true,
      allowedAppointmentStatusCodes: ['C']
    }
  },
  methods: {
    formatDateToString (date) {
      return date.slice(0,10)
    },
  },
  computed: {
    filteredAppointments () {
      return this.appointments
        .filter(appointment => new Date(appointment.date) <= new Date() || !this.allowedAppointmentStatusCodes.includes(appointment.statusCode))
        .map(appointment => {
          const firstProcedure = (appointment?.appointmentProcedures || [])[0]
          return {
            ...appointment,
            firstProcedureName: firstProcedure?.procedure?.name || this.$_t('n/a'),
            firstProcedureStartTime: firstProcedure?.startTime?.slice(11, 16) || this.$_t('n/a'),
            firstProcedureidHis: firstProcedure?.idHis || this.$_t('n/a')
          }
        })
    }
  }
}
</script>

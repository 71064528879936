<template>
  <v-card
    color="#fff"
    width="80vw"
  >
    <v-card-title>{{ $_t('New patient') }}<v-spacer />
      <v-alert v-if="!valid" color="red" type="error" dense>
        {{ $_t('Not all mandatory fields have been completed') }}
      </v-alert>
    </v-card-title>
    <v-divider></v-divider>
    <v-card-text style="height: 80vh;">
      <v-form
        ref="form"
        v-model="valid"
        lazy-validation
        :disabled="loading"
      >
        <v-row>
          <v-col cols="12" lg="6">
            <v-row>
              <v-col cols="12" class="pt-5 pb-4">
                <span class="subtitle-1 grey--text text--darken-2">{{
                    $_t('Personal details')
                  }}</span>
                <div class="divider-line"></div>
              </v-col>
            </v-row>

            <v-text-field
              v-model="name"
              :rules="nameRules"
              :label="$_t('First name') + ' *'"
              outlined
              color="primary"
              class="my-0"
              :disabled="disabledInputs.includes('name')"
            ></v-text-field>

            <v-text-field
              v-model="surname"
              :rules="surnameRules"
              :label="$_t('Last name') + ' *'"
              outlined
              color="primary"
              class="my-0"
              :disabled="disabledInputs.includes('surname')"
            ></v-text-field>

            <v-text-field
              v-model="patientNumber"
              v-if="false"
              :rules="patientNumberRules"
              :label="$_t('NHS number')"
              outlined
              color="primary"
              class="my-0"
              validate-on-blur
            ></v-text-field>

            <v-menu
              ref="menu"
              v-model="birthdayMenu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
              :disabled="disabledDoB"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="birthdayFormatted"
                  :label="$_t('Date of birth') + ' *'"
                  readonly
                  :rules="birthdayRules"
                  v-bind="attrs"
                  v-on="on"
                  outlined
                  :disabled="loading"
                  validate-on-blur
                ></v-text-field>
              </template>
              <v-date-picker
                ref="birthdayPicker"
                v-model="birthday"
                :max="new Date().toISOString().substr(0, 10)"
                min="1900-01-01"
                @change="setBirthday()"
                :locale="getLocale()"
                :first-day-of-week="1"
              ></v-date-picker>
            </v-menu>

            <v-select
              v-model="sex"
              :items="sexList"
              :label="$_t('Sex')"
              outlined
              color="primary"
              class="my-0"
            ></v-select>
          </v-col>
          <v-col cols="12" lg="6">
            <v-row>
              <v-col cols="12" class="pt-5 pb-4">
                <span class="subtitle-1 grey--text text--darken-2">{{
                    $_t('Address')
                  }}</span>
                <div class="divider-line"></div>
              </v-col>
            </v-row>

            <v-text-field
              v-model="streetName"
              :label="$_t('Address line 1')"
              :rules="[
                (v) =>
                  !v ||
                  (v && v.length <= 100) ||
                  $_t('Address line 1') +
                    ' ' +
                    $_t('must be less than or equal to') +
                    ' 100 ' +
                    $_t('characters')
              ]"
              outlined
              color="primary"
              class="my-0"
              :disabled="loading"
            ></v-text-field>

            <v-text-field
              v-model="streetNumber"
              :label="$_t('Address line 2')"
              :rules="[
                (v) =>
                  !v ||
                  (v && v.length <= 20) ||
                  $_t('Street number') +
                    ' ' +
                    $_t('must be less than or equal to') +
                    ' 20 ' +
                    $_t('characters')
              ]"
              outlined
              color="primary"
              class="my-0"
              :disabled="loading"
            ></v-text-field>

            <v-text-field
              v-model="town"
              :label="$_t('City')"
              :rules="[
                (v) =>
                  !v ||
                  (v && v.length <= 50) ||
                  $_t('City') +
                    ' ' +
                    $_t('must be less than or equal to') +
                    ' 50 ' +
                    $_t('characters')
              ]"
              outlined
              color="primary"
              class="my-0"
              :disabled="loading"
            ></v-text-field>

            <v-text-field
              v-model="zipCode"
              :label="$_t('Post code')"
              :rules="[
                (v) =>
                  !v ||
                  (v && v.length <= 7) ||
                  $_t('Post code') +
                    ' ' +
                    $_t('must be less than or equal to') +
                    ' 7 ' +
                    $_t('characters')
              ]"
              outlined
              color="primary"
              class="my-0"
              :disabled="loading"
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="6">
            <v-row>
              <v-col cols="12" class="pt-0 pb-4">
                <span class="subtitle-1 grey--text text--darken-2">{{
                    $_t('Contact details')
                  }}</span>
                <div class="divider-line"></div>
              </v-col>
            </v-row>

            <vue-tel-input
              v-model="phoneNumber"
              @validate="onValidateMobile"
              inputClasses="phone-margin-fix v-input__slot"
              style="color: #000;line-height:2.3em;"
              :inputOptions="{placeholder: $_t('Mobile number') + ' *'}"
              name="mobileNumber"
              v-bind:style="{
                  'border-color': (mobileValid ? 'rgba(0,0,0,.42)' : '#ff5252'),
                  'border-width': (mobileValid ? '1px' : '2px'),
                }"
              mode="international"
              :disabled="loading"
              defaultCountry="GB"
            >
              <template v-slot:arrow-icon>
                <span>{{ '▼' }}</span>
              </template>
            </vue-tel-input>

            <v-text-field
              v-model="email"
              :label="
                agreementEmailCheckbox
                  ? $_t('Email address') + ' *'
                  : $_t('Email address (optional)')
              "
              :rules="emailRules"
              outlined
              color="primary"
              class="mt-8"
              :disabled="loading"
              validate-on-blur
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary darken-1"
        text
        :disabled="loading"
        @click="$emit('cancel')"
      >
        {{ $_t('Cancel') }}
      </v-btn>
      <v-btn
        color="primary darken-1"
        text
        :loading="loading"
        @click="createPatientTemporary"
      >
        {{ $_t('Create') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import config from '@src/config';
import moment from 'moment/moment';
import {computed, getCurrentInstance, onMounted, ref, watch} from '@vue/composition-api';
import Vue from 'vue';

const VueTelInput = () =>
  Promise.all([
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input'),
    import(/* webpackChunkName: "chunk-vue-tel-input" */ 'vue-tel-input/dist/vue-tel-input.css'),
  ]).then(([{ VueTelInput }]) => VueTelInput);

export default {
  name: 'PatientFormUK',
  components: { VueTelInput },
  setup(props, context) {
    const _vm = getCurrentInstance()
    /* settings */
    let settings = {}

    if (config.patientFormSettingsJson) {
      try {
        settings = JSON.parse(atob(config.patientFormSettingsJson))
      } catch (e) {
        settings = {}
      }
    }

    const loading = ref(false)
    /* fields */
    const form = ref();
    const submitted = ref(false)
    const valid = ref(true)
    const name = ref('')
    const surname = ref('')
    const patientNumber = ref('')
    const streetName = ref('')
    const streetNumber = ref('')
    const aptNumber = ref('')
    const zipCode = ref('')
    const town = ref('')
    const phoneNumber = ref('')
    const email = ref('')
    const agreementEmailCheckbox = ref(true)
    const agreementSMS_MMSCheckbox = ref(false)
    const agreementPhoneCheckbox = ref(false)
    const termsAndConditionsCheckbox = ref(false)
    const disabledInputs = ref([])
    const disabledDoB = ref(false)
    const birthday = ref(null)
    const birthdayMenu = ref(false)
    const birthdayPicker = ref()
    const birthdayFormatted = ref()
    const custom9 = ref('')
    const referralType = ref('')
    const referralReason = ref('')
    const sex = ref('')
    const sexList = [{text: 'Female', value: 'F'}, {text: 'Male', value: 'M'}, {text: 'Other', value: 'U'}]
    const mobileValid = ref(true)
    const mobileValidation = ref(false)

    const setBirthday = () => {
      if (moment(birthday.value).isValid()) {
        birthdayFormatted.value = moment(birthday.value).format(
          config.dateFormat
        );
      }
      birthdayMenu.value = false;
    }

    /* rules */
    const nameRules = [
      (v) =>
        !!v ||
        _vm.proxy.$_t('First name') +
        ' ' +
        _vm.proxy.$_t('is required'),
      (v) =>
        (v && v.length <= 50) ||
        _vm.proxy.$_t('First name') +
        ' ' +
        _vm.proxy.$_t('must be less than or equal to') +
        ' 50 ' +
        _vm.proxy.$_t('characters')
    ];
    const surnameRules = [
      (v) =>
        !!v ||
        _vm.proxy.$_t('Last name') + ' ' + _vm.proxy.$_t('is required'),
      (v) =>
        (v && v.length <= 50) ||
        _vm.proxy.$_t('Last name') +
        ' ' +
        _vm.proxy.$_t('must be less than or equal to') +
        ' 50 ' +
        _vm.proxy.$_t('characters')
    ];
    const patientNumberRules = [
      (v) =>
        !v ||
        _vm.proxy.$_t('Patient number is invalid')
    ];
    const phoneNumberRules = [
      (v) =>
        !!v ||
        _vm.proxy.$_t('Mobile number is required'),
      (v) =>
        !isNaN(
          v
        ) || _vm.proxy.$_t('Phone number must only contain numbers')
    ];
    const emailRules = [
      (v) =>
        /^$|.+@.+\..+/.test(v) || _vm.proxy.$_t('E-mail must be valid'),
      (v) =>
        (!v && !agreementEmailCheckbox.value) ||
        (v && v.length <= 50) ||
        _vm.proxy.$_t('Email') +
        ' ' +
        _vm.proxy.$_t('must be less than or equal to') +
        ' 50 ' +
        _vm.proxy.$_t('characters')
    ];
    const birthdayRules = [
      (v) =>
        !!v ||
        _vm.proxy.$_t('Date of birth') +
        ' ' +
        _vm.proxy.$_t('is required'),
      (v) =>
        (v && v.length <= 10) ||
        _vm.proxy.$_t('Birth day') +
        ' ' +
        _vm.proxy.$_t('must be less than or equal to') +
        ' 10 ' +
        _vm.proxy.$_t('characters')
    ]

    watch(birthdayMenu, (val) => {
      val && setTimeout(() => (birthdayPicker.value.activePicker = 'YEAR'));
    });

    const init = () => {
      referralType.value = ''
    }

    onMounted(init);

    const getFormObj = () => {
      return {
        firstName: name.value || '',
        lastName: surname.value || '',
        address1: streetName.value ? streetName.value : '',
        address2: streetNumber.value ? streetNumber.value : '',
        address3: aptNumber.value ? aptNumber.value : '',
        town: town.value || '',
        postCode: zipCode.value || '',
        emailAddress1: email.value ? email.value : '',
        patientNumber1: patientNumber.value || '',
        mobileNumber1: phoneNumber.value || '',
        dateOfBirth: moment(birthday.value).format('YYYY-MM-DD') || '',
        sex: sex.value || ''
      };
    };

    const submitForm = () => {
      if (!phoneNumber.value || !mobileValid) {
        Vue._notify.error('Please provide a valid mobile number.')
        mobileValid.value = false
        return
      }
    };

    const getLocale = () => {
      if (
        config.languageSwitchEnabled &&
        localStorage.getItem('lang')
      ) {
        return localStorage.getItem('lang');
      }

      return config.defaultLanguage;
    }

    const onValidateMobile = ({ valid }) => {
      if (!mobileValidation.value) {
        mobileValidation.value = true
        return
      }

      if (!phoneNumber.value) {
        mobileValid.value = false
      } else {
        mobileValid.value = valid
      }
    }

    const createPatientTemporary = () => {
      if (form.value.validate()) {
        loading.value = true

        _vm.proxy.$_rest.post(`/practitioners/${_vm.proxy.$store.getters['app/currentUserId']}/patients-temporary`, getFormObj(), response => {
          loading.value = false
          context.emit('created', response.data)
        }, () => {
          loading.value = false
          _vm.proxy.$_notify.error(_vm.proxy.$_t('The patient could not be created.'))
        })
      }
    }

    return {
      config,
      form,
      valid,
      name,
      surname,
      patientNumber,
      streetName,
      streetNumber,
      aptNumber,
      zipCode,
      town,
      phoneNumber,
      email,
      agreementEmailCheckbox,
      agreementSMS_MMSCheckbox,
      agreementPhoneCheckbox,
      termsAndConditionsCheckbox,
      nameRules,
      phoneNumberRules,
      patientNumberRules,
      emailRules,
      submitForm,
      submitted,
      disabledInputs,
      disabledDoB,
      birthday,
      birthdayFormatted,
      birthdayMenu,
      birthdayPicker,
      getLocale,
      birthdayRules,
      setBirthday,
      surnameRules,
      custom9,
      onValidateMobile,
      mobileValid,
      referralType,
      settings,
      sex,
      sexList,
      referralReason,
      createPatientTemporary,
      loading
    };
  }
};
</script>

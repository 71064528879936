<template>
<div>
    <v-list-item
        :clickable="clickable"
        :style="{opacity: alert.removedDate ? 0.5 : 1}"
    >
        <v-list-item-icon>
            <v-avatar
            tile
                :color="getAlertType(alert).bgColor"
                size="36"
                >
                <v-icon
                :color="getAlertType(alert).iconColor"
                >mdi-alert
                </v-icon>

            </v-avatar

        ></v-list-item-icon>
        <v-list-item-content>
            <v-list-item-title>{{ alert.name }}</v-list-item-title>
            <v-list-item-subtitle
                caption
                lines="2"
            >{{ alert.description }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content
            v-if="showCreatedBy && !alert.removedDate"
            side
        >
            <v-list-item-subtitle>
              {{$_t('Created by')}}:&nbsp;{{ alert.addedByUserName }}</v-list-item-subtitle>
            <v-list-item-subtitle
                caption
                lines="2"
            >{{$_t('Created date')}}:&nbsp;{{ useHumanDateTimeSecFromTimestamp(alert.addedDate) }}
            </v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content
            v-if="showRemovedBy && alert.removedDate"
            side

        >
            <v-list-item-title
                caption
                class="text-red"
            >{{$_t('Removed by')}}:{{ alert.removedByUserName }}
            </v-list-item-title>
            <v-list-item-subtitle
                caption
                lines="2"
                class="text-red"
            >{{$_t('Removed date')}}:{{ useHumanDateTimeSecFromTimestamp(alert.removedDate) }}
            </v-list-item-subtitle>
        </v-list-item-content>

    </v-list-item>
</div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'
import useDateHelpers from '../../helpers/useDateHelpers';


export default defineComponent({
    name: 'PatientAlertType',
    props: {
        alert: {
            type: Object,
            default: {}
        },
        badgeText: {
            type: String,
            default: ''
        },
        showCreatedBy: {
            type: Boolean,
            default: false
        },
        showRemovedBy: {
            type: Boolean,
            default: false
        },
        clickable: {
            type: Boolean,
            default: false
        },
        removeable: {
            type: Boolean,
            default: false
        },
        alertTypes: {
            type: Object,
            default: {}
        }
    },
    setup(props) {
        const { useHumanDateTimeSecFromTimestamp } = useDateHelpers()
        const formatDate = (timestamp)=> {
            return timestamp
        }
        const getAlertType =  (alert)=> {
            return props.alertTypes[alert.typeCode?.toLowerCase()] || {}
        }

        return {
            useHumanDateTimeSecFromTimestamp,
            getAlertType
        }
    },
})
</script>

<template>
  <v-container style="min-height: 460px;">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    />
    <v-row
      v-if="!loading"
    >
      <v-col
        cols="12"
        class="py-0"
      >
        <div class="text-center text-subtitle-1">
          {{$_t('Please select one of the following payment types')}}:

        </div>
        <v-radio-group
          v-model="panel"
        >
          <v-radio
            v-if="!medCardDefault || medicalCardInvalidAlert"
            :value="0"
            label="Cash/Card"
          ></v-radio>
          <v-radio
            v-if="!medCardDefault || medicalCardInvalidAlert"
            :value="1"
            label="Private insurance"
          ></v-radio>
          <v-radio
            v-if="insurerMedicalCard && medCardDefault"
            :value="2"
            label="HSE funded (including GP Direct Access scheme)"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-slide-y-transition mode="out-in">
        <v-col
          v-if="panel === 1"
          cols="12"
          class="py-0"
        >
          <v-card>
            <v-card-text class="py-8">
              <v-row>
                <v-col cols="12">
                  <v-select
                    v-model="insurer"
                    label="Insurer"
                    :items="selectItemsFiltered()"
                    :loading="insurerLoading"
                    outlined
                    hide-details
                    @change="updateInsurer(false)"
                  />
                </v-col>
                <v-col cols="12" v-if="insurer" class="mt-0 pt-0">
                  <div class="text-subtitle-1 mt-0 pt-0 text-justify">
                    Please note that some private health insurance plans carry an excess or do not fully cover all scans.
                    Affidea would advise that you check with your insurer in advance that you are fully covered for this procedure at our clinic.
                    Please note that you will be required to pay in full on the day and claim the relevant amount back from you insurer directly if your plan does not qualify for direct settlement.
                    If your insurer rejects the claim and will not cover the cost of your treatment then Affidea will charge you as a patient directly using the credit card details provided.
                  </div>
                  <v-checkbox
                    v-model="insurerCheckbox"
                    ref="insurerCheckbox"
                    :error="insurerCheckboxError"
                    label="Please tick the box to acknowledge your acceptance of same."
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="insuranceNumber"
                    label="Insurance membership number"
                    outlined
                    hide-details
                    @change="updateInsuranceData"
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="insuranceMonthDialog"
                    :return-value.sync="insuranceMonth"
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="insuranceMonth"
                        label="Next renewal month"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        outlined
                        hide-details
                        v-bind="attrs"
                        v-on="on"
                      />
                    </template>
                    <v-date-picker
                      v-model="insuranceMonth"
                      type="month"
                      scrollable
                      :min="now"
                      @input="$refs.dialog.save(insuranceMonth);updateInsuranceData();insuranceMonthDialog=false"
                    >
                    </v-date-picker>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition mode="out-in">
        <v-col
          v-if="panel===2 && medCardValidationRequired"
          cols="12"
          class="py-0"
        >
          <v-card :loading="medicalCardValidating">
            <v-card-text class="py-8">
              <v-text-field
                v-model="medicalCard"
                label="Medical card number"
                outlined
                hide-details
                @change="updateInsuranceData"
                :error="medicalCardInvalid"
              />
              <v-alert
                v-if="medicalCardInvalidAlert"
                class="mt-8"
                border="bottom"
                colored-border
                type="error"
                elevation="2"
              >
                <p>We had trouble validating your card. Please note if your card is not valid, you will be charged on the day upon arrival for your appointment. Please acknowledge acceptance of same or select alternative payment mathod.</p>
                <v-checkbox
                  v-model="medicalCardInvalidCheckbox"
                  ref="medicalCardInvalidCheckbox"
                  :error="medicalCardInvalidCheckboxError"
                  label="Please tick the box to acknowledge your acceptance of same."
                />
              </v-alert>
              <v-alert
                v-if="false && medicalCardValid"
                class="mt-8 mb-0"
                border="bottom"
                colored-border
                type="success"
                elevation="2"
              >
                We have successfully validated your medical card number.
              </v-alert>
            </v-card-text>
            <template slot="progress">
              <v-progress-linear
                height="4"
                indeterminate
              ></v-progress-linear>
            </template>
          </v-card>
        </v-col>
      </v-slide-y-transition>
      <v-slide-y-transition mode="out-in">
        <v-col
          v-if="insurer && insurers[insurer].noteWeb"
          cols="12"
          class="my-8 py-0"
        >
          <v-card>
            <v-card-text>
              <h6 class="subtitle-1 pa-4 text-center">
                {{ insurers[insurer].noteWeb }}
              </h6>
            </v-card-text>
          </v-card>
        </v-col>
      </v-slide-y-transition>
    </v-row>
  </v-container>
</template>

<script>
import config from '@config'
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentInsurance',
  props: {},
  data () {
    return {
      insurer: null,
      insurerCheckbox: false,
      insurerCheckboxError: false,
      insurerLoading: false,
      insurers: {},
      insuranceNumber: '',
      insuranceMonth: '',
      medicalCard: '',
      medicalCardValidating: false,
      medicalCardValid: false,
      medicalCardInvalid: false,
      medicalCardInvalidAlert: false,
      medicalCardInvalidCheckbox: false,
      medicalCardInvalidCheckboxError: false,
      insuranceMonthDialog: false,
      panel: null,
      insurerCash: null,
      insurerMedicalCard: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters('app', { currentUser: 'currentUser', organization: 'organization' }),
    ...mapGetters('appointment', { appointment: 'appointment' }),
    now () {
      const date = new Date()
      return date.toISOString()
    },
    appointmentInsurerConfig () {
      if (this.appointment.insurer.configJson) {
        return JSON.parse(this.appointment.insurer.configJson);
      }
      else {
        return {}
      }
    },
    medCardDefault () {
      return this.appointmentInsurerConfig.insurerValidation === 'MEDCARD';
    },
    medCardValidationRequired () {
      return this.appointmentInsurerConfig.insurerValidationMedcardRequired !== false;
    }
  },
  mounted () {
    this.init()
    this.getInsurers((response) => {
      if (response.data.length) {
        this.insurers = {}
        response.data.forEach(el => {
            if (el.codeRis === 'CASH') {
              this.insurerCash = el.id
            }
            if (el.codeRis === 'MEDCARD') {
              this.insurerMedicalCard = el.id
            }
            this.insurers[el.id] = el
        })
      }
      //if app insurer is MEDCARD, we select this panel by default
      if (this.medCardDefault) {
        this.panel = 2;
      }
    });
  },
  watch: {
    panel (val) {
      if (val === 0) {
        this.insurer = this.insurerCash
      } else if (val === 1) {
        this.insurer = null
      } else if (val === 2) {
        this.insurer = this.insurerMedicalCard
      }
    }
  },
  methods: {
    selectItemsFiltered () {
      const appointmentScanId = this.appointment.appointmentProcedures.flatMap(item => item.procedure)[0]?.scanId

      const clinic = this.appointment.clinic || this.appointment.preferredClinic

      const insurerIdsNotAvailable = clinic?.insurerClinics?.filter(obj => 
        obj.insurerClinicConfigJson?.limitWebForScanIds?.includes(appointmentScanId) && obj.insurer
      )?.map(obj => obj.insurer?.id)?.filter(x => x) || []

      return Object.values(this.insurers).filter(el => 
        el.codeRis !== 'CASH' && el.codeRis !== 'MEDCARD' && 
        (!insurerIdsNotAvailable.length || !insurerIdsNotAvailable.includes(el.id))
      ).map(el => ({
        value: el.id,
        text: el.nameWeb
      }))
    },
    init () {
      this.$store.commit('app/setAppBarTitle', 'Payment type')
      this.$store.commit('app/setAppBarButton1', {
        label: 'Back',
        icon: 'mdi-chevron-left',
        click: () => {
          this.$emit('prev')
        }
      })
      this.$store.commit('app/setAppBarButton2', {
        label: 'Next',
        icon: 'mdi-chevron-right',
        click: () => {
          if (this.medicalCardValidating) {
            this.$_notify.error('Please wait until your medical card is being validated.')
            return
          }
          this.insurerCheckboxError = false
          this.medicalCardInvalidCheckboxError = false

          if (this.panel === null || this.panel === undefined) {
            this.$_notify.error('Please select your payment type')
            return
          } else if (this.panel === 1 && !this.insurer) {
            this.$_notify.error('Please select your insurer')
            return
          } else if (this.panel === 1 && !this.insurerCheckbox) {
            this.insurerCheckboxError = true
            this.$vuetify.goTo(this.$refs.insurerCheckbox)
            this.$_notify.error('Please tick the box to acknowledge your acceptance.')
            return
          } else if (this.panel === 1 && !this.insuranceNumber) {
            this.$_notify.error('Please enter your insurance membership number.')
            return
          } else if (this.panel === 1 && !this.insuranceMonth) {
            this.$_notify.error('Please select your next renewal month.')
            return
          } else if (this.panel === 2 && !this.medicalCard && this.medCardValidationRequired) {
            this.medicalCardInvalid = true
            this.$_notify.error('Please enter your medical card number.')
            return
          } else if (this.panel === 2 && this.medicalCardInvalid && !this.medicalCardInvalidCheckbox) {
            this.medicalCardInvalidCheckboxError = true
            this.$vuetify.goTo(this.$refs.medicalCardInvalidCheckbox)
            this.$_notify.error('Please tick the box to acknowledge your acceptance.')
            return
          }

          if (this.panel === 0) {
            if (this.insurerCash) {
              this.insurer = this.insurerCash
              this.updateInsurer(true)
              return
            } else {
              this.$_notify.error('Please select your payment type')
              return
            }
          }
          sessionStorage.setItem('patientInsurerId', this.insurer)
          this.$emit('next')
        }
      })
      this.$store.dispatch('app/appBarShow')
      this.$root.$emit('scrollToTop')
    },
    getInsurers (callback) {
      this.insurerLoading = true
      this.$_rest.get(`patients/${this.currentUser.id}/insurers`,
        { groups: 'insurer' },
        response => {
          this.insurerLoading = false
          this.loading = false
          callback(response)
        },
        error => {
          this.insurerLoading = false
          this.loading = false
          this.$_notify.error(error)
        }
      )
    },
    updateInsurer (updateSessionAndGoNext = false) {
      this.insurerLoading = true
      this.$store.commit('app/setAppBarButton2Loading', true)
      let url = `/appointments/${this.appointment.id}/insurer`
      const groups = { groups: 'all, procedure_procedure_clinics, appointment_status, appointment_procedure_status, clinic_scan_room, appointment_procedure_procedure, appointment_appointment_procedures, appointment_patient, procedure_scan, procedure_referral_template, appointment_clinic, appointment_insurer, appointment_practitioner_practice, practitioner_practice, appointment_preferred_clinic' }
      const paramsEncoded = this.$_rest.toUrlEncoded(groups)
      url += `?${paramsEncoded}`

      this.$_rest.put(url, { insurerId: this.insurer }, undefined,
        response => {
          this.$store.dispatch('appointment/setAppointment', response.data)
          if (updateSessionAndGoNext) {
            sessionStorage.setItem('patientInsurerId', this.insurer)
            this.$emit('next')
          }
          this.$store.commit('app/setAppBarButton2Loading', false)
          this.insurerLoading = false
        },
        error => {
          this.insurerLoading = false
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    },
    async updateInsuranceData () {
      //if medical card, we need custom validation
      if (this.medicalCard) {
        this.medicalCardValidating = true

        const medCardValidationResponse = await this.checkMedicalCard()
        const medCardValidationResult = await medCardValidationResponse.json()

        if (!medCardValidationResult || medCardValidationResult.status !== 'S' || !medCardValidationResult.response.eligibility) {
          this.medicalCardValidating = false
          this.medicalCardValid = false
          this.medicalCardInvalid = true
          this.medicalCardInvalidAlert = true
          return;
        }

        this.medicalCardValidating = false
        this.medicalCardValid = true
        this.medicalCardInvalid = false
        this.medicalCardInvalidAlert = false
      }

      this.$_rest.put(
        `/appointments/${this.appointment.id}/custom`,
        {
          custom3: this.insuranceNumber,
          custom5: this.insuranceMonth,
          custom6: this.medicalCard
        }, undefined,
        response => {
          this.insurerLoading = false
        },
        error => {
          this.insurerLoading = false
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    },
    async checkMedicalCard () {
      return fetch(
        config.standaloneWebBookingUrl +
        '/init/?type=portal_share&hash=' +
        this.organization.hash +
        '&appId=' +
        this.appointment.id +
        '&action=validateMedicalCardNumber',
        {
          method: 'POST',
          headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            Authorization:
              'Bearer ' + sessionStorage.getItem(config.authTokenName)
          },
          body: JSON.stringify({
            medicalCardNumber: this.medicalCard
          })
        }
      )
    }
  }
}
</script>

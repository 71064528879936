export default {
  appTitle: process.env.VUE_APP_APP_TITLE || '',
  appLogo: process.env.VUE_APP_APP_LOGO || process.env.VUE_APP_FAVICON || '',
  apiUrl: process.env.VUE_APP_URL_API,
  enableGoogleMaps: process.env.VUE_APP_ENABLE_GOOGLE_MAPS,
  googleMapsAPIKey: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
  keyRecaptchaSite: process.env.VUE_APP_RECAPTCHA_SITE_KEY,
  authClientId: process.env.VUE_APP_AUTH_CLIENT_ID,
  authTokenName: process.env.VUE_APP_AUTH_TOKEN_NAME,
  authEndpoint: process.env.VUE_APP_AUTH_ENDPOINT,
  authLogoutEndpoint: process.env.VUE_APP_AUTH_LOGOUT_ENDPOINT,
  authBasicEndpoint: process.env.VUE_APP_AUTH_BASIC_ENDPOINT,
  authBasicLogoutEndpoint: process.env.VUE_APP_AUTH_BASIC_LOGOUT_ENDPOINT,
  authScope: process.env.VUE_APP_AUTH_SCOPE,
  authRedirectUrl: process.env.VUE_APP_AUTH_REDIRECT_URL,
  appointmentCentreLink: process.env.VUE_APP_APPOINTMENT_CENTRE_LINK,
  loadingMessagesEnabled: process.env.VUE_APP_LOADING_MESSAGES_ENABLED === 'true',
  favicon: process.env.VUE_APP_FAVICON,
  privacyPolicyLink: process.env.VUE_APP_PRIVACY_POLICY_LINK,
  showPatientDetails: process.env.VUE_APP_SHOW_PATIENT_DETAILS === 'true',
  patientEmailMandatory: process.env.VUE_APP_PATIENT_EMAIL_MANDATORY === 'true',
  patientEmailHide: process.env.VUE_APP_PATIENT_EMAIL_MANDATORY === 'hide',
  newAppointmentComponents: process.env.VUE_APP_PATIENT_NEW_APPOINTMENT_COMPONENTS_CSV ? process.env.VUE_APP_PATIENT_NEW_APPOINTMENT_COMPONENTS_CSV : '',
  confirmedAppointmentComponents: process.env.VUE_APP_PATIENT_CONFIRMED_APPOINTMENT_COMPONENTS_CSV ? process.env.VUE_APP_PATIENT_CONFIRMED_APPOINTMENT_COMPONENTS_CSV : '',
  webBookingLink: process.env.VUE_APP_WEB_BOOKING_LINK,
  webBookingContactLink: (process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK && process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK.length) ? process.env.VUE_APP_WEB_BOOKING_CONTACT_LINK : process.env.VUE_APP_WEB_BOOKING_LINK,
  faqLink: process.env.VUE_APP_FAQ_LINK,
  practitionerMenuItems: process.env.VUE_APP_PRACTITIONER_MENU_ITEMS_CSV ? process.env.VUE_APP_PRACTITIONER_MENU_ITEMS_CSV.split(',') : [],
  practitionerExaminationImagesEnabled: process.env.VUE_APP_PRACTITIONER_EXAMINATION_IMAGES_ENABLED === 'true',
  practitionerMDTEnable: process.env.VUE_APP_PRACTITIONER_MDT_ENABLE === 'true',
  practitionerNewCreatePatientEnabled: process.env.VUE_APP_PRACTITIONER_NEW_CREATE_PATIENT_ENABLED === 'true',
  practitionerNewCreatePatientForm: process.env.VUE_APP_PRACTITIONER_NEW_CREATE_PATIENT_FORM ?? '',
  practitionerNewBookingEnabled: process.env.VUE_APP_PRACTITIONER_NEW_BOOKING_ENABLED === 'true',
  practitionerNewMultiScan: process.env.VUE_APP_PRACTITIONER_NEW_MULTI_SCAN === 'true',
  practitionerNewSplitBy: process.env.VUE_APP_PRACTITIONER_SPLIT_BY ? process.env.VUE_APP_PRACTITIONER_SPLIT_BY.split(',') : [],
  pacsViewerCode: process.env.VUE_APP_PACS_VIEWER_CODE,
  ignorePacsViewerCode: process.env.VUE_APP_IGNORE_PACS_VIEWER_CODE === 'true',
  languageSwitchEnabled: process.env.VUE_APP_LANGUAGE_SWITCH_ENABLED === 'true',
  procedureSharingEnabled: process.env.VUE_APP_PROCEDURE_SHARING_ENABLED === 'true',
  patientExaminationImagesEnabled: process.env.VUE_APP_PATIENT_EXAMINATION_IMAGES_ENABLED === 'true',
  patientExaminationImagesDownloadManualUrl: process.env.VUE_APP_PATIENT_EXAMINATION_IMAGES_DOWNLOAD_MANUAL_URL || '',
  reportViewer: process.env.VUE_APP_REPORT_VIEWER === 'true',
  standaloneWebBookingUrl: process.env.VUE_APP_STANDALONE_WEB_BOOKING_URL,
  standaloneWebBookingScanId: process.env.VUE_APP_STANDALONE_WEB_BOOKING_SCAN_ID,
  standaloneWebBookingRegionId: process.env.VUE_APP_STANDALONE_WEB_BOOKING_REGION_ID,
  webBookingNoCallMeBack: process.env.VUE_APP_WEB_BOOKING_NO_CALL_ME_BACK,
  webBookingSkipClinicSelection: process.env.VUE_APP_WEB_BOOKING_SKIP_CLINIC_SELECTION,
  webBookingScheduleOnConfirm: process.env.VUE_APP_WEB_BOOKING_SCHEDULE_ON_CONFIRM,
  webBookingOrgHash: process.env.VUE_APP_WEB_BOOKING_ORG_HASH,
  termsLink: process.env.VUE_APP_TERMS_LINK,
  shareOtpMethodSms: process.env.VUE_APP_SHARE_OTP_METHOD_SMS === 'true',
  shareOtpMethodGenerate: process.env.VUE_APP_SHARE_OTP_METHOD_GENERATE === 'true',
  communicationsEmail: process.env.VUE_APP_COMMUNICATIONS_EMAIL,
  cancelDialogEnabled: process.env.VUE_APP_CANCEL_DIALOG_ENABLED === 'true',
  rescheduleEnabled: process.env.VUE_APP_RESCHEDULE_ENABLED === 'true',
  cancellationPolicyLink: process.env.VUE_APP_CANCELLATION_POLICY_LINK,
  paymentEnabled: process.env.VUE_APP_PAYMENT_ENABLED === 'true',
  clientWebsiteUrl: process.env.VUE_APP_CLIENT_WEBSITE_URL || 'aptvision.com',
  safetyQuestionsAdditionalInfo: process.env.VUE_APP_SAFETY_QUESTIONS_ADDITIONAL_INFO,
  bookingAdditionalInfo: process.env.VUE_APP_BOOKING_ADDITIONAL_INFO,
  additionalDropdownsForNew: process.env.VUE_APP_PRACTITIONER_NEW_ADDITIONAL_DROPDOWN_CSV ? process.env.VUE_APP_PRACTITIONER_NEW_ADDITIONAL_DROPDOWN_CSV.split(',') : [],
  practitionerNewPatientPhotoEnabled: process.env.VUE_APP_PRACTITIONER_NEW_PATIENT_PHOTO_ENABLED === 'true',
  defaultColumnsGrid_appointmentPractices: process.env.VUE_APP_DEFAULT_COLUMNS_GRID_APPOINTMENT_PRACTICES ? process.env.VUE_APP_DEFAULT_COLUMNS_GRID_APPOINTMENT_PRACTICES.split(',') : [],
  callPurposeApponitmentCancelRequestId:process.env.VUE_APP_CALL_PURPOSE_APPOINTMENT_CANCEL_REQUEST_ID ? process.env.VUE_APP_CALL_PURPOSE_APPOINTMENT_CANCEL_REQUEST_ID : '',
  callSourceApponitmentCancelRequestId:process.env.VUE_APP_CALL_SOURCE_APPOINTMENT_CANCEL_REQUEST_ID ? process.env.VUE_APP_CALL_SOURCE_APPOINTMENT_CANCEL_REQUEST_ID : '',
  acknowledgeContentHtml: process.env.VUE_APP_ACKNOWLEDGE_CONTENT_HTML || 'VUE_APP_ACKNOWLEDGE_CONTENT_HTML',
  acknowledgeTermsHtml: process.env.VUE_APP_ACKNOWLEDGE_TERMS_HTML || 'VUE_APP_ACKNOWLEDGE_TERMS_HTML',
  acknowledgeTermsLink: process.env.VUE_APP_ACKNOWLEDGE_TERMS_LINK,
  recaptchaEnabled: (process.env.VUE_APP_RECAPTCHA_ENABLED === 'true'),
  recaptchaSiteKey: process.env.VUE_APP_RECAPTCHA_SITE_KEY || '',
  labelBookingConfirmationAdditionalSection: process.env.VUE_APP_BOOKING_CONFIRM_ADDITIONAL_SECTION || '',
  labelBookingConfirmationAdditionalCaption: process.env.VUE_APP_BOOKING_CONFIRM_ADDITIONAL_CAPTION || '',
  themeLightPrimary: (process.env.VUE_APP_THEME_LIGHT_PRIMARY) ?? '#418fde',
  themeLightSecondary: (process.env.VUE_APP_THEME_LIGHT_SECONDARY) ?? '#424242',
  disableSettings: process.env.VUE_APP_DISABLE_SETTINGS === 'true',
  showPracticeSwitch: process.env.VUE_APP_SHOW_PRACTICE_TOGGLE === 'true',
  showPracticeDropdown: process.env.VUE_APP_SHOW_PRACTICE_DROPDOWN === 'true',
  dateFormat: (process.env.VUE_APP_DATE_FORMAT) ?? 'dd.MM.yyyy',
  dateFormatLong: (process.env.VUE_APP_DATE_FORMAT_LONG) ?? 'dddd, Do MMM YYYY',
  theme: undefined,
  patientContactRequired: process.env.VUE_APP_PATIENT_CONTACT_REQUIRED === 'true',
  patientContactAdditionalMessage: (process.env.VUE_APP_PATIENT_CONTACT_INFO) ?? '',
  patientContactHeader: (process.env.VUE_APP_PATIENT_CONTACT_HEADER) ?? '',
  patientContactSubtitle: (process.env.VUE_APP_PATIENT_CONTACT_SUBTITLE) ?? '',
  hideAppointmentGridDetailsBasics: process.env.VUE_APP_HIDE_APPOINTMENT_GRID_BASIC_DETAILS === 'true',
  hideAppointmentGridFilters: process.env.VUE_APP_HIDE_APPOINTMENT_GRID_FILTERS === 'true',
  PRIVACY_FORM_TEMPLATE_ID: (process.env.VUE_APP_PRIVACY_FORM_TEMPLATE_ID) ?? '',
  hideCovidFaceMaskInfo: process.env.VUE_APP_HIDE_COVID_MASK_INFO === 'true',
  isFormIframe:process.env.VUE_APP_IS_FORM_IFRAME === 'true',
  iframeFormLink: process.env.VUE_APP_IFRAME_FORM_LINK ?? '',
  iframeFormReplaceComponentCsv: process.env.VUE_APP_IFRAME_FORM_REPLACE_COMPONENT_CSV ?? '',
  iframeClientCode: process.env.VUE_APP_IFRAME_CLIENT_CODE ?? '',
  getTheme() {
    if (typeof this.theme === 'undefined') {
      try {
        this.theme = JSON.parse((process.env.VUE_APP_THEME || '{}').replaceAll('\'', '"'))
      } catch (e) {
        // @ts-ignore
        this.theme = {}
      }
    }
    return this.theme
  }
}

<template>
  <v-container class="pa-0 ma-0 mt-7">
    <v-row v-if="schedulingTypeComputed === 'CUSTOM'">
      <v-col
        style="min-width: 300px"
        cols="12"
        lg="4"
        xs="12"
        class="py-0 my-0 mb-3"
      >
        <v-date-picker
          v-model="picker"
          @change="makeCustomDate"
          :min="min"
        />
      </v-col>
      <v-col
        style="min-width: 150px"
        cols="12"
        sm="2"
        xs="12"
        class="py-0 my-0"
      >
        <v-btn
          v-for="i in 8"
          :key="i"
          class="ma-1"
          text
          style="display: block"
          width="100%"
          :color="isButtonSelected(i, 'weeks') ? 'secondary' : 'primary'"
          :value="btnWeeks"
          @click="setDate(i, 'weeks')"
        >

          {{ i }} {{translateButtons('Week',i)}}
        </v-btn>
      </v-col>
      <v-col
        style="min-width: 150px"
        cols="12"
        sm="2"
        xs="12"
        class="py-0 my-0"
      >
        <v-btn
          v-for="i in [1,2,3,4,5,6,9,12]"
          :key="i"
          class="ma-1"
          text
          width="100%"
          style="display: block"
          :color="isButtonSelected(i, 'months') ? 'secondary' : 'primary'"
          :value="btnMonths"
          @click="setDate(i, 'months')"
        >
          {{ i }} {{translateButtons('Month',i)}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { defineComponent, ref, watch, computed, getCurrentInstance } from '@vue/composition-api'
import moment from 'moment'
import useDateHelpers from '../../helpers/useDateHelpers';


export default defineComponent({
  name: 'Scheduling',
  props: {
    value: String,
    min: String
  },
  setup (props, { emit }) {
    const _vm = getCurrentInstance()
    const { useSqlDate } = useDateHelpers()

    const btnWeeks = ref(localStorage.getItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks')) || null
    const btnMonths = ref(localStorage.getItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths')) || null
    const items = ref([
      { value: 'ASAP', label: _vm.proxy.$_t('ASAP') },
      { value: 'CUSTOM', label: _vm.proxy.$_t('Custom date') }
    ])
    const picker = computed({
      get: () => {
        return props.value ? useSqlDate(props.value) : null
      },
      set (value) {
        emit('input', value)
      }
    })
    const schedulingType = ref('CUSTOM')
    const schedulingTypeComputed = computed({
      get: () => {
        return schedulingType.value
      },
      set (value) {
        schedulingType.value = value
        if (value === 'ASAP') {
          picker.value = null
        }
      }
    })
    const menu = ref(false)

    const isButtonSelected = (amount, interval) => {
      const date = new moment()
      date.add(amount, interval)
      return useSqlDate(date) === useSqlDate(picker.value)
    }
    const makeCustomDate = ()=>{
        btnWeeks.value = null
        btnMonths.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', null)
    }
    const translateButtons = (item,count) => {
      let translate = item
      if(count >1) {
        translate = `${item}s`
      }
      return _vm.proxy.$_t(translate)
    }

    const setDate = (amount, interval) => {
      const today = new moment()
      today.add(amount, interval)

      picker.value = useSqlDate(today)
      if(interval.includes('month')) {
        btnWeeks.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', amount)
        btnMonths.value = amount
      }
      if(interval.includes('week')) {
        btnMonths.value = null
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnMonths', null)
        localStorage.setItem('currentPractitionerPractice_' + _vm.proxy.$store.getters['app/currentUser'].id + '_btnWeeks', amount)
        btnWeeks.value = amount
      }

    }

    return {
      items,
      menu,
      picker,
      schedulingTypeComputed,
      setDate,
      btnWeeks,
      btnMonths,
      translateButtons,
      makeCustomDate,
      isButtonSelected
    }
  }
})
</script>

<style scoped>

</style>

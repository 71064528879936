<template>
  <v-container fluid>
    <div
      style="height: 5px"
    >
      <v-progress-linear
        v-if="loading"
        indeterminate
      />
    </div>
    <v-card
      v-if="config.practitionerNewSplitBy && config.practitionerNewSplitBy.length"
      elevation="0"
      class="px-4"
    >
      <v-card-title class="pl-0">
        {{ $_t('Examinations') }}
      </v-card-title>
      <v-simple-table>
        <thead>
          <tr>
            <th
              class="grey--text"
            >
              {{ $_t('Examination') }}
            </th>
            <th
              v-if="config.practitionerNewSplitBy.includes('PRIORITY')"
              class="grey--text"
            >
              {{ $_t('Priority') }}
            </th>
            <th
              v-if="config.practitionerNewSplitBy.includes('PREFERRED_DATE')"
              class="grey--text"
            >
              {{ $_t('Scheduling') }}
            </th>
          </tr>
        </thead>
        <tbody>
        <tr
          v-for="(appProc, index) in appointmentProceduresSorted"
          :key="index"
        >
          <template v-if="typeof appProc === 'string'">
            <td
              style="border: none"
            >
              <h3>{{ appProc }}</h3>
            </td>
          </template>
          <template v-else>
            <td style="width: 30%">{{ appProc.procedure.name }}</td>
            <td v-if="config.practitionerNewSplitBy.includes('PRIORITY')" style="width: 20%">
              <priority-dropdown
                v-model="appProc.appointmentPriority"
                @change="onPriorityChange(appProc.appointmentPriority, appProc)"
              />
            </td>
            <td v-if="config.practitionerNewSplitBy.includes('PREFERRED_DATE')" style="width: 50%">
              <scheduling
                v-if="isPriorityPlanned(appProc.appointmentPriority)"
                v-model="appProc.preferredAppointmentDate"
                :min="format(new Date(), 'yyyy-MM-dd')"
                @input="onSchedulingInput"
              />
              <p v-else>{{ $_t('ASAP') }}</p>
            </td>
          </template>
        </tr>
        </tbody>
      </v-simple-table>
    </v-card>
    <v-row>
      <v-col v-if="!config.practitionerNewSplitBy.includes('PRIORITY')" cols="6" lg="3" md="3">
        <v-card elevation="0">
          <v-card-title>
            {{ $_t('Select priority') }}
          </v-card-title>
          <v-card-text>
            <priority-dropdown
              v-model="appointmentPriority"
              @change="onPriorityChange(appointmentPriority)"
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col v-if="!config.practitionerNewSplitBy.includes('PREFERRED_DATE')" cols="6" lg="4" md="6">
        <v-card-title>
          {{ $_t('Scheduling') }}
        </v-card-title>
        <scheduling
          v-if="isPriorityPlannedComputed"
          v-model="appointment.preferredAppointmentDate"
          :min="format(new Date(), 'yyyy-MM-dd')"
        />
        <p v-else>{{ $_t('ASAP') }}</p>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="6" md="6">
        <v-card elevation="0">
          <v-card-text>
            <v-row class="pa-0 ma-0" v-if="config.practitionerNewBookingEnabled && appointment.appointmentProcedures && appointment.appointmentProcedures.length === 1">
              <v-col cols="3" class="pa-0 ma-0 pt-5">
                <div class="text-subtitle-1">{{ $_t('Book') }}:</div>
              </v-col>
              <v-col cols="9" class="pa-0 ma-0 pt-4">
                <v-btn
                  color="primary"
                  class="mr-6"
                  @click="showClinicSchedule"
                >
                  {{ $_t('Book Now') }}
                </v-btn>
                <span v-if="appointment.schedule">{{ $_t('Booked for') }}:&nbsp;{{ appointment.schedule.date }}&nbsp;{{ appointment.schedule.time }}</span>
                 <v-dialog
                   v-model="dialogClinicSchedule"
                   width="80vw"
                   scrollable
                 >
                   <v-card
                     height="80vh">
                     <v-card-title>
                       {{ $_t('Appointment booking') }}
                       <v-spacer />
                       <v-btn
                         fab
                         icon
                         small
                         @click="dialogClinicSchedule = false"
                       >
                         <v-icon>mdi-close</v-icon>
                       </v-btn>
                     </v-card-title>
                     <v-card-text>
                       <clinic-schedule
                         v-if="dialogClinicSchedule"
                         :appointment="appointment"
                         @scheduled="appointmentScheduled"
                       />
                     </v-card-text>
                   </v-card>
                 </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    <v-col v-if="config.practitionerNewPatientPhotoEnabled" cols="12" lg="3" md="3">
      <v-card-title>
        <v-row>
        <v-col cols="8"  style="margin-top:-10px;">{{ $_t('Patient Photo') }}</v-col>
        <v-col cols="4" style="margin-top:-25px;">
          <patient-photo-upload @attachments="photoAttachments" :patient-id="patientId" />
        </v-col>
        </v-row>
      </v-card-title>
      <template v-if="attachments.length > 0">
        <div v-for="(pic,i) in attachments" :key="i">
          <v-img
              :src="`data:${pic.file.format}, ${pic.file.base64}`"
              height="125"
              class="grey darken-4"
              contain
            ></v-img>
        </div>
      </template>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="12" md="12" v-if="canBeDisplay('episode')">
        <v-card-title>
          {{ $_t('Select episode') }}
        </v-card-title>
        <v-card-text>
          <PatientEpisodeDropdown
            :patient-id="patientId"
            v-model="patientEpisode"
            :mandatory="mandatoryValueComponentItem.includes('patientEpisodeId')"
          />
        </v-card-text>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" lg="3" md="3" v-if="canBeDisplay('category')">
        <v-card-title>
          {{ $_t('Patient group') }}
        </v-card-title>
        <v-card-text>
          <CategoryDropdown
            v-model="appointmentCategory"
            :mandatory="mandatoryValueComponentItem.includes('categoryId')"
          />
        </v-card-text>
      </v-col>
      <v-col cols="12" lg="3" md="3" v-if="canBeDisplay('location')">
        <v-card-title>
          {{ $_t('Select location') }}
        </v-card-title>
        <v-card-text>
          <PatientLocationDropdown
            v-model="patientLocation"
            :mandatory="mandatoryValueComponentItem.includes('patientLocationId')"
          />
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

import PriorityDropdown from '@components/appointment/PriorityDropdown.vue'
import CategoryDropdown from '@components/appointment/AppointmentCategoryDropdown.vue'
import PatientLocationDropdown from '@components/appointment/PatientLocationDropdown.vue'
import PatientEpisodeDropdown from '@components/appointment/PatientEpisodeDropdown.vue'
import Scheduling from '@components/appointment/Scheduling.vue'
import PatientPhotoUpload from '@components/common/PatientPhotoUpload.vue'
import ClinicSchedule from '@components/appointment/ClinicSchedule.vue'

import moment from 'moment'
import { format } from 'date-fns'
import config from '@src/config'

export default {
  name: 'EditNew',
  components: { PriorityDropdown, Scheduling, PatientPhotoUpload, CategoryDropdown, PatientLocationDropdown, PatientEpisodeDropdown, ClinicSchedule },
  props: {
    appointment: {
      type: Object
    },
    complete: {
      type: Boolean
    },
    patientId: {
      type: String
    }
  },
  computed: {
    format () {
      return format
    },
    config() {
      return config
    },
    appointmentPriority: {
      get () {
        return this.appointment.priorityCode
      },
      set (value) {
        this.updateAppointmentPriority(value)
      }
    },
    appointmentCategory: {
      get () {
        return this.appointment.categoryId
      },
      set (value) {
        this.updateAppointmentCategory(value)
      }
    },
    patientLocation: {
      get () {
        return this.appointment.patientLocationId
      },
      set (value) {
        this.updatePatientLocation(value)
      }
    },
    patientEpisode: {
      get () {
        return this.appointment.patientEpisodeId
      },
      set (value) {
        this.updatePatientEpisode(value)
      }
    },
    appointmentProceduresSorted () {
      if (!this.appointment.appointmentProcedures || !this.appointment.appointmentProcedures.length) {
        return []
      }
      const appointmentProcedures = [...this.appointment.appointmentProcedures]
      appointmentProcedures.sort((a, b) => a.procedure?.scan?.name > b.procedure?.scan?.name ? 1 : -1)
      const appProcsWithDividers = []
      appointmentProcedures.forEach(appProc => {
        if (!appProcsWithDividers.includes(appProc.procedure?.scan?.name)) {
          appProcsWithDividers.push(appProc.procedure.scan.name)
        }
        appProcsWithDividers.push(appProc)
      })
      return appProcsWithDividers
    },
    isPriorityPlannedComputed () {
      return this.isPriorityPlanned(this.appointmentPriority)
    }
  },
  data () {
    return {
      loading: false,
      attachments:[],
      mandatoryValueComponentItem: [
        {
          appointmentField: 'categoryId',
          viewName: 'category'
        },
        {
          appointmentField: 'patientLocationId',
          viewName: 'location'
        },
        {
          appointmentField: 'patientEpisodeId',
          viewName: 'episode'
        }
      ],
      dialogClinicSchedule: false
    }
  },
  mounted () {
    this.appointmentPriority  = this.appointment.priorityCode
    this.appointmentCategory  = this.appointment.categoryId
    this.patientLocationId = this.appointment.patientLocationId
    this.patientEpisodeId = this.appointment.patientEpisodeId
    if (!this.appointment.preferredAppointmentDate) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { preferredAppointmentDate: moment(Date.now()).format('YYYY-MM-DD') }))
    }
    if (this.appointment.appointmentPriority && this.appointment.preferredAppointmentDate
      && !this.appointment.appointmentProcedures.filter(appProc => !appProc.appointmentPriority).length) {
      this.$emit('update:complete', !!this.appointment.priorityCode)
    }
    this.$store.dispatch('appointmentPriority/getPriorities')
  },
  methods: {
    onSchedulingInput (value) {
      this.$emit('update:appointment', JSON.parse(JSON.stringify(this.appointment)))
    },
    canBeDisplay(componentName) {
      return config.additionalDropdownsForNew.includes(componentName) || null
    },
    photoAttachments(attachments) {
      this.attachments = attachments
    },
    updateAppointmentPriority (code) {
      this.appointment.priorityCode = code
      this.$emit('update:appointment', Object.assign({}, this.appointment, { priorityCode: code }))
    },
    updateAppointmentCategory (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { categoryId: id }))
    },
    updatePatientLocation (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { patientLocationId: id }))
    },
    updatePatientEpisode (id) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { patientEpisodeId: id }))
    },
    mandatoryValues() {
      let disabledContinue = []
      if(this.mandatoryValueComponentItem.length) {
        for(const item of this.mandatoryValueComponentItem) {
          disabledContinue.push(!this.canBeDisplay(item.viewName) || !!this.appointment[item.appointmentField])
        }
      } else {
        disabledContinue = true
      }

      return disabledContinue.every(val=>val)
    },
    showClinicSchedule() {
      this.dialogClinicSchedule = true
    },
    appointmentScheduled(data) {
      this.$emit('update:appointment', Object.assign({}, this.appointment, { schedule: data }))
      this.dialogClinicSchedule = false
    },
    onPriorityChange (priorityCode, appointmentProcedure = null) {
      if (this.isPriorityPlanned(priorityCode)) {
        return
      }
      if (appointmentProcedure) {
        appointmentProcedure.preferredAppointmentDate = null
      } else {
        this.appointment.preferredAppointmentDate = null
        this.$emit('update:appointment', (JSON.parse(JSON.stringify(this.appointment))))
      }
    },
    isPriorityPlanned (priorityCode) {
      return this.$store.getters['appointmentPriority/list'].find(priority => priority.code === priorityCode)?.isPlanned
    }
  },
  watch: {
    appointment: {
      handler: function (appointment) {
        this.$emit('update:complete', !!appointment.priorityCode && !!appointment.preferredAppointmentDate && this.mandatoryValues()
          && (!config.practitionerNewSplitBy.includes('PRIORITY') || !this.appointment.appointmentProcedures.filter(appProc => !appProc.appointmentPriority).length))
      },
      deep: true
    },
  },

}
</script>

<style scoped>
.v-data-table >>> tr:hover {
  background: transparent !important;
}
</style>

<template>
  <v-row justify="center">
    <v-col cols="12">
      <v-container class="py-0">
        <v-card :loading="clinicsLoading" class="mx-auto">
          <template slot="progress">
            <v-progress-linear
              color="primary"
              indeterminate
            ></v-progress-linear>
            <p v-if="!visible && !ignorePreferredClinicSearch" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Please wait while we search for available slots in your preferred clinic. This may take a moment or two, please do not refresh the page.') }}</p>
            <p v-if="!visible && ignorePreferredClinicSearch" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Unfortunately, we have no available slots in your preferred clinic at this time. Please wait while we search for available slots in all our locations. This may take a minute or two to complete, please do not refresh the page.') }}</p>
            <p v-if="visible" class="my-16 px-4 text-center font-weight-bold">{{ $_t('Please wait while we search for available slots in all our locations. This may take a minute or two to complete, please do not refresh the page.') }}</p>
          </template>
        </v-card>
        <div v-if="!clinicsLoading && bookingPossible">
          <v-row>
            <v-col cols="12">
              <h4 class="text-center mt-4">
                <span v-if="preferredClinicNotFound">{{ $_t('Unfortunately, there is currently no availability in your chosen clinic, please see other options below.') }}<br></span>
                <span>{{ $_t('If you would prefer to speak to an agent please click on "Call me back to help book my appointment" below.') }}</span>
              </h4>
            </v-col>
            <v-col cols="12">
              <patient-appointment-booking-call-me-back :appointment-id="getAppointment.id"></patient-appointment-booking-call-me-back>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-card class="mx-auto">
                <v-card-text>
                  <div class="pa-2">

                    <v-row>
                      <v-col cols="12">
                        <label
                          for="filter-clinic-names"
                          class="d-inline-flex mb-2 home-input-label font-weight-bold"
                          >{{ $_t('Find clinic by name') }}</label
                        >
                        <v-autocomplete
                          v-model="filteredClinicById"
                          :items="clinics"
                          item-value="id"
                          item-text="name"
                          id="filter-clinic-names"
                          outlined
                          dense
                          chips
                          small-chips
                          hide-details
                          @input="findClinic"
                        >
                          <template v-slot:no-data>
                            <p class="ma-0 py-3 px-2">
                              {{ $_t('No clinics found') }}
                            </p>
                          </template>
                          <template v-slot:selection="data">
                            <v-chip
                              v-if="filteredClinicById"
                              v-bind="data.attrs"
                              :input-value="data.item.id"
                              @click="data.select"
                              @click:close="removeClinicName()"
                              close
                            >
                              {{ data.item.name }}
                            </v-chip>

                          </template>
                          </v-autocomplete>
                      </v-col>

                    </v-row>
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12">
              <v-card class="mx-auto mb-8">
                <v-card-text>
                  <v-row>
                    <v-col cols="12" md="6">
                      <v-progress-linear
                        v-if="regionsLoader"
                        color="primary"
                        indeterminate
                        class="mb-2"
                      ></v-progress-linear>
                      <v-slide-y-transition
                        class="py-0"
                        group
                        tag="v-list"
                      >
                        <v-row
                        v-for="region in clinicRegions"
                        :key="region.id"
                      >
                        <v-col
                          cols="12"
                        >

                          <v-btn
                            x-large
                            block
                            @click="onRegionClicked(region.id)"
                            :outlined="region.id !== getSelectedRegionId"
                            color="primary"
                            class="full-width"
                          >
                            {{ region.name }}
                            <v-spacer></v-spacer>
                            <v-icon v-if="region.id !== getSelectedRegionId"
                              >mdi-chevron-right</v-icon
                            >
                            <v-icon v-else>mdi-chevron-down</v-icon>
                          </v-btn>
                        </v-col>
                        </v-row>
                      </v-slide-y-transition>

                      </v-col>
                    <v-col cols="12" md="6">
                      <div v-if="clinicsFiltered && clinicsFiltered.length">
                        <v-row
                          v-for="clinic in clinicsFiltered" :key="clinic.id"
                          >
                          <v-col cols="12">
                            <v-card>
                              <v-card-title>
                                {{ clinic.name }}
                                <v-chip
                                  v-if="clinic.id === preferredClinicId"
                                  class="ma-2"
                                  color="yellow"
                                >
                                  {{ $_t('Preferred') }}
                                </v-chip>
                              </v-card-title>
 
                              <v-card-text>
                                <div class="divider-line"></div>
                                <div v-if="clinic.newAvailabilityDateTime" class="pa-2 pl-5">
                                  <span>{{ $_t('First available appointment date') }}: <v-chip small label :color="clinic.newAvailabilityDateTime? 'green' : 'red'" variant="flat">
                                    {{ clinic.newAvailabilityDateTime ? firstAvailableSlotdate(clinic) : $_t('None') }}
                                  </v-chip></span>
                                </div>
                                  <v-divider></v-divider>
                                <div class="pa-2 pl-5">
                                  <span>{{ $_t('Address') }}:&nbsp;</span>
                                  <span class="font-weight-bold">{{ clinic.address }}</span>
                                </div>
                                <v-divider></v-divider>
                                  </v-card-text>
                                  <v-card-actions class="pt-0" :style="($vuetify.breakpoint.smAndDown) ? 'display: grid' : ''">

                                <v-btn
                                  @click="showMap(clinic)"
                                  :class="($vuetify.breakpoint.smAndDown) ? 'full-width' : '' + ' text-none'"
                                  color="primary"
                                  :disabled="regionsLoader"
                                >
                                  {{ $_t('Show on map') }}
                                  <v-icon>mdi-map-marker-outline</v-icon>
                                </v-btn>
                                <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
                                <v-btn
                                  @click="onClinicClicked(clinic)"
                                  :class="(($vuetify.breakpoint.smOnly) ? 'full-width' : '') + ' text-none'"
                                  color="primary"
                                  :disabled="regionsLoader"
                                  :loading="regionsLoader"
                                >
                                  <span>{{ $_t('Schedule a visit') }} <br class="d-block" />{{ $_t('Check availability') }}</span>
                                  <v-icon class="ml-1">mdi-calendar-month</v-icon>
                                </v-btn>
                              </v-card-actions>
                            </v-card>
                          </v-col>

                        </v-row>
                      </div>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </div>
        <div class="mb-8" v-if="!clinicsLoading && (!bookingPossible)">
          <v-row>
            <v-col cols="12">
              <h3 class="mt-16 text-center text-subtitle-1">
                {{ $_t('We are sorry, however, we do not have any slots available to book online at this time.') }}<br>
                {{ $_t('Our team will be in contact to schedule your appointment.') }}<br>
                {{ $_t('Please note we are currently experiencing a high volume of appointment requests.') }}

              </h3>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-col>
  </v-row>
</template>

<script>
import config from '@src/config'
import Vue from 'vue'
import { mapGetters } from 'vuex'
import useDateHelpers from '@helpers/useDateHelpers'
import PatientAppointmentBookingCallMeBack from '@components/appointment/booking/callMeBack/callMeBack.vue'
import { uniqBy, isEmpty } from 'lodash'

const { useDifferenceInYears, useHumanDateTime } = useDateHelpers()


export default Vue.extend({
  components: {
    PatientAppointmentBookingCallMeBack
  },
  name: 'BookingClinics',
  props: {
    accessCode: {
      type: String,
      default: ''
    },
    appointment: {
      type: Object,
      default: () => {}
    },
    visible: {
      type: Boolean,
      default: true
    },
    showRegions: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    googleMapsApiKeyLoaded: false,
    noCallMeBack: config.webBookingNoCallMeBack,
    clinicsLoading: true,
    clinics: null,
    ignorePreferredClinicSearch: false,
    clinicsMapImageName: null,
    filteredClinicById: null,
    filteredClinicRegions: [],
    selectedPlace: null,
    selectedClinic: null,
    preferredClinicNotFound: true,
    distance: 0,
    showIconCurrentLocation: true,
    showIconClearLocation: false,
    googleMaps: null,
    googleMapsMarkers: [],
    additionalInfo: {},
    config: config,
    selectedRegionId: null,
    preferredClinicId: null,
    regionsLoader:false
  }),
  mounted: function() {
    this.fetchAppointment();
    this.getAdditionalInfo();
  },
  watch: {
    getSelectedRegionId(newValue, oldValue) {
      if (newValue && oldValue) {
        this.onRegionChange(newValue)
      }
    }
  },
  computed: {
    ...mapGetters('booking', ['getAppointment']),
    ...mapGetters('booking', { getClinic: 'getClinic' }),
    ...mapGetters('app', { organization: 'organization' }),
    patientAge() {
      const dateOfBirth = this.getAppointment.patient.dateOfBirth
      const age = dateOfBirth ? useDifferenceInYears(new Date(), this.getAppointment.patient.dateOfBirth) : 0
      return age;
    },
    bookingPossible () {
      return this.clinics && this.clinics.length
    },
    appointmentAccessCode() {
      return this.accessCode || this.$route.params.appointmentAccessCode.replace(/\W/g, '')
    },
    getSelectedRegionId() {
      if(this.filteredClinicById) {
       return this.clinics.find(item => item.id === this.filteredClinicById)?.region.id
      }

      return this.selectedRegionId
    },
    clinicsFiltered() {
      if (!this.clinics) {
        return [];
      }
      let clinics = []
      if(this.filteredClinicById) {
        const found = this.clinics.find(item => item.id === this.filteredClinicById)
        if (typeof found !== 'undefined') {
          clinics.push(found)
        }
        return clinics

      }

      clinics =  this.clinics.filter(item=> item.region.id === this.selectedRegionId)
      if(this.preferredClinicId) {
        const preferredClinic =  clinics.find(item => item.id === this.preferredClinicId)
        clinics =  clinics.filter(item => item.id !== this.preferredClinicId).sort(function(a,b){
          return new Date(b.newAvailabilityDateTime) - new Date(a.newAvailabilityDateTime);
        })
        if (preferredClinic) {
          clinics.unshift(preferredClinic)
        }

      }
      return clinics

    },
    clinicRegions() {
      if (!this.clinics) {
        return [];
      }
      let regionId = this.selectedRegionId
      const clinicRegions = this.clinics
        .map(clinic => {return {name:clinic.region.name, id: clinic.region.id}});
      const unique = uniqBy(clinicRegions, 'id')
      if(this.filteredClinicById) {
        const found = this.clinics.find(item => item.id === this.filteredClinicById)
        regionId = found.region.id
      }
      if(regionId) {
        const preferredRegion = unique.find(item => item.id === regionId)
        const preferredFirst =  unique.filter(item => item.id !== regionId)
        if (!isEmpty(preferredRegion)) {
          preferredFirst.unshift(preferredRegion)
        }
        return preferredFirst

      }
      return unique;
    }
  },
  methods: {
    onClinicClicked(clinic) {
      if (this.showRegions) {
        this.$store.commit('booking/setClinic', clinic)
        this.$emit('continue')
      }
    },
    onRegionChange(regionId) {
      if (this.showRegions) {
          this.regionsLoader = true
          this.fetchAvailabilitySlotByRegion(regionId).then(responseAvailabilitySlot => {
            this.clinics = [...this.processClinics(this.clinics, this.mapDatasetResponse(responseAvailabilitySlot))]
            this.preferredClinicId = this.clinics
                  .find(clinic => clinic.id === this.getAppointment.preferredClinic.id)?.id || null
            this.regionsLoader = false
          }).catch(() => {
            this.$_notify.error('Could not load region.');
            this.regionsLoader = false
          })
      }
    },
    firstAvailableSlotdate(clinic) {
      return useHumanDateTime(clinic.newAvailabilityDateTime)
    },
    fetchAvailabilitySlotByClinic(clinicId = null){
      return new Promise((resolve, reject) => {
        this.$_rest.get(
          `patients/${ this.getAppointment.patient.id }/datasets/availability-per-clinic-or-region/default`,
          { appointmentProcedureId: this.getAppointment.appointmentProcedures[0].id,
            clinicId: clinicId || this.getAppointment.preferredClinic.id,
            insurerId:this.getAppointment.insurer.id
           },
          response => {
            resolve(response)
          },
          error => {
            this.$_notify.error(error)
            reject()
          })
      })
    },
    fetchAvailabilitySlotByRegion(regionId){
      return new Promise((resolve, reject) => {
        this.$_rest.get(
          `patients/${ this.getAppointment.patient.id }/datasets/availability-per-clinic-or-region/default`,
          { appointmentProcedureId: this.getAppointment.appointmentProcedures[0].id,
            regionId,
            insurerId:this.getAppointment.insurer.id
           },
          response => {
            resolve(response)
          },
          error => {
            this.$_notify.error(error)
            reject()
          })
      })
    },
    findClinic(clinicId) {
      this.filteredClinicById = clinicId
    },
    showMap (clinic) {
      if(!clinic.lat && !clinic.lng) {
        this.$_notify.error(this.$_t('Could not find clinic on map.'))
        return
      }
      window.open(
        'https://www.google.com/maps/search/?api=1&query=' +
        clinic.lat +
          ',' +
          clinic.lng,
        '_blank'
      )
    },
    onRegionClicked (regionId) {
      this.selectedRegionId = regionId
    },
    removeClinicName() {
      this.filteredClinicById = null
    },
    processClinics(clinics, availabilitySlotForClinics) {
      const availabilityObject = Object.freeze({
        availabilityDate: null,
        availabilityPeriod: null,
        availabilityStart: null,
        newAvailabilityDateTime: null
      })
      clinics.forEach(function(element,i) {
        const availableSlotForClinics = availabilitySlotForClinics.filter(item=>item.clinicId === element.id).sort(function(a,b){
          return new Date(a.newAvailabilityDateTime) - new Date(b.newAvailabilityDateTime);
        })
        if (Array.isArray(availableSlotForClinics) && availableSlotForClinics.length) {
          const object = { ...availabilityObject }
          object.availabilityDate = availableSlotForClinics[0].availabilityDate
          object.availabilityPeriod = availableSlotForClinics[0].availabilityPeriod
          object.availabilityStart = availableSlotForClinics[0].availabilityStart
          object.newAvailabilityDateTime = availableSlotForClinics[0].newAvailabilityDateTime

          clinics[i] = { ...element, ...object }

        } else {
          clinics[i] = {...element, ...availabilityObject}
        }

      });

      return clinics

    },
    fetchAppointment() {
      this.clinicsLoading = true;
      this.$_rest.get(
        `/appointments/${this.appointment.id}`,
        {
          groups:
            'all,appointment_status,appointment_procedure_status,clinic_scan_room,appointment_procedure_procedure,procedure_procedure_clinics,appointment_appointment_procedures,appointment_patient,procedure_scan,procedure_referral_template,appointment_clinic,appointment_preferred_clinic,appointment_insurer'
        },
        async appointmentResponse => {
          appointmentResponse.data.appointmentProcedures = appointmentResponse.data.appointmentProcedures
            .filter(ap => ap.statusCode !== 'X' && ap.statusCode !== 'XR')

          this.$store.commit('booking/setAppointment', appointmentResponse.data);

          this.clinicsLoading = true;
          this.preferredClinicNotFound = false;

          try {
            let responseAvailabilitySlot = null
            const clinics = await this.fetchClinics();
           
            const clinicsAvailable = clinics.filter(item=>item.isActive && item.region && item.isApplicableForWeb)
           
            const properClinic = clinicsAvailable.find(clinic => clinic.id === this.getAppointment.preferredClinic.id)
            if (this.showRegions) {
              responseAvailabilitySlot = await this.fetchAvailabilitySlotByRegion(properClinic.region.id)
            } else {
              responseAvailabilitySlot = await this.fetchAvailabilitySlotByClinic()

            }

            this.clinics = this.processClinics(clinicsAvailable, this.mapDatasetResponse(responseAvailabilitySlot))


            if (this.clinics.length && (!this.visible || this.showRegions)) {
              const preferredClinic = this.clinics
                .find(c => c.id === this.getAppointment.preferredClinic.id)

              if (!preferredClinic) {
                this.preferredClinicNotFound = true;
                this.clinicsLoading = false;
                return;
              }

              if (preferredClinic) {
                  this.$store.commit('booking/setClinic', preferredClinic)
                  this.selectedRegionId = preferredClinic.region.id
                this.preferredClinicId = preferredClinic.id


              }
              if (!this.showRegions) {
                this.$emit('continue')
              }

            }

            this.clinicsLoading = false;
          }
          catch {
            this.clinicsLoading = false;
            this.$_notify.error('Could not load clinics.');
          }
        },
        () => {
          this.$_notify.error('Could not get appointment.');
        },
        { prefixRoutesWithPatientId: true }
      );
    },
    fetchClinics() {
      return new Promise((resolve, reject) => {
        this.$_rest.get(
          `patients/${ this.getAppointment.patient.id }/clinics`,
          { groups: 'all,region,clinic,clinic_region, clinic_clinic_scan_rooms' },
          response => {
            resolve(response.data)
          },
          error => {
            this.$_notify.error(error)
            reject()
          })
      })

    },
    getAdditionalInfo () {
      if (this.appointment.appointmentProcedures.length > 0) {
        this.appointment.appointmentProcedures.forEach((ap) => {
          if (ap.procedure?.scan?.webBookingSlotsSelectionAdditionalInfo) {
            this.additionalInfo[ap.procedure.scan.code] = ap.procedure.scan.webBookingSlotsSelectionAdditionalInfo
          }
        })
      }
    }
  }
});
</script>


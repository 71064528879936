<template>
  <v-container fluid>
    <div class="mb-5 text-subtitle-2">{{ $_t('Patient Timeline') }}
      <v-btn class="ml-2" color="primary" :to="`/practitioner/appointment/new/patientId/${patientId}`">
        {{ $_t('New order') }}
      </v-btn>
    </div>
    <v-progress-linear v-if="loading" />
    <div v-if="!loading && patientAppointments.length" style="display: flex; flex-direction: column-reverse;">
      <div v-for="(yearAppointments, year) in patientAppointmentsByYear" :key="year">
        <div class="text-h6 my-4">{{ year }}</div>
        <v-timeline dense>
          <v-timeline-item v-for="(appointment, index) in yearAppointments" :key="index" size="small" color="primary"
            class="mb-10">
            <v-card>
              <v-card-text>
                <div style="display: flex; align-items: flex-start;">
                  <span class="text-subtitle-2 font-weight-bold">
                    {{ appointment.appointmentDate ? useHumanDate(appointment.appointmentDate) : $_t('Not scheduled') }} -
                  </span>
                  <v-chip small elevated
                    :color="appointment.appointmentStatusNameStyled ? '#' + appointment.appointmentStatusNameStyled.split('^')[1] : ''"
                    class="ml-2 mb-2">
                    {{ appointment.appointmentStatusNameStyled.split('^')[0] }}
                  </v-chip>
                </div>
                <div class="text-capitalize font-weight-normal">
                  <v-label>
                    {{ appointment.appointmentIdShort || 'A' + appointment.appointmentId.substr(appointment.appointmentId.length - 11) }}
                    {{ appointment.clinicName || appointment.clinicNameShort || $_t('No clinic specified') }}
                  </v-label>
                </div>
                <div v-if="appointment.appointmentProcedureDetailsAgg.length">
                  <div v-for="(procedure, index) in appointment.appointmentProcedureDetailsAgg"
                    :key="index"
                  >
                    <div class="mt-2 ml-5">
                      <div class="d-flex mb-2">
                        <strong>
                          {{ procedure.procedureName }}
                            ({{procedure.startTime ? procedure.startTime.split(':').slice(0, 2).join(':') : $_t('Not scheduled')}} - {{procedure.duration}} {{ $_t('min') }}) 
                        </strong>
                      </div>
                      <v-avatar :color="'#' + procedure.appointmentProcedureStatusNameStyled.split('^')[1]"
                          size="24" rounded class="mr-2 ml-5" 
                        />
                        <v-label class="ml-5 mt-2">
                          {{ procedure.appointmentProcedureStatusNameStyled.split('^')[0]}}
                        </v-label>
                    </div>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions>
                <v-spacer />
                <v-btn text class="ma-2 float-right" color="primary" @click="appointmentClicked(appointment)">
                  {{ $_t('View Details') }}
                  <v-icon dark right>
                    mdi-eye
                  </v-icon>
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </div>
    </div>
    <div v-if="!loading && !patientAppointments.length" class="text-h5">
      {{ $_t('No appointments found for selected patient') }}
    </div>
  </v-container>
</template>

<script>
import useDateHelpers from '@helpers/useDateHelpers';

const { useHumanDateTimeSec, useHumanDate } = useDateHelpers()

export default {
  name: 'PatientTimeline',
  props: {
    patientId: String
  },
  data() {
    return {
      patientAppointments: [],
      appointmentsByYear: {},
      loading: false,
      useHumanDate: useHumanDate,
      useHumanDateTimeSec: useHumanDateTimeSec,
      appointmentDrawerOpen: false,
      selectedAppointmentId: null,
      proceduresPanel: true
    }
  },
  computed: {
    patientAppointmentsByYear() {
      return this.appointmentsByYear
    }
  },
  methods: {
    appointmentClicked(appointment) {
      this.$store.commit('appointment/setAppointment', this.patientAppointments.find(item => item.appointmentId === appointment.appointmentId))
      this.$router.push({ name: 'patient-appointment-details', params: { id: appointment.appointmentId, source: 'patient_timeline' } })
    },
    getAppointments() {
      this.loading = true;
      this.$_rest.get(
        `/practitioners/${this.$store.getters['app/currentUserId']}/datasets/patient-appointments`,
        {
          filter: {
            patientId: { x: this.patientId, type: 'eq' }
          }
        },
        response => {
          this.patientAppointments = this.mapDatasetResponse(response)
            .sort((a, b) => {
              const dateA = new Date(a.appointmentCreatedDatetime);
              const dateB = new Date(b.appointmentCreatedDatetime);
              return dateB - dateA;
            });
          this.patientAppointments.forEach(appointment =>  {
            appointment.appointmentProcedureDetailsAgg = appointment.appointmentProcedureDetailsAgg?.filter(item => item.procedureName) || []
          })
          this.processPatientTimeline()
          this.loading = false;
        },
        error => {
          this.loading = false;
          this.$_notify.error(this.$_t('Error fetching patient appointments'));
        }
      );
    },
    getYearFromDate(dateString) {
      const date = new Date(dateString);
      return date.getFullYear().toString();
    },
    processPatientTimeline() {
      const appointments = {};
      for (const appointment of this.patientAppointments) {
        const year = this.getYearFromDate(appointment.appointmentCreatedDatetime);
        if (!appointments[year]) {
          appointments[year] = [];
        }
        appointments[year].push(appointment);
      }
      return this.appointmentsByYear = appointments;
    }
  },
  mounted() {
    this.getAppointments()
  },
  beforeDestroy() {
    this.$root.$off('open-navbar')
    this.$store.commit('app/patientBannerShow', false)
  }
}
</script>



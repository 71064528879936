<template>
  <v-container fluid>
    <v-subheader><h4>{{ $_t('Documents') }}</h4></v-subheader>
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-list
      v-if="!loading"
      subheader
      two-line
    >
      <v-list-item
        v-for="appointmentDocument in appointmentDocuments"
        :key="appointmentDocument.id"
        selectable
        @click="viewDocument(appointmentDocument)"
      >
        <v-list-item-avatar>
          <v-icon
            v-if="!appointmentDocumentThumbs[appointmentDocument.id]"
            class="grey lighten-1"
            dark
          >
            mdi-file-document
          </v-icon>
          <v-img
            v-if="appointmentDocumentThumbs[appointmentDocument.id]"
            class="grey lighten-1"
            :src="appointmentDocumentThumbs[appointmentDocument.id]"
            dark
          ></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="appointmentDocument.document.category.name"></v-list-item-title>

          <v-list-item-subtitle v-text="useHumanDateTime(appointmentDocument.document.createdDatetime)"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn
            icon
            :loading="appointmentDocument.downloading"
            @click.stop="downloadDocument(appointmentDocument)"
          >
            <v-icon color="grey lighten-1">mdi-download</v-icon>
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
    <template
      v-if="!loading && !appointmentDocuments.length"
    >
      <v-subheader>{{ $_t('No documents') }}</v-subheader>
    </template>
    <!--  Upload feature disabled, ticket: https://aptvision.atlassian.net/browse/RIS-9286   -->
    <template v-if="!loading && false">
      <v-subheader><h4>{{ $_t('Upload') }}</h4></v-subheader>
      <v-container v-if="appointmentId">
        <v-row>
          <v-col cols="12">
            <ul>
              <li v-for="file in files" :key="file.id">
                <span>{{file.name}}</span>&nbsp;&nbsp;&nbsp;
                <span v-if="file.error">{{file.error}}</span>
                <span v-else-if="file.success">{{ $_t('uploaded successfully') }}</span>
                <span v-else-if="file.active">{{ $_t('uploading') }}</span>
                <span v-else-if="!!file.error">{{ file.error }}</span>
                <span v-else></span>
              </li>
            </ul>
          </v-col>
        </v-row>
        <v-row v-show="!files || !files.length">
          <v-col cols="12">
            <file-upload
              class="btn btn-primary"
              :post-action="uploadRoute"
              :headers="uploadHeaders"
              extensions="gif,jpg,jpeg,png,webp,pdf"
              accept="image/png,image/gif,image/jpeg,image/webp,application/pdf'"
              :multiple="false"
              :size="1024 * 1024 * 10"
              v-model="files"
              @input-filter="inputFilter"
              ref="upload">
              <v-btn>{{ $_t('Upload file') }}</v-btn>
            </file-upload>
          </v-col>
        </v-row>
        <v-row v-if="files && files.length && !files[0].success && !files[0].active" class="mt-2">
          <v-col cols="12">
            <v-btn v-if="!uploadRef || !uploadRef.active" @click.prevent="uploadRef.active=true">
              {{ $_('Start upload') }}
            </v-btn>
            <v-btn v-if="!uploadRef || !uploadRef.active" @click.prevent="files=[];uploadRef.active=false;" class="ml-2">
              {{ $_('Cancel') }}
            </v-btn>
            <v-btn type="button" v-else @click.prevent="uploadRef.active = false">
              {{ $_('Stop Upload') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row v-if="files && files.length && files[0].success" class="mt-2">
          <v-col cols="12">
            <v-btn type="button" @click.prevent="uploadAnother">
              {{ $_('Upload another') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-dialog
      v-model="previewDialog"
      width="90vw"
      scrollable
      transition="dialog-bottom-transition"
    >
      <v-card
        width="100%"
        max-height="95vh"
        height="95vh"
        color="#fff"
      >
        <v-card-title>
          {{ previewDocumentTitle }}
          <v-spacer />
          <v-btn
            fab
            small
            icon
            @click="hideDocument"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text
          v-if="previewLoading"
        >
          <v-progress-linear
            indeterminate
            color="primary"
          ></v-progress-linear>
        </v-card-text>
        <v-card-text
          v-if="!previewLoading && previewDocumentType === 'pdf'"
        >
          <div style="width: 100%; overflow-y: scroll;">
            <pdf-viewer
              v-for="i in pdfNumPages"
              :key="i"
              :src="previewSrc"
              :page="i"
            ></pdf-viewer>
          </div>
        </v-card-text>
        <v-card-text
          v-if="!previewLoading && previewDocumentType !== 'pdf'"
        >
          <v-img
            :src="previewSrc"
            width="100%"
            contain
          />
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn
            color="primary"
            text
            @click="downloadDocument(previewAppointmentDocument)"
            :loading="previewAppointmentDocument && previewAppointmentDocument.downloading"
            :disabled="!previewAppointmentDocument"
          >
            {{ $_t('Download') }}
            <v-icon class="ml-1" small>mdi-download</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Vue from 'vue'
import useDateHelpers from '../../helpers/useDateHelpers';
import config from '@src/config'
import FileUpload from 'vue-upload-component'
import pdfViewer from '@components/common/PdfViewer.vue'

const { useHumanDateTime } = useDateHelpers()
export default {
  name: 'AppointmentDocumentList',
  components: { FileUpload, pdfViewer },
  props: {
    appointmentId: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      useHumanDateTime: useHumanDateTime,
      loading: false,
      appointmentDocuments: [],
      appointmentDocumentThumbs: {},
      files: [],
      upload: null,
      previewDialog: false,
      previewSrc: '',
      previewLoading: false,
      previewAppointmentDocument: null,
      previewDocumentType: '',
      previewDocumentTitle: '',
      previewPdf: null,
      pdfNumPages: undefined
    }
  },
  computed: {
    uploadRoute() {
      return `${config.apiUrl}/organizations/${this.$store.getters['app/currentOrganizationId']}/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-documents/upload`
    },
    uploadHeaders() {
      return {'Authorization': 'Bearer ' + sessionStorage.getItem(config.authTokenName)}
    },
    uploadRef() {
      return this.$refs['upload']
    }
  },
  methods: {
    viewDocument (appointmentDocument) {
      this.previewLoading = true
      this.previewDialog = true
      this.previewAppointmentDocument = appointmentDocument
      this.previewDocumentType = 'image'
      this.previewDocumentTitle = appointmentDocument?.document?.name
      this.getDocumentFile(appointmentDocument, (blob) => {
        this.previewSrc = URL.createObjectURL(blob)
        if (appointmentDocument?.document?.type?.code === 'pdf') {
          this.previewPdf = pdfViewer.components.pdf.createLoadingTask(this.previewSrc)

          this.previewPdf.promise.then(pdf => {
            this.pdfNumPages = pdf.numPages
            this.previewDocumentType = 'pdf'
            this.previewLoading = false
          })
        } else {
          this.previewLoading = false
        }
      })
    },
    downloadDocument (appointmentDocument) {
      Vue.set(appointmentDocument, 'downloading', true)
      if (this.previewSrc) {
        this.doDownloadDocument(this.previewSrc, appointmentDocument.document.name, false)
        Vue.set(appointmentDocument, 'downloading', false)
      } else {
        this.getDocumentFile(appointmentDocument, (blob) => {
          const url = URL.createObjectURL(blob);
          this.doDownloadDocument(url, appointmentDocument.document.name, true)
          Vue.set(appointmentDocument, 'downloading', false)
        })
      }
    },
    doDownloadDocument(url, name, revokeUrl) {
      const anchor = document.createElement('a');
      anchor.href =  url
      anchor.download = name
      anchor.click();
      if (revokeUrl) {
        window.URL.revokeObjectURL(url)
      }
    },
    hideDocument () {
      this.previewSrc = ''
      this.previewDialog = false
    },
    getDocumentFile(appointmentDocument, successHandler, thumb) {
      const documentPageId = appointmentDocument.document.documentPages[0].id
      this.$_rest.get(
        `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-documents/${appointmentDocument.id}/documents/${appointmentDocument.document.id}/document-pages/${documentPageId}/file`,
        {},
        successHandler,
        () => {},
        {
          blob: !thumb
        }
      )
    },
    previewDialogClosed() {
      URL.revokeObjectURL(this.previewSrc)
      this.previewSrc = ''
      this.previewDocumentType = ''
      this.previewDocumentTitle = ''
    },
    getDocumentsForAppointment() {
      if (!this.appointmentId) {
        throw new Error('"appointmentId" is not set')
      }
      this.loading = true;
      this.appointmentDocuments = []
      this.$_rest.get(
        `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-documents`,
        {
          groups: 'appointment_document_document,document_category,document_document_pages,document_type,all,all_composite'
        },
        resp => {
          if (resp.data) {
            this.appointmentDocuments = resp.data
          } else {
            this.$_notify.error('Could not retrieve documents list for appointment');
          }
          this.loading = false;
        }
      )
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        // Before adding a file
        // Filter system files or hide files
        if (/(\/|^)(Thumbs\.db|desktop\.ini|\..+)$/.test(newFile.name)) {
          return prevent()
        }

        // Filter php html js file
        if (/\.(php5?|html?|jsx?)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    uploadAnother() {
      this.files = []
    },

  },
  mounted() {
    this.getDocumentsForAppointment()
  },
  watch: {
    previewDialog(val) {
      if (!val) {
        this.previewDialogClosed()
      }
    },
    files: {
      deep: true,
      handler(files) {
        if (files && files.length && files[0].success) {
          this.getDocumentsForAppointment()
        }
      }
    },
    appointmentDocuments(appointmentDocuments) {
      if (Array.isArray(appointmentDocuments) && appointmentDocuments.length) {
        appointmentDocuments.forEach((appointmentDocument, ind) => {
          if (
            appointmentDocument.document &&
            appointmentDocument.document.documentPages &&
            Array.isArray(appointmentDocument.document.documentPages) &&
            appointmentDocument.document.documentPages.length
          ) {
            const documentPageId = appointmentDocument.document.documentPages[0].id
            this.$_rest.get(
              `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${this.appointmentId}/appointment-documents/${appointmentDocument.id}/documents/${appointmentDocument.document.id}/document-pages/${documentPageId}/file`,
              {
                groups: 'all,all_composite',
                thumb: true
              },
              resp => {
                if (resp.data) {
                  Vue.set(this.appointmentDocumentThumbs, appointmentDocument.id, resp.data)
                }
                this.loading = false;
              }
            )
          }
        })
      }
    }
  }
};
</script>

<style scoped></style>

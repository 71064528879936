<template>
    <v-container class="ma-0 px-0 pt-0" fluid style="height:92vh">
      <v-dialog
      v-model="confirmDialog"
      max-width="290"
    >
      <v-card>
        <v-card-title class="text-h5">
          {{$_t('Form error')}}
        </v-card-title>

        <v-card-text>
          {{$_t('Failed setting up form. Please proceed to the next step.')}}

        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="green darken-1"
            text
            @click="proceedNextStep"
          >
          {{$_t('Next step')}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      <v-progress-linear
        v-if="!token"
        indeterminate
        color="primary"
        class="mb-2"
      />
      <iframe
        id="formIframe"
        loading="lazy"
        width="100%"
        height="100%"
        :src="getIframeLink"
        />

      </v-container>

    </template>

<script>
import config from '@src/config';
import { mapGetters, mapState } from 'vuex'

const iFrameAction = {
  LOG_ID: null,
  SUCCESS:{status: 200,type: 'SUCCESS'},
  ERROR500: { name:'ERROR500', status: 500, type:'error', actionType: 'ERROR500', message:'An error occurred while saving the form' },
  ERROR400: { name:'ERROR400', status: 400, type: 'error', actionType: 'ERROR400', message: 'Form error (probably validation failed)' },
  ERROR400_STAGE: { type: 'error_400_stage', stage: 'ERROR400', payload: { message: 'Next attempt after error 400' } },
  ERROR500_STAGE: { type: 'error_500_stage', stage: 'ERROR500', payload: { message: 'Next attempt after error 500' } },
  BROWSER_BEFORE_CLOSE: { type: 'browser_close_refresh', stage: 'REFRESH_OR_CLOSE', payload: { message: 'Patient attempted closing browse' } },
  BROWSER_CLOSED: { type: 'browser_close_refresh_done', message:'Patient closed browser' },
  IFRAME_OPEN: { type: 'create', payload:{integrationCode:'pcms_patient_appointment', actionName:'IFRAME_OPEN'} },
  IFRAME_FORMS_DONE: { type: 'success', status: 200 },
  IFRAME_TOKEN_ERROR:{ type:'error', message:'Iframe token error'}
}

export default {
  name: 'IframeLayout',
  props: {
    iframe: {
      type: Object,
      default: ()=>({})
    }
  },
  data () {
    return {
      token: null,
      config,
      iframeLoaded: false,
      nextButton: null,
      confirmDialog: false,
      currentIframeAction: null,
      errorCount: 0
    }
  },
  computed: {
    ...mapGetters('appointment', { appointment: 'appointment' }),
    ...mapGetters('app', { patient: 'currentUser' }),

    getIframeLink() {
      return this.iframe.iframeFormLink
    }
  },
  methods: {
    proceedNextStep() {
      this.confirmDialog = false
      this.$store.commit('app/setAppBarButton2Disabled', false)
      this.errorCount = 0
      this.saveToLogs(iFrameAction[this.currentIframeAction.actionType])
      this.redirectToNext()
    },
    reload() {
      this.confirmDialog = false
      this.errorCount = 0
      this.iframeError()

    },
    sendTokenToIframe() {
      if (this.token && this.iframeLoaded) {
        document.getElementById('formIframe').contentWindow.postMessage(JSON.stringify({ token: this.token }), '*')
      }
    },
    messageHandler(event) {
      const eventData = event.data
      if (eventData === 'loaded') {
        this.iframeLoaded = true
        this.saveToLogs(iFrameAction.IFRAME_OPEN)
        this.sendTokenToIframe()
        return
      }

      if (eventData?.status === iFrameAction.SUCCESS.status) {
        this.$store.commit('app/setAppBarButton2Disabled', false)
        this.$_notify.success(this.$_t('The form has been successfully fill out.'))
        this.errorCount = 0
        this.confirmDialog = false
        this.saveToLogs(iFrameAction.IFRAME_FORMS_DONE)
        this.redirectToNext()
      }

      if (eventData?.status === iFrameAction.ERROR500.status) {
        this.processIframeError(iFrameAction.ERROR500)
        this.saveToLogs(iFrameAction.ERROR500_STAGE)
        return
      }

      if (eventData?.status === iFrameAction.ERROR400.status) {
        this.processIframeError(iFrameAction.ERROR400)
        this.saveToLogs(iFrameAction.ERROR400_STAGE)
        return
      }


    },
    redirectToNext() {
      this.$store.commit('app/setAppBarButton2', {
          label: this.$_t('Next'),
          icon: 'mdi-chevron-right',
          click: () => {
            this.$emit('next')
          }
      })

      this.currentIframeAction = null
      this.$_notify.success(this.$_t('You are being redirected to the next step'))
      setTimeout(() => {
        this.$emit('next')
      }, 1000);
    },

    saveToLogs(action) {
      const actionType = action.type
      switch (actionType) {
        case 'create':
          this.saveToLogCreate(action).then(id => {
            iFrameAction.LOG_ID = id
          })

          break
        case 'browser_close_refresh':
          this.saveToLogStage(action).then(id => {
            iFrameAction.LOG_ID = id
          })
          break

          case 'browser_close_refresh_done':
          this.saveToLogError(action).then(id => {
            iFrameAction.LOG_ID = id
          })
          break

        case 'error':
          this.saveToLogError(action).then(id => {
            iFrameAction.LOG_ID = id
          })
          break
        case 'success':
          this.saveToLogSuccess(action).then(id => {
            iFrameAction.LOG_ID = id
          })
          break
          case 'error_400_stage':
          this.saveToLogStage(action).then(id => {
            iFrameAction.LOG_ID = id
          })
          break
      }
    },
    saveToLogCreate(action) {
      return new Promise((resolve, reject) => {

        this.$_rest.post(
          'external-integration-log',
          action.payload
          ,
          (response) => {
            if (response.data?.id) {

              resolve(response.data?.id)
            }
          },
          (error) => {
            console.error('Cannot create log')
            reject()
          },
          {
            prefixRoutesWithOrganizationId: true,
          }
        )
      })
    },
    saveToLogStage(action) {
      return new Promise((resolve, reject) => {

        this.$_rest.post(
          `external-integration-log/${iFrameAction.LOG_ID}/stage`,
          {
            stage: action.stage,
            payload: action.payload
          },
          (response) => {
            if (response.data?.id) {
              iFrameAction.LOG_ID = response.data?.id
            }
          },
          (error) => {
            console.error('Cannot add stage to log')
            reject()
          },
          {
            prefixRoutesWithOrganizationId: true,
          }
        )
      })
    },
    saveToLogError(action) {
      return new Promise((resolve, reject) => {

        this.$_rest.put(
          `external-integration-log/${iFrameAction.LOG_ID}/error`,
          {
            message: action.message
          },
          undefined,
          (response) => {
            if (response.data?.id) {
              iFrameAction.LOG_ID = response.data?.id
            }
          },
          (error) => {
            console.error('Cannot add error to log')
            reject()
          },
          {
            prefixRoutesWithOrganizationId: true,
          }
        )
      })
    },
    saveToLogSuccess(action) {
      return new Promise((resolve, reject) => {

        this.$_rest.put(
          `external-integration-log/${iFrameAction.LOG_ID}/success`,
          {},
          undefined,
          (response) => {
            if (response.data?.id) {
              iFrameAction.LOG_ID = response.data?.id
            }
          },
          (error) => {
            console.error('Cannot add success to log')
            reject()
          },
          {
            prefixRoutesWithOrganizationId: true,
          }
        )
      })
    },

    iframeError() {
      if (this.errorCount === 0) {
        this.token = null
        this.getToken()
        this.errorCount = 1
      } else {
        this.confirmDialog = true
      }
    },
    processIframeError(action) {
      const actionType = action.actionType
      switch (actionType) {
        case 'ERROR500':
          this.$_notify.error(this.$_t('An error occurred while saving the form.'))
          this.currentIframeAction = action
          this.iframeError()
          break
        case 'ERROR400':
          this.$_notify.error(this.$_t('Form error (probably validation failed)'))
          this.currentIframeAction = action
          this.iframeError()
          break
      }


    },

    browserBeforeUnloadHandler(event) {
      event.preventDefault()
      this.saveToLogs(iFrameAction.BROWSER_BEFORE_CLOSE)

      const confirmationMessage = this.$_t('Are you sure? Changes you made may not be saved.')
      // return event.originalEvent.returnValue = confirmationMessage // left for test in browsers Greg
      event = event || window.event

      if (event) {
        return event.returnValue = confirmationMessage
      }

      // For Safari
      return confirmationMessage

    },
    browserUnloadHandler(event) {
      event.preventDefault()
      this.saveToLogs(iFrameAction.BROWSER_CLOSED)
    },


    getToken() {
      const args = {
        systemID: 'APTIE',
        accountStatus: 2
      }
      this.$_rest.post(
        'jwt/pcms-patient-appointment',
        {
          appointmentId: this.appointment.id,
          clientCode: this.config.iframeClientCode,
          patientId: this.patient.id,
          args


        },
        (response) => {
          if (response && response.data) {
            this.token = response.data.token
            this.confirmDialog = false
            this.sendTokenToIframe()
          }
        },
        (error) => {
          this.$_notify.error(this.$_t('Token error'))
          this.token = null

          this.saveToLogs(iFrameAction.IFRAME_TOKEN_ERROR)
          this.redirectToNext()
        },
        {
          prefixRoutesWithOrganizationId: true,
        }
      )
    }
  },
  mounted() {
    this.getToken()
  },

  created() {
    window.addEventListener('message', this.messageHandler, false)
    window.addEventListener('beforeunload', this.browserBeforeUnloadHandler)
    window.addEventListener('unload', this.browserUnloadHandler)

  },

  beforeDestroy () {
    window.removeEventListener('message', this.messageHandler)
    window.removeEventListener('beforeunload', this.browserBeforeUnloadHandler)
    window.removeEventListener('unload', this.browserUnloadHandler)
  }

}

</script>











import { defineComponent } from '@vue/composition-api'
import useCommonHelpers from '../../../helpers/useCommonHelpers';

export default defineComponent({
    name:'PatientLocationSlot',
    props: {
    item: {
        type: String,
        default: null
        
    }
    },
    setup(props) {
        const { parseJson } = useCommonHelpers()
        const patientLocationJson = ()=>{
            const json = parseJson(props.item)

            if(json?.pointOfCare) {
                return json.pointOfCare
            } else {
                return ''
            }
        }


        return {
            patientLocationJson
        }
    },
})

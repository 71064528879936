// @ts-ignore
import _Vue from 'vue'
import RestApiInterface from 'src/interfaces/RestApiInterface'
// import OrganizationInterface from 'src/interfaces/OrganizationInterface'
import RestApiOptionsInterface from 'src/interfaces/RestApiOptionsInterface'
import auth from '../helpers/auth'
// import UserInterface from 'src/interfaces/UserInterface'

const RestApi = {
  install (Vue: typeof _Vue, options: RestApiOptionsInterface) {
    const apiUrl = options.apiUrl || (typeof options.config === 'undefined' ? '' : options.config.apiUrl)
    const toUrlEncoded = (params, keys = [] as any, isArray = false) => {
      const p = Object.keys(params).map(key => {
        const val = params[key]

        if ('[object Object]' === Object.prototype.toString.call(val) || Array.isArray(val)) {
          if (Array.isArray(params)) {
            keys.push('')
          } else {
            keys.push(key)
          }
          return toUrlEncoded(val, keys, Array.isArray(val))
        } else {
          let tKey = key

          if (keys.length > 0) {
            const tKeys = isArray ? keys : [...keys, key]
            tKey = tKeys.reduce((str, k) => {
              return '' === str ? k : `${ str }[${ k }]`
            }, '')
          }
          if (isArray) {
            return `${ tKey }[]=${ val }`
          } else {
            return `${ tKey }=${ val }`
          }

        }
      }).join('&')

      keys.pop()
      return p
    }

    const getAuthHeader = () => {
      // return authorization header with jwt token
      const token = sessionStorage.getItem((typeof options.config === 'undefined' ? '' : options.config.authTokenName))
      if (token) {
        return { Authorization: 'Bearer ' + token }
      } else {
        auth.redirectToAuth()
      }
    }
    const authHeader = () => getAuthHeader()
    const handleResponse = async (response: Response, options) => {
      if (response.status === 401) {
        const json = await response.json()
        if (json?.errors[0]?.code === 'S006') {
          await auth.refreshToken().then(() => {
            throw 'token_refreshed'
          });
          return;
        } else {
          auth.logout()
          throw Error('Unauthorized')
        }
      }

      // below error is to handle AppointmentGridDetails.vue fetching procedures/alerts/studies errors
      // while this component is opened from PatientTimeLine.vue due to practitioner not belonging to appointment or having access to procedure
      // (these appointments are displayed on the timeline as a list of all patient's appointments)
      if(response.status === 403) {
        throw Error('You cannot access this resource')
      }
      if (options && options.handleResponse === false) {
        return response
      }
      if (options && options.blob) {
        return response.blob()
      }

      const json = response.json()
      if (response.status === 200) {
        return json
      } else {
        // @ts-ignore
        throw Error(json.message || response.statusText)
      }
    }

    const getUrl = (endpoint: string, optionsOverride: RestApiOptionsInterface | undefined) => {
      const optionsCombined = Object.assign({}, options, optionsOverride)
      let url = apiUrl
      if (optionsCombined.prefixRoutesWithOrganizationId) {
        if (typeof optionsCombined.store === 'undefined' ||
          typeof optionsCombined.store.state === 'undefined' ||
          typeof optionsCombined.store.state.app === 'undefined'
        ) {
          throw Error('Rest api requires app store to be configured prior to calling an api')
        }

        const activeOrganizationId = optionsCombined.store.getters['app/currentOrganizationId']
        if (typeof activeOrganizationId === 'undefined') {
          throw Error('Rest API requires an active organization to be set up')
        }

        url += '/organizations/' + activeOrganizationId

        // prefix with patient
        if (optionsCombined.prefixRoutesWithPatientId) {
          const currentPatient = optionsCombined.store.getters['app/currentUser']
          if (!currentPatient.id) {
            throw Error('Rest API requires current patient to be set up')
          }
          url += (currentPatient.isTemporary ? '/patients-temporary' : '/patients') + '/' + currentPatient.id
        }

        return url + '/' + endpoint.replace(/^\//, '')
      } else if (optionsCombined.prefixRoutesWithUserId) {
        if (typeof optionsCombined.store === 'undefined' ||
          typeof optionsCombined.store.state === 'undefined' ||
          typeof optionsCombined.store.state.User === 'undefined'
        ) {
          throw Error('Rest api requires User store to be configured prior to calling an api')
        }
        const activeUser = optionsCombined.store.state.User.currentUser || {}
        if (!activeUser.id.trim()) {
          throw Error('Rest API requires an active user to be set up')
        }
        return apiUrl + '/users/' + activeUser.id.trim() + '/' + endpoint.replace(/^\//, '')
      }

      return apiUrl + endpoint
    }

    const pollTimeouts: { [index: string]: ReturnType<typeof setTimeout> } = {}

    // @ts-ignore
    Vue.prototype.$_rest = {
      const: {
        ABORT_ERROR: 20
      },
      get (endpoint, params, successHandler, errorHandler, optionsOverride) {
        let url = getUrl(endpoint, optionsOverride)

        if (typeof params === 'object') {
          const paramsEncoded = toUrlEncoded(params)

          if (paramsEncoded) {
            url += `?${ paramsEncoded }`
          }
        }

        let headers: {} | undefined = {}
        if (!optionsOverride?.dontIncludeAuthHeader) {
          headers = authHeader()
        } else if (optionsOverride?.recaptchaToken) {
          headers['Recaptcha-Token'] = optionsOverride?.recaptchaToken
        }

        const abortController = new AbortController()
        fetch(url, {
          method: 'GET', // or 'PUT'
          signal: abortController.signal,
          headers
        })
          .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              this.get(endpoint, params, successHandler, errorHandler, optionsOverride)
            } else {
              throw error;
            }
          }))
          .then(data => {
            if (typeof successHandler === 'function') {
              successHandler(data)
            }
          })
          .catch(error => {
            if (error?.message !== 'Unauthorized' && typeof errorHandler === 'function') {
              errorHandler(error)
            }
          })
        return abortController
      },
      post (endpoint, data, successHandler, errorHandler, optionsOverride) {
        const url = getUrl(endpoint, optionsOverride)

        if (typeof data !== 'object') {
          data = {}
        }
        const abortController = new AbortController()
        fetch(url, {
          method: 'POST', // or 'PUT'
          signal: abortController.signal,
          headers: {
            ...authHeader(), ...{
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          },
          body: JSON.stringify(data)
        })
          .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              this.post(endpoint, data, successHandler, errorHandler, optionsOverride)
            } else {
              throw error;
            }
          }))
          .then(data => {
            if (typeof successHandler === 'function') {
              successHandler(data)
            }
          })
          .catch(error => {
            if (typeof errorHandler === 'function') {
              errorHandler(error)
            }
          })
        return abortController
      },
      upload (endpoint, data, successHandler, errorHandler, optionsOverride) {
        const url = getUrl(endpoint, optionsOverride)

        if (typeof data !== 'object') {
          data = {}
        }
        const abortController = new AbortController()
        fetch(url, {
          method: 'POST', // or 'PUT'
          signal: abortController.signal,
          headers: {
            ...authHeader()
          },
          body: data
        })
          .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              this.get(endpoint, data, successHandler, errorHandler, optionsOverride)
            } else {
              throw error;
            }
          }))
          .then(data => {
            if (typeof successHandler === 'function') {
              successHandler(data)
            }
          })
          .catch(error => {
            if (error?.message === 'Unauthorized' && typeof errorHandler === 'function') {
              errorHandler(error)
            }
          })
        return abortController
      },
      put (endpoint, data, id, successHandler, errorHandler, optionsOverride) {
        let url = getUrl(endpoint, optionsOverride)

        if (typeof data !== 'object') {
          data = {}
        }

        if (typeof id !== 'undefined') {
          url += '/' + id
        }

        let headers: {} | undefined = {}
        if (!optionsOverride?.dontIncludeAuthHeader) {
          headers = authHeader()
        } else if (optionsOverride?.recaptchaToken) {
          headers['Recaptcha-Token'] = optionsOverride?.recaptchaToken
        }

        const abortController = new AbortController()
        fetch(url, {
          method: 'PUT',
          signal: abortController.signal,
          headers,
          body: new URLSearchParams(toUrlEncoded(data))
        })
          .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              this.put(endpoint, data, id, successHandler, errorHandler, optionsOverride)
            } else {
              throw error;
            }
          }))
          .then(data => {
            if (typeof successHandler === 'function') {
              successHandler(data)
            }
          })
          .catch(error => {
            if (error?.message === 'Unauthorized' && typeof errorHandler === 'function') {
              errorHandler(error)
            }
          })
        return abortController
      },
      remove (endpoint, successHandler, errorHandler, optionsOverride) {
        const url = getUrl(endpoint, optionsOverride)
        const abortController = new AbortController()
        fetch(url, {
          method: 'DELETE', // or 'PUT'
          signal: abortController.signal,
          headers: authHeader()
        })
          .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              this.remove(endpoint, successHandler, errorHandler, optionsOverride)
            } else {
              throw error;
            }
          }))
          .then(data => {
            if (typeof successHandler === 'function') {
              successHandler(data)
            }
          })
          .catch(error => {
            if (error?.message === 'Unauthorized' && typeof errorHandler === 'function') {
              errorHandler(error)
            }
          })
        return abortController
      },
      getConfig () {
        return options.config
      },
      toUrlEncoded (params, keys = [] as any, isArray = false) {
        return toUrlEncoded(params, keys, isArray)
      },
      poll (endpoint, params, successHandler, errorHandler, interval, optionsOverride) {
        // clear timeout if exists
        if (typeof pollTimeouts[endpoint] !== 'undefined') {
          clearTimeout(pollTimeouts[endpoint])
        }

        // if no handler defined, cancel poll
        if (typeof successHandler !== 'function') {
          return
        }

        if (typeof interval === 'undefined') {
          interval = 30
        }

        const handler: () => void = () => {
          let url = getUrl(endpoint, optionsOverride)

          if (typeof params === 'object') {
            const paramsEncoded = toUrlEncoded(params)

            if (paramsEncoded) {
              url += `?${ paramsEncoded }`
            }
          }

          fetch(url, {
            method: 'GET', // or 'PUT'
            headers: authHeader()
          })
            .then(response => handleResponse(response, optionsOverride).catch(error => {
            if (error === 'token_refreshed') {
              handler()
            } else {
              throw error;
            }
          }))
            .then(data => {
              if (typeof successHandler === 'function') {
                successHandler(data)
              }
            })
            .catch(error => {
              if (error?.message === 'Unauthorized' && typeof errorHandler === 'function') {
                errorHandler(error)
              }
            })
            .finally(() => {
              this.poll(endpoint, params, successHandler, errorHandler, interval, optionsOverride)
            })
        }
        pollTimeouts[endpoint] = setTimeout(handler, interval * 1000)
      }
    } as RestApiInterface
  }
}

export default RestApi

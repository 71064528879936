export const gridColumns = {
  appointmentPractices: [
    'appointmentStatusName',
    'appointmentIdShort',
    'patientIdShort',
    'patientNumber1',
    'patientIdExternal',
    'appointmentCategoryName',
    'appointmentPriorityName',
    'patientEpisodeConsultingPractitionerFullName',
    'appointmentSpecialityName',
    'practitionerFullName',
    'secondaryPractitionerFullName',
    'appointmentSourceName',
    'appointmentDateCreated',
    'patientFullName',
    'patientDateOfBirth',
    'appointmentCategoryName',
    'appointmentSourceName',
    'procedureName',
    'clinicNameShort',
    'appointmentDate',
    'startTime',
    'cancelReason'
  ],
  alerts: [
    'alertStatusName',
    'isAppointmentCancelRequested',
    'alertTypeName',
    'createdDatetime',
    'alertBody',
    'appointmentIdShort',
    'patientFullName',
    'patientIdShort',
    'patientNumber1',
    'patientIdExternal',
    'sex',
    'dateOfBirth',
    'appointmentProcedureStatusName',
    'clinicName',
    'procedureName',
    'appointmentDate',
    'startTime'
  ],
  appointments: [
    'appointmentIdShort',
    'appointmentDateCreated',
    'appointmentStatusNameStyled',
    'appointmentPriorityNameStyled',
    'clinicNameShort',
    'appointmentDate',
    'procedureName',
    'startTime',
    'duration',
    'appointmentCategoryNameStyled'
  ],
  episodes:[
    'patientEpisodeNumber',
    'patientEpisodeStatusName',
    'patientFullName',
    'patientIdShort',
    'patientNumber1',
    'patientIdExternal',
    'createdDatetime',
    'referringPractitionerFullName',
    'consultingPractitionerFullName'
  ]
};

<template>
  <v-select
    v-model="model"
    :items="priorities"
    item-value="code"
    item-text="name"
    v-bind="$attrs"
    :loading="loading"
  />
</template>

<script>
export default {
  name: 'PriorityDropdown',
  props: {
    value: {
      type: String
    }
  },
  computed: {
    model: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('change', value)
      }
    },
    priorities () {
      return this.$store.getters['appointmentPriority/list'].filter(el => el.isActive).sort((a, b) => a.displayOrder - b.displayOrder)
    },
    defaultPriority () {
      return this.$store.getters['appointmentPriority/list'].find(priority => priority.isDefault)?.code || 'N'
    }
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    this.getPriorities()
  },
  methods: {
    getPriorities () {
      this.$store.dispatch('appointmentPriority/getPriorities').then(priorities => {
        if (!this.model) {
          this.model = this.defaultPriority
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

<template>
  <vue-recaptcha
    ref="invisibleRecaptcha"
    @verify="verifyRecaptcha"
    @error="showErrorRecaptcha"
    @expired="onExpiredRecaptcha"
    @render="execute"
    size="invisible"
    :sitekey="recaptchaSiteKey()"
    :loadRecaptchaScript="true"
  >
  </vue-recaptcha>
</template>
<script>

import config from '@src/config';
import VueRecaptcha from 'vue-recaptcha';

export default {
  name: 'ReCaptcha',
  components: {
    VueRecaptcha
  },
  methods: {
    verifyRecaptcha(recaptchaToken) {
      this.$emit('verify', recaptchaToken)
    },
    showErrorRecaptcha(message) {
      this.$_notify.error(message)
    },
    onExpiredRecaptcha() {
      this.$refs.invisibleRecaptcha.reset()
    },
    recaptchaSiteKey() {
      return config.recaptchaSiteKey;
    },
    resetRecaptcha() {
      if (this.$refs.invisibleRecaptcha) {
        this.$refs.invisibleRecaptcha.reset()
      }
    },
    execute() {
      this.$refs.invisibleRecaptcha.execute();
    }
  }
}
</script>

import _Vue from 'vue'

const translate = {
  install (Vue: typeof _Vue) {
    Vue.prototype.$_t = function (msg: string, params: any) {
      // use this to turn on translations
      msg = this.$t(msg)
      for (const key in params) {
        msg = msg.replace(new RegExp('{ *' + key + ' *}', 'g'), params[key])
      }
       return msg

      // use this to turn off translations
      //return msg

      // use this to only show untranslated text
      // return '-'
    }
  }
}

export default translate

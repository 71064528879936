<template>
  <v-container
    fluid
  >
    <v-row
      style="height: 100vh;"
      align="center"
      justify="center"
    >
      <v-col
        cols="12"
        align-self="center"
      >
        <v-container>
          <v-row justify="center">
            <v-progress-circular
              :size="128"
              :width="7"
              color="primary"
              :indeterminate="!isError"
            >
              <v-img
                :src="config.favicon"
                max-height="96"
                max-width="96"
                contain
              />
            </v-progress-circular>
          </v-row>
          <v-row
            v-if="!isError"
            justify="center"
          >
            <h1 class="headline mt-5">
              {{ message.message }}
            </h1>
          </v-row>
          <v-row
            v-else
            justify="center"
          >
            <h1 class="headline mt-5 red--text text-center">
              {{ message.message }}
              <br>
            </h1>
          </v-row>
          <v-row v-if="message.link">
            <v-btn color="primary" style="margin:0 auto" :href="message.link" class="mt-5" target="_blank">Appointment centre</v-btn>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import config from '@config'
export default {
  name: 'Loading',
  props: {
    message: {
      type: Object,
      required: true,
      validator: function (value) {
        if (typeof value.status === 'undefined' || typeof value.message === 'undefined') {
          return false
        }

        return ['INFO', 'ERROR', 'LOADING'].includes(value.status)
      }
    }
  },
  data: () => ({
    config: config
  }),
  computed: {
    isError () {
      return this.message.status === 'ERROR'
    }
  },
  methods: {}
}
</script>

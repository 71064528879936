<template>
  <div>
    <v-dialog v-resize="onResize" v-model="dialog" :max-width="windowSize.x * 0.8" @click:outside="$emit('hide', false)">
      <v-container fluid class="pa-md-0" v-if="render">

        <v-card class="pa-md-4 mx-md-auto">
          <v-row>
            <v-col>
              <v-card-title class="text-h5">
                {{$_t('Available columns')}}
              </v-card-title>
              <v-card-text>
                <v-simple-table
                fixed-header
                :height="windowSize.y * 0.7"
                >
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-left">
                          {{$_t('Column name')}}
                        </th>
                        <th class="text-left">
                          {{$_t('On/Off')}}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="(item, index) in getAllColumns" :key="index">
                        <td>{{ item.text }}</td>
                        <td>
                          <v-switch
                            v-model="item.selected"
                            inset
                            @change="columnsSelected(item)"
                          ></v-switch>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-col>
            <v-col>
              <v-card-title class="text-h5">
                {{$_t('Selected columns')}}<br>&nbsp;
              </v-card-title>
              <v-list-item-subtitle class="subtitle-2"> {{$_t('You can drag and drop columns')}}</v-list-item-subtitle>
              <v-spacer></v-spacer>
              <v-card-text>
                 <v-simple-table
                fixed-header
                :height="windowSize.y * 0.7"
                >
                <v-list subheader two-line>
                  <draggable v-model="dragSelected" @end="endDrag">
                  <v-list-item v-for="(item, index) in dragSelected" :key="index" class="border mb-sm-1">
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="getTitle(item)"
                      ></v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action>
                      <v-btn icon>
                        <v-icon color="grey lighten-1" @click="removeSelected(item)">mdi-trash-can</v-icon>
                      </v-btn>
                    </v-list-item-action>
                  </v-list-item>
                  </draggable>
                </v-list>
                 </v-simple-table>
              </v-card-text>
            </v-col>
          </v-row>

          <v-card-actions>
             <v-switch
                v-if="permitSortColumns"
                v-model="sortColumnsOnGrid"
                inset
                :label="$_t('Permit to sort columns directly on grid')"
              >
              </v-switch>
            <v-spacer></v-spacer>
            <v-btn color="secondary" @click="$emit('hide', false)">
              {{$_t('Close')}}
            </v-btn>

            <v-btn color="primary" @click="$emit('save')">
              {{$_t('Save')}}
            </v-btn>
          </v-card-actions>
        </v-card>

      </v-container>
    </v-dialog>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
export default {
  name: 'GridColumns',
   components: {
    draggable,
  },
  props: {
    dialog: Boolean,
    allColumns: Array,
    selectedColumns: Array,
    render:{
      type: Boolean,
      default: true
    },
    permitSortColumns:{
      type:Boolean,
      default:false
    }
  },
  data() {
    return {
      dragSelected:[],
      windowSize: {x:0,y:0}
    };
  },
  computed:{
    getAllColumns() {
      return this.allColumns
    },
    getSelectedColumns: {
    // getter
      get: function () {
        return this.selectedColumns
      },
      // setter
      set: function (newValue) {
        this.selectedColumns = newValue
      }
    },
    sortColumnsOnGrid: {
        get: function () {
          return this.$store.getters['app/getSortColumnsOnGrid']
        },
        set: function (newValue) {
          this.$store.commit('app/setSortColumnsOnGrid',newValue)
        }
      }
  },
  methods: {
    onResize () {
        this.windowSize = { x: window.innerWidth, y: window.innerHeight }
      },
    endDrag(){
      this.$emit('drop',this.dragSelected)
    },
    columnsSelected(item,force=false) {
      if(force) {
        item.selected = false
      }
      if (item.selected) {
        this.selectedColumns.push(item.value)
      } else {
        const index = this.selectedColumns.indexOf(item.value)
        if (index > -1) {
          this.selectedColumns.splice(index, 1)
        }
      }

    },
    removeSelected(item) {
      const index = this.selectedColumns.indexOf(item)
        if (index > -1) {
          this.selectedColumns.splice(index, 1)
        }
    },
    getTitle(item){
      const found = this.allColumns.find(el=>el.value === item)
      return found ? found.text : ''
    }
  },
  watch:{
    getSelectedColumns(val){
      this.dragSelected = [...val]
    }
  },
  mounted(){}
};
</script>
<style scoped>
  .border {
    border: 1px dashed orange !important;
  }
</style>

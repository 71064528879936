<template>
    <v-card class="main-container">
      <v-row>
        <v-spacer />
        <v-btn 
          class="mt-5 mr-5" 
          fab
          small 
          elevation="5"
          @click="$emit('close')"
        >
          <v-icon>
            mdi-close
          </v-icon>
        </v-btn>
      </v-row>
      <v-row justify="center" class="mt-2">
        <v-col cols="12" md="8" lg="6">
              <div class="text-h6 text-left">{{ $_t('Patient Search') }}</div>
              <v-divider />
              <div style="display: flex; align-items: center; width: 100%;">
                <v-text-field
                  v-model="searchVal"
                  clearable
                  @click:clear="searchClear"
                  :label="$_t('Patient search')"
                  prepend-icon="mdi-account-search"
                  @keyup.enter="searchPatients"
                  :loading="loading"
                  :disabled="loading"
                  :placeholder="$_t('Type something and press enter to search')"
                />
                <v-icon class="ml-10" @click="searchPatients">
                  mdi-magnify
                </v-icon>
              </div>
              <div class="text-center text-subtitle-2"  v-if="entries && !entries.length">
                <v-label>
                  {{$_t('No patients found')}}
                </v-label>
              </div>
                <div class="search-results">
                  <v-row v-for="item in items" :key="item.id">
                      <v-list >
                        <v-list-item @click="goToPatientDetails(item)">
                          <v-list-item-action>
                            <v-icon>mdi-account</v-icon>
                          </v-list-item-action>
                          <v-list-item-content>
                            <v-list-item-title
                              v-text="`${item.fullName || ''}${item.number1 ? ' (' +item.number1+')' : ''}, born: ${ item.dateOfBirth ? moment(item.dateOfBirth).format(formatDate) : '' }`"
                            ></v-list-item-title>
                            <v-list-item-subtitle v-text="`${item.fullAddress || ''} ${item.fullPhoneNumbers || ''}`"></v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                  </v-row>
                </div>
        </v-col>
      </v-row>
  </v-card>
</template>

<script>
import moment from 'moment/moment';

export default {
  name: 'PatientSearchDialog',
  data() {
    return {
      entries: undefined,
      searchVal: '',
      loading: false
    }
  },
  computed: {
    moment () {
      return moment
    },
    patient: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('clear-storage')
      }
    },
    items () {
      return this.entries?.map(entry => {
        return this.processPatient(entry)
      })
    },
  },
  methods: {
    searchPatients () {
      if (!this.searchVal || this.searchVal.length < 2) {
        this.entries = undefined
        return
      }
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/patients`, {
        groups: '',
        search: this.searchVal
      }, data => {
        this.loading = false

        if(data.data.length){
          this.$emit('search', { searchVal: this.searchVal, data: data.data})
          this.entries = data.data
        } else {
          this.entries = []
        }

      }, error => {
        this.loading = false
        this.$_notify.error(this.$_t('Error searching for patients'))
      })
    },
    searchClear(){
      this.entries = undefined
    },
    goToPatientDetails(patient){
      this.$store.commit('patient/setPatient', patient)
      this.$router.push({name:'PatientDetails', params: {patientId: patient.id}})
      this.$store.dispatch('app/setShowSearchDialog', false)
    },
    processPatient(patientData) {
      patientData.fullName = [patientData.title, patientData.firstName, patientData.lastName].filter(el => el).join(' ')
      patientData.fullAddress = [patientData.address1, patientData.address2, patientData.address3, patientData.postCode, patientData.town].filter(el => el).join(', ')
      const phoneNumbers = [patientData.phoneNumber1, patientData.phoneNumber2].filter(el => el)
      patientData.fullPhoneNumbers = phoneNumbers.length ? '| phone: ' + phoneNumbers.join(', ') : ''

      return patientData
    }
  }
}
</script>

<style >
.main-container{
  height: 80vh;
  width: 100vw;
  overflow:hidden;
}
.search-results{
  max-height: calc(80vh - 120px);
  overflow: auto;
  overflow-x: hidden;
}
</style>

<template>
  <practitioner-layout>
    <router-view />
    <v-container fluid>
      <v-progress-linear
        v-if="loading"
      />
      <v-row v-else>
        <v-col
          cols="12"
          md="4"
          lg="6"
        >
              <PatientTimeline
                :patient-id="patientId"
              />
        </v-col>
      </v-row>
    </v-container>
  </practitioner-layout>
</template>

<script>
import PatientTimeline from '@components/patient/PatientTimeLine.vue'
import PractitionerLayout from '@layouts/practitioner.vue';

export default {
  name:'PatientDetails',
  components: { PractitionerLayout, PatientTimeline},
  data(){
    return{
      patient: {},
      loading: false,
      patientDetailsFetch: undefined
    }
  },
  computed: {
    patientId(){
      return this.$route.params.patientId
    }
  },
  methods:{
    getPatientDetails(){
      this.loading = true
      const patientId = this.patientId
      if(this.patientDetailsFetch){
        this.patientDetailsFetch.abort()
      }
      this.patientDetailsFetch = this.$_rest.get(`/practitioners/${this.$store.getters['app/currentUserId']}/patients/${patientId}`,{
      }, response => {
        this.patient = response.data
        this.loading = false
      }, error => {
        if (error?.code !== this.$_rest.const.ABORT_ERROR) {
          this.$_notify.error(this.$_t('Error fetching patient details'))
        }
        this.loading = false
      })
    }
  },
  watch: {
    patientId(value){
      this.getPatientDetails()
    },
    patient(patient){
      this.$store.dispatch('patient/setPatient', patient)
        .then(() => {
        this.$store.commit('app/patientBannerShow',true)
        })
    }
  },
  mounted() {
   this.getPatientDetails()
  }
}
</script>

<style>

.d-flex.flex-column {
  display: flex;
  flex-direction: column;
}
</style>

import Vue from 'vue'
import Vuex from 'vuex'
import app, { AppState } from './modules/app'
import appointment from './modules/appointment'
import notification,{NotificationState} from './modules/notification'
import practitioner from './modules/practitioner'
import alerts,{AlertsState} from './modules/alerts'
import patient from './modules/patient'
import booking from './modules/booking'
import appointmentPriority from './modules/appointmentPriority'


Vue.use(Vuex)
export interface State {
  alerts: AlertsState;
  notification: NotificationState;
  app: AppState;
}

export default new Vuex.Store<State>({
  modules: {
    app,
    appointment,
    notification,
    practitioner,
    alerts,
    patient,
    booking,
    appointmentPriority
  },
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production'
})

import { getCurrentInstance } from '@vue/composition-api'
import { format,differenceInYears,fromUnixTime } from 'date-fns'


export default function () {
  const vm = getCurrentInstance() as any
  const formatDate = 'dd/MM/yyyy'
  const formatDateSql = 'yyyy-MM-dd'
  const formatDateTimeSql = 'yyyy-MM-dd HH:mm'
  const formatDateTime = 'dd/MM/yyyy HH:mm'
  const formatDateTimeSec = 'dd/MM/yyyy HH:mm:ss'

  const convertDate = (date: string | Date)=> {
    return new Date(date)
  }

  const useSqlDate = (dateString: string) => {
    return format(new Date(dateString),formatDateSql);
  }

  const useSqlDateTime = (dateString: string) => {
    return format(new Date(dateString),formatDateTimeSql);
  }

  const useHumanDate = (dateString: string) => {
    if (!dateString) {
      return ''
    }
    if (dateString.includes('T')) {
      dateString = dateString.slice(0, 19).replace('T', ' ')
      return format(new Date(dateString.replace(/-/g, '/')), formatDate)
    }
    return format(new Date(dateString),formatDate);
  }

  const useHumanDateTime = (dateString: string) => {
    if (!dateString) {
      return ''
    }
    if (dateString.includes('T')) {
      dateString = dateString.slice(0, 19).replace('T', ' ')
      return format(new Date(dateString.replace(/-/g, '/')), formatDateTime)
    }
    return format(new Date(dateString), formatDateTime)
  }

  const useHumanDateTimeSec = (dateString: string) => {
    if (!dateString) {
      return ''
    }
    if (dateString.includes('T')) {
      dateString = dateString.slice(0, 19).replace('T', ' ')
      return format(new Date(dateString.replace(/-/g, '/')), formatDateTimeSec)
    }
    return format(new Date(dateString), formatDateTimeSec)
  }

  const useHumanDateFromTimestamp = (dateString: number) => {
    const result = fromUnixTime(dateString /1000)
    return format(result,formatDate);
  }
  const useHumanDateTimeFromTimestamp = (dateString: number) => {
    const result = fromUnixTime(dateString /1000)
    return format(result,formatDateTime);
  }
  const useHumanDateTimeSecFromTimestamp = (dateString: number) => {
    const result = fromUnixTime(dateString /1000)
    return format(result,formatDateTimeSec);
  }

  const useDifferenceInYears = (dateString1: string | Date,dateString2: string | Date) => {
    return differenceInYears(convertDate(dateString1), convertDate(dateString2))
  }

  return {
    useHumanDate,
    useHumanDateTime,
    useHumanDateTimeSec,
    useDifferenceInYears,
    useHumanDateFromTimestamp,
    useHumanDateTimeFromTimestamp,
    useHumanDateTimeSecFromTimestamp,
    useSqlDate,
    useSqlDateTime
  }
}

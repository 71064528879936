import { render, staticRenderFns } from "./Appointment.vue?vue&type=template&id=c161e8ac&scoped=true&"
import script from "./Appointment.vue?vue&type=script&lang=js&"
export * from "./Appointment.vue?vue&type=script&lang=js&"
import style0 from "./Appointment.vue?vue&type=style&index=0&id=c161e8ac&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c161e8ac",
  null
  
)

export default component.exports
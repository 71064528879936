<template>
  <v-container fluid>
    <grid
      :data="items"
      :sort-by="sortBy"
      :loading="loading"
      :showAddRow="false"
      :headers-preset="headersPreset"
      :headers-preset-type="headersPresetType"
      hide-filters
      hide-search
      @rowAdd="rowAdd"
      @refresh="$emit('refresh',true)"
      @rowClick="rowClicked"
    ></grid>
  </v-container>
</template>

<script>
import Grid from '@components/common/Grid.vue'
import appointmentsHeadersPreset from '@src/data/dataset/gridHeaders/appointments';


export default {
  name: 'PatientAppointmentGridComponent',
  components: { Grid },
  props: {
    rightDrawer: {
      type: Object
    },

    columnsGrid:{
      type:Array,
      default:()=>[]
    }
  },
  data () {
    return {
      items: [],
      searchResult: {},
      sortBy: ['clinicName'],
      loading: false,
      headersPresetType:'appointments',
      headersPreset:appointmentsHeadersPreset
    }
  },
  computed:{

  },
  methods: {
    getData () {
      this.loading = true
      this.$_rest.get(`/practitioners/${ this.$store.getters['app/currentUserId'] }/datasets/appointments`, {
        filter: {
          practitionerPracticeId: this.$store.getters['app/practitionerPractice'].id,
          patientId: { x: this.rightDrawer.patientId, type: 'eq' }
        }
      }, response => {
        this.loading = false
        this.items = this.mapDatasetResponse(response)
      }, error => {
        this.loading = false
        this.$_notify.error(error)
      })
    },
    rowAdd () {},
    rowClicked () {},
    assignData(){
      if(this.rightDrawer.data.length > 0){
      this.items = [...this.rightDrawer.data]
    } else {
      this.getData()
    }
    }
  },
  watch:{
    rightDrawer:{
      deep: true,
      handler(val){
        this.loading = val.loading
          this.assignData()
      }
    }
  },
  mounted () {
    this.assignData()
  }
}
</script>

<style scoped>

</style>

<template>
  <RouteDialog
    :allow-full-screen="true"
    @fullScreenChange="fullScreenChange"
    @close="close"
  >
    <v-container fluid>
      <v-row>
        <v-col :cols="isFullScreen ? 6 : 12">
          <v-card class="mx-2 mt-5">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-textarea
                    v-if="checkRequiredNote"
                    counter
                    :label="`${$_t('Add note')} *`"
                    v-model="note"
                  ></v-textarea>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="8" class="pa-2">
                  <v-btn
                    v-if="checkAlertStatus"
                    class="mr-2"
                    @click="saveAlert('ACKNOWLEDGED')"
                    color="primary"
                    outlined
                    :loading="buttonLoading"
                    :disabled="!note && checkRequiredNote"
                  >
                    <v-icon left>
                      mdi-check
                    </v-icon>
                    {{ checkRequiredNote ? $_t('Acknowledge and Reply') : $_t('Acknowledge')}}
                  </v-btn>
                  <v-btn v-if="cancelAllowed"
                         class="mr-2"
                         @click="cancelAppointment"
                         color="primary"
                         :loading="buttonLoading"
                         :disabled="cancelDisabled"
                  >
                    <v-icon
                      left
                    >
                      mdi-check
                    </v-icon>
                    {{ $_t('Cancel Appointment')}}
                  </v-btn>
                  <v-btn
                    v-if="checkAppointmentStatus"
                    @click="saveCallQueue"
                    color="secondary"
                    :loading="buttonLoading"
                    :disabled="!note && checkRequiredNote"
                  >
                    <v-icon
                      left
                    >
                      mdi-check
                    </v-icon>
                    {{ $_t('Request Cancellation') }}
                  </v-btn>
                </v-col>
                <v-col cols="4" class="pa-2">
                </v-col>
              </v-row>
              <v-row v-if="shouldShowReport">
                <v-col cols="12" >
                  <v-card min-height="800px">
                    <appointment-report-preview
                      :fetchRoute="reportFetchRoute"
                      :row="activeRow"
                    />
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="activeRow && activeRow.appointmentId">
          <v-row>
            <v-col>
              <appointment-document-list
                :appointment-id="activeRow.appointmentId"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col :cols="isFullScreen ? 6 : 12">
          <grid
            vertical
            dense
            :data="activeRowData"
            :headers-preset-type="headersPresetType"
            :headers-preset="headersPreset"
            hide-filters
            hide-search
            :loading="loading"
          ></grid>
        </v-col>
      </v-row>
    </v-container>
    <template v-slot:toolbar>
      <!-- TODO the button below is temporary disabled because @paulw: "this is not yet fully implemented" -->
      <v-btn
        v-if="false"
        outlined
        dark
        color="black"
        :loading="loading"
        class="mr-2 ml-1"
        @click="nextAlert"
      >
        {{ $_t('Next') }}
        <v-icon></v-icon>
      </v-btn>
    </template>
  </RouteDialog>
</template>
<script>
import RouteDialog from '@components/core/RouteDialog.vue'
import { mapGetters } from 'vuex'
import alertsHeadersPreset from '@src/data/dataset/gridHeaders/alerts';
import Grid from '@components/common/Grid.vue'
import config from '@config'
import AppointmentReportPreview from '@components/appointment/AppointmentReportPreview.vue'
import { isEmpty} from 'lodash'
import AppointmentDocumentList from '@components/appointment/DocumentList.vue'

const alertStatuses = ['A']
const appointmentStatuses = ['N','RI','AC']


export default {
  name:'AlertGridDetails',
  components: {AppointmentDocumentList, RouteDialog, Grid, AppointmentReportPreview },
  data () {
    return {
      dialog: true,
      headersPresetType: 'alerts',
      headersPreset: alertsHeadersPreset,
      activeRow: null,
      buttonLoading: false,
      alertId: null,
      loading: false,
      note: null,
      isAdvanceAppointment: true,
      isFullScreen: false
    }
  },
  props:{
    id:{
      type:String,
      default:null
    }
  },
  computed: {
    ...mapGetters('alerts', {items: 'data' }),
    ...mapGetters({
      alertColumns: 'app/columns'
    }),
    activeRowData() {
      if (this.activeRow) {
        return [this.activeRow]
      }
      return []
    },
    checkAlertStatus() {
      return this.activeRow && !alertStatuses.includes(this.activeRow.alertStatusCode)
    },
    checkAppointmentStatus() {
      if(config.callPurposeApponitmentCancelRequestId && config.callSourceApponitmentCancelRequestId) {
        return this.activeRow && appointmentStatuses.includes(this.activeRow.appointmentStatusCode) && this.toBoolean(this.activeRow.isAppointmentCancelRequested)
      }
      return false
    },
    checkRequiredNote() {
      if(this.checkAppointmentStatus && this.activeRow && this.toBoolean(this.activeRow.isNoteRequired)) {
        return true
      } else if(this.checkAlertStatus && this.activeRow && this.toBoolean(this.activeRow.isNoteRequired)) {
        return true
      } else {
        return false
      }
    },
    reportFetchRoute() {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/reports/${this.activeRow.appointmentProcedureReportId}/file`
    },
    shouldShowReport() {
      return this.activeRow && this.activeRow.appointmentStatusCode && this.activeRow.appointmentProcedureStatusCode === 'FAP'
    },
    cancelAllowed () {
      return this.activeRow &&
        (this.$store.getters['app/currentUser'].organization.config.referrerPortalSettings?.allowAppointmentCancellationForStatusCodes || [])
          .includes(this.activeRow.appointmentStatusCode)
    },
    cancelDisabled () {
      return false
    }
  },
  methods:{
    fullScreenChange(isFullScreen) {
      this.isFullScreen = isFullScreen
    },
    getNote() {
       if(this.note) {
        return {note:this.note,appointmentId:this.activeRow.appointmentId}
      }
      return {}
    },
    close() {
      this.$router.push('/practitioner/alerts')
    },
    saveCallQueue() {
      this.loading = true
      this.buttonLoading = true
      this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/request-cancel`,
          {
            callPurposeId: config.callPurposeApponitmentCancelRequestId,
            callSourceId: config.callSourceApponitmentCancelRequestId,
            alertRecipientId: this.activeRow.alertRecipientId
          },
          undefined,
          () => {
            this.buttonLoading = false
            this.loading = false
            this.$_notify.success(this.$_t('Cancellation request was successfully sent'))
            this.$root.$emit('refresh-alerts-grid','RequestCancel')
            if(this.checkRequiredNote) {
              this.isAdvanceAppointment = false
              this.saveAlert()
              return
            }
            this.$router.back()
          },
          error => {
            this.$_notify(error)
            this.buttonLoading = false
            this.loading = false
          }
        )

      setTimeout(() => {
        this.buttonLoading = false
      }, 2000);
    },
    saveAlert(action = 'CANCELLATION REQUESTED') {
      this.buttonLoading = true
      this.loading = true
      this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/practitioner'].id }/alert-recipients`,
          {
            isRead: 1,
            practitionerPractice:this.$store.getters['app/practitionerPractice']?.id,
            action,
            ...this.getNote()
          },
          this.activeRow.alertRecipientId,
          (res) => {
            if (this.isAdvanceAppointment) {
              this.advanceAppointment().then(() => {
                  this.buttonLoading = false
                  this.loading = false
                  isEmpty(this.getNote()) ? this.$_notify.success(this.$_t('Alert has been successfully acknowledged')) : this.$_notify.success(this.$_t('Alert acknowledgement and note have been successfully sent'))
                  this.$root.$emit('refresh-alerts-grid','Acknowledged')
                  this.$router.back()

              }).catch(error=>{
                this.buttonLoading = false
                this.loading = false
              })
            } else {
                  this.buttonLoading = false
                  this.loading = false
                  isEmpty(this.getNote()) ? this.$_notify.success(this.$_t('Alert has been successfully acknowledged')) : this.$_notify.success(this.$_t('Alert acknowledgement and note has been successfully sent'))
                  this.$root.$emit('refresh-alerts-grid','Acknowledged')
                  this.isAdvanceAppointment = true;
                  this.$router.back()
            }
          },
          error => {
            this.$_notify(error)
            this.buttonLoading = false
            this.loading = false
          }
        )
    },
    advanceAppointment () {
      return new Promise((resolve, reject) => {
        this.$_rest.put(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/advance`,
          {},
          undefined,
          data => resolve(data),
          error => reject(error)
        )
      })
    },
    cancelAppointment () {
      this.buttonLoading = true
      return new Promise((resolve, reject) => {
        this.$_rest.post(
          `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${ this.activeRow.appointmentId }/cancel`,
          {
            cancelNote: 'Cancelled by referrer in Referrer Portal'
          },
          data => {
            this.buttonLoading = false
            this.loading = false
            this.$_notify.success(this.$_t('Appointment successfully cancelled'))
            this.$root.$emit('refresh-alerts-grid','Cancel')
            if(this.checkRequiredNote) {
              this.isAdvanceAppointment = false
              this.saveAlert()
              return
            }
            this.$router.back()
            resolve(data)
          },
          error => {
            this.buttonLoading = false
            reject(error)
          }
        )
      })
    },
    setParams(){
      this.alertId = typeof this.$route.params.id !== 'undefined' ? this.$route.params.id : this.id
      return this.alertId ? true : false
    },
    processRouteParam() {
      if(this.setParams()){
        this.activeRow = this.items.find(item=>item.alertId === this.alertId)
        this.buttonLoading = false
        this.loading = false
      } else {
        this.$_notify.error(this.$_t('Wrong params'))
        this.loading = false
      }
    },
    nextAlert() {
      const activeRowIndex = this.items.findIndex(item => item.alertId === this.alertId)

      if (activeRowIndex && typeof this.items[activeRowIndex + 1] !== 'undefined') {
        this.$router.push('/practitioner/alerts/alert-details/' + this.items[activeRowIndex + 1].alertId)
      } else {
        this.close()
      }
    }
  },
  watch:{
    '$route.params.id'(val) {
      this.processRouteParam()
    },
    items(val){
      this.loading = true
       if(val.length) {
        this.processRouteParam()
      }
    }
  },
  mounted(){
    this.processRouteParam()
  },
  created(){
    this.loading = true
  }
}
</script>

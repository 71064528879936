<template>
  <v-main>
    <!--  Desktop version  -->
    <v-app-bar
      v-if="this.$vuetify.breakpoint.smAndUp"
      app
      :class="theme('appBarClass')"
      :color="theme('appBarColor')"
      elevation="0"
    >
      <v-img v-if="config.appLogo" :src="config.appLogo" max-height="50" max-width="150" contain />
      <v-toolbar-title v-if="this.$vuetify.breakpoint.mdAndUp">{{ config.appTitle }}</v-toolbar-title>
      <v-btn :color="theme('appBarButtonColor')" @click="goTo('PatientAppointments')" text class="text-capitalize ml-md-12" :style="activeMenuItemStyle('PatientAppointments')">
        {{ $_t('My Visits') }}
      </v-btn>
      <v-btn v-if="config.faqLink" :color="theme('appBarButtonColor')" @click="open(config.faqLink)" text class="text-capitalize" :style="activeMenuItemStyle('PatientFaq')">
        {{ $_t('F.A.Q.') }}
      </v-btn>
      <v-btn :color="theme('appBarButtonColor')" @click="goTo('PatientProfile')" text class="text-capitalize" :style="activeMenuItemStyle('PatientProfile')">
        {{ $_t('My Profile') }}
      </v-btn>
      <v-btn v-if="!config.disableSettings" :color="theme('appBarButtonColor')" @click="goTo('PatientSettings')" text class="text-capitalize" :style="activeMenuItemStyle('PatientSettings')">
        {{ $_t('Settings') }}
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn :color="theme('appBarButtonColor')" @click="logOut()" text class="text-capitalize">
        {{ $_t('Logout') }}
        <v-icon class="ml-2">mdi-logout-variant</v-icon>
      </v-btn>
    </v-app-bar>
    <!--  Mobile version  -->
    <v-app-bar
      v-if="this.$vuetify.breakpoint.xsOnly"
      app
      :class="theme('appBarClass')"
      :color="theme('appBarColor')"
      elevation="0"
    >
      <v-img v-if="config.appLogo" :src="config.appLogo" max-height="50" max-width="150" contain />
      <v-toolbar-title>{{ config.appTitle }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-menu
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            icon
            v-bind="attrs"
            v-on="on"
            :color="theme('appBarButtonColor')"
            class="ml-2 icon-hover"
          >
            <v-icon>mdi-menu</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="goTo('PatientAppointments')">
            <v-list-item-title>{{$_t('My Visits')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="open(config.faqLink)" v-if="config.faqLink">
            <v-list-item-title>{{$_t('F.A.Q.')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goTo('PatientProfile')">
            <v-list-item-title>{{$_t('My Profile')}}</v-list-item-title>
          </v-list-item>
          <v-list-item @click="goTo('PatientSettings')" v-if="!config.disableSettings">
            <v-list-item-title>{{$_t('Settings')}}</v-list-item-title>
          </v-list-item>
          <v-list-item
            @click="logOut()"
          >
            <v-list-item-title>{{$_t('Logout')}}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
    <v-container class="ma-0 px-0 pt-0" fluid>
      <v-card
        elevation="0"
        :class="theme('appCardClass')"
        :style="theme('appCardStyle')"
      >
        <v-container>
          <v-card-title class="headline font-weight-bold" v-if="$vuetify.breakpoint.smAndUp" style="padding-top: 30px">
            {{ title }}
            <v-spacer></v-spacer>
            <v-btn v-if="actionTitle && (actionHref || actionLink)" color="primary" class="text-none px-sm-12" @click="actionClick">{{$_t(actionTitle)}}</v-btn>
          </v-card-title>
          <v-card-title class="justify-center" v-else>
            <v-btn v-if="actionTitle && (actionHref || actionLink)" color="primary" class="text-none mb-2" @click="actionClick">{{$_t(actionTitle)}}</v-btn>
            <div style="width: 100%" class="text-center">{{ title }}</div>
          </v-card-title>
          <v-card-subtitle class="white--text mx-md-12"></v-card-subtitle>
        </v-container>
      </v-card>
      <v-container>
        <v-card
          ref="appEl"
          class="mx-md-4"
          :style="{ position: 'relative', 'margin-top': '120px' }"
        >
          <slot></slot>
        </v-card>
      </v-container>
    </v-container>
  </v-main>
</template>

<script>
import auth from '@src/helpers/auth';
import config from '@src/config';

export default {
  name: 'PatientLayout',
  props: {
    title: String,
    actionLink: String,
    actionTitle: String,
    actionHref: String
  },
  methods: {
    logOut() {
      auth.logout();
    },
    goTo(destination) {
      this.$router.push({name: destination})
    },
    open(url) {
      window.open(url)
    },
    actionClick() {
      if (this.actionHref) {
        window.open(this.actionHref, '_blank');
      } else {
        this.goTo(this.actionLink);
      }
    },
    theme (key) {
     switch (key) {
        case 'appBarClass':
          return typeof this.customTheme['appBarClass'] !== 'undefined' ? this.customTheme.appBarClass : 'background-gradient-bar white--text font-weight-bold'
        case 'appBarButtonColor':
          return typeof this.customTheme['appBarButtonColor'] !== 'undefined' ? this.customTheme.appBarButtonColor : 'white'
        case 'appBarButtonActiveStyle':
          return typeof this.customTheme['appBarButtonActiveStyle'] !== 'undefined' ? this.customTheme.appBarButtonActiveStyle : 'border-bottom: 1px solid white; border-radius: 0'
        case 'appCardClass':
          return typeof this.customTheme['appCardClass'] !== 'undefined' ? 'app-card ' + this.customTheme.appCardClass : 'app-card background-gradient white--text'
        default:
          return typeof this.customTheme[key] !== 'undefined' ? this.customTheme[key] : ''
      }
    },
    activeMenuItemStyle (name) {
      return this.currentLink === name ? this.theme('appBarButtonActiveStyle') : ''
    }
  },
  created () {
    this.currentLink = this.$router.currentRoute.name
    this.customTheme = config.getTheme()
  },
  data () {
    return {
      config: config,
      currentLink: null,
      customTheme: {}
    }
  }
};
</script>

<style scoped>
.background-gradient {
  background: radial-gradient(
    circle at 0,
    #31d4ff 25%,
    #3295ff 100%,
    #808080 100%
  );
  opacity: 0.81;
}

.background-gradient-bar {
  background: radial-gradient(
    circle at 0,
    #31d4ff -25%,
    #3295ff 100%,
    #808080 100%
  );
  opacity: 1;
}

.app-card {
  position: absolute;
  width: 100%;
  height: 200px;
  border-top-left-radius: 0;
  border-top-right-radius: 0
}
</style>

<template>
<div>
    <v-dialog
        v-model="dialog"
        transition="slide-x-reverse-transition"
        scrollable
        :max-width="maxWidth"
        content-class="my-custom-dialog"
        open-delay="500"
      >
        <v-card tile style="display: inline-table; height: 100%">
          <v-toolbar
            flat
            dark
            color="white"
          >
            <slot name="toolbar" />
            <v-spacer></v-spacer>
            <v-btn
              v-if="allowFullScreen"
              outlined
              icon
              fab
              small
              dark
              @click="isFullScreen = !isFullScreen"
              color="black"
              class="mr-2"
            >
              <v-icon>{{ isFullScreen ? 'mdi-fullscreen-exit': 'mdi-fullscreen' }}</v-icon>
            </v-btn>
            <v-btn
              outlined
              icon
              fab
              small
              dark
              @click="dialog = false"
              color="black"
              class="mr-0"
            >
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <div>
            <slot></slot>
          </div>
        </v-card>
      </v-dialog>
</div>

</template>
<script>
export default {
  name:'RouteDialog',
  props: {
    allowFullScreen: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    maxWidth() {
      return this.isFullScreen || this.$vuetify.breakpoint.mdAndDown ? '100%' : '60%'
    }
  },
  data () {
    return {
      dialog: true,
      isFullScreen: false
    }
  },
  watch: {
    dialog(val){
      if(!val){
        this.$emit('close')
      }
    },
    isFullScreen(val) {
      this.$emit('fullScreenChange', val)
    }
  },
  mounted(){
    this.$root.$on('close-dialog',()=>{
      this.dialog = false
    })
  }
}
</script>
<style>
.my-custom-dialog {
   position: absolute;
    top: 0;
    right: 0;
    margin:0 !important;
    height:100vh;

}
.v-dialog:not(.v-dialog--fullscreen) {
    max-height: 100% !important;
}
.v-toolbar {
  flex:none !important;
}
</style>

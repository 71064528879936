<template>
  <div class="pa-2 text-center home-text-2 slots__not-available">
    <v-icon color="red"> mdi-alert-octagon-outline</v-icon>
    <div>
      <div v-if="iterationsOnSearchNextSlotsLimitReached" class="text-center font-weight-bold text-subtitle-1">
        <h3 >
          {{ $_t('We could not find availability within next { iterations } days', { iterations: iterationsOnSearchNextSlotsLimitDays }) }}.
          {{ $_t('Please continue to search further times and dates or consider checking alternative locations for booking') }}.
        </h3>
      </div>
      <div v-else>
        <h3 class="text-center text-subtitle-1">
        {{  $_t('There is no availability in this time slot please continue to search alternative times and dates.') }}
        </h3>
      </div>
    </div>
  </div>
</template>

<style scoped>
.slots__not-available {
  color: rgba(0, 0, 0, 0.5);
  border: 2.5px solid rgb(243, 10, 10);
  border-radius: 8px;
}
img {
  color: red;
}
</style>

<script>
import Vue from 'vue';

export default Vue.extend({
  name: 'SlotsNotAvailable',
  props: ['iterationsOnSearchNextSlotsLimitReached', 'iterationsOnSearchNextSlotsLimitDays']
});
</script>
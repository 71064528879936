<template>
  <v-container fluid>
    <div v-if="list.length > 0 && !loader">
      <v-list subheader two-line>
        <v-subheader><h4>{{$_t('Procedures')}}</h4></v-subheader>
        <v-list-group
          v-for="item in list"
          :key="item.id"
          value="true"
          no-action
        >
          <template v-slot:activator>
            <v-list-item-avatar v-if="item.filteredReports || item.filteredReports.length > 0">
              <v-icon dark>
                mdi-file-document
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-avatar v-else>
                <v-icon class="" dark>
                  mdi-file-document
                </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title
                v-text="item.procedure.name"
              ></v-list-item-title>
              <v-list-item-subtitle
                v-text="item.procedure.code"
              ></v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                v-if="false"
                @click.stop="openImages"
                outlined
                color="primary"
              >
                <v-icon left color="primary">mdi-eye</v-icon>
                {{ $_t('Images') }}
              </v-btn>
            </v-list-item-action>
          </template>
          <template v-if="item.filteredReports && item.filteredReports.contentFileDirectory && item.filteredReports.contentFileName">
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title>
                  {{ $_t('Report') }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $_t('Approved Date') }}: {{ useHumanDateTime(item.filteredReports.approvedDatetime) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action v-if="!displayReport">
                <v-btn
                  @click="$emit('show-report',item.filteredReports.id)"
                  outlined
                  color="primary"
                >
                  <v-icon left color="primary">mdi-eye</v-icon>
                  {{ $_t('View') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-list-item v-if="appointmentId && filterAlertsPerAppointmentProcedure(item).length">
              <v-list-item-content>
                <v-list-item-title>
                  {{ $_t('Alerts') }}
                </v-list-item-title>
                <appointment-alert-list
                  :list="filterAlertsPerAppointmentProcedure(item)"
                  @acknowledged="alertAcknowledged"
                />
              </v-list-item-content>
            </v-list-item>
            <v-list-item v-if="displayReport">
              <v-list-item-content class="px-1">
                <v-card min-height="800px">
                  <v-card-text>
                    <appointment-report-preview
                      :fetchRoute="reportFetchRoute(item.filteredReports)"
                      :row="item"
                    />
                  </v-card-text>
                </v-card>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list-item v-else-if="item.filteredReports">
            <v-list-item-content>
              <v-list-item-title>
                {{ $_t('Report not available for download, contact the clinic') }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item v-if="item.study">
            <v-list-item-content>
              <v-list-item-title>
                {{ $_t('Examination') }}
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-btn
                @click="generateViewerLink(
                  item.appointmentId,
                  item.id,
                  item.study.id
                )"
                outlined
                color="primary"
              >
                <v-icon left color="primary">mdi-eye</v-icon>
                {{ $_t('View') }}
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-group>
      </v-list>
    </div>
    <v-progress-linear
      v-if="loader"
      indeterminate
      color="primary"
    ></v-progress-linear>
  </v-container>
</template>

<script>
import useDateHelpers from '../../helpers/useDateHelpers';
import config from '@src/config'
import AppointmentReportPreview from '@components/appointment/AppointmentReportPreview.vue'
import AppointmentAlertList from '@components/appointment/AlertList.vue'

const { useHumanDateTime } = useDateHelpers()
export default {
  name: 'AppointmentProcedureList',
  components: { AppointmentAlertList, AppointmentReportPreview },
  props: {
    appointmentId: {
      type: String,
      default: ''
    },
    list: {
      type: Array,
      default: () => [],
    },
    alerts: {
      type: Array,
      default: () => [],
    },
    loader: Boolean,
    displayReport: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      useHumanDateTime: useHumanDateTime,
      viewerLinkLoading: false,
      loading: false
    }
  },
  methods: {
    filterAlertsPerAppointmentProcedure(appProc) {
      if (!this.alerts || !Array.isArray(this.alerts)) {
        return []
      }
      return this.alerts.filter(alert => alert.appointmentProcedureId === appProc.id)
    },
    getAcknowledgedData(alert){
      return alert.alertRecipients.filter(recipient => recipient.isRead === 1)
    },
    alertEligibleToAcknowledgement(alert) {
      return alert.status !== 'A' && this.getCurrentUserAlertRecipient(alert)
    },
    generateViewerLink (appointmentId, appProcId, appointmentProcedureStudyId) {
      this.viewerLinkLoading = true
      const pacsViewerCode = (config.ignorePacsViewerCode) ? 'ORIGINAL' : config.pacsViewerCode;
      this.$_rest.get(
        `/practitioners/${this.$store.getters['app/currentUserId']}/appointments/${appointmentId}/appointment-procedures/${appProcId}/appointment-procedure-studies/${appointmentProcedureStudyId}/viewer-link/${pacsViewerCode}`,
        {},
        resp => {
          if (resp.data.viewerUrl) {
            window.open(resp.data.viewerUrl, '_blank')
          } else {
            this.$_notify.error('Could not generate a viewer link')
          }
          this.viewerLinkLoading = false
        }
      )
    },
    openImages() {
      window.open('https://j4care.aptvision.com/SMooTHViewer/launch.jsf?accessId=n6ijxQICunDgec7NPyFoEXbBuNVxIx3thvCyPgP1WPvARJJCcM2btJNqYywt3e0NPxISy0mcqRJU1WWFyuu4EehxZpYJxYp4mdSOICm3E6CtVFaLYkHZajvt2pXPfZCK&viewerType=SimpleViewer')
    },
    reportFetchRoute(item) {
      return `/practitioners/${ this.$store.getters['app/currentUserId'] }/appointments/${this.appointmentId}/reports/${item.id}/file`
    },
    getCurrentUserAlertRecipient(alert) {
      return alert.alertRecipients?.find(recipient => !recipient.isRead && recipient.practitionerPractice?.id === this.$store.getters['app/practitionerPractice']?.id)
    },
    alertAcknowledged(alert) {
      this.$emit('acknowledged', alert)
    }
  }
};
</script>

<style scoped></style>

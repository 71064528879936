var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit($event)}}},[_c('validation-provider',{attrs:{"name":_vm.$_t('First Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$_t('First Name'),"required":""},model:{value:(_vm.contactDataCopyLocal.firstName),callback:function ($$v) {_vm.$set(_vm.contactDataCopyLocal, "firstName", $$v)},expression:"contactDataCopyLocal.firstName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$_t('Last Name'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$_t('Last Name'),"required":""},model:{value:(_vm.contactDataCopyLocal.lastName),callback:function ($$v) {_vm.$set(_vm.contactDataCopyLocal, "lastName", $$v)},expression:"contactDataCopyLocal.lastName"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$_t('Phone Number'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"label":_vm.$_t('Phone Number'),"required":""},model:{value:(_vm.contactDataCopyLocal.mobileNumber1),callback:function ($$v) {_vm.$set(_vm.contactDataCopyLocal, "mobileNumber1", $$v)},expression:"contactDataCopyLocal.mobileNumber1"}})]}}],null,true)}),_c('validation-provider',{attrs:{"name":_vm.$_t('Contact Type'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.contactTypes,"item-value":"id","item-text":"name","error-messages":errors,"label":_vm.$_t('Contact Type'),"required":""},model:{value:(_vm.contactDataCopyLocal.typeId),callback:function ($$v) {_vm.$set(_vm.contactDataCopyLocal, "typeId", $$v)},expression:"contactDataCopyLocal.typeId"}})]}}],null,true)}),_c('v-col',{attrs:{"cols":"12"}},[_c('div',{staticClass:"float-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"error"},on:{"click":_vm.cancel}},[_vm._v(" "+_vm._s(_vm.$_t('Cancel'))+" ")]),_c('v-btn',{staticClass:"float-right",attrs:{"type":"submit","color":"primary","disabled":invalid}},[_vm._v(" "+_vm._s(_vm.$_t('Save'))+" ")])],1)])],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <div id="app">
    <v-app class="app-bg" :style="style">
      <div v-if="!initializing">
        <router-view></router-view>
        <div v-if="config.languageSwitchEnabled">
          <v-btn @click="setLocale('pl')" color="primary" text>PL</v-btn>
          <v-btn @click="setLocale('en')" color="primary" text>EN</v-btn>
          <v-btn @click="setLocale('hu')" color="primary" text>HU</v-btn>
        </div>
      </div>
      <div v-else-if="loadingMessage && loadingMessage.status && (config.loadingMessagesEnabled || loadingMessage.status === 'ERROR')">
        <Loading :message="loadingMessage" />
      </div>
      <div v-else-if="loadingMessage && loadingMessage.status && !config.loadingMessagesEnabled">
        <Loading :message="{message: '', status: 'LOADING'}" />
      </div>
      <CoreNotification />
    </v-app>
  </div>
</template>

<script>
import CoreNotification from '@components/core/Notification.vue'
import { AppMode } from '@src/enum/App'
import auth from '@src/helpers/auth'
import Loading from '@components/core/_Loading.vue'
import config from '@config'
import useCommonHelpers from './helpers/useCommonHelpers';
import { PractitionerRoles } from './enum/PractitionerRoles'
import {gridColumns } from './data/dataset/gridHeaders/deafultColumnsGrid'
export default {
  components: {
    CoreNotification, Loading
  },
  computed: {
    style () {
      return '';
      //return process.env.VUE_APP_MODE === AppMode.patient ? 'background-image: url(\'/bg.jpg\');background-repeat: no-repeat;background-attachment: fixed;background-position: center; background-size: cover' : ''
    }
  },
  data () {
    return {
      config: config,
      initializing: true,
      loadingMessage: {}
    }
  },
  mounted () {
   this.checkSettings()
  },
  methods: {
    setLocale(lang) {
      localStorage.setItem('locale', lang)
      window.location.reload()
    },
    checkSettings() {
      if (this.$store.getters['app/authTokenExchangeString']) {
        auth.exchangeCodeForToken(this.$store.getters['app/authTokenExchangeString']).then(data => {
          this.$store.dispatch('app/setAuthTokenExchangeString', '')
          auth.handleVerifiedTokens(data.authResponse.access_token, data.authResponse?.refresh_token)
          this.init()
        }).catch(error => {
          auth.redirectToAuth()
        })
      } else {
        this.init()
      }
    },
    init () {
      this.$router.onReady(() => {
        if (!auth.isPublicRoute(this.$route.name)) {
          this.verifyToken()
        } else {
          this.initializing = false
        }
      })
    },
    verifyToken () {
      this.loadingMessage = { status: 'LOADING', message: this.$_t('Verifying session, please wait...') }

      // check token and validity
      if (!auth.verifySavedToken()) {
        this.loadingMessage = {
          status: 'ERROR',
          message: this.$_t('Redirecting to authentication...')
        }
        auth.redirectToAuth()
      }

      this.getUser().then((user) => {
        this.initializing = false
        this.loadingMessage = {}
      })
    },
    getUser () {
      return new Promise((resolve, reject) => {
        this.loadingMessage = { status: 'LOADING', message: this.$_t('Loading user data...') }
        this.$_rest.get('/current-user', undefined, response => {
          this.$store.dispatch('app/setOrganization', response.data.organization)
          this.$store.dispatch('app/setCurrentUser', response.data)
          //TODO: temporary , later needs check if columns from org config
          for (const property in gridColumns) {
            //console.log(`${property}: ${gridColumns[property]}`);
            if(typeof config['defaultColumnsGrid_'+property] !== 'undefined' && config['defaultColumnsGrid_'+property].length) {
              gridColumns[property] = config['defaultColumnsGrid_'+property]
            }
          }
          this.$store.dispatch('app/setColumns',gridColumns)
          let route, callback
          switch (response.data.authType) {
            case 'practitioner':
              this.$store.commit('app/setPractitionerPracticesLoading', true)
              route = `/practitioners/${response.data.id}?groups=${['all','practitioner_practices','practitioner_practice_practice'].join(',')}`
              callback = (data) => {
                this.$store.commit('app/setPractitionerPracticesLoading', false)
                return new Promise ((resolve, reject) => {
                  if (!data.data.practitionerPractices || !data.data.practitionerPractices.length) {
                    this.loadingMessage = { status: 'ERROR', message: this.$_t('Unable to load practice list') }
                    return reject()
                  }
                  this.$store.dispatch('app/setPractitioner', data.data)
                  this.$store.dispatch('app/setPractitionerPractices', data.data.practitionerPractices)
                  this.resolvePractitionerPractice()
                  // roles- temporary switch off Greg
                  // this.fetchRoles(response.data.id,response.data.authType).then((roles)=>{
                  //   this.$store.dispatch('app/setRoles', roles.data)
                  //   if(!roles.data) {
                  //     // do if empty You do not have permissions to access this application.
                  //     this.loadingMessage = { status: 'ERROR', message: this.$_t('You do not have permissions to access this application.') }
                  //   } else {
                  //     if (['', '/'].includes(this.$route.path) ||
                  //       (
                  //         (this.$route.meta.accessRoles?.length && this.isGrantedAny(this.$route.meta.accessRoles)) ||
                  //       this.isGrantedAny(PractitionerRoles.superAdmin)
                  //       )
                  //     ) {
                  //       // move on only if has permissions to access route
                  //         resolve()
                  //     } else {
                  //       this.loadingMessage = { status: 'ERROR', message: this.$_t('You do not have permissions to access this view. You will now be redirected...') }

                  //       setTimeout(() => {

                  //         auth.logout()
                  //         this.$router.push('/')
                  //       }, 5000)
                  //     }
                  //   }
                  // })
                  // .catch(error => {
                  //   this.initializing = true
                  //   this.loadingMessage = { status: 'ERROR', message: this.$_t('Permissions details could not be retrieved. Please contact your system administrator.') }
                  //   reject()
                  //   auth.logout()
                  // })

                  resolve()
                })
              }
              break
            case 'patient':
              route = `/patients/${response.data.id}`
              callback = (data) => {
                return new Promise ((resolve, reject) => {
                  this.$store.dispatch('app/setPatient', data)
                  resolve()
                })
              }
              break
            case 'user':
              route = `/users/${response.data.id}`
              callback = (data) => {
                return new Promise ((resolve, reject) => {
                  this.$store.dispatch('app/setUser', data)
                  resolve()
                })
              }
              break
          }
          this.loadingMessage = { status: 'LOADING', message: this.$_t('Loading user details...') }
          this.$_rest.get(route, undefined, user => {
            if (callback && typeof callback === 'function') {
              callback(user).then(() => {

                  resolve()


              }).catch(error => reject(error))
            }
          }, error => {
            this.loadingMessage = { status: 'ERROR', message: this.$_t('Unable to load user details') }
            reject()
          })
        }, error => {
          if (error.message === 'Unauthorized') {
            this.loadingMessage = {
              status: 'ERROR',
              message: this.$_t('User session expired, redirecting to authentication...')
            }
          } else {
            this.loadingMessage = {
              status: 'ERROR',
              message: this.$_t('Could not load user data. Please contact the appointment centre'),
              link: config.appointmentCentreLink
            }
          }
          reject()
        }, {
          prefixRoutesWithOrganizationId: false
        })
      })
    },

    fetchRoles(id,authType){
      this.loadingMessage = { status: 'LOADING', message: this.$_t('Loading permissions...') }
      return new Promise((resolve, reject) => {
        const apiEndpoint = `/${authType}s/${id}/roles/ris-vue`
         this.$_rest.get(apiEndpoint, undefined, response => {
          resolve(response)
          }, error => {
            this.initializing = true
            this.loadingMessage = { status: 'ERROR', message: this.$_t('Permissions details could not be retrieved. Please contact your system administrator.') }
            reject(error)
          })
      })
    },
    resolvePractitionerPractice () {
      let practiceToSet = null
      if (!this.$store.getters['app/practitionerPractice']) {
        if (localStorage.getItem('currentPractitionerPractice_' + this.$store.getters['app/currentUser'].id)) {
          try {
            practiceToSet = JSON.parse(
              localStorage.getItem('currentPractitionerPractice_' + this.$store.getters['app/currentUser'].id)
            )
          } catch (e) {
            console.error(e)
          }
        }
        if (Array.isArray(this.$store.getters['app/practitionerPractices']) && this.$store.getters['app/practitionerPractices'].length === 1) {
          practiceToSet = this.$store.getters['app/practitionerPractices'][0]
        }
      }
      if (practiceToSet && this.$store.getters['app/practitionerPractices'].find(practice => practice.id === practiceToSet.id)) {
        this.$store.dispatch('app/setPractitionerPractice', practiceToSet)
      } else {
        this.$store.dispatch('app/setPractitionerPractice', null)
      }
    },
    isGrantedAny (roles) {
    const currentRoles = this.$store.getters['app/roles']
    if (Array.isArray(roles)) {
      return currentRoles.some(el => roles.includes(el))
    } else {
      return currentRoles.includes(roles)
    }
  }
  },
  page: {
    // All subcomponent titles will be injected into this template.
    titleTemplate (title) {
      return process.env.VUE_APP_TITLE
    }
  }
}
</script>

<style lang="sass">
  @import '../node_modules/typeface-roboto/index.css'
</style>

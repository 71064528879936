<template>
  <practitioner-layout v-if="render">
    <router-view></router-view>
    <v-container fluid>
      <grid
        :columns="columns()"
        :data="itemsFiltered"
        :headers-preset-type="headersPresetType"
        :headers-preset="headersPreset"
        :loading="loading"
        filter-by-column-name="alertStatusCode"
        :class="loading ? 'alert-grid-grey-out' : null"
        :filter-name="$_t('Alert status')"
        :sort-by="defaultSort"
        :sort-desc="defaultDesc"
        :disable-sort="disableSort"
        :search-value.sync="search"
        :show-grid-settings="showGridSettings"
        :multi-sort="multiSort"
        :alert-statuses="alertStatuses"
        @rowAdd="rowAdd"
        @refresh="getAlerts"
        @rowClick="rowClicked"
      ></grid>
    </v-container>
  </practitioner-layout>
</template>

<script>
import Grid from '@components/common/Grid.vue'
import PractitionerLayout from '@router/layouts/practitioner.vue'
import { mapGetters } from 'vuex'
import alertsHeadersPreset from '@src/data/dataset/gridHeaders/alerts';

export default {
  name: 'AlertGrid',
  components: { Grid, PractitionerLayout },
  props: {
    disableSort: {
      type: Boolean,
      default: false
    },
    showGridSettings: {
      type: Boolean,
      default: true
    },
    multiSort: {
      type: Boolean,
      default: false
    },
  },
  data () {
    return {
      activeRow: null,
      render:true,
      buttonLoading:false,
      headersPresetType:'alerts',
      headersPreset: alertsHeadersPreset,
      search: '',
      defaultSort: [],
      defaultDesc: [],
      alertStatuses: [],
      loadingAlerts: false,
      loadingAlertStatuses: false,
      alertsLoadedCallback: null
    }
  },
  computed: {
    ...mapGetters('alerts', {items: 'data' }),
    ...mapGetters({
      alertColumns: 'app/columns',
    }),
    loading () {
      return this.loadingAlerts || this.loadingAlertStatuses
    },
    itemsFiltered() {
      return !this.items.length ? [] : this.filterItems()
    },
    refreshed() {
      return this.$store.getters['app/getRefreshed']
    }
  },
  methods: {
    columns() {
      return this.alertColumns(this.headersPresetType)
    },
    filterItems(){
      const newItems = [...this.items].sort((a, b) => {
        return a.createdDatetime <= b.createdDatetime
      })
      this.$store.commit('alerts/setData',newItems)
      return newItems

    },
    getAlertStatuses(){
      this.loadingAlertStatuses = true
      this.$_rest.get(`practitioners/${this.$store.getters['app/currentUserId']}/alert-statuses`, {},
      response => {
        this.alertStatuses = response.data.filter(status => status.isActive)
        this.loadingAlertStatuses = false
      }, error => {
          this.$_notify.error(this.$_t('Failed to fetch alert statuses'))
          this.loadingAlertStatuses = false
      })
    },
    getAlerts () {
      this.loadingAlerts = true
      this.$store.dispatch('alerts/getAlerts').finally(() => {
        this.loadingAlerts = false
        if (typeof this.alertsLoadedCallback === 'function') {
          this.alertsLoadedCallback()
          this.alertsLoadedCallback = null
        }
      })
    },
    getData() {
      this.getAlerts()
      this.getAlertStatuses()
    },
    rowAdd () {},
    rowClicked (row) {
      const action = () => {
        this.activeRow = row
        this.$router.push({ name: 'alert-details', params: { id: row.alertId } })
        // this.alertRightDrawer = true
      }
      if (this.loadingAlerts) {
        this.alertsLoadedCallback = action
      } else {
        action()
      }
    },

    processRefreshAlerts(objectToRefresh){
      this.activeRow = {...this.activeRow,...objectToRefresh}
      this.items.forEach((data,index)=>{
        if(data.alertId === this.activeRow.alertId) {
          this.items[index] = this.activeRow
        }
      })
      this.$store.commit('app/setRefreshed',true)


    }
  },
  watch:{
    refreshed(val){
       if(val) {
        this.render = false
        this.$nextTick(()=>{
          this.render = true
          this.$store.commit('app/setRefreshed',false)
        })
      }
    }

  },
  mounted(){
    this.getData()
    this.defaultSort = ['createdDatetime']
    this.defaultDesc = [false]

    this.$root.$on('refresh-alerts-grid',()=>{
      this.getAlerts()
    })
  }
}
</script>

<style scoped>
>>>.alert-grid-grey-out table tbody{
  opacity: 0.5;
}
</style>

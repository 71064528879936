export default [
  {
    value: 'appointmentIdShort',
    text: 'Appt. ID',
    align: 'left',
    width: 110,
    visible:true
  },
  {
    value: 'appointmentDateCreated',
    text: 'Created Date',
    align: 'left',
    visible: true,
    width: 150
  },
  {
    value: 'appointmentStatusNameStyled',
    text: 'Appt. Status',
    align: 'left',
    visible: true,
    width: 220,
    sortable: true
  },
  {
    value: 'appointmentPriorityNameStyled',
    text: 'Priority',
    align: 'left',
    visible: true,
    width: 130
  },
  {
    value: 'clinicNameShort',
    text: 'Site',
    align: 'left',
    visible: true,
    width: 200
  },
  {
    value: 'appointmentDate',
    text: 'Appt. Date',
    align: 'left',
    visible: true,
    width: 100
  },
  {
    value: 'procedureName',
    text: 'Procedure',
    align: 'left',
    visible: true,
    width: 220
  },
  {
    value: 'startTime',
    text: 'Start Time',
    align: 'left',
    visible: true,
    width: 100
  },
  {
    value: 'duration',
    text: 'Duration',
    align: 'left',
    visible: true
  },
  {
    value: 'appointmentCategoryNameStyled',
    text: 'Patient Group',
    align: 'left',
    width: 150,
    visible: true
  },
  {
    value: 'appointmentStatusColorCode',
    text: 'Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusColorCode',
    text: 'Document Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentCategoryColorCode',
    text: 'Category Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentPriorityColorCode',
    text: 'Priority Name Styled',
    align: 'left',
    visible:false
  },
  {
    value:'practitionerFullName',
    text: 'Referrer',
    width:180,
    align:'left',
    visible: false
  },
  {
    value:'secondaryPractitionerFullName',
    text:'Secondary Referrer',
    width: 200,
    align:'left',
    visible: false
  },
  {
    value:'appointmentSpecialityName',
    text:'Speciality',
    align:'left',
    visible: false,
    width: 120
  },
  {
    value:'patientEpisodeConsultingPractitionerFullName',
    text:'Episode Responsible',
    align:'left',
    visible: false,
    width: 200
  },
  {
    value: 'cancelReason',
    text: 'Cancel Reason',
    align: 'left',
    visible: false,
    width: 250
  }
]

export default [
  {
    value: 'appointmentId',
    text: 'Appt. ID',
    align: 'left',
    visible: true,
    width: 140,
    sortable: true
  },
  {
    value: 'patientIdShort',
    text: 'Patient ID',
    align: 'left',
    visible: true,
    width: 130
  },
  {
    value: 'organizationId',
    text: 'Organization Id',
    align: 'left',
    visible:false
  },
  {
    value: 'clinicNameShort',
    text: 'Site',
    align: 'left',
    visible: true,
    width: 150
  },
  {
    value: 'appointmentDate',
    text: 'Appt. Date',
    align: 'left',
    visible: true,
    width: 100
  },
  {
    value: 'appointmentDateCreated',
    text: 'Created Date',
    align: 'left',
    visible: true,
    width: 150
  },
  {
    value: 'appointmentSpecialRequestSummary',
    text: 'Special Request',
    align: 'left',
    visible:false
  },
  {
    value: 'procedureName',
    text: 'Procedure',
    align: 'left',
    visible: true,
    width: 200
  },
  {
    value: 'insurerName',
    text: 'Insurer Name',
    align: 'left',
    visible: false
  },
  {
    value: 'clinicScanRoomCode',
    text: 'Scan Room Code',
    align: 'left',
    visible:false
  },
  {
    value: 'duration',
    text: 'Duration',
    align: 'left',
    visible:false
  },
  {
    value: 'startTime',
    text: 'Start Time',
    align: 'left',
    visible: true,
    width: 100
  },
  {
    value: 'appointmentStatusName',
    text: 'Appt. Status',
    align: 'left',
    visible: true,
    width: 220,
    sortable: true
  },
  {
    value: 'appointmentDocumentStatusName',
    text: 'Document Status Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentSourceName',
    text: 'Source',
    width: 160,
    align: 'left',
    visible:true

  },
  {
    value: 'appointmentProtocollingStatusName',
    text: 'Protocolling Status Name',
    align: 'left',
    visible:false

  },
  {
    value: 'appointmentCategoryName',
    text: 'Patient Group',
    align: 'left',
    width: 150,
    visible: true
  },
  {
    value: 'appointmentPriorityName',
    text: 'Priority',
    align: 'left',
    visible: true,
    width: 130
  },
  {
    value: 'appointmentStatusColorCode',
    text: 'Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentDocumentStatusColorCode',
    text: 'Document Status Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentSourceColorCode',
    text: 'Source Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentProtocollingStatusColorCode',
    text: 'Protocolling StatusName Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentCategoryColorCode',
    text: 'Category Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'appointmentPriorityColorCode',
    text: 'Priority Name Styled',
    align: 'left',
    visible:false
  },
  {
    value: 'patientDateOfBirth',
    text: 'Date Of Birth',
    align: 'left',
    visible: true,
    width: 120
  },
  {
    value: 'patientNumber1',
    text: 'NHS No.',
    align: 'left',
    visible: true,
    width: 120
  },
  {
    value: 'patientFullName',
    text: 'Patient Name',
    align: 'left',
    visible: true,
    width: 160
  },
  {
    value: 'patientIdExternal',
    width: '100px',
    text: 'BHR No.',
    align: 'left',
    visible:true
  },
  {
    value:'practitionerFullName',
    text: 'Referrer',
    width:180,
    align:'left',
    visible:true
  },
  {
    value:'secondaryPractitionerFullName',
    text:'Secondary Referrer',
    width: 200,
    align:'left',
    visible:true
  },
  {
    value:'appointmentSpecialityName',
    text:'Speciality',
    align:'left',
    visible:true,
    width: 120
  },
  {
    value:'patientEpisodeConsultingPractitionerFullName',
    text:'Episode Responsible',
    align:'left',
    visible:true,
    width: 200
  },
  {
    value: 'cancelReason',
    text: 'Cancel Reason',
    align: 'left',
    visible: true,
    width: 250
  }
];

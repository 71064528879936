var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"color":"#fff","width":"80vw"}},[_c('v-card-title',[_vm._v(_vm._s(_vm.$_t('New patient'))),_c('v-spacer'),(!_vm.valid)?_c('v-alert',{attrs:{"color":"red","type":"error","dense":""}},[_vm._v(" "+_vm._s(_vm.$_t('Not all mandatory fields have been completed'))+" ")]):_vm._e()],1),_c('v-divider'),_c('v-card-text',{staticStyle:{"height":"80vh"}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","disabled":_vm.loading},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-4",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Personal details')))]),_c('div',{staticClass:"divider-line"})])],1),_c('v-text-field',{staticClass:"my-0",attrs:{"rules":_vm.nameRules,"label":_vm.$_t('First name') + ' *',"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('name')},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('v-text-field',{staticClass:"my-0",attrs:{"rules":_vm.surnameRules,"label":_vm.$_t('Last name') + ' *',"outlined":"","color":"primary","disabled":_vm.disabledInputs.includes('surname')},model:{value:(_vm.surname),callback:function ($$v) {_vm.surname=$$v},expression:"surname"}}),(false)?_c('v-text-field',{staticClass:"my-0",attrs:{"rules":_vm.patientNumberRules,"label":_vm.$_t('NHS number'),"outlined":"","color":"primary","validate-on-blur":""},model:{value:(_vm.patientNumber),callback:function ($$v) {_vm.patientNumber=$$v},expression:"patientNumber"}}):_vm._e(),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","min-width":"auto","disabled":_vm.disabledDoB},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":_vm.$_t('Date of birth') + ' *',"readonly":"","rules":_vm.birthdayRules,"outlined":"","disabled":_vm.loading,"validate-on-blur":""},model:{value:(_vm.birthdayFormatted),callback:function ($$v) {_vm.birthdayFormatted=$$v},expression:"birthdayFormatted"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.birthdayMenu),callback:function ($$v) {_vm.birthdayMenu=$$v},expression:"birthdayMenu"}},[_c('v-date-picker',{ref:"birthdayPicker",attrs:{"max":new Date().toISOString().substr(0, 10),"min":"1900-01-01","locale":_vm.getLocale(),"first-day-of-week":1},on:{"change":function($event){return _vm.setBirthday()}},model:{value:(_vm.birthday),callback:function ($$v) {_vm.birthday=$$v},expression:"birthday"}})],1),_c('v-select',{staticClass:"my-0",attrs:{"items":_vm.sexList,"label":_vm.$_t('Sex'),"outlined":"","color":"primary"},model:{value:(_vm.sex),callback:function ($$v) {_vm.sex=$$v},expression:"sex"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-5 pb-4",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Address')))]),_c('div',{staticClass:"divider-line"})])],1),_c('v-text-field',{staticClass:"my-0",attrs:{"label":_vm.$_t('Address line 1'),"rules":[
              function (v) { return !v ||
                (v && v.length <= 100) ||
                _vm.$_t('Address line 1') +
                  ' ' +
                  _vm.$_t('must be less than or equal to') +
                  ' 100 ' +
                  _vm.$_t('characters'); }
            ],"outlined":"","color":"primary","disabled":_vm.loading},model:{value:(_vm.streetName),callback:function ($$v) {_vm.streetName=$$v},expression:"streetName"}}),_c('v-text-field',{staticClass:"my-0",attrs:{"label":_vm.$_t('Address line 2'),"rules":[
              function (v) { return !v ||
                (v && v.length <= 20) ||
                _vm.$_t('Street number') +
                  ' ' +
                  _vm.$_t('must be less than or equal to') +
                  ' 20 ' +
                  _vm.$_t('characters'); }
            ],"outlined":"","color":"primary","disabled":_vm.loading},model:{value:(_vm.streetNumber),callback:function ($$v) {_vm.streetNumber=$$v},expression:"streetNumber"}}),_c('v-text-field',{staticClass:"my-0",attrs:{"label":_vm.$_t('City'),"rules":[
              function (v) { return !v ||
                (v && v.length <= 50) ||
                _vm.$_t('City') +
                  ' ' +
                  _vm.$_t('must be less than or equal to') +
                  ' 50 ' +
                  _vm.$_t('characters'); }
            ],"outlined":"","color":"primary","disabled":_vm.loading},model:{value:(_vm.town),callback:function ($$v) {_vm.town=$$v},expression:"town"}}),_c('v-text-field',{staticClass:"my-0",attrs:{"label":_vm.$_t('Post code'),"rules":[
              function (v) { return !v ||
                (v && v.length <= 7) ||
                _vm.$_t('Post code') +
                  ' ' +
                  _vm.$_t('must be less than or equal to') +
                  ' 7 ' +
                  _vm.$_t('characters'); }
            ],"outlined":"","color":"primary","disabled":_vm.loading},model:{value:(_vm.zipCode),callback:function ($$v) {_vm.zipCode=$$v},expression:"zipCode"}})],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-4",attrs:{"cols":"12"}},[_c('span',{staticClass:"subtitle-1 grey--text text--darken-2"},[_vm._v(_vm._s(_vm.$_t('Contact details')))]),_c('div',{staticClass:"divider-line"})])],1),_c('vue-tel-input',{staticStyle:{"color":"#000","line-height":"2.3em"},style:({
                'border-color': (_vm.mobileValid ? 'rgba(0,0,0,.42)' : '#ff5252'),
                'border-width': (_vm.mobileValid ? '1px' : '2px'),
              }),attrs:{"inputClasses":"phone-margin-fix v-input__slot","inputOptions":{placeholder: _vm.$_t('Mobile number') + ' *'},"name":"mobileNumber","mode":"international","disabled":_vm.loading,"defaultCountry":"GB"},on:{"validate":_vm.onValidateMobile},scopedSlots:_vm._u([{key:"arrow-icon",fn:function(){return [_c('span',[_vm._v(_vm._s('▼'))])]},proxy:true}]),model:{value:(_vm.phoneNumber),callback:function ($$v) {_vm.phoneNumber=$$v},expression:"phoneNumber"}}),_c('v-text-field',{staticClass:"mt-8",attrs:{"label":_vm.agreementEmailCheckbox
                ? _vm.$_t('Email address') + ' *'
                : _vm.$_t('Email address (optional)'),"rules":_vm.emailRules,"outlined":"","color":"primary","disabled":_vm.loading,"validate-on-blur":""},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary darken-1","text":"","disabled":_vm.loading},on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" "+_vm._s(_vm.$_t('Cancel'))+" ")]),_c('v-btn',{attrs:{"color":"primary darken-1","text":"","loading":_vm.loading},on:{"click":_vm.createPatientTemporary}},[_vm._v(" "+_vm._s(_vm.$_t('Create'))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
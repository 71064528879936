<template>
  <v-container
    v-if="render"
    ref="formTop"
    fluid
    class="pa-0 ma-0"
  >
    <v-row>
      <v-col
        v-if="!hideHeader"
        cols="12"
        sm="12"
        class="text-h6"
      >
        {{ form.title }}<span class="subtitle-1" v-if="subtitle">&nbsp;({{ subtitle }})</span>
      </v-col>
      <v-col
        v-if="!hideHeader"
        cols="12"
        sm="12"
        class="subtitle-1"
      >
        {{ form.description }}
      </v-col>
      <v-col
        cols="12"
        sm="12"
        class="mx-0 px-0"
      >
        <v-card
          v-for="(sections, indexsection) in form.sections"
          :key="indexsection"
          color="rgba(255,255,255,0)"
          class="pa-md-1 mt-md-1"
        >
          <v-card-title
            class="text-break text-subtitle-1 font-weight-medium pa-1 ma-0"
          >
            {{ sections.title }}
          </v-card-title>
          <v-card-subtitle
            class="text-break text-subtitle-2 pa-1 ma-0"
          >
            {{ sections.description }}
          </v-card-subtitle>
          <v-card-text
            class="pa-1 ma-0"
          >
            <div
              v-for="(question, indexquestion) in sections.questions"
              v-show="typeof question.visible === 'undefined' || question.visible === true"
              :key="indexquestion"
              :ref="`question-${question.id}`"
              class="ma-0"
            >
              <div class="text-subtitle-1 font-weight-medium text-break">
                  {{ question.title }}
                <span class="text-h5 red--text">
                  {{ question.required ? '*' : '' }}
                </span>
              </div>
              <div class="text-subtitle-1 text-break">
                    {{ question.description }}
              </div>
              <div class="mb-1">
                <div v-if="question.type === 'DATE'">
                  <h6 class="text-subtitle-1">
                    {{ question.name }}
                  </h6>
                  <v-menu
                    v-model="question.picker"
                    :close-on-content-click="true"
                    transition="scale-transition"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="question.answer"
                        :label="$_t('testQuestions.select_date')"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                        clearable
                      />
                    </template>
                    <v-date-picker
                      v-model="question.answer"
                      @change="
                          changeDate($event, question)
                        "
                    />
                  </v-menu>
                </div>
                <div v-if="question.type === 'TEXT'">
                  <v-textarea
                    v-model="question.answer"
                    :label="$_t('safetyQuestions.your_answer')"
                    :rules="[value => (!question.required || !!value) || 'Required.']"
                    outlined
                    rows="1"
                    auto-grow
                    @change="changeText($event, question)"
                  />
                </div>
                <div v-if="question.type === 'SELECT_SINGLE'">
                  <v-btn-toggle
                    v-model="question.answer"
                    @change="
                        selectSingle($event, question)
                      "
                    :style="{display: question.answers.length > 2 ? 'block' : 'flex'}"
                  >
                    <div
                      v-for="(answer) in question.answers"
                      :key="answer.id"
                    >
                      <v-btn
                        :value="answer.title"
                        color="primary"
                        outlined
                        :style="{minWidth: question.answers.length > 2 ? '250px' : '10px'}"
                      >
                        {{ answer.title }}
                      </v-btn>
                    </div>
                  </v-btn-toggle>
                </div>
                <div v-if="question.type === 'SELECT_MULTIPLE'">
                  <v-btn-toggle
                    v-model="question.answer"
                    shaped
                    max="200"
                    multiple
                    style="display: block;"
                    @change="
                        selectMulti($event, question)
                      "
                  >
                    <div
                        v-for="(answer, index) in question.answers"
                        :key="index"
                        style="display: block"
                    >
                      <v-btn
                          :value="answer.title"
                          color="primary"
                          outlined
                          class="mr-1"
                          style="min-width: 250px;"
                      >
                        {{ answer.title }}
                      </v-btn>
                    </div>
                  </v-btn-toggle>
                </div>
                <div v-if="question.type === 'SIGNATURE'">
                  <div class="text-subtitle-2">
                    Please use your mouse, touchpad or touchscreen of your device to draw your signature in the box below.
                  </div>
                  <div style="border: 1px solid black">
                    <VueSignaturePad
                      :ref="`signature-${question.id}`"
                      height="150px"
                      :options="{
                        onEnd: () => { saveSignatureAnswer(question) },
                        onBegin: () => { $refs[`signature-${question.id}`][0].resizeCanvas() }
                      }"
                    />
                  </div>
                  <v-btn @click="undoSignature(question)">
                    {{ $_t('testQuestions.undo') }}
                  </v-btn>
                </div>
              </div>
              <v-divider />
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" v-if="!hideCompleteButton">
        <div class="text-right">
          <v-spacer />
          <v-btn
            color="primary"
            class="mr-1"
            min-width="120px"
            :loading="loading"
            @click="saveForm"
          >
            {{ $_t('Complete') }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'AppointmentForms',
  props: {
    form: Object,
    saveRoute: String,
    complete: Boolean,
    categoryCode: String,
    subtitle: String,
    hideHeader: {
      type: Boolean,
      default: false
    },
    showComplete: {
      type: Boolean,
      default: false
    },
    hideCompleteButton: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rules: [value => !!value || 'Required.'],
      formEdited: true,
      loading: false,
      render: true,
    }
  },
  computed: {
    ...mapGetters('appointment', { appointment: 'appointment' }),
  },
  methods: {
    getUnanswered () {
      return this.form.sections.map(section => section.questions
        .map(question => (typeof question.visible === 'undefined' || question.visible) && question.required  && question))
        .flat(2)
        .filter(el => el && (!el.answer || (Array.isArray(el.answer) && !el.answer.length) || (!Array.isArray(el.answer) && !el.answer.trim())))
    },
    checkCompletion () {
      this.$emit('update:complete', !this.getUnanswered().length)
      if (!this.getUnanswered().length) {
        this.$emit('complete')
      }
    },
    validateForm () {
      const data = {}
      const alerts = []

      let missing = null
      this.form.sections.every(section => {
        if (missing) {
          return false
        }
        section.questions.every(question => {
          if ((typeof question.visible === 'undefined' || question.visible) && question.required && !question.answer) {
            missing = question
            return false
          }
          if (question.type === 'SELECT_MULTIPLE') {
            data[question.id] = (question.answer || []).join(', ')
          }
          else {
            data[question.id] = question.answer
          }
          if (question.alert !== undefined && Array.isArray(question.alert)) {
            if (question.alert.includes(question.answer) && question.alertTypeCode !== undefined) {
              if (question.alertTypeCode && !alerts.includes(question.alertTypeCode)) {
                alerts.push(question.alertTypeCode)
              }
            }
          }
          return true
        })
        return true
      })

      if (missing) {
        this.showUnansweredQuestions()
        this.$_notify.error('Please complete all the fields marked with *')
        throw new Error('Form incomplete')
      }

      return { data, alerts }
    },
    saveForm () {
      // TODO: ignore if form hasn't changed
      return new Promise((resolve, reject) => {
        let data = {}
        let alerts = []

        try {
          const form = this.validateForm()
          data = form.data
          alerts = form.alerts
        } catch (error) {
          return reject()
        }

        this.formEdited = false
        this.loading = true
        this.$_rest.post(this.saveRoute,
          {
            categoryCode: typeof this.form.categoryCode !== 'undefined' ? this.form.categoryCode : this.categoryCode,
            name: this.form.documentName,
            templateId: this.form.templateId,
            dataJson: {
              templateId: this.form.rowId || this.form.templateId,
              data: data
            },
            alerts: alerts
          },
          response => {
            this.$emit('saved')
            this.loading = false
            resolve()
          },
          error => {
            this.$_notify.error(error)
            this.loading = false
            reject(error)
          }
        )
        return true
      })
    },
    showUnansweredQuestions () {
      // this.$nextTick(() => {
      //   this.$refs[`question-${this.getUnanswered()[0]?.id}`].scrollTop = 0
      // })
      if (typeof this.$vuetify !== 'undefined') {
        this.$vuetify.goTo(this.$refs[`question-${this.getUnanswered()[0]?.id}`][0])
      }
    },
    saveAlert () {
      this.$_rest.put(
        `/appointments/${this.appointment.id}/custom`,
        {
          custom8: 'Alert: ' + this.form.alertMessage
        }, undefined,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        response => {
        },
        error => {
          this.$_notify.error(error)
        },
        { prefixRoutesWithPatientId: true }
      )
    },
    getSelectedAnswers (question) {
      return (question.answers || []).filter(answer => {
        if (question.type === 'SELECT_SINGLE') {
          return question.answer === answer.title
        } else if (question.type === 'SELECT_MULTIPLE') {
          return question.answer.includes(answer.title)
        }
      })
    },
    getNotSelectedAnswers (question) {
      return (question.answers || []).filter(answer => {
        if (question.type === 'SELECT_SINGLE') {
          return question.answer !== answer.title
        } else if (question.type === 'SELECT_MULTIPLE') {
          return !question.answer.includes(answer.title)
        }
      })
    },
    handleEvents (event, question) {
      this.formEdited = true
      if (typeof question.alert === 'object') {
        if (question.alert.includes(question.answer)) {
          this.saveAlert()
        }
      }
      // perform opposite events for unselected answers
      this.getNotSelectedAnswers(question).forEach(answer => {
        (answer.events || []).forEach(event => {
          event = {...event}
          event.type = this.getOppositeEventType(event.type);
          (event.targets || []).forEach(targetQuestionId => {
            this.executeEvent(question, this.getQuestionByReference(targetQuestionId), event)
          })
        })
      })
      // perform events
      this.getSelectedAnswers(question).forEach(answer => {
        (answer.events || []).forEach(event => {
          (event.targets || []).forEach(targetQuestionId => {
            this.executeEvent(question, this.getQuestionByReference(targetQuestionId), event)
          })
        })
      })
      this.checkCompletion()
      this.$emit('change')
    },
    executeEvent (sourceQuestion, targetQuestion, event) {
      switch (event.type) {
        case 'show':
          this.render = false
          targetQuestion.visible = true
          this.render = true
          break
        case 'hide':
          this.render = false
          targetQuestion.visible = false
          this.render = true
          break
        default:
          throw new Error('Unsupported event type: ' + event.type)
      }
    },
    getOppositeEventType (eventType) {
      switch (eventType) {
        case 'show':
          return 'hide'
        case 'hide':
          return 'show'
        default:
          throw new Error('Unsupported event type: ' + eventType)
      }
    },
    changeText (event, question) {
      this.handleEvents(event, question)
    },
    selectSingle (event, question) {
      this.handleEvents(event, question)
    },
    selectMulti (event, question) {
      this.handleEvents(event, question)
    },
    changeDate (event, question) {
      this.handleEvents(event, question)
    },
    signatureEvent (event, question) {
      this.handleEvents(event, question)
    },
    undoSignature (question) {
      this.$refs[`signature-${question.id}`][0].undoSignature()
      const { isEmpty, data } = this.$refs[`signature-${question.id}`][0].saveSignature()
      this.formEdited = true
      question.answer = isEmpty ? '' : data
      this.checkCompletion()
    },
    saveSignatureAnswer (question) {
      const { isEmpty, data } = this.$refs[`signature-${question.id}`][0].saveSignature()
      if (!isEmpty) {
        this.formEdited = true
        question.answer = data
      }
      else {
        question.answer = ''
      }
      this.checkCompletion()
    },
    getQuestionByReference (reference) {
      let question = null
      this.form.sections.some(section => {
        question = section.questions.find(q => q.id === reference)
        if (question) {
          return true
        }
      })
      return question
    }
  },
  mounted () {
    // this.init()
  },
  watch: {
    loading (value) {
      this.$emit('update:loading', value)
    },
    form: {
      handler: function (form) {
        this.$emit('update:form', form)
      },
      deep: true
    }
  }
}
</script>
